import styled from "styled-components";
import { Button as AntButton } from "antd";
import { ButtonProps } from "antd/lib/button";
import {colorMap} from "../styled/UXSettings";

interface BtnProps extends ButtonProps {
    category?: string;
    theme?: string;
    pressed?: boolean;
    mode?: "main" | "sub"
}

export const Header = styled(AntButton)<BtnProps>`
    &.ant-btn {
        display: inline-block;
        outline: none;
        box-sizing: border-box;
        text-align: center;
        border: none;
        font-size: ${p => p.mode==="sub" ? "18px" : "22px"};
        height: 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        color: #686868;
        width: 223px;
        background-color: ${p => colorMap.backgroundColor[p.theme]};
        &:hover {
            ${p => !p.disabled && !p.pressed && `
                color: ${p.mode === "sub" ? colorMap.data[p.theme] : colorMap.primaryHover[p.theme]};
            `}
            ${p => p.disabled && `
                color: ${p.mode === "sub" ? colorMap.secondaryOneDisabled[p.theme] : colorMap.primaryDisabled[p.theme]};
                background-color: ${colorMap.backgroundColor[p.theme]};
                cursor: not-allowed;
            `}
            
        }
        &:hover > .anticon-down{
            ${p => !p.disabled && !p.pressed && `
                color: ${colorMap.data[p.theme]};
            `}
        }
        .anticon-down{
            color: ${p => colorMap.secondaryOneHover[p.theme]};
            vertical-align: baseline;
        }
        
        &:active > .anticon-down{
            color: ${p => colorMap.data[p.theme]};
        }
        ${p => p.disabled && `
            color: ${p.mode === "sub" ? colorMap.secondaryOneDisabled[p.theme] : colorMap.primaryDisabled[p.theme]};
            background-color: ${colorMap.backgroundColor[p.theme]};

            & > .anticon-down{
                color: ${colorMap.secondaryOneDisabled[p.theme]};
            }
        
        `
        }
        ${p => p.pressed && `
            color: ${p.mode === "sub" ? colorMap.data[p.theme] : colorMap.primarySelected[p.theme]};
            background-color: ${colorMap.backgroundColor[p.theme]};

            & > .anticon-down{
                color: ${colorMap.data[p.theme]};
            }
        `}
    }
    &[ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}
`;
