import {
  call,
  retry,
  put,
  all,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import assign from 'lodash/assign';
import * as URL from '@src/utils/url';
import * as types from './types/types';
import * as actions from './actions/ruleEngine';
import * as api from '../service/api';
import {
  convertRuleConfigFromBackendFormatToUIFormat,
  convertRuleConfigFromUIFormatToBackendFormat,
} from '../utils/ruleEngine/index';
import { initPendo } from '../utils/pendo';
import store from '@src/store/index';
import {
  getSampleDataJobsV2,
  SAMPLE_DATA_JOBS_QUERY_KEY,
} from '@src/service/apis/ruleEngine/sampleData';
import {
  BP_PARAMS_KEY,
  RULE_VERSION_PARAMS_KEY,
  VERSION_1,
} from '@src/utils/constants';
import { queryClient } from '@src/App';

const MAX_TRIES = 1;
const RETRY_INTERVAL = 500; // 500ms

export function* pushLocation({ type, path }) {
  yield put(push(path));
}

function* watchPushLocation() {
  yield takeEvery(types.PUSH_LOCATION, pushLocation);
}

export function* getInputMetadataQuality({ type, params }) {
  try {
    let baseQueryParams = {
      customerId: params.customerId,
      startDate: params.startDate,
      endDate: params.endDate,
    };
    if (params.isAd) {
      baseQueryParams = assign(baseQueryParams, { isAd: params.isAd });
    }
    if (params.isLive) {
      baseQueryParams = assign(baseQueryParams, { isLive: params.isLive });
    }
    const selectedPlayers = params.selectedPlayers;
    const initialValue = {};
    const data = yield all(
      selectedPlayers.reduce((obj, playerName) => {
        let queryParams =
          playerName !== 'All Traffic'
            ? assign({ playerName }, baseQueryParams)
            : assign({}, baseQueryParams);
        return {
          ...obj,
          [playerName]: retry(
            MAX_TRIES,
            RETRY_INTERVAL,
            api.getInputMetadataQuality,
            queryParams,
          ),
        };
      }, initialValue),
    );
    yield put(actions.receiveInputMetadataQuality(data));
  } catch (e: any) {
    yield put(actions.receiveInputMetadataQuality({}));
    yield call(
      message.error,
      'Fail to load metadata report due to: ' + e.message,
    );
  }
}

function* watchGetInputMetadataQuality() {
  yield takeLatest(
    types.REQUEST_INPUT_METADATA_QUALITY,
    getInputMetadataQuality,
  );
}

export function* getPlayerList({ type, params }) {
  try {
    const data = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getPlayerList,
      params,
    );
    yield put(actions.receivePlayerList(data));
  } catch (e: any) {
    yield put(actions.receivePlayerList([]));
    yield call(message.error, 'Fail to load app list due to: ' + e.message);
  }
}

function* watchGetPlayerList() {
  yield takeLatest(types.REQUEST_PLAYER_LIST, getPlayerList);
}

export function* getTagDetails({ type, params }) {
  try {
    let queryParams = {
      customerId: params.customerId,
      startDate: params.startDate,
      endDate: params.endDate,
      key: params.key,
    };
    if (params.isAd) {
      queryParams = assign({ isAd: params.isAd }, queryParams);
    }
    if (params.isLive) {
      queryParams = assign({ isLive: params.isLive }, queryParams);
    }
    if (params.playerName !== 'All Traffic') {
      queryParams = assign({ playerName: params.playerName }, queryParams);
    }
    const data = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getTagDetails,
      queryParams,
    );
    yield put(
      actions.receiveTagDetails({
        ...data,
        key: params.key,
        playerName: params.playerName,
      }),
    );
  } catch (e: any) {
    yield put(
      actions.receiveTagDetails({
        key: params.key,
        playerName: params.playerName,
      }),
    );
    yield call(message.error, 'Fail to load tag values due to: ' + e.message);
  }
}

function* watchGetTagDetails() {
  yield takeEvery(types.REQUEST_TAG_DETAILS, getTagDetails);
}

export function* getCustomerMetadataStrategy({ type, params }) {
  try {
    const data = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getCustomerMetadataStrategy,
      params,
    );
    if (data && data.status === 200) {
      yield put(actions.receiveCustomerMetadataStrategy(data));
    } else {
      yield put(actions.receiveCustomerMetadataStrategy({}));
    }
  } catch (e: any) {
    yield put(actions.receiveCustomerMetadataStrategy({}));
    yield call(
      message.error,
      'Fail to load customer metadata strategy due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchGetCustomerMetadataStrategy() {
  yield takeLatest(
    types.REQUEST_CUSTOMER_METADATA_STRATEGY,
    getCustomerMetadataStrategy,
  );
}

function* getRuleEngineRequiredFields({ type, params }) {
  try {
    yield put(actions.startLoading('request-fields'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getRuleEngineFields,
      params,
    );
    yield put(actions.endLoading('request-fields'));
    if (res && res.code === 'SUCCESS') {
      yield put(actions.receiveRuleEngineRequiredFields(res.data));
    } else {
      yield put(actions.receiveRuleEngineRequiredFields([]));
    }
  } catch (e: any) {
    yield put(actions.endLoading('request-fields'));
    yield put(actions.receiveRuleEngineRequiredFields([]));
    yield call(
      message.error,
      'Fail to get required fields due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchGetRuleEngineRequiredFields() {
  yield takeLatest(
    types.REQUEST_RULE_ENGINE_REQUIRED_FIELDS,
    getRuleEngineRequiredFields,
  );
}

function* getRuleEngineSupportLetter() {
  try {
    yield put(actions.startLoading('support-letter'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getRuleEngineSupportLetter,
    );
    yield put(actions.endLoading('support-letter'));
    if (res && res.code === 'SUCCESS') {
      yield put(actions.receiveRuleEngineSupportLetter(res.data));
    } else {
      yield put(actions.receiveRuleEngineSupportLetter([]));
    }
  } catch (e: any) {
    yield put(actions.endLoading('support-letter'));
    yield put(actions.receiveRuleEngineSupportLetter([]));
    yield call(
      message.error,
      'Fail to get support letter due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchGetRuleEngineSupportLetter() {
  yield takeEvery(
    types.REQUEST_RULE_ENGINE_SUPPORT_LETTER,
    getRuleEngineSupportLetter,
  );
}

function* getRuleEngineOptions() {
  const options = {
    letter: [],
    ruleFieldMappingType: [],
  };
  try {
    yield put(actions.startLoading('get-all-options'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getRuleEngineOptions,
    );
    yield put(actions.endLoading('get-all-options'));
    if (res && res.code === 'SUCCESS') {
      res.data.forEach((group) => {
        switch (group.groupCode) {
          case types.GROUP_CODE_LETTER_CASING:
            options.letter = group.sysDictItems;
            break;
          case types.GROUP_CODE_RULE_FIELD_MAPPING_TYPE:
            options.ruleFieldMappingType = group.sysDictItems;
            break;
        }
      });
      yield put(actions.receiveRuleEngineOptions(options));
    } else {
      yield put(actions.receiveRuleEngineOptions(options));
    }
  } catch (e: any) {
    yield put(actions.endLoading('get-all-options'));
    yield put(actions.receiveRuleEngineOptions(options));
    yield call(
      message.error,
      'Fail to get all options due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchGetRuleEngineOptions() {
  yield takeEvery(types.REQUEST_RULE_ENGINE_OPTIONS, getRuleEngineOptions);
}

function* getTagKeyList() {
  try {
    yield put(actions.startLoading('get-tag-key'));
    const res = yield retry(MAX_TRIES, RETRY_INTERVAL, api.getTagKeyList);
    yield put(actions.endLoading('get-tag-key'));
    if (res && res.code === 'SUCCESS') {
      // const tags = res.data.map((item, index) => ({name: item, code: item}));
      yield put(actions.receiveTagKeyList(res.data));
    } else {
      yield put(actions.receiveTagKeyList([]));
    }
  } catch (e: any) {
    yield put(actions.endLoading('get-tag-key'));
    yield put(actions.receiveTagKeyList([]));
    yield call(
      message.error,
      'Fail to get tag key list due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchGetTagKeyList() {
  yield takeEvery(types.REQUEST_TAG_KEY_LIST, getTagKeyList);
}

function* getTagSampleData({ type, tagKey, pageNum = 1 }) {
  try {
    yield put(actions.startLoading('get-tag-sample'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getTagSampleData,
      tagKey,
      pageNum,
    );
    yield put(actions.endLoading('get-tag-sample'));
    if (res && res.code === 'SUCCESS') {
      yield put(actions.receiveTagSampleData(res.data));
    } else {
      yield put(actions.receiveTagSampleData([]));
    }
  } catch (e: any) {
    yield put(actions.endLoading('get-tag-sample'));
    yield put(actions.receiveTagSampleData([]));
    yield call(
      message.error,
      'Fail to get tag sample data due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchGetTagSampleData() {
  yield takeEvery(types.REQUEST_TAG_SAMPLE_DATA, getTagSampleData);
}

function* runRegexTest({ type, data }) {
  try {
    yield put(actions.startLoading('run-regex'));
    const res = yield retry(MAX_TRIES, RETRY_INTERVAL, api.runRegexTest, data);
    yield put(actions.endLoading('run-regex'));
    if (res && res.code === 'SUCCESS') {
      yield put(actions.receiveRegexTestResult(JSON.stringify(res.data)));
    } else {
      yield put(actions.receiveRegexTestResult(res.code + ': ' + res.message));
    }
  } catch (e: any) {
    yield put(actions.endLoading('run-regex'));
    yield put(actions.receiveRegexTestResult([]));
    yield call(
      message.error,
      'Fail to run regex test due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchRunRegexTest() {
  yield takeEvery(types.RUN_REGEX_TEST, runRegexTest);
}

function* requestRuleVersions({ type, data }) {
  try {
    yield put(actions.startLoading('get-versions'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.requestRuleVersions,
      {},
    );
    if (res && res.code === 'SUCCESS') {
      const store = yield select();
      const ruleEngine = store.ruleEngine;
      const activeVersion = ruleEngine.activeVersion;
      yield put(actions.receiveRuleVersions(res.data));
      if (!activeVersion.state || data.force) {
        if (res.data.length) {
          const ruleUuid = URL.get(RULE_VERSION_PARAMS_KEY);
          const targetRule = res.data.find((i) => i.uuid === ruleUuid);
          if (targetRule) {
            yield put(actions.requestRuleConfig(targetRule.uuid));
          } else {
            yield put(actions.requestRuleConfig(res.data[0].uuid));
          }
        } else {
          yield put(actions.requestInitialRule(null));
        }
      } else if (activeVersion.uuid) {
        yield put(actions.requestRuleConfig(activeVersion.uuid));
      }
    } else {
      yield put(actions.receiveRuleVersions([]));
    }
    yield put(actions.endLoading('get-versions'));
  } catch (e: any) {
    console.log(e);
    yield put(actions.endLoading('get-versions'));
    yield put(actions.receiveRuleVersions([]));
    yield call(
      message.error,
      'Fail to get rule versions due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchRequestRuleVersions() {
  yield takeEvery(types.REQUEST_RULE_VERSIONS, requestRuleVersions);
}

function* requestRuleConfig({ type, data }) {
  try {
    yield put(actions.startLoading('get-rule-config'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.requestRuleConfig,
      data,
    );
    if (res && res.code === 'SUCCESS') {
      URL.set({ [RULE_VERSION_PARAMS_KEY]: res.data.uuid });
      URL.set({ [BP_PARAMS_KEY]: res.data.businessParcelId });
      yield put(actions.setActiveVersion(res.data));
      yield put(actions.requestDryRunList({ ruleId: res.data.uuid }));
      const rules = convertRuleConfigFromBackendFormatToUIFormat(res.data);
      yield put(actions.receiveRules(rules));
      // V2
      yield put({
        type: types.RECEIVE_RULE_INFO,
        data: res.data,
      });
    } else {
      const rules = convertRuleConfigFromBackendFormatToUIFormat({});
      yield put(actions.receiveRules(rules));
    }
    yield put(actions.endLoading('get-rule-config'));
  } catch (e: any) {
    yield put(actions.endLoading('get-rule-config'));
    const rules = convertRuleConfigFromBackendFormatToUIFormat({});
    yield put(actions.receiveRules(rules));
    yield call(
      message.error,
      'Fail to get rule config due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchRequestRuleConfig() {
  yield takeEvery(types.REQUEST_RULE_CONFIG, requestRuleConfig);
}

function* requestInitialRule({ type, data }) {
  try {
    yield put(actions.startLoading('get-initial-rule'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.requestInitialRule,
      data,
    );
    if (res && res.code === 'SUCCESS') {
      yield put(actions.setActiveVersion(res.data));
      const rules = convertRuleConfigFromBackendFormatToUIFormat(res.data);
      yield put(actions.receiveRules(rules));
      yield put(actions.receiveDryRunList([]));
      // V2
      yield put({
        type: types.RECEIVE_RULE_INFO,
        data: res.data,
      });
      if (data) {
        yield put(push('/rule-engine/mapping-rules'));
      }
    } else {
      const rules = convertRuleConfigFromBackendFormatToUIFormat({});
      yield put(actions.receiveRules(rules));
    }
    yield put(actions.endLoading('get-initial-rule'));
  } catch (e: any) {
    yield put(actions.endLoading('get-initial-rule'));
    const rules = convertRuleConfigFromBackendFormatToUIFormat({});
    yield put(actions.receiveRules(rules));
    yield call(
      message.error,
      'Fail to get initial rule due to: ' +
        e.message +
        ', please reload the page.',
    );
  }
}

function* watchRequestInitialRule() {
  yield takeEvery(types.REQUEST_INITIAL_RULE, requestInitialRule);
}

function* postSaveRule({ type, data }) {
  try {
    yield put(actions.startLoading('save-rule'));
    const store = yield select();
    const ruleEngine = store.ruleEngine;
    //V1
    // const requestBody =
    //   convertRuleConfigFromUIFormatToBackendFormat(ruleEngine);
    //V2
    const requestBody = ruleEngine.draftRule;
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.postSaveRule,
      requestBody,
    );
    if (res && res.code === 'SUCCESS') {
      // V1
      yield put(actions.requestRuleVersions({ force: true }));
      // V2
      yield put({
        type: types.RECEIVE_RULE_INFO,
        data: res.data,
      });
      yield call(message.success, 'Changes are saved to current version.');
    } else {
      yield call(message.error, 'Fail to save rule, please try again.');
    }
    yield put(actions.endLoading('save-rule'));
  } catch (e: any) {
    yield put(actions.endLoading('save-rule'));
    console.log('error', e);
    yield call(
      message.error,
      'Fail to save rule due to: ' + e.message + ', please try again.',
    );
  }
}

function* watchPostSaveRule() {
  yield takeEvery(types.POST_SAVE_RULE, postSaveRule);
}

function* putUpdateRule({ type, data }) {
  try {
    yield put(actions.startLoading('update-rule'));
    const store = yield select();
    const ruleEngine = store.ruleEngine;
    // V1
    // const requestBody =
    //   convertRuleConfigFromUIFormatToBackendFormat(ruleEngine);
    const requestBody = ruleEngine.draftRule;
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.putUpdateRule,
      data,
      requestBody,
    );
    if (res && res.code === 'SUCCESS') {
      //V1
      yield put(actions.setActiveVersion(res.data));
      const rules = convertRuleConfigFromBackendFormatToUIFormat(res.data);
      yield put(actions.receiveRules(rules));
      //V2
      yield put({
        type: types.RECEIVE_RULE_INFO,
        data: res.data,
      });
      yield call(message.success, 'Changes are saved to current version.');
    } else {
      yield call(message.error, 'Fail to update rule, please try again.');
    }
    yield put(actions.endLoading('update-rule'));
  } catch (e: any) {
    yield put(actions.endLoading('update-rule'));
    yield call(message.error, 'Fail to update rule, please try again.');
  }
}

function* watchPutUpdateRule() {
  yield takeEvery(types.PUT_UPDATE_RULE, putUpdateRule);
}

function* requestDryRunList({ type, data }) {
  try {
    yield put(actions.startLoading('get-dry-run-list'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.requestDryRunList2,
      data,
    );
    if (res && res.code === 'SUCCESS') {
      yield put(actions.receiveDryRunList(res.data));
    } else {
      yield call(
        message.error,
        'Fail to get dry run list: ' + res.message + ', please try again.',
      );
    }
    yield put(actions.endLoading('get-dry-run-list'));
  } catch (e: any) {
    yield put(actions.endLoading('get-dry-run-list'));
    yield call(
      message.error,
      'Fail to get dry run list: ' + e.message + ', please try again.',
    );
  }
}

function* watchRequestDryRunList() {
  yield takeEvery(types.REQUEST_DRY_RUN_LIST, requestDryRunList);
}

function* requestChangeWorkflowState({ type, data }) {
  try {
    yield put(actions.startLoading('version-actions'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.changeWorkflowState,
      data,
    );
    if (res && res.code === 'SUCCESS') {
      yield call(message.success, `Successfully ${data.ruleEvent}`);
      yield put(actions.requestRuleVersions({ pureRequest: true }));
      yield put(actions.requestDryRunList({ ruleId: data.uuid }));
    } else {
      yield call(
        message.error,
        'Fail to change rule state: ' + res.message + ', please try again.',
      );
    }
    yield put(actions.endLoading('version-actions'));
  } catch (e: any) {
    yield put(actions.endLoading('version-actions'));
    yield call(
      message.error,
      'Fail to change rule state due to: ' + e.message + ', please try again.',
    );
  }
}

function* watchChangeWorkflowState() {
  yield takeEvery(types.CHANGE_WORKFLOW_STATE, requestChangeWorkflowState);
}

function* getUserInfo({ type, data }) {
  try {
    yield put(actions.startLoading('get-user-info'));
    const state = yield select();
    const projectCode =
      state.ruleEngine.activeBusinessParcel?.project?.projectCode;
    const res = yield retry(MAX_TRIES, RETRY_INTERVAL, api.getUserInfo);
    /*
     *
     * 2021.10.15
     * The custom tag limit has been changed to a separate API to get the number.
     * The front-end structure remains the same.
     * Add a customerConfig object to userInfo.
     *
     * */
    const customTagLimitRes = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getCustomerTagLimit,
      projectCode,
    );
    if (
      res &&
      res.code === 'SUCCESS' &&
      customTagLimitRes &&
      customTagLimitRes.code === 'SUCCESS'
    ) {
      const customerConfig = customTagLimitRes.data;
      res.data['customerConfig'] = { ...customerConfig };
      yield put(actions.receiveUserInfo(res.data));
      initPendo(
        res.data.sysUser.username,
        res.data.customerBaseInfo.customerName,
      );
    } else {
      yield call(
        message.error,
        'Fail to get user info due to: ' + res.message + ', please try again.',
      );
    }
    yield put(actions.endLoading('get-user-info'));
  } catch (e: any) {
    yield put(actions.endLoading('get-user-info'));
    yield put(actions.receiveUserInfo({}));
    yield call(
      message.error,
      'Fail to get user info due to: ' + e.message + ', please try again.',
    );
  }
}

function* watchGetUserInfo() {
  yield takeEvery(types.GET_USER_INFO, getUserInfo);
}

function* getDryRunReport({ type, data }) {
  try {
    yield put(actions.startLoading('get-dry-run-report'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getDryRunReport,
      data,
    );
    if (res && res.code === 'SUCCESS') {
      const store = yield select();
      const ruleEngine = store.ruleEngine;
      const dryRunReports = [...ruleEngine.dryRunReports];
      dryRunReports.forEach((item) => {
        if (item.uuid === data) {
          item.reports = res.data;
          item.details = {};
        }
      });
      yield put(actions.receiveDryRunReport(dryRunReports));
    } else {
      yield call(
        message.error,
        'Fail to get dry run report: ' + res.message + ', please try again.',
      );
    }
    yield put(actions.endLoading('get-dry-run-report'));
  } catch (e: any) {
    yield put(actions.endLoading('get-dry-run-report'));
    yield call(
      message.error,
      'Fail to get dry run report due to: ' + e.message + ', please try again.',
    );
  }
}

function* watchGetDryRunReport() {
  yield takeEvery(types.GET_DRY_RUN_REPORT, getDryRunReport);
}

function* getDryRunReportFieldDetail({ type, data }) {
  try {
    yield put(actions.startLoading('get-dry-run-report-field-detail'));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.getDryRunReportFieldDetail,
      data,
    );
    if (res && res.code === 'SUCCESS') {
      const store = yield select();
      const ruleEngine = store.ruleEngine;
      const dryRunReports = [...ruleEngine.dryRunReports];
      dryRunReports.forEach((item) => {
        if (item.dryRunId === data.dryRunId) {
          if (!item.details) {
            item.details = {};
          }
          item.details[data.fieldName] = res.data;
        }
      });
      yield put(actions.receiveDryRunReport(dryRunReports));
    } else {
      yield call(
        message.error,
        'Fail to get dry run report field detail: ' +
          res.message +
          ', please try again.',
      );
    }
    yield put(actions.endLoading('get-dry-run-report-field-detail'));
  } catch (e: any) {
    yield put(actions.endLoading('get-dry-run-report-field-detail'));
    yield put(actions.receiveUserInfo({}));
    yield call(
      message.error,
      'Fail to get dry run report field detail due to: ' +
        e.message +
        ', please try again.',
    );
  }
}

function* watchGetDryRunReportFieldDetail() {
  yield takeEvery(
    types.GET_DRY_RUN_REPORT_FIELD_DETAIL,
    getDryRunReportFieldDetail,
  );
}

function* requestBusinessParcels() {
  const LOADING_KEY = 'business-units';
  try {
    yield put(actions.startLoading(LOADING_KEY));
    const res = yield retry(
      MAX_TRIES,
      RETRY_INTERVAL,
      api.fetchBusinessParcels,
    );
    yield put(actions.receiveBusinessParcels(res.data));
    if (res.data.length) {
      const buUuid = URL.get(BP_PARAMS_KEY);
      const targetBU = res.data.find((i) => i.uuid === buUuid);
      if (targetBU) {
        yield put(actions.setActiveBusinessParcel(targetBU));
      } else {
        yield put(actions.setActiveBusinessParcel(res.data[0]));
      }
    }
    yield put(actions.endLoading(LOADING_KEY));
  } catch (e: any) {
    yield put(actions.endLoading(LOADING_KEY));
    yield call(
      message.error,
      'Fail to fetch data due to: ' + e.message + ', please try again.',
    );
  }
}

function* watchFetchBusinessParcels() {
  yield takeLatest(types.FETCH_BUSINESS_UNITS, requestBusinessParcels);
}

function* activeBusinessParcelChange(data) {
  yield put(actions.requestRuleEngineOptions({}));
  yield put(actions.requestRuleVersions({ force: true }));
  yield put(actions.requestTagKeyList({}));
  yield put(
    actions.receiveSampleDataJobs({
      customerId: '',
      endDate: '',
      jobMessage: '',
      jobStatus: null,
      startDate: '',
    }),
  );
  URL.set({ [BP_PARAMS_KEY]: data?.data?.uuid });
}

function* watchSetActiveBusinessParcel() {
  yield takeEvery(types.SET_ACTIVE_BUSINESS_UNIT, activeBusinessParcelChange);
}

function* getSampleDataJobs() {
  const state = yield select((state) => {
    return {
      activeBusinessParcel: state.ruleEngine.activeBusinessParcel,
    };
  });

  let requestFn: any = getSampleDataJobsV2;

  if (state.activeBusinessParcel.reVersion === VERSION_1) {
    requestFn = api.getSampleDataJobs;
  }
  const res = yield call(
    requestFn,
    state.activeBusinessParcel.project.projectCode,
  );
  if (res && res.code === 'SUCCESS') {
    let data = res.data;
    if (state.activeBusinessParcel.reVersion === VERSION_1) {
      data = data.latest;
    } else {
      data = data.entities?.[0];
    }
    if (data) {
      data.jobStatus = data.jobStatus?.toLowerCase();
      if (data.jobStatus === 'success' || data.jobStatus === 'failed') {
        queryClient.invalidateQueries(SAMPLE_DATA_JOBS_QUERY_KEY);
        clearTimeout(window.__RULE_ENGINE_FETCH_SAMPLE_DATA_JOBS_TIMER__);
      } else {
        window.__RULE_ENGINE_FETCH_SAMPLE_DATA_JOBS_TIMER__ = setTimeout(() => {
          store.dispatch(actions.getSampleDataJobs());
        }, 10 * 1000);
      }
      yield put(actions.receiveSampleDataJobs(data));
    }
  }
}

function* watchGetSampleDataJobs() {
  yield takeLatest(types.GET_SAMPLE_DATA_JOBS, getSampleDataJobs);
}

export default [
  watchPushLocation,
  watchGetInputMetadataQuality,
  watchGetPlayerList,
  watchGetTagDetails,
  watchGetCustomerMetadataStrategy,
  watchGetRuleEngineRequiredFields,
  watchGetRuleEngineSupportLetter,
  watchGetRuleEngineOptions,
  watchGetTagKeyList,
  watchGetTagSampleData,
  watchRunRegexTest,
  watchRequestRuleVersions,
  watchRequestRuleConfig,
  watchRequestInitialRule,
  watchPostSaveRule,
  watchPutUpdateRule,
  watchRequestDryRunList,
  watchGetUserInfo,
  watchGetDryRunReport,
  watchGetDryRunReportFieldDetail,
  watchChangeWorkflowState,
  watchFetchBusinessParcels,
  watchSetActiveBusinessParcel,
  watchGetSampleDataJobs,
];
