/*
*
* @Author : zyang@conviva.com
* @Date : 2021/3/8
* @Version : 1.0
*
*/
if (window.__POWERED_BY_QIANKUN__) {
  window.__webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
}
