import React from 'react';
import { Spin, Modal } from 'antdV3';
import { Modal as ConModal } from 'conviva-design/es/components/Modal';
import { Button } from 'conviva-design/es/components/Button';
import { Button as ButtonV2 } from 'conviva-design/es/components/ButtonV2';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import SiderMenu from './SiderMenu';
import PageHeader from './Header';
import {
  requestRuleEngineOptions,
  requestTagKeyList,
  updateRule,
  saveRule,
  createDryRunReport,
} from '@src/store/actions/ruleEngine';
import {
  Layout,
  LayoutWrap,
  Content,
  PageTitle,
  UnsavedField,
  UnsavedTips,
} from './BaseLayoutStyled';
import { ruleEngineSelector, locationSelector } from '@src/store/selector';
import {
  DRY_RUN_REPORT_NAME_PREFIX,
  GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
} from '@src/store/types/types';
import NavigationPrompt from 'react-router-navigation-prompt';
import { RULE_STATUS_INIT, THEME_LIGHT } from '@src/utils/constants';
import Modals from '@src/pages/RuleEngine/layouts/modals';
import { openModalAction } from '@src/store/actions/modal';
import { IRuleEngineReducer } from '@src/typing/ruleEngine';
import { message } from 'antd';
import { getFieldFullPath } from '@src/utils/ruleEngine';

const selector = createSelector(
  ruleEngineSelector,
  locationSelector,
  (ruleEngine, location) => {
    return {
      ruleEngine,
      location,
    };
  },
);

interface Props {
  requestRuleEngineOptions: any;
  requestTagKeyList: any;
  createDryRunReport: any;
  updateRule: any;
  saveRule: any;
  children: any;
  location?: any;
  match?: any;
  routes?: any;
  openModalAction: any;
  ruleEngine: IRuleEngineReducer;
}

// TODO: pre/post-mds
interface State {
  showCreateDryRunModal: boolean;
  dryRunWaringVisible: boolean;
  saveAnywayVisible: boolean;
  customTagNoFilledList: any[];
  fieldNoFilledList: any[];
}

interface ICreateDryRunReportBody {
  endDate: string;
  startDate: string;
  ruleId: string;
}

class RuleEngineLayout extends React.PureComponent<Props, State> {
  formRef: any;

  constructor(props) {
    super(props);
    this.formRef = null;
    this.state = {
      showCreateDryRunModal: false,
      dryRunWaringVisible: false,
      saveAnywayVisible: false,
      customTagNoFilledList: [],
      fieldNoFilledList: [],
    };
  }

  componentDidMount() {}

  getPagePath = () => this.props.location.pathname.split('/').pop();

  getPageTitle = () => {
    const path = this.getPagePath();
    let pageTitle = 'Mapping Rules';
    if (path.indexOf('dry-run') > -1) {
      pageTitle = path
        .split('-')
        .map((item) => capitalize(item))
        .join(' ');
    }
    return pageTitle;
  };

  getCustomTagNoMapFromList() {
    const ruleFieldMappings =
      this.props.ruleEngine.draftRule?.ruleFieldMappings || [];
    const customTagNoFilledList = ruleFieldMappings.filter(
      (i) =>
        i.ruleFieldMappedFroms.length === 0 &&
        i.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
    );
    return customTagNoFilledList;
  }

  clickSave = () => {
    // Check regex rule.
    const regexRuleList =
      this.props.ruleEngine.draftRule?.ruleFieldExtractingConfigs || [];
    const emptyRegexField = regexRuleList.find(
      (i) => i.regexExpressions.length === 0,
    );
    if (emptyRegexField) {
      const fullPath = getFieldFullPath(
        emptyRegexField.parentPath,
        emptyRegexField.tagName,
      );
      message.warning(`[${fullPath}] regex rules is empty!`);
      return false;
    }
    const ruleFieldMappings =
      this.props.ruleEngine.draftRule?.ruleFieldMappings || [];
    const fieldNoFilledList = ruleFieldMappings.filter(
      (i) =>
        i.ruleFieldMappedFroms.length === 0 &&
        i.groupCode !== GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
    );

    const customTagNoFilledList = this.getCustomTagNoMapFromList();
    if (fieldNoFilledList.length > 0 || customTagNoFilledList.length > 0) {
      this.setState({
        saveAnywayVisible: true,
        customTagNoFilledList,
        fieldNoFilledList,
      });
      return;
    }
    this.handleSave();
  };

  handleSave = () => {
    const activeRuleUUID = this.props.ruleEngine.activeVersion.uuid;
    if (activeRuleUUID) {
      this.props.updateRule(activeRuleUUID);
    } else {
      this.props.saveRule();
    }
  };

  genActionBtn = () => {
    const { activeRule, draftRule } = this.props.ruleEngine;
    const hasUnsaved = !isEqual(activeRule, draftRule);
    return (
      <div
        data-test-id="rule-engine-save-btn"
        style={{ marginRight: '30px', textAlign: 'center' }}
      >
        <Button
          theme={THEME_LIGHT}
          clickFn={this.clickSave}
          disabled={
            this.props.ruleEngine.activeVersion.state !== RULE_STATUS_INIT
          }
        >
          Save
        </Button>
        {hasUnsaved ? <UnsavedTips>unsaved changes</UnsavedTips> : ''}
      </div>
    );
  };

  showCreateDryRun = () => {
    const customTagNoFilledList = this.getCustomTagNoMapFromList();
    if (customTagNoFilledList.length > 0) {
      this.setState({ dryRunWaringVisible: true, customTagNoFilledList });
    } else {
      this.setState({ showCreateDryRunModal: true });
    }
  };

  checkUnsave = (crntLocation, nextLocation) => {
    let active = false;
    const { activeRule, draftRule } = this.props.ruleEngine;
    const hasUnsaveRule = !isEqual(activeRule, draftRule);
    if (
      nextLocation.pathname.indexOf('dry-run') > -1 ||
      nextLocation.pathname.indexOf('rule-engine') === -1
    ) {
      active = true;
    }
    return active && hasUnsaveRule;
  };

  genPromptDom = ({ isActive, onCancel, onConfirm }) => {
    const { activeRule, draftRule } = this.props.ruleEngine;
    const currentRule = draftRule?.ruleFieldMappings || [];
    const baseRule = activeRule?.ruleFieldMappings || [];
    let unsaveFields: any[] = [];
    currentRule.forEach((filed, index) => {
      if (!isEqual(filed, baseRule[index])) {
        unsaveFields.push(
          <UnsavedField key={filed.fieldCode}>
            {filed.fieldName} rule should be submitted.{' '}
          </UnsavedField>,
        );
      }
    });
    const oldCustomTags = baseRule.filter(
      (i) => i.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
    );
    const currentCustomTags = currentRule.filter(
      (i) => i.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
    );
    // New custom tags
    if (!isEqual(oldCustomTags, currentCustomTags)) {
      unsaveFields.push(
        <UnsavedField key="custom">
          Custom tags have changes that have not yet been saved.{' '}
        </UnsavedField>,
      );
      console.log(
        'unsave',
        'Custom tags have changes that have not yet been saved',
      );
    }
    if (isActive) {
      return (
        <Modal
          title="Save"
          visible
          onCancel={onCancel}
          footer={<Button clickFn={onCancel}>OK</Button>}
        >
          <div style={{ fontSize: '16px', marginBottom: '10px' }}>
            You have unsaved changes
          </div>
          {unsaveFields}
        </Modal>
      );
    }
    return '';
  };

  render() {
    const activePath = this.getPagePath();
    const theme = THEME_LIGHT;
    const dryRunWarningFooterButtons = [
      <ButtonV2
        data-test-id="dry-run-ok-btn"
        key="OK"
        category="secondary"
        theme={theme}
        onClick={() => {
          this.setState({ dryRunWaringVisible: false });
        }}
      >
        OK
      </ButtonV2>,
    ];
    const saveAnywayFooterButtons = [
      <ButtonV2
        data-test-id="save-dialog-continue-btn"
        key="edit"
        category="secondary"
        theme={theme}
        onClick={() => {
          this.setState({ saveAnywayVisible: false });
        }}
      >
        Continue Editing
      </ButtonV2>,
      <ButtonV2
        data-test-id="save-dialog-save-anyway-btn"
        key="save"
        theme={theme}
        onClick={() => {
          this.handleSave();
          this.setState({ saveAnywayVisible: false });
        }}
      >
        Save Anyway
      </ButtonV2>,
    ];
    return (
      <Layout>
        <PageHeader />
        <LayoutWrap>
          <SiderMenu activePath={this.getPagePath()} />
          <Content>
            <Spin spinning={this.props.ruleEngine.loading['global']}>
              {activePath !== 'dry-run' ? (
                <PageTitle>
                  {this.getPageTitle()}
                  {this.genActionBtn()}
                </PageTitle>
              ) : null}
              {this.props.children}
            </Spin>
          </Content>
        </LayoutWrap>
        {/*Save anyway modal*/}
        <ConModal
          title="Save"
          visible={this.state.saveAnywayVisible}
          onCancel={() => {
            this.setState({ saveAnywayVisible: false });
          }}
          theme={theme}
          footer={saveAnywayFooterButtons}
        >
          <p>
            All fields need to be filled. If no tag is available please add a
            fallback value. (e.g. set 0 for numeric fields, set unknown to
            normal fields)
          </p>
          {this.state.fieldNoFilledList.map((item) => {
            return (
              <UnsavedField key={item.fieldCode}>
                {item.fieldName} need to be filled.
              </UnsavedField>
            );
          })}
          {this.state.customTagNoFilledList.map((item) => {
            return (
              <UnsavedField key={item.fieldCode}>
                {item.fieldName} (custom tag) need to be filled.
              </UnsavedField>
            );
          })}
        </ConModal>
        {/*Dry run custom tag warning modal*/}
        <ConModal
          title="Warning"
          visible={this.state.dryRunWaringVisible}
          onCancel={() => {
            this.setState({ dryRunWaringVisible: false });
          }}
          theme={theme}
          footer={dryRunWarningFooterButtons}
        >
          <p>
            Mapping rules are required to ensure tag values are populated
            properly, please apply rules and save again.
          </p>
          {this.state.customTagNoFilledList.map((item) => {
            return (
              <UnsavedField key={item.fieldCode}>
                {item.fieldName} (custom tag) need to be filled.
              </UnsavedField>
            );
          })}
        </ConModal>
        <NavigationPrompt when={this.checkUnsave}>
          {this.genPromptDom}
        </NavigationPrompt>
        <Modals />
      </Layout>
    );
  }
}

const connectedRuleEngineLayout = connect(selector, {
  requestRuleEngineOptions,
  requestTagKeyList,
  updateRule,
  saveRule,
  createDryRunReport,
  openModalAction,
})(RuleEngineLayout);

export default connectedRuleEngineLayout;
