export const MODIFIED = 'MODIFIED';

export const ADDED = 'ADDED';

export const REMOVED = 'REMOVED';

export const HIGHLIGHT = 'HIGHLIGHT';

export const LIGHTGREEN = 'LIGHTGREEN';

export const PINK = 'PINK';

export const WHITE = 'WHITE';
