import React from 'react';
import arrayMove from 'array-move';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />,
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
  ),
);

interface IProps extends TableProps<any> {
  onSortEnd: any;
  rowKey: string;
  showSort?: boolean;
}

const defaultProps = {
  onSortEnd: () => {},
  rowKey: 'id',
  showSort: true,
};

const SortableTable: React.FC<IProps> = (props) => {
  props = { ...defaultProps, ...props };
  const dataSource = props.dataSource || [];
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...dataSource], oldIndex, newIndex);
      console.log('Sorted items: ', newData);
      props.onSortEnd(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x[props.rowKey] === restProps['data-row-key'],
    );
    return <SortableItem index={index} {...restProps} />;
  };

  let columns = props.columns || [];
  if (props.showSort) {
    columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      ...columns,
    ];
  }

  return (
    <Table
      pagination={false}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...props}
      columns={columns}
    />
  );
};

export default SortableTable;
