import styled from 'styled-components';

export const PageTitle = styled('div')`
  border-bottom: 5px solid #e9edf0;
  padding-bottom: 21px;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  background: white;
  justify-content: space-between;
`;

export const RuleVersionSelectorWrapper = styled.div`
  .mds-ant-select:not(.mds-ant-select-customize-input)
    .mds-ant-select-selector {
    border: none;
    outline: none;
    background: #f6f6f6;
    border-radius: 0;
    border-left: 6px solid #0d36a1;
    height: 36px;
    color: #000000;
    font-size: 22px;
    letter-spacing: 0;
  }
  .mds-ant-select-focused:not(.mds-ant-select-disabled).mds-ant-select:not(.mds-ant-select-customize-input)
    .mds-ant-select-selector {
    border-color: #0d36a1;
    border-right-width: 0;
    box-shadow: none;
  }
  .mds-ant-select-selection-selected-value {
    height: 36px;
    line-height: 36px;
  }
`;

export const FetchDataStateContainer = styled.div`
  text-align: center;
  transform: translateY(5px);
`;
