import React, { useEffect, useState } from 'react';
import { ThemeContext } from '@src/contexts';
import { Button } from 'conviva-design/es/components/ButtonV2';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import {
  modifyUpdateFilterMark,
  updateSelectedFilterFields,
} from '@src/store/actions/gapAnalysis';

interface IFilterButton {
  label: string;
  filterListValues: string[];
}

const FilterButton: React.FC<IFilterButton> = ({ label, filterListValues }) => {
  const dispatch = useAppDispatch();
  const { theme } = React.useContext(ThemeContext);
  const state = useAppSelector((state) => {
    return {
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const { selectedFilterFields } = gapAnalysis;

  const [modalContentType, setModalContentType] = useState(filterListValues[0]);

  useEffect(() => {
    selectedFilterFields.forEach((item) => {
      if (item.key === label) {
        setModalContentType(item.value);
      }
    });
  }, [label, selectedFilterFields]);

  const onContentTypeChange = (i) => {
    setModalContentType(i);
    const editedFilterFields = selectedFilterFields.map((item) => {
      if (item.key === label) {
        item.value = i;
      }
      return item;
    });
    dispatch(updateSelectedFilterFields(editedFilterFields));
    dispatch(modifyUpdateFilterMark(true));
  };

  return (
    <>
      {filterListValues.map((i) => {
        return (
          <Button
            key={label + i}
            data-test-id={label}
            onClick={() => {
              onContentTypeChange(i);
            }}
            theme={theme}
            category={modalContentType === i ? '' : 'secondary'}
          >
            {i}
          </Button>
        );
      })}
    </>
  );
};

export default FilterButton;
