import React, { useState } from 'react';
import { getBackground } from './common';
import { ADDED, REMOVED } from './constants';
const RegexRule = (props: any) => {
  const { regexList1, regexList2, setRegexItem1, setRegexItem2 } = props;
  const [activeIndex, settActiveIndex] = useState(0);

  const rightVersion = (item1: any, index: number) => {
    setRegexItem1(item1);
    setRegexItem2(regexList2[index]);
    settActiveIndex(index);
  };

  const RegexLeftCompare = (item, index) => {
    if (item) {
      return (
        <p style={getBackground(item, index, activeIndex)}>
          {item.compareType === ADDED ? (
            <span
              style={{
                margin: '0 18px',
                fontWeight: 'bold',
              }}
            >
              +
            </span>
          ) : item.compareType === REMOVED ? (
            <span
              style={{
                margin: '0 18px',
                fontWeight: 'bold',
              }}
            >
              -
            </span>
          ) : (
            <span className="compareMark"></span>
          )}
          <span>{item.tagName} </span>
        </p>
      );
    } else {
      return <p style={getBackground(item, index, activeIndex)}></p>;
    }
  };

  return (
    <ul className="left">
      {regexList1.length
        ? regexList1.map((item: any, index: any) => {
            return (
              <li key={index} onClick={() => rightVersion(item, index)}>
                {RegexLeftCompare(item, index)}
                {RegexLeftCompare(regexList2[index], index)}
              </li>
            );
          })
        : regexList2?.map((item: any, index: any) => {
            return (
              <li key={index} onClick={() => rightVersion(item, index)}>
                {RegexLeftCompare(item, index)}
                {RegexLeftCompare(regexList1[index], index)}
              </li>
            );
          })}
    </ul>
  );
};

export default RegexRule;
