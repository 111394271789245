import * as types from '@src/store/types/gapAnalysis';
import {
  IJobData,
  IFilterList,
  IResultList,
  IFilterFields,
} from '@src/typing/gapAnalysis';
import { IBusinessParcel } from '@src/typing/businessParcel';

interface IGapAnalysisReducer {
  gapAnalysisReports: string[];
  historicalJobPagination: {
    pageNum: number;
    pageSize: number;
    pages: number;
    total: number;
  };
  historicalJob: IJobData[];
  latestJob: IJobData | null;
  currentJob: IJobData | null;
  conditionFilterList: IFilterList[];
  conditionResultList: IResultList[];
  coverageResultList: IResultList[];
  currentResultList: IResultList[];
  selectedFilterFields: IFilterFields[];
  coverageFilterFields: IFilterFields[];
  currentFilterFields: IFilterFields[];
  updateCoverageMark: boolean;
  updateFilterMark: boolean;
  activeBusinessParcel: IBusinessParcel;
}

const initState: IGapAnalysisReducer = {
  gapAnalysisReports: [],
  historicalJobPagination: {
    pageNum: 1,
    pageSize: 5,
    pages: 1,
    total: 5,
  },
  historicalJob: [],
  latestJob: null,
  currentJob: null,
  conditionFilterList: [],
  conditionResultList: [],
  coverageResultList: [],
  currentResultList: [],
  selectedFilterFields: [],
  coverageFilterFields: [],
  currentFilterFields: [],
  updateCoverageMark: true,
  updateFilterMark: true,
  activeBusinessParcel: {
    dataSource: {
      dataSourceCode: '',
      dataSourceName: '',
      dataSourceUuid: '',
      partition: '',
    },
    createTime: '',
    createUser: '',
    description: '',
    name: '',
    project: {
      projectCode: '',
      projectName: '',
      uuid: '',
    },
    publisher: null,
    relatedTemplates: null,
    reVersion: 'V1',
    ruleInfo: null,
    status: 'ENABLED',
    updateTime: '',
    updateUser: '',
    uuid: '',
  },
};

const resetState: IGapAnalysisReducer = {
  gapAnalysisReports: [],
  historicalJobPagination: {
    pageNum: 1,
    pageSize: 5,
    pages: 1,
    total: 5,
  },
  historicalJob: [],
  latestJob: null,
  currentJob: null,
  conditionFilterList: [],
  conditionResultList: [],
  coverageResultList: [],
  currentResultList: [],
  selectedFilterFields: [],
  coverageFilterFields: [],
  currentFilterFields: [],
  updateCoverageMark: true,
  updateFilterMark: true,
  activeBusinessParcel: {
    dataSource: {
      dataSourceCode: '',
      dataSourceName: '',
      dataSourceUuid: '',
      partition: '',
    },
    createTime: '',
    createUser: '',
    description: '',
    name: '',
    project: {
      projectCode: '',
      projectName: '',
      uuid: '',
    },
    publisher: null,
    relatedTemplates: null,
    reVersion: 'V1',
    ruleInfo: null,
    status: 'ENABLED',
    updateTime: '',
    updateUser: '',
    uuid: '',
  },
};

function gapAnalysisReducer(
  state = initState,
  action: any,
): IGapAnalysisReducer {
  switch (action.type) {
    case types.RESET_GAP_ANALYSIS_STATE:
      return resetState;
    case types.UPDATE_GAP_ANALYSIS_REPORTS:
      return {
        ...state,
        gapAnalysisReports: [action.data.uuid],
        activeBusinessParcel: { ...action.data },
      };
    case types.UPDATE_HISTORICAL_JOB:
      return { ...state, historicalJob: [...action.data] };
    case types.UPDATE_HISTORICAL_JOB_PAGINATION:
      return { ...state, historicalJobPagination: { ...action.data } };
    case types.UPDATE_LATEST_JOB:
      return { ...state, latestJob: { ...action.data } };
    case types.UPDATE_CURRENT_JOB:
      return { ...state, currentJob: { ...action.data } };
    case types.UPDATE_CONDITION_FILTER_LIST:
      return { ...state, conditionFilterList: [...action.data] };
    case types.UPDATE_CONDITION_RESULT_LIST:
      return { ...state, conditionResultList: [...action.data] };
    case types.UPDATE_COVERAGE_RESULT_LIST:
      return { ...state, coverageResultList: [...action.data] };
    case types.UPDATE_CURRENT_RESULT_LIST:
      return { ...state, currentResultList: [...action.data] };
    case types.UPDATE_SELECTED_FILTER_FIELDS:
      return { ...state, selectedFilterFields: [...action.data] };
    case types.UPDATE_COVERAGE_FILTER_FIELDS:
      return { ...state, coverageFilterFields: [...action.data] };
    case types.UPDATE_CURRENT_FILTER_FIELDS:
      return { ...state, currentFilterFields: [...action.data] };
    case types.UPDATE_COVERAGE_MARK:
      return { ...state, updateCoverageMark: action.data };
    case types.UPDATE_FILTER_MARK:
      return { ...state, updateFilterMark: action.data };
    case types.RESET_CONDITION_AND_COVERAGE:
      return {
        ...state,
        coverageFilterFields: [],
        coverageResultList: [],
        conditionResultList: [],
        selectedFilterFields: [],
      };
    default:
      return state;
  }
}

export default gapAnalysisReducer;
