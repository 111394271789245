import * as types from '@src/store/types/ruleCompare';

export function initializeRuleCompareState(payload) {
  return {
    type: types.INITIALIZE_RULE_STATE,
    payload: payload,
  };
}

export function updateStateRuleSelectVersion1(payload) {
  return {
    type: types.UPDATE_STATE_RULE_SELECT_VERSION1,
    payload: payload,
  };
}

export function updateStateRuleSelectVersion2(payload) {
  return {
    type: types.UPDATE_STATE_RULE_SELECT_VERSION2,
    payload: payload,
  };
}

export function updateStateRuleFieldsVersion1(payload) {
  return {
    type: types.UPDATE_STATE_RULE_FIELDS_VERSION1,
    payload: payload,
  };
}

export function updateStateRuleFieldsVersion2(payload) {
  return {
    type: types.UPDATE_STATE_RULE_FIELDS_VERSION2,
    payload: payload,
  };
}

export function updateStateRuleRegexVersion1(payload) {
  return {
    type: types.UPDATE_STATE_RULE_REGEX_VERSION1,
    payload: payload,
  };
}

export function updateStateRuleRegexVersion2(payload) {
  return {
    type: types.UPDATE_STATE_RULE_REGEX_VERSION2,
    payload: payload,
  };
}
