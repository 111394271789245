import React from 'react';
import styled from 'styled-components';
import {THEME_LIGHT} from "../../../utils/constants";
import SKD_ICON from "../../../asset/sdk.png";
import LIB_ICON from "../../../asset/lib.png";

const APP_TYPE_SDK = 'SDK';
const APP_TYPE_LIBRARY = 'Library';
const Header = styled.div<{showIcon?: boolean}>`
  font-size: 14px;
  font-weight: normal;
  font-family: "Open Sans";
  letter-spacing: 0;
  text-align: center;
  color: #4A4A4A;
  cursor: pointer;
  line-height: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  .header-title {
    width: ${({ showIcon }) => showIcon ? '82px' : '102px'};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const imgStyle = {
    marginRight: '3px'
}

function ColumnHeader({ app }) {
  const showTitle = `${app.appName || ''} ${app.appVersion || ''}`;
  return (
      <Header theme={THEME_LIGHT} title={showTitle} showIcon={!!app.appType}>
        {app.appType === APP_TYPE_SDK ? <img style={{width: '20px', height: '9px', ...imgStyle}} src={SKD_ICON} alt=""/> : ''}
        {app.appType === APP_TYPE_LIBRARY ? <img style={{width: '22px', height: '10px', ...imgStyle}} src={LIB_ICON} alt=""/> : ''}
        <div className="header-title">
            <div className="header-title">{app.appName}</div>
            <div className="header-title">{app.appVersion}</div>
        </div>
      </Header>
  );
}

export default ColumnHeader;
