import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { Table, Dropdown, Menu } from 'antd';
import { Icon } from 'antdV3';
import { Button } from 'conviva-design/es/components/Button';
import { Tooltip } from 'conviva-design/es/components/Tooltip';
import { DryRunWrapper, ActionButton } from './styled';
import { StyledTableWrapper } from '@src/components/styled';
import { ruleEngineSelector } from '@src/store/selector';
import {
  addDryRunReportTab,
  pushLocation,
  updateDryRunCompareLists,
} from '@src/store/actions/ruleEngine';
import { THEME_LIGHT } from '@src/utils/constants';
import { IDryRunInfo, IRuleEngineReducer } from '@src/typing/ruleEngine';
import { requestDryRunList2, useRequestDryRunList } from '@src/service/api';
import { Spin } from 'antd';
import { PageTitle } from '../../layouts/BaseLayoutStyled';
import ModalCreateDryRun from './components/ModalCreateDryRun';
import { IBusinessParcel } from '@src/typing/businessParcel';
import { IRuleInfo } from '@src/typing/ruleEngine';
import { ColumnsType } from 'antd/lib/table';
import { toUrl } from '@src/utils/url';
import { getNameByDryRunInfo } from '@src/utils/ruleEngine';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import { initializeState } from '@src/store/actions/dryRunCompare';

const selector = createSelector(ruleEngineSelector, (ruleEngine) => {
  return {
    ruleEngine,
  };
});

const DryRunList: React.FC<{
  businessParcel: IBusinessParcel;
  ruleInfo: IRuleInfo;
}> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const { businessParcel, ruleInfo } = props;
  const partition = businessParcel.dataSource?.partition || '';
  const {
    data: dryRunListInfo,
    isLoading,
    isRefetching,
    refetch,
  } = useRequestDryRunList(businessParcel, ruleInfo);
  // console.log('dryRunListInfo');
  // console.log(dryRunListInfo);
  const dateFormat = 'YYYY/MM/DD';
  let dataSource: any[] = [];
  if (dryRunListInfo) {
    dataSource = dryRunListInfo.entities;
  }

  const store = useAppSelector((state) => {
    return {
      ruleEngine: state.ruleEngine,
    };
  });
  const { activeVersion, activeBusinessParcel } = store.ruleEngine;

  const columns: ColumnsType<IDryRunInfo> = useMemo(() => {
    const { reVersion } = businessParcel;
    return [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: (text, record, index) => {
          return getNameByDryRunInfo(record);
        },
      },
      ...(reVersion === 'V2'
        ? [
            {
              key: 'name',
              title: 'Description',
              dataIndex: 'description',
            },
          ]
        : []),
      {
        key: 'timePeriod',
        title: 'Time Period',
        dataIndex: 'timePeriod',
        render: (text, record, index) => {
          const { startDate, endDate } = record;
          let duration = 'N/A';
          if (partition !== 'NONE' && startDate && endDate) {
            duration = `${moment
              .utc(record.startDate)
              .format(dateFormat)} - ${moment
              .utc(record.endDate)
              .format(dateFormat)}`;
          }
          return duration;
        },
      },
      {
        key: 'jobStatus',
        title: (
          <div>
            Status{' '}
            <Icon
              data-test-id="refresh-dryrun-status-icon"
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={(evt) => {
                evt.preventDefault();
                refetch();
              }}
              type="sync"
            />
          </div>
        ),
        dataIndex: 'jobStatus',
        render: (text, record, index) => {
          if (!record.effective) {
            return (
              <div>
                Not Valid
                <Tooltip
                  theme={THEME_LIGHT}
                  placement="right"
                  title="Note that new changes have been made to current version and not reflected in this report."
                >
                  <Icon
                    type="info-circle"
                    style={{ marginLeft: '10px' }}
                    theme="filled"
                  />
                </Tooltip>
              </div>
            );
          }
          return <div>{text}</div>;
        },
      },
      {
        key: 'userName',
        title: 'Creator',
        dataIndex: 'userName',
      },
      ...(reVersion === 'V2'
        ? [
            {
              key: 'createTime',
              title: 'Create Time',
              dataIndex: 'jobStartTime',
            },
            {
              key: 'finishTime',
              title: 'Finish Time',
              dataIndex: 'jobEndTime',
            },
          ]
        : []),

      {
        key: 'action',
        render: (text, record, index) => {
          const canView = record.jobStatus === 'SUCCESS';
          const menu = (
            <Menu>
              <Menu.Item
                key="view"
                disabled={!canView}
                onClick={() => {
                  history.push(
                    toUrl({
                      path: '/dry-run/:businessParcelId/:ruleId/:dryRunId',
                      params: {
                        businessParcelId: businessParcel.uuid,
                        ruleId: ruleInfo.uuid,
                        dryRunId: record.uuid,
                      },
                    }),
                  );
                }}
              >
                view
              </Menu.Item>
              <Menu.Item
                key="compare"
                disabled={!canView}
                onClick={() => {
                  dispatch(
                    updateDryRunCompareLists([
                      {
                        businessParcelId: activeBusinessParcel.uuid,
                        ruleId1: activeVersion.uuid,
                        dryRunId1: record.uuid,
                        ruleId2: activeVersion.uuid,
                        dryRunId2: record.uuid,
                      },
                    ]),
                  );
                  dispatch(
                    initializeState({
                      dryRunContentData: [],
                      dryRunOption1: [],
                      dryRunOption2: [],
                    }),
                  );

                  history.push(
                    toUrl({
                      path: '/dry-run-compare',
                      query: {
                        businessParcelId: businessParcel.uuid,
                        ruleId1: ruleInfo.uuid,
                        dryRunId1: record.uuid,
                        ruleId2: activeVersion.uuid,
                        dryRunId2: record.uuid,
                      },
                    }),
                  );
                }}
              >
                compare
              </Menu.Item>
            </Menu>
          );
          return record.effective ? (
            <Dropdown overlay={menu} placement="bottomLeft" trigger={['hover']}>
              <ActionButton>
                <Icon type="more" style={{ fontSize: '22px' }} />
              </ActionButton>
            </Dropdown>
          ) : (
            ''
          );
        },
      },
    ];
  }, [businessParcel, history, refetch, ruleInfo, partition]);

  return (
    <Spin spinning={isLoading || isRefetching} tip={'Loading'}>
      <PageTitle>
        Dry Run
        <div
          data-test-id="create-dry-run-btn"
          style={{ float: 'right', marginRight: '30px' }}
        >
          <Button
            theme={THEME_LIGHT}
            disabled={!ruleInfo.uuid}
            clickFn={() => {
              setModalVisible(true);
            }}
          >
            Create Dry Run
          </Button>
        </div>
      </PageTitle>
      <DryRunWrapper>
        <StyledTableWrapper>
          <Table
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            rowKey={(record) => record.uuid}
          />
        </StyledTableWrapper>
      </DryRunWrapper>
      {modalVisible && (
        <ModalCreateDryRun
          businessParcel={businessParcel}
          ruleInfo={ruleInfo}
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
          onCreateSuccess={() => {
            setModalVisible(false);
            refetch();
          }}
        />
      )}
    </Spin>
  );
};

const IndexPage: React.FC<{
  ruleEngine: IRuleEngineReducer;
}> = (props) => {
  const {
    ruleEngine: { activeBusinessParcel, activeRule },
  } = props;
  const dataReady = activeBusinessParcel && activeRule;
  return dataReady ? (
    <DryRunList businessParcel={activeBusinessParcel} ruleInfo={activeRule} />
  ) : (
    <Spin spinning={!dataReady} tip="Loading">
      <div
        style={{
          minHeight: '300px',
        }}
      />
    </Spin>
  );
};

export default connect(selector, {
  addDryRunReportTab,
  pushLocation,
  requestDryRunList: requestDryRunList2,
})(IndexPage);
