// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '@src/contexts';
import { TableWrapper } from './styled';
import { Table } from 'conviva-design/es/components';
import { Icon } from 'conviva-design/es/components/Icon';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc/dist/react-sortable-hoc';
import { HoverDiv } from './styled';

const DraggableContainer = sortableContainer(({ children }) => children);
const DraggableElement = sortableElement(({ children }) => children);
const DraggableHandle = sortableHandle(({ children }) => children);

function getIconType(isAutoSortEnabled, sortOrder) {
  if (isAutoSortEnabled) {
    return '';
  } else {
    return sortOrder === 'asc' ? 'up' : 'down';
  }
}

export const Col = ({ theme, index, children, ...rest }) => {
  if (index === 0) {
    return <div {...rest}>{children}</div>;
  } else {
    return index === 1 ? (
      <div {...rest}>
        <HoverDiv className="metric-header">
          <div className="handle"></div>
          <div>{children}</div>
        </HoverDiv>
      </div>
    ) : (
      <DraggableElement index={index}>
        <div {...rest}>
          <HoverDiv className="metric-header">
            <DraggableHandle>
              <div className="handle">...</div>
            </DraggableHandle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div></div>
              {children}
            </div>
          </HoverDiv>
        </div>
      </DraggableElement>
    );
  }
};

interface IMetricTable {
  data: any[];
  columns: any[];
  sortBy?: {};
  onColumnSort?: (any) => void;
  frozenData?: any[];
  components?: {};
}

// table hoc
function MetricTable({
  data,
  columns,
  sortBy,
  onColumnSort,
  frozenData = [],
  components = {},
}: IMetricTable) {
  const { theme } = useContext(ThemeContext);
  const [cols, setCols] = useState(columns);

  useEffect(() => {
    setCols(columns);
  }, [columns]);
  const onColumnOrderChange = (...arg: any) => {};

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const newData = [...cols];
    [newData[newIndex], newData[oldIndex]] = [
      newData[oldIndex],
      newData[newIndex],
    ];
    setCols(newData);
    onColumnOrderChange(oldIndex, newIndex);
  };

  const isAutoSortEnabled = false;
  const AdvanceSortIndicator = (data) => {
    const { className, sortOrder } = data;
    return (
      <div className={className}>
        <Icon
          cvvtheme={theme}
          type={getIconType(isAutoSortEnabled, sortOrder)}
        />
      </div>
    );
  };
  components = {
    SortIndicator: AdvanceSortIndicator,
    ...components,
  };

  const headerCellProps = (args) => {
    return {
      tagName: Col,
      index: args.columnIndex,
      theme,
    };
  };
  return (
    <TableWrapper theme={theme}>
      <DraggableContainer useDragHandle onSortEnd={handleSortEnd} axis="x">
        <Table
          fixed
          data={data}
          columns={cols}
          theme={theme}
          rowHeight={69}
          headerHeight={60}
          sortBy={sortBy}
          onColumnSort={onColumnSort}
          components={components}
          headerCellProps={headerCellProps}
          frozenData={frozenData}
        />
      </DraggableContainer>
    </TableWrapper>
  );
}

export default MetricTable;
