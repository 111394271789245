import React, { memo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { SiderWrap, StyledMenuWrap } from './styled';
import { useAppSelector } from '@src/store/hooks';

const { SubMenu, Item } = Menu;

interface Props {
  activePath: string;
}

const Sider: React.FC<Props> = (props) => {
  const history = useHistory();
  const state = useAppSelector(
    (state) => {
      return {
        urlQueryParams: state.system.urlQueryParams,
      };
    },
    (prev, next) => {
      return prev.urlQueryParams === next.urlQueryParams;
    },
  );

  const handleClick = (e) => {
    if (e.key === 'mapping-rules') {
      history.push('/rule-engine/mapping-rules?' + state.urlQueryParams);
    }
  };

  return (
    <SiderWrap data-test-id="sider-menu">
      <StyledMenuWrap>
        <Menu
          defaultOpenKeys={['mapping-rules']}
          selectedKeys={[props.activePath]}
          mode="inline"
          onClick={handleClick}
        >
          <SubMenu
            key="mapping-rules"
            title="Mapping Rules"
            onTitleClick={handleClick}
          >
            <Item data-test-id="menu-required-fields" key="required-fields">
              <Link to={'/rule-engine/required-fields?' + state.urlQueryParams}>
                Required Fields
              </Link>
            </Item>
            <Item data-test-id="menu-automated-fields" key="automated-fields">
              <Link
                to={'/rule-engine/automated-fields?' + state.urlQueryParams}
              >
                Automated Fields
              </Link>
            </Item>
            <Item data-test-id="menu-custom-tags" key="custom-tags">
              <Link to={'/rule-engine/custom-tags?' + state.urlQueryParams}>
                Custom Tags
              </Link>
            </Item>
            <Item data-test-id="menu-regex-match-rules" key="regex-match-rules">
              <Link
                to={'/rule-engine/regex-match-rules?' + state.urlQueryParams}
              >
                RegEx Match Rules
              </Link>
            </Item>
          </SubMenu>
          <Item key="dry-run" data-test-id="menu-dry-run">
            <Link to={'/rule-engine/dry-run?' + state.urlQueryParams}>
              Dry Run
            </Link>
          </Item>
        </Menu>
      </StyledMenuWrap>
    </SiderWrap>
  );
};

export default memo(Sider);
