import { systemConfigurationHttpClient } from '@src/service/http';
import { useQuery } from 'react-query';

export const getUserInfo = async () => {
  try {
    let url = `/sys-users/login-user-info`;
    return await systemConfigurationHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const fetchSupportedLetter = () => {
  return systemConfigurationHttpClient.get(
    '/sys-dict-groups?groupCodes=LETTER_CASING,CI_REQUIRED_FIELD,AUTOMATED_METADATA_MAPPING&itemEnabled=true',
  );
};

//Queries
export const useUserInfo = () => {
  return useQuery(['get_user_info'], async () => {
    const { data } = await getUserInfo();
    return data;
  });
};

export const useSupportedLetterType = () => {
  return useQuery(['letterType'], async () => {
    const { data } = await fetchSupportedLetter();
    const options = data[0].sysDictItems
      .filter((i: any) => i.enabled)
      .map((i: any) => ({
        label: i.name,
        value: i.code,
      }));
    options.unshift({ label: 'no change', value: null });
    return options;
  });
};
