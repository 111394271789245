import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import modalReducer from '@src/store/reducers/modal';
import ruleEngineReducer from '@src/store/reducers/ruleEngine';
import mdsReducer from '@src/store/reducers/system';
import dryRunCompareReducer from '@src/store/reducers/dryRunCompare';
import ruleCompareReducer from '@src/store/reducers/ruleCompare';
import gapAnalysisReducer from '@src/store/reducers/gapAnalysis';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    modal: modalReducer,
    system: mdsReducer,
    ruleEngine: ruleEngineReducer,
    dryRunCompare: dryRunCompareReducer,
    ruleCompare: ruleCompareReducer,
    gapAnalysis: gapAnalysisReducer,
  });
