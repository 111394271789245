import React from 'react';
import { List, Spin } from 'antdV3';
import { useQuery } from 'react-query';
import { EReactQueryKey } from '@src/utils/constants';
import { requestDryRunReportFieldDetailV1 } from '@src/service/api';

export const DryRunReportCompareDetailV1: React.FC<{
  dryRunId1?: string;
  fieldId: string;
  dryRunId2?: string;
}> = (props) => {
  const { dryRunId1 = '', fieldId, dryRunId2 = '' } = props;

  const { data: dataSource1, status } = useQuery(
    [EReactQueryKey.DRY_RUN_REPORT_FIELD_DETAIL, dryRunId1, fieldId],
    async () => {
      return await requestDryRunReportFieldDetailV1({
        params: {
          dryRunId: dryRunId1,
          fieldName: fieldId,
        },
      });
    },
  );
  const { data: dataSource2 } = useQuery(
    [EReactQueryKey.DRY_RUN_REPORT_FIELD_DETAIL, dryRunId2, fieldId],
    async () => {
      return await requestDryRunReportFieldDetailV1({
        params: {
          dryRunId: dryRunId2,
          fieldName: fieldId,
        },
      });
    },
  );
  let res: any = {
    list1: dataSource1,
    list2: dataSource2,
  };

  return (
    <Spin tip={'Loading...'} spinning={status === 'loading'} size={'small'}>
      {!Array.isArray(res) ? (
        <DryRunReportCompareDetail dataSource={res} />
      ) : null}
    </Spin>
  );
};

export const DryRunReportCompareDetail: React.FC<{
  dataSource: any;
}> = (props) => {
  const { dataSource } = props;
  return (
    <div
      style={{
        display: 'flex',
        maxHeight: '300px',
        overflow: 'scroll',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ width: '45%', marginRight: '3%' }}>
        <p style={{ width: '100%', borderBottom: '1px solid #ccc' }}>
          dry run 1
        </p>
        <List
          dataSource={dataSource.list1}
          renderItem={(item: any) => (
            <List.Item key={item.fieldValue}>
              <div style={{ marginRight: '10px', whiteSpace: 'pre' }}>
                {item.fieldValue}
              </div>
              <div>{item.count}</div>
            </List.Item>
          )}
        />
      </div>
      <div style={{ width: '45%' }}>
        <p style={{ width: '100%', borderBottom: '1px solid #ccc' }}>
          dry run 2
        </p>
        <List
          dataSource={dataSource.list2}
          renderItem={(item: any) => (
            <List.Item key={item.fieldValue}>
              <div style={{ marginRight: '10px', whiteSpace: 'pre' }}>
                {item.fieldValue}
              </div>
              <div>{item.count}</div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};
