import React from 'react';
import { Provider } from 'react-redux';
import { message } from 'antd';
import { renderRoutes } from 'react-router-config';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import store from '@src/store';
import { history, routes } from '@src/router';
import Layout from '@src/layouts/BaseLayout';

message.config({
  duration: 3,
  maxCount: 3,
  // Since all message import form antdV4 add the custom prefix.
  prefixCls: 'mds-ant-message',
});

console.log('Current ENV: ' + process.env.REACT_APP_ENV);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <Layout>{renderRoutes(routes)}</Layout>
        </ConnectedRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
