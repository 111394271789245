import React from 'react';

type ResultType = {
  regexCompare1?: any;
  regexCompare2?: any;
};

const RegexRuleRight = (props: any) => {
  const { regexItem1, regexItem2 } = props;

  const compareRegexKeys = ['regexExpressions'];
  const compareRegex = (regex1, regex2) => {
    if (regex1 && regex2) {
      let res: ResultType = {
        regexCompare1: {},
        regexCompare2: {},
      };
      compareRegexKeys.forEach((key) => {
        if (JSON.stringify(regex1[key]) !== JSON.stringify(regex2[key])) {
          res.regexCompare1[key] = regex1?.[key];
          res.regexCompare2[key] = regex2?.[key];
        }
      });
      return res;
    }
    return {
      regexCompare1: regex1,
      regexCompare2: regex2,
    };
  };

  const res = compareRegex(regexItem1, regexItem2);

  const noDataDom = (
    <ul
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      No Data in version
    </ul>
  );

  const renderCompareResult = (result) => {
    if (result) {
      return (
        <ul>
          {result.regexExpressions ? (
            <li style={{ lineHeight: '20px' }}>
              <b>Regex Rules:</b>
              {result?.regexExpressions?.map((v, i) => {
                return (
                  <p style={{ marginLeft: '20px' }} key={i}>
                    <span
                      style={{ display: 'inline-block', marginRight: '15px' }}
                    >
                      {i + 1}.
                    </span>
                    <span>{v}</span>
                  </p>
                );
              })}
            </li>
          ) : (
            noDataDom
          )}
        </ul>
      );
    }
    return noDataDom;
  };

  return (
    <div className="right">
      <div>
        {renderCompareResult(res.regexCompare1)}
        {renderCompareResult(res.regexCompare2)}
      </div>
    </div>
  );
};

export default RegexRuleRight;
