import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Form, Radio, Select, Spin } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import { RULE_STATUS_INIT } from '@src/utils/constants';
import { ADDED, MODIFIED, REMOVED } from './constants';
import RegexRule from './RegexRule';
import FieldsRight from './FieldsRight';
import { requestRuleConfig } from '@src/service/api';
import { getBackground } from './common';
import './index.less';
import RegexRuleRight from './RegexRuleRight';
import {
  updateStateRuleFieldsVersion1,
  updateStateRuleFieldsVersion2,
  updateStateRuleRegexVersion1,
  updateStateRuleRegexVersion2,
  updateStateRuleSelectVersion1,
  updateStateRuleSelectVersion2,
} from '@src/store/actions/ruleCompare';

const { Option } = Select;

const RuleCompare = (props) => {
  const [version1, setVersion1]: any = useState([]);
  const [version2, setVersion2]: any = useState([]);
  const [regexList1, setRegexList1]: any = useState([]);
  const [regexList2, setRegexList2]: any = useState([]);
  const [item1, setItem1] = useState(null);
  const [item2, setItem2] = useState(null);
  const [regexItem1, setRegexItem1] = useState(null);
  const [regexItem2, setRegexItem2] = useState(null);
  const [value, setValue] = useState<string>('fields');
  const [versionSame, setVersionSame] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const store = useAppSelector((state) => {
    return {
      ruleEngine: state.ruleEngine,
      ruleCompare: state.ruleCompare,
    };
  });
  let { ruleVersions, activeBusinessParcel, activeRule } = store.ruleEngine;
  const {
    ruleSelectVersion1,
    ruleSelectVersion2,
    ruleFieldsVersion1,
    ruleFieldsVersion2,
    ruleRegexVersion1,
    ruleRegexVersion2,
  } = store.ruleCompare;
  const ruleTitle = activeBusinessParcel.project?.projectName;

  const onFinish = async (values: any) => {
    const { selectVersion1, selectVersion2 } = values;
    setShow(true);
    setLoading(true);
    setActiveIndex(0);
    // compare list
    let fieldsCompare1: any = [];
    let fieldsCompare2: any = [];
    let regexCompare1: any = [];
    let regexCompare2: any = [];
    if (selectVersion1 === selectVersion2) {
      setVersionSame(true);
    } else {
      setVersionSame(false);
      let v1FieldsRuleList = await requestRuleConfig(selectVersion1);
      let v2FieldsRuleList = await requestRuleConfig(selectVersion2);
      let v1FieldsData = v1FieldsRuleList?.data?.ruleFieldMappings || [];
      let v2FieldsData = v2FieldsRuleList?.data.ruleFieldMappings || [];

      v1FieldsData?.forEach((item) => {
        let index = v2FieldsData?.findIndex(
          (v) => v.fieldCode === item.fieldCode,
        );
        if (index > -1) {
          if (JSON.stringify(item) !== JSON.stringify(v2FieldsData[index])) {
            item.compareType = MODIFIED;
            v2FieldsData[index].compareType = MODIFIED;
            fieldsCompare1.push(item);
            fieldsCompare2.push(v2FieldsData[index]);
          }
        } else {
          item.compareType = ADDED;
          fieldsCompare1.push(item);
          fieldsCompare2.push(null);
        }
      });
      v2FieldsData?.forEach((item) => {
        let index = v1FieldsData.findIndex(
          (v) => v.fieldCode === item.fieldCode,
        );
        if (index === -1) {
          item.compareType = REMOVED;
          fieldsCompare2.push(item);
          fieldsCompare1.push(null);
        }
      });
      setItem1(fieldsCompare1[0] || null);
      setItem2(fieldsCompare2[0] || null);
      setVersion1(fieldsCompare1);
      setVersion2(fieldsCompare2);
      //  Regex rule
      let v1RegexData =
        v1FieldsRuleList?.data?.ruleFieldExtractingConfigs || [];
      let v2RegexData =
        v2FieldsRuleList?.data?.ruleFieldExtractingConfigs || [];
      if (!v2RegexData.length) {
        for (let key in v1RegexData) {
          v1RegexData[key].compareType = ADDED;
          regexCompare1.push(v1RegexData[key]);
          regexCompare2.push(null);
        }
      } else if (!v1RegexData.length) {
        for (let key in v2RegexData) {
          v2RegexData[key].compareType = REMOVED;
          regexCompare1.push(null);
          regexCompare2.push(v2RegexData[key]);
        }
      } else if (v1RegexData.length && v2RegexData.length) {
        v1RegexData.forEach((item) => {
          let index = v2RegexData?.findIndex(
            (v) => v.parentPath === item.parentPath,
          );
          if (index > -1) {
            if (JSON.stringify(item) !== JSON.stringify(v2RegexData[index])) {
              if (item.parentPath === v2RegexData[index].parentPath) {
                item.compareType = MODIFIED;
                v2RegexData[index].compareType = MODIFIED;
                regexCompare1.push(item);
                regexCompare2.push(v2RegexData[index]);
              } else {
                item.compareType = ADDED;
                regexCompare1.push(item);
                regexCompare2.push(null);
              }
            }
          }
        });
        v2RegexData.forEach((item) => {
          let index = v1RegexData.findIndex(
            (v) => v.parentPath === item.parentPath,
          );
          if (index === -1) {
            item.compareType = REMOVED;
            regexCompare2.push(item);
            regexCompare1.push(null);
          }
        });
      }
      setRegexItem1(regexCompare1[0] || null);
      setRegexItem2(regexCompare2[0] || null);
      setRegexList1(regexCompare1);
      setRegexList2(regexCompare2);

      dispatch(updateStateRuleFieldsVersion1(fieldsCompare1));
      dispatch(updateStateRuleFieldsVersion2(fieldsCompare2));
      dispatch(updateStateRuleRegexVersion1(regexCompare1));
      dispatch(updateStateRuleRegexVersion2(regexCompare2));
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const RadioOnChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
  };

  const changeRules = (item1: any, index: number) => {
    setActiveIndex(index);
    setItem1(item1);
    setItem2(version2[index]);
  };

  const ruleVersionOptions = ruleVersions.map((item) => {
    const ruleState = item.state !== RULE_STATUS_INIT ? `(${item.state})` : '';
    return (
      <Option
        data-test-id="version-list-item"
        value={item.uuid}
        key={item.uuid}
      >
        {ruleTitle} {item.versionNo}.0 {ruleState}
      </Option>
    );
  });

  const renderLeftCompare = (item, index) => {
    if (item) {
      return (
        <p style={getBackground(item, index, activeIndex)}>
          {item.compareType === ADDED ? (
            <span
              style={{
                margin: '0 18px',
                fontWeight: 'bold',
              }}
            >
              +
            </span>
          ) : item.compareType === REMOVED ? (
            <span
              style={{
                margin: '0 18px',
                fontWeight: 'bold',
              }}
            >
              -
            </span>
          ) : (
            <span className="compareMark"></span>
          )}
          <span title={item.fieldName}>{item.fieldName} </span>
        </p>
      );
    } else {
      return <p style={getBackground(item, index, activeIndex)}></p>;
    }
  };

  const clearDate = () => {
    dispatch(updateStateRuleFieldsVersion1([]));
    dispatch(updateStateRuleFieldsVersion2([]));
    dispatch(updateStateRuleRegexVersion1([]));
    dispatch(updateStateRuleRegexVersion2([]));
    setShow(false);
    setItem1(null);
    setItem2(null);
    setVersion1([]);
    setVersion2([]);
    setRegexItem1(null);
    setRegexItem2(null);
    setRegexList1([]);
    setRegexList2([]);
  };

  const changeVersion2 = (value) => {
    form.setFieldsValue({ selectVersion2: value });
    dispatch(updateStateRuleSelectVersion2(value));
    clearDate();
  };
  const changeVersion1 = async (value) => {
    form.setFieldsValue({ selectVersion1: value });
    dispatch(updateStateRuleSelectVersion1(value));
    clearDate();
  };

  useEffect(() => {
    form.setFieldsValue({
      selectVersion1: ruleSelectVersion1 || activeRule?.uuid,
      selectVersion2: ruleSelectVersion2 || ruleVersions[0]?.uuid,
    });
    if (ruleSelectVersion1 === '') {
      dispatch(updateStateRuleSelectVersion1(activeRule?.uuid));
    }
    if (ruleSelectVersion2 === '') {
      dispatch(updateStateRuleSelectVersion2(ruleVersions[0]?.uuid));
    }
  }, []);

  useEffect(() => {
    if (ruleFieldsVersion1.length > 0 && ruleFieldsVersion2.length > 0) {
      setShow(true);
      setItem1(ruleFieldsVersion1[0]);
      setItem2(ruleFieldsVersion2[0]);
      setVersion1(ruleFieldsVersion1);
      setVersion2(ruleFieldsVersion2);
    }
    if (ruleRegexVersion1.length > 0 && ruleRegexVersion2.length > 0) {
      setRegexItem1(ruleRegexVersion1[0]);
      setRegexItem2(ruleRegexVersion2[0]);
      setRegexList1(ruleRegexVersion1);
      setRegexList2(ruleRegexVersion2);
    }
  }, []);

  const NoData = (
    <ul
      style={{
        width: '50%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      No change
    </ul>
  );

  const leftCompare = () => {
    if (show) {
      if (!versionSame) {
        if (value === 'fields') {
          return (
            <ul className="left">
              {version1.length
                ? version1?.map((item: any, index: any) => {
                    return (
                      <li key={index} onClick={() => changeRules(item, index)}>
                        {renderLeftCompare(item, index)}
                        {renderLeftCompare(version2[index], index)}
                      </li>
                    );
                  })
                : version2?.map((item: any, index: any) => {
                    return (
                      <li key={index} onClick={() => changeRules(item, index)}>
                        {renderLeftCompare(item, index)}
                        {renderLeftCompare(version1[index], index)}
                      </li>
                    );
                  })}
            </ul>
          );
        } else {
          return (
            <RegexRule
              regexList1={regexList1}
              regexList2={regexList2}
              regexItem1={regexItem1}
              regexItem2={regexItem2}
              setRegexItem1={setRegexItem1}
              setRegexItem2={setRegexItem2}
            />
          );
        }
      }
    }
  };

  return (
    <ConfigProvider prefixCls="mds-ant">
      <Spin spinning={loading}>
        <div className="ruleVersionCompare">
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Form.Item
              label="Version 1"
              name="selectVersion1"
              style={{ display: 'flex' }}
            >
              <Select onChange={changeVersion1}>{ruleVersionOptions}</Select>
            </Form.Item>
            <span style={{ marginTop: '-20px' }}>Compare With</span>
            <Form.Item
              label="Version 2"
              name="selectVersion2"
              style={{ display: 'flex' }}
            >
              <Select onChange={changeVersion2}>{ruleVersionOptions}</Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Compare
              </Button>
            </Form.Item>
          </Form>
          {versionSame ? (
            <div className="compareTip">
              Please choose two different version to compare
            </div>
          ) : null}
          <div className="compareContent">
            <Radio.Group
              onChange={RadioOnChange}
              style={{ marginBottom: 3 }}
              value={value}
              buttonStyle="solid"
            >
              <Radio.Button value="fields">Fields</Radio.Button>
              <Radio.Button value="regexRule">Regex Rule</Radio.Button>
            </Radio.Group>
            <div className="compare">
              <div className="compareLeft">
                <p className="leftVersion">
                  <span>Version 1</span>
                  <span>Version 2</span>
                </p>

                {leftCompare()}
              </div>
              <div className="compareRight">
                <p className="rightVersion">
                  <span>Version 1</span>
                  <span>Version 2</span>
                </p>
                {show ? (
                  !versionSame ? (
                    value === 'fields' ? (
                      <FieldsRight field1={item1} field2={item2} />
                    ) : (
                      <RegexRuleRight
                        regexItem1={regexItem1}
                        regexItem2={regexItem2}
                      />
                    )
                  ) : (
                    <div className="right">
                      <div>
                        {NoData}
                        {NoData}
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default RuleCompare;
