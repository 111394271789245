import { ruleEngineHttpClient } from '@src/service/http';
import { getActiveProjectCode } from '@src/utils/ruleEngine';
import { IConfigTemplate } from '@src/typing/IDMergeConfiguration';

const baseUrl = '/metadata-configuration';

export const requestMetadataConfiguration = (templateId: string) => {
  const projectCode = getActiveProjectCode();
  const url = `${baseUrl}/instances?metadataTemplateId=${templateId}&projectCode=${projectCode}`;
  return ruleEngineHttpClient.get(url);
};

export const postMetadataConfiguration = (
  pipelineConfigInstances: IConfigTemplate[],
) => {
  const url = `${baseUrl}/instances`;
  return ruleEngineHttpClient.post(url, {
    pipelineConfigInstances,
  });
};
