/*
 *
 * @Author : zyang@conviva.com
 * @Date : 2021/2/26
 * @Version : 1.0
 *
 */

import React from 'react';
import { Modal } from 'antd';
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router-dom';
import store from '@src/store';
import Coverage from '@src/pages/PreMdsCoverage/index';
import { RuleEngine } from '@src/pages/RuleEngine';
import VersionControl from '@src/pages/VersionControl';
import DryRunReport from '@src/pages/RuleEngine/modules/DryRun/DryRunReport';
import FiledMapRuleV2 from '@src/pages/RuleEngine/modules/FiledMapRule/v2index';
import RegexMatchRuleV2 from '@src/pages/RuleEngine/modules/RegexMatchRule/v2index';
import RuleCompare from '@src/pages/RuleVersionCompare';
import DryRunCompare from '@src/pages/DryRunCompare';
import DryRun from '@src/pages/RuleEngine/modules/DryRun';
import { selectRegexToActive } from '@src/store/actions/ruleEngine';
import {
  GROUP_CODE_AUTOMATED_METADATA_MAPPING,
  GROUP_CODE_CI_REQUIRED_FIELD,
  GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
} from '@src/store/types/types';
import GapAnalysis from '@src/pages/GapAnalysis';

const baseUrl = window.__POWERED_BY_QIANKUN__ ? '/app/mds' : '';

export const history = createBrowserHistory({
  basename: baseUrl,
});

// router change
history.listen(() => {
  store.dispatch(selectRegexToActive(''));
  if (history.location) {
    window.parent.postMessage(
      {
        type: 'sync-url',
        url: history.location.pathname + history.location.search,
      },
      '*',
    );
  }
  Modal.destroyAll();
});

export const routes = [
  {
    path: `/`,
    component: '',
    render() {
      return <Redirect to="/coverage-report" />;
    },
    exact: true,
  },
  {
    path: `/coverage-report`,
    render: () => {
      return <Coverage />;
    },
  },
  {
    path: `/gap-analysis-report`,
    render: () => {
      return <GapAnalysis />;
    },
  },
  {
    path: `/rule-engine`,
    component: RuleEngine,
    routes: [
      {
        path: `/rule-engine/mapping-rules`,
        // component: FiledMapRuleV2,
        render: () => <FiledMapRuleV2 type="ALL" />,
      },
      {
        path: `/rule-engine/automated-fields`,
        // component: FiledMapRuleV2,
        render: () => (
          <FiledMapRuleV2 type={GROUP_CODE_AUTOMATED_METADATA_MAPPING} />
        ),
      },
      {
        path: `/rule-engine/required-fields`,
        // component: FiledMapRuleV2,
        render: () => <FiledMapRuleV2 type={GROUP_CODE_CI_REQUIRED_FIELD} />,
      },
      {
        path: `/rule-engine/regex-match-rules`,
        component: RegexMatchRuleV2,
      },
      {
        path: `/rule-engine/custom-tags`,
        render: () => (
          <FiledMapRuleV2 type={GROUP_CODE_CUSTOMER_CUSTOM_FIELDS} />
        ),
      },
      {
        path: `/rule-engine/dry-run`,
        component: DryRun,
      },
    ],
  },
  {
    path: `/version-control`,
    component: VersionControl,
  },
  {
    // path: `/dry-run/:ruleId/:versionNo/:dryRunId`,
    path: '/dry-run/:businessParcelId/:ruleId/:dryRunId',
    component: DryRunReport,
  },
  {
    path: `/rule-compare`,
    component: RuleCompare,
  },
  {
    path: `/dry-run-compare`,
    component: DryRunCompare,
  },
];
