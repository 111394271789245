import React, { useState, useEffect, useCallback } from 'react';
import { Spin, InputNumber, Button, DatePicker, Dropdown, Menu } from 'antd';
import { MoreOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  ContentWrapper,
  TopBarWrapper,
} from '@src/pages/PreMdsCoverage/styled';
import './gapAnanlysisReport.less';
import CreateNewReports from '@src/pages/GapAnalysis/components/CreateNewReports';
import {
  useShowCondition,
  useShowCoverage,
} from '@src/service/apis/gapAnalysis';
import { ThemeContext } from '@src/contexts';
import MetricTable from '@src/components/MetricTable/GapAnalysisTable';
import {
  GapAnalysisHeader,
  GapAnalysisScope,
} from '@src/components/MetricTable/common';
import SampleValueModal from '@src/pages/GapAnalysis/components/SampleValueModal';
import Cell from '@src/pages/GapAnalysis/components/Cell';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import {
  modifyUpdateCoverageMark,
  modifyUpdateFilterMark,
  resetConditionAndCoverage,
  updateConditionFilterList,
  updateConditionResultList,
  updateCoverageFilterFields,
  updateCoverageResultList,
  updateCurrentFilterFields,
  updateCurrentJob,
  updateCurrentResultList,
  updateSelectedFilterFields,
} from '@src/store/actions/gapAnalysis';
import FilterModal from '@src/pages/GapAnalysis/components/FilterModal';
import { IColumn } from '@src/typing/gapAnalysis';
import { sortBy as _sortBy, cloneDeep as _cloneDeep } from 'lodash';

const STATUS_NOT_REQUIRED = 'N/A';
const ALL_APPS_DATA_KEY = 'ALL';
const ALL_TAGS = 'allTags';
const TAG_DISPLAY_NAME = 'tagDisplayName';
const TAG_KEY_NAME = 'tagKeyName';
const ORDER_DESC = 'desc';
const ORDER_ASC = 'asc';
const COLUMN_WIDTH = 102 + 33;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const DEFAULT_SELECTED_BREAK_DOWN_FIELD = 5;

const createAliasKey = (label: string) => {
  return label.replace(/\./g, '_');
};

interface IGapAnalysisReport {
  openCreateNewReport: boolean;
  onOpenCreateNewReport: () => void;
  onCancelCreateNewReport: () => void;
  onCreateNewReport: (values: any) => void;
  onOpenHistoricalReport: () => void;
}

const GapAnalysisReport: React.FC<IGapAnalysisReport> = ({
  openCreateNewReport,
  onOpenCreateNewReport,
  onCancelCreateNewReport,
  onCreateNewReport,
  onOpenHistoricalReport,
}) => {
  const dispatch = useAppDispatch();
  const { theme } = React.useContext(ThemeContext);
  const state = useAppSelector((state) => {
    return {
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const {
    currentJob,
    latestJob,
    selectedFilterFields,
    coverageFilterFields,
    currentFilterFields,
    conditionResultList,
    coverageResultList,
    currentResultList,
    updateCoverageMark,
    updateFilterMark,
    activeBusinessParcel: {
      dataSource: { dataSourceCode },
    },
  } = gapAnalysis;
  const jobId = currentJob?.uuid;

  const commonRequestParams = {
    jobId: jobId,
    dataSourceCode,
  };

  const conditionRequest = {
    ...commonRequestParams,
    filterCondition: selectedFilterFields,
  };

  const { data: conditionData, isLoading: conditionLoading } =
    useShowCondition(conditionRequest);

  const filterResult =
    Array.isArray(coverageResultList) && coverageResultList.length > 0
      ? coverageResultList
          .map((item) => {
            const { column } = item;
            if (!column.checked) return null;
            return column;
          })
          .filter(Boolean)
      : conditionData?.resultList.map((item) => item.column);

  const shouldUpdatedCoverage =
    selectedFilterFields.length === conditionData?.filterList.length &&
    updateCoverageMark;

  const enabled = (() => {
    let enabled = false;
    if (!conditionLoading && jobId !== '') {
      const filterListLength = conditionData?.filterList.length;
      const resultListLength = conditionData?.resultList.length;
      if (!!(filterListLength && resultListLength)) {
        enabled =
          selectedFilterFields.length !== 0 &&
          filterResult.length !== 0 &&
          coverageFilterFields.length !== 0;
      } else if (filterListLength) {
        enabled = coverageFilterFields.length !== 0;
      } else if (resultListLength) {
        enabled = filterResult.length !== 0;
      } else {
        enabled =
          selectedFilterFields.length === 0 &&
          filterResult.length === 0 &&
          coverageFilterFields.length === 0;
      }
    }
    return updateCoverageMark && enabled;
  })();

  const coverageParams = {
    ...commonRequestParams,
    filterFields: coverageFilterFields,
    breakDownFields: filterResult,
    // Let the showCondition interface call before the showCoverage interface
    enabled: enabled,
  };

  const { data: coverageData, isLoading: showCoverageLoading } =
    useShowCoverage(coverageParams);

  const [loading, setLoading] = useState(false);
  const [thresholdInput, setThresholdInput] = useState<number>(80);
  /* gap analysis Table */
  const [cols, setCols] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [frozenData, setFrozenData] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState({
    key: TAG_DISPLAY_NAME,
    order: ORDER_ASC,
  });
  /* sample value Modal */
  const [sampleValueVisible, setSampleValueVisible] = useState(false);
  const [tag, setTag] = useState<{ tagKey: string; parentPath: string }>({
    tagKey: '',
    parentPath: '',
  });
  const [currentColumn, setCurrentColumn] = useState<IColumn | {}>({});
  /* filter Modal */
  const [filterVisible, setFilterVisible] = useState(false);

  const buildTableData = useCallback(
    (response) => {
      if (!Array.isArray(response?.rows)) return [];
      return response?.rows
        ?.map((row) => {
          let key = row.tag.tagKey;
          if (row.tag.parentPath) {
            key = `${row.tag.tagKey} ${row.tag.parentPath}`;
          }
          if (row.tag === 'ALL' && !row.breakDownFields) {
            return null;
          }
          const appObj = {};
          const apps = coverageResultList
            .map((l) => {
              if (!l.column.checked) return null;
              const label = l?.column?.label;
              const found = row.breakDownFields.find((r) => {
                return r.label === label;
              });
              if (found) {
                appObj[createAliasKey(found.label)] = found;
              } else {
                appObj[createAliasKey(label)] = {
                  coverage: 'N/A',
                  label: label,
                };
              }
              return l;
            })
            .filter(Boolean);

          return {
            id: key,
            key: key,
            parentPath: row.tag.parentPath,
            tagDisplayName: row.tag.tagKey,
            tagKeyName: row.tag.tagKey,
            [ALL_APPS_DATA_KEY]: row.breakDownFields[0],
            apps: apps,
            ...appObj,
          };
        })
        .filter(Boolean);
    },
    [coverageResultList],
  );

  const buildMetricColCell = useCallback(
    (options) => {
      const { cellData, column, rowData } = options;
      const { tagDisplayName } = rowData;
      if (!cellData) return <div></div>;
      let category = 'Good';
      const value = parseInt(cellData);
      if (value === 0) {
        category = 'Critical';
      }
      if (value > 0 && value < thresholdInput) {
        category = 'Warning';
      }
      let disabled = false;
      const isAllTagsRow = tagDisplayName === 'All Tags';

      if (cellData === STATUS_NOT_REQUIRED || isAllTagsRow) {
        return (
          <Cell
            onClick={() => {}}
            pressed={true}
            disabled={disabled}
            cellData={cellData}
            column={column}
            rowData={rowData}
            unit={isAllTagsRow ? '%' : ''}
            category={category}
          />
        );
      }

      return (
        <Cell
          onClick={onClickCell}
          pressed={false}
          disabled={disabled}
          cellData={cellData}
          column={column}
          rowData={rowData}
          unit="%"
          category={category}
        />
      );
    },
    [thresholdInput],
  );

  const buildTableCols = useCallback(
    (response) => {
      const mainCol = [
        {
          title: 'All Tags',
          dataKey: 'tagDisplayName',
          key: 'tagDisplayName',
          width: 290,
          frozen: true,
          align: 'left',
          headerRenderer: () => {
            return <div></div>;
          },
          cellRenderer: ({ cellData, rowData }) => {
            return <GapAnalysisScope text={cellData} rowData={rowData} />;
          },
        },
        {
          title: 'ALL ',
          aliasAppName: ALL_APPS_DATA_KEY,
          dataKey: `${ALL_APPS_DATA_KEY}.coverage`,
          key: ALL_APPS_DATA_KEY,
          width: COLUMN_WIDTH,
          frozen: true,
          resizable: false,
          align: 'left',
          headerRenderer: () => (
            <GapAnalysisHeader app={{ column: { label: 'ALL' } }} />
          ),
          cellRenderer: buildMetricColCell,
        },
      ];
      const appsCols = response
        .map((app) => {
          const { column } = app;
          if (!column.checked) return null;
          const aliasKey = createAliasKey(column.label);
          const dataKey = `${aliasKey}.coverage`;
          return {
            appName: column.label,
            aliasAppName: aliasKey,
            /* The symbol "." is not recognized in dataKey and is converted to "_". */
            dataKey: dataKey,
            key: dataKey + new Date().getTime() + Math.random(),
            width: COLUMN_WIDTH,
            align: 'left',
            sortable: true,
            headerRenderer: () => <GapAnalysisHeader app={app} />,
            cellRenderer: buildMetricColCell,
          };
        })
        .filter(Boolean);
      // shift "All Apps" column.
      if (Array.isArray(appsCols)) {
        return [...mainCol, ...appsCols];
      }
      return [];
    },
    [buildMetricColCell],
  );

  useEffect(() => {
    setLoading(showCoverageLoading || conditionLoading);
  }, [conditionLoading, showCoverageLoading]);

  useEffect(() => {
    if (!showCoverageLoading && coverageData) {
      const tableData = buildTableData(coverageData);
      const sortKey = createAliasKey(sortBy.key);
      const order = sortBy.order;
      let tableDataSorted: any[];
      if (sortKey !== TAG_KEY_NAME && sortKey !== TAG_DISPLAY_NAME) {
        tableDataSorted = _sortBy(tableData, (o) => parseInt(o[sortKey]));
        if (order === ORDER_DESC) {
          tableDataSorted = tableDataSorted.reverse();
        }
      } else {
        tableDataSorted = _sortBy(tableData, (o) => o[sortKey].toLowerCase());
      }
      setData(tableDataSorted);
      setSampleValueVisible(false);
      !enabled && dispatch(modifyUpdateCoverageMark(false));
    }
  }, [
    buildTableData,
    coverageData,
    dispatch,
    enabled,
    showCoverageLoading,
    sortBy,
  ]);

  useEffect(() => {
    if (shouldUpdatedCoverage) {
      dispatch(updateCoverageFilterFields(_cloneDeep(selectedFilterFields)));
      dispatch(updateCoverageResultList(_cloneDeep(conditionResultList)));
    }
  }, [
    conditionResultList,
    dispatch,
    selectedFilterFields,
    shouldUpdatedCoverage,
  ]);

  useEffect(() => {
    if (!conditionLoading) {
      if (conditionData) {
        // filter button list
        dispatch(updateConditionFilterList(conditionData.filterList));
        // default value
        const filterFields = conditionData.filterList.map((item: any) => {
          return {
            key: item.key,
            value: item.values[0],
          };
        });
        const resultList = conditionData.resultList || [];
        const addCheckedConditionData = resultList.map((item, index) => {
          item.column.checked = index < DEFAULT_SELECTED_BREAK_DOWN_FIELD;
          return item;
        });
        if (selectedFilterFields.length === 0) {
          dispatch(updateSelectedFilterFields(filterFields));
        }
        if (updateFilterMark) {
          dispatch(updateConditionResultList(addCheckedConditionData));
        }
      }
    }
  }, [
    conditionData,
    conditionLoading,
    dispatch,
    updateCoverageMark,
    updateFilterMark,
    selectedFilterFields.length,
  ]);

  useEffect(() => {
    const cols = buildTableCols(coverageResultList);
    setCols(cols);
    const frozenData = buildFrozenData(coverageResultList);
    setFrozenData(frozenData);
  }, [buildTableCols, coverageResultList, dispatch, thresholdInput]);

  const menu = () => {
    const items = [
      { key: 'create', label: 'Create New Report' },
      { key: 'historical', label: 'Historical Report' },
    ];
    return <Menu items={items} onClick={onActionApply}></Menu>;
  };

  const onActionApply = ({ key }) => {
    switch (key) {
      case 'create':
        onOpenCreateNewReport();
        break;
      case 'historical':
        onOpenHistoricalReport();
        break;
      default:
        break;
    }
  };

  const onCancelSampleValueModal = () => {
    setSampleValueVisible(false);
  };

  const onBackLatestJob = () => {
    dispatch(updateCurrentJob(latestJob));
    dispatch(resetConditionAndCoverage());
    dispatch(modifyUpdateCoverageMark(true));
    dispatch(modifyUpdateFilterMark(true));
  };

  const onClickCell = ({ currentColumn, rowData }) => {
    const { tagKeyName, parentPath } = rowData;
    if (tagKeyName === ALL_TAGS) return;
    setSampleValueVisible(true);
    setTag({ tagKey: tagKeyName, parentPath });
    if (currentColumn) {
      setCurrentColumn(currentColumn);
    } else {
      setCurrentColumn({});
    }
  };

  const onOpenFilterVisible = () => {
    setFilterVisible(true);
    dispatch(updateCurrentResultList(_cloneDeep(conditionResultList)));
    dispatch(updateCurrentFilterFields(_cloneDeep(selectedFilterFields)));
    dispatch(modifyUpdateCoverageMark(false));
  };

  const onCancelFilterVisible = () => {
    setFilterVisible(false);
    dispatch(modifyUpdateFilterMark(false));
    dispatch(updateSelectedFilterFields(_cloneDeep(currentFilterFields)));
    dispatch(updateConditionResultList(_cloneDeep(currentResultList)));
    dispatch(updateCurrentResultList([]));
    dispatch(updateCurrentFilterFields([]));
  };

  const onApplyFilterVisible = () => {
    setFilterVisible(false);
    dispatch(modifyUpdateFilterMark(false));
    dispatch(modifyUpdateCoverageMark(true));
    dispatch(updateCurrentResultList([]));
    dispatch(updateCurrentFilterFields([]));
    dispatch(updateCoverageResultList(_cloneDeep(conditionResultList)));
    dispatch(updateCoverageFilterFields(_cloneDeep(selectedFilterFields)));
    // update the conditionResultList, in order to refresh the FilterModal component.
    // reason: the latest state cannot be obtained after the FilterModal component is rendered.
    dispatch(updateConditionResultList(_cloneDeep(conditionResultList)));
  };

  const buildFrozenData = (response) => {
    if (!Array.isArray(response)) return [];
    const appObj = {};
    const apps = response
      .map((item) => {
        if (!item.column.checked) return null;
        const label = item.column.label;
        appObj[createAliasKey(label)] = item.column;
        return item;
      })
      .filter(Boolean);
    const frozenData = {
      id: ALL_TAGS,
      key: ALL_TAGS,
      tagDisplayName: 'All Tags',
      tagKeyName: ALL_APPS_DATA_KEY,
      [ALL_APPS_DATA_KEY]: { label: ALL_APPS_DATA_KEY, coverage: '100.0' },
      apps: apps,
      ...appObj,
    };
    return [frozenData];
  };

  const onColumnSort = (sort) => {
    setSortBy({ key: sort.key, order: sort.order });
  };

  return (
    <Spin spinning={loading}>
      <ContentWrapper theme={theme}>
        <TopBarWrapper
          style={{ justifyContent: 'space-between', marginTop: '30px' }}
        >
          <div className="top-left">
            {currentJob && currentJob?.uuid !== latestJob?.uuid ? (
              <div className="back-latest-job" onClick={onBackLatestJob}>
                <ArrowLeftOutlined style={{ fontSize: '32px' }} />
                <span>Back To Current Gap Analysis Report</span>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="top-right">
            <div className="threshold">
              <span>Threshold: </span>
              <InputNumber
                className="threshold-inputNumber"
                defaultValue={80}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                onChange={(value) => {
                  setThresholdInput(value);
                }}
                value={thresholdInput}
              />
            </div>
            <div className="filter" data-test-id="filter-btn">
              <Button className="filter-button" onClick={onOpenFilterVisible}>
                Filter
              </Button>
            </div>
            {currentJob?.timePeriod && (
              <RangePicker
                format={dateFormat}
                style={{ height: '32px' }}
                disabled={true}
                value={[
                  moment(currentJob?.startTime),
                  moment(currentJob?.endTime),
                ]}
              />
            )}
            <div className="action">
              <Dropdown overlay={menu()} trigger={['click']}>
                <MoreOutlined style={{ fontSize: '32px' }} />
              </Dropdown>
            </div>
          </div>
        </TopBarWrapper>
        <div className="report-name">{currentJob?.reportName}</div>
        <MetricTable
          frozenData={frozenData}
          data={data}
          columns={cols}
          sortBy={sortBy}
          onColumnSort={onColumnSort}
        />
        {/* filter Modal */}
        <FilterModal
          visible={filterVisible}
          onCancel={onCancelFilterVisible}
          loading={conditionLoading}
          onApply={onApplyFilterVisible}
        />
      </ContentWrapper>
      {/* create new report Modal */}
      <CreateNewReports
        open={openCreateNewReport}
        onCancel={onCancelCreateNewReport}
        onCreate={onCreateNewReport}
      />
      {/* sample value Modal */}
      <SampleValueModal
        visible={sampleValueVisible}
        onCancel={onCancelSampleValueModal}
        tag={tag}
        currentColumn={currentColumn}
      />
    </Spin>
  );
};

export default GapAnalysisReport;
