import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker, Form, Modal, Spin } from 'antd';
import { Tooltip } from 'conviva-design/es/components/Tooltip';
import { THEME_LIGHT, VERSION_1 } from '@src/utils/constants';
import { closeModal } from '@src/pages/RuleEngine/layouts/modals/index';
import { useAppSelector, useAppDispatch } from '@src/store/hooks';
import { submitSampleDataJob } from '@src/service/api';
import { submitSampleDataJobV2 } from '@src/service/apis/ruleEngine/sampleData';
import { getSampleDataJobs } from '@src/store/actions/ruleEngine';

const { RangePicker } = DatePicker;

export const MODAL_KEY = 'FETCH_SAMPLE_DATA';

const FetchSampleDataModal: React.FC = (props) => {
  const [dates, setDates] = useState<any>([]);
  const [value, setValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => {
    return {
      visibleKey: state.modal.visibleKey,
      loading: state.ruleEngine.loading['global'],
      activeBusinessParcel: state.ruleEngine.activeBusinessParcel,
    };
  });

  const disabledDate = (current) => {
    const afterToday = current > moment().startOf('day');
    if (!dates || dates.length === 0) {
      return afterToday;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;
    return tooLate || tooEarly || afterToday;
  };

  const handleSubmit = (e) => {
    form.validateFields().then((values) => {
      let dateFormat = '';
      let requestFn = submitSampleDataJobV2;
      let startDateTime = values.time[0]
        .utc()
        .startOf('day')
        .format(dateFormat);
      let endDateTime = values.time[1].utc().endOf('day').format(dateFormat);
      // IF FOR V2
      if (state.activeBusinessParcel.reVersion === VERSION_1) {
        dateFormat = 'YYYY-MM-DD';
        requestFn = submitSampleDataJob;
        startDateTime = values.time[0].format(dateFormat);
        endDateTime = values.time[1].format(dateFormat);
      }

      const requestBody = {
        startDateTime,
        endDateTime,
        projectCode: state.activeBusinessParcel.project.projectCode,
      };
      setLoading(true);
      requestFn(requestBody)
        .then((res) => {
          closeModal(MODAL_KEY);
          dispatch(getSampleDataJobs());
          form.resetFields();
        })
        .finally(() => setLoading(false));
    });
  };

  const iconLabel = (
    <span>
      <span style={{ marginRight: '5px' }}>Time Period</span>
      <Tooltip
        theme={THEME_LIGHT}
        placement="right"
        title={'Please choose time period less than 7 days.'}
      >
        {/*<Icon type="info-circle" theme="filled" style={{color: '#9B9B9B'}} />*/}
      </Tooltip>
    </span>
  );
  return (
    <Modal
      title="Fetch Data"
      visible={state.visibleKey === MODAL_KEY}
      onCancel={() => closeModal(MODAL_KEY)}
      onOk={handleSubmit}
      okButtonProps={{ loading: loading }}
      destroyOnClose
      afterClose={() => {
        setDates([]);
        setValue(null);
        setLoading(false);
      }}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="time"
            label={iconLabel}
            style={{ marginBottom: '0px' }}
            rules={[
              { required: true, message: 'Please select the time period' },
            ]}
          >
            <RangePicker
              value={value}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
              onChange={(val) => setValue(val)}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default FetchSampleDataModal;
