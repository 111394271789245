export const initPendo = (user, account) => {
  // @ts-ignore
  pendo.initialize({
    apiKey: '7df8727f-b771-4e46-6ae1-f3c3bb45f266',
    visitor: {
      id: user,
    },
    account: {
      id: account,
    },
  });
};
