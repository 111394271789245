import {
  ADDED,
  HIGHLIGHT,
  LIGHTGREEN,
  MODIFIED,
  PINK,
  REMOVED,
  WHITE,
} from './constants';

export const getBackground = (item, index, activeIndex): any => {
  let background = '';
  if (index === activeIndex) {
    background = HIGHLIGHT;
  } else {
    if (item?.compareType === ADDED) {
      background = LIGHTGREEN;
    } else if (item?.compareType === REMOVED) {
      background = PINK;
    } else if (item?.compareType === MODIFIED) {
      background = WHITE;
    }
  }
  return { background };
};
