import React, { useContext } from 'react';
import { ScopeWithThreshold } from 'conviva-design/es/components/ScopeWithThreshold';
import get from 'lodash/get';
import { ThemeContext } from '@src/contexts';
import { ScopeWrapper } from '@src/components/MetricTable/styled';
import { Tooltip } from 'antd';

const GapAnalysisScope = ({ text, rowData }) => {
  const { theme } = useContext(ThemeContext);
  const thresholdCategory = get(rowData, ['threshold', rowData.id], null);
  const tipTitle = `parentPath: ${rowData?.parentPath}`;
  const isTitle = text === 'All Tags';
  const haveParentPath = rowData?.parentPath && rowData?.parentPath !== '';
  if (isTitle || !haveParentPath) {
    return (
      <ScopeWrapper>
        <ScopeWithThreshold
          theme={theme}
          content={[{ text: text, type: 'text' }]}
          category={thresholdCategory}
        />
      </ScopeWrapper>
    );
  }
  return (
    <Tooltip title={tipTitle}>
      <ScopeWrapper>
        <ScopeWithThreshold
          theme={theme}
          content={[{ text: text, type: 'text' }]}
          category={thresholdCategory}
        />
      </ScopeWrapper>
    </Tooltip>
  );
};

export default GapAnalysisScope;
