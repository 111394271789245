import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Menu, Dropdown } from 'antd';
import { MoreOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  PageTitle,
  FetchDataStateContainer,
  RuleVersionSelectorWrapper,
} from './HeaderStyled';
import { useAppSelector, useAppDispatch } from '@src/store/hooks';
import {
  requestRuleConfig,
  selectFieldToActive,
  selectRegexToActive,
  setActiveBusinessParcel,
  getSampleDataJobs,
  getUserInfo,
  receiveRuleEngineRequiredFields,
} from '@src/store/actions/ruleEngine';
import {
  PERMISSION_ACCESS_DATA_MODEL,
  PERMISSION_APPROVE_RULE,
  RULE_STATUS_INIT,
  VERSION_2,
} from '@src/utils/constants';
import { openModalAction } from '@src/store/actions/modal';
import {
  ADMIN_PANEL_MODAL_KE,
  ID_MERGE_CONFIGURATION_MODAL_KEY,
} from '@src/pages/RuleEngine/layouts/modals';
import { fetchSampleData } from '@src/pages/RuleEngine/hooks';
import { useSupportedLetterType } from '@src/service/apis/system';
import { useTagKeyList } from '@src/service/apis/ruleEngine/sampleData';
import { toUrl } from '@src/utils/url';
import { initializeRuleCompareState } from '@src/store/actions/ruleCompare';
import {
  resetGapAnalysisState,
  updateGapAnalysisReports,
} from '@src/store/actions/gapAnalysis';

const { Option } = Select;

const Header: React.FC = () => {
  // Fetch supported letter casing;
  useSupportedLetterType();
  useTagKeyList();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const store = useAppSelector((state) => {
    return {
      ruleEngine: state.ruleEngine,
    };
  });

  const {
    ruleVersions,
    activeVersion,
    businessParcels,
    activeBusinessParcel,
    sampleDataJobs,
    userInfo,
  } = store.ruleEngine;

  useEffect(() => {
    console.log('activeVersion.uuid', activeVersion.uuid);
    if (activeVersion.uuid) {
      dispatch(requestRuleConfig(activeVersion.uuid));
    }
  }, [activeVersion.uuid, dispatch]);

  useEffect(() => {
    console.log('activeBusinessParcel.uuid', activeBusinessParcel.uuid);
    store.ruleEngine.fields = [];
    store.ruleEngine.dryRunCompareLists = [];
    if (activeBusinessParcel.uuid) {
      dispatch(getSampleDataJobs());
      dispatch(getUserInfo());
    }
    return () => {
      clearTimeout(window.__RULE_ENGINE_FETCH_SAMPLE_DATA_JOBS_TIMER__);
    };
  }, [activeBusinessParcel.uuid, dispatch]);

  const ruleTitle = activeBusinessParcel.project?.projectName;

  const ruleVersionOptions = ruleVersions.map((item) => {
    const ruleState = item.state !== RULE_STATUS_INIT ? `(${item.state})` : '';
    return (
      <Option
        data-test-id="version-list-item"
        value={item.uuid}
        key={item.uuid}
      >
        {ruleTitle} {item.versionNo}.0 {ruleState}
      </Option>
    );
  });
  if (!activeVersion.uuid) {
    ruleVersionOptions.unshift(
      <Option value="" key="">
        {ruleTitle} New Version
      </Option>,
    );
  }

  const { startDate, endDate, jobStatus, jobMessage, isFetching } =
    sampleDataJobs;
  const formattedStartDate = startDate?.split('T')[0];
  const formattedEndDate = endDate?.split('T')[0];
  const sampleDataDateRange = `Data Date Range: ${formattedStartDate} to ${formattedEndDate}`;
  const sampleDataState = `Fetch Data State: ${jobStatus} ${
    jobStatus === 'failed' ? jobMessage : ''
  }`;
  const isSampleDataFetching = isFetching;
  const canShowSampleDataFetchState = sampleDataJobs?.jobStatus;

  const genActionDropdown = () => {
    const items: any[] = [
      {
        label: (
          <div>
            Fetch Data
            {isSampleDataFetching && (
              <>
                {` (${jobStatus})`} <LoadingOutlined />
              </>
            )}
          </div>
        ),
        key: 'fetchData',
        disabled: isSampleDataFetching,
      },
    ];
    if (
      activeBusinessParcel.reVersion === VERSION_2 &&
      userInfo.permissions.includes(PERMISSION_APPROVE_RULE)
    ) {
      items.push({ key: 'idConfig', label: 'ID Merge Configuration' });
    }
    if (
      userInfo.permissions.includes(PERMISSION_APPROVE_RULE) ||
      userInfo.permissions.includes(PERMISSION_ACCESS_DATA_MODEL)
    ) {
      items.push({ key: 'admin', label: 'Admin Panel' });
    }

    items.push({ key: 'gapAnalysis', label: 'Gap Analysis Report' });

    return <Menu onClick={onActionsApply} items={items} />;
  };

  const onActionsApply = ({ key }) => {
    switch (key) {
      case 'fetchData':
        fetchSampleData();
        break;
      case 'admin':
        dispatch(openModalAction(ADMIN_PANEL_MODAL_KE));
        break;
      case 'idConfig':
        dispatch(openModalAction(ID_MERGE_CONFIGURATION_MODAL_KEY));
        break;
      case 'gapAnalysis':
        history.push(
          toUrl({
            path: '/gap-analysis-report',
          }),
        );
        dispatch(resetGapAnalysisState());
        dispatch(updateGapAnalysisReports(activeBusinessParcel));
        break;
    }
  };

  const handleVersionChange = (versionId) => {
    if (versionId) {
      dispatch(requestRuleConfig(versionId));
      dispatch(selectFieldToActive(''));
      dispatch(selectRegexToActive(''));
    }
  };

  const menu = () => {
    const items: any[] = [
      {
        label: 'compare with',
        key: 'compare',
        disabled: !activeVersion.uuid ? true : false,
      },
    ];
    return <Menu onClick={onActions} items={items} />;
  };

  const onActions = ({ key }) => {
    switch (key) {
      case 'compare':
        dispatch(receiveRuleEngineRequiredFields([activeVersion]));
        dispatch(
          initializeRuleCompareState({
            ruleSelectVersion1: '',
            ruleSelectVersion2: '',
            ruleFieldsVersion1: [],
            ruleFieldsVersion2: [],
            ruleRegexVersion1: [],
            ruleRegexVersion2: [],
          }),
        );
        history.push(
          toUrl({
            path: '/rule-compare',
            query: {
              uuid: activeVersion?.uuid,
            },
          }),
        );
        break;
    }
  };

  return (
    <PageTitle>
      <RuleVersionSelectorWrapper>
        <Select
          data-test-id="rule-version-selector"
          onChange={handleVersionChange}
          value={activeVersion.uuid || ''}
        >
          {ruleVersionOptions}
        </Select>
        <Dropdown overlay={menu} trigger={['click']}>
          <a
            onClick={(e) => e.preventDefault()}
            style={{ fontSize: 26, color: 'rgba(0, 0, 0, 0.65)' }}
          >
            <MoreOutlined />
          </a>
        </Dropdown>
      </RuleVersionSelectorWrapper>
      <div style={{ position: 'relative', top: '-30px' }}>
        <div
          data-test-id="product-selector"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Select
            value={activeBusinessParcel?.uuid}
            onChange={(value) => {
              const selected = businessParcels?.find((i) => i.uuid === value);
              if (selected) {
                dispatch(setActiveBusinessParcel(selected));
              }
            }}
            dropdownMatchSelectWidth={284}
          >
            {businessParcels?.map((b) => (
              <Option key={b.uuid} value={b.uuid}>
                {b.project?.projectName}
              </Option>
            ))}
          </Select>
          <span
            data-test-id="rule-engine-actions"
            style={{ marginLeft: '10px' }}
          >
            <Dropdown overlay={genActionDropdown()} trigger={['click']}>
              <a
                onClick={(e) => e.preventDefault()}
                style={{ fontSize: 26, color: 'rgba(0, 0, 0, 0.65)' }}
              >
                <MoreOutlined />
              </a>
            </Dropdown>
          </span>
        </div>
        {canShowSampleDataFetchState && (
          <FetchDataStateContainer data-test-id="fetch-data-state">
            {activeBusinessParcel.dataSource.partition !== 'NONE'
              ? sampleDataDateRange
              : ''}
            <div>{sampleDataState}</div>
          </FetchDataStateContainer>
        )}
      </div>
    </PageTitle>
  );
};

export default Header;
