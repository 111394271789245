import styled from 'styled-components';
import { Button } from 'antdV4';

export const DryRunWrapper = styled.div`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

export const ActionButton = styled(Button)`
  width: 30px !important;
  height: 30px !important;
  border-radius: 30px !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`;

export const StatusTagBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 10px;
`;
