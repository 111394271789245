import React from 'react';
import styled from 'styled-components';
import { THEME_LIGHT } from '@src/utils/constants';

export const Header = styled.div<{ showIcon?: boolean }>`
  font-size: 14px;
  font-weight: normal;
  font-family: 'Open Sans';
  letter-spacing: 0;
  text-align: center;
  color: #4a4a4a;
  cursor: pointer;
  line-height: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  .header-title {
    width: ${({ showIcon }) => (showIcon ? '82px' : '102px')};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

function ColumnHeader({ app }) {
  const showTitle = app.column.label;
  return (
    <Header theme={THEME_LIGHT} title={showTitle} showIcon={false}>
      <div className="header-title">
        <div className="header-title">{showTitle}</div>
      </div>
    </Header>
  );
}

export default ColumnHeader;
