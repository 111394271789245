import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Popover, InputNumber, Icon } from 'antdV3';
import { Tooltip } from 'conviva-design/es/components/Tooltip';
import { DryRunWrapper } from './styled';
import { StyledTableWrapper } from '../../../../components/styled';
import { StatusTagBox } from './styled';
import {
  pushLocation,
  receiveDryRunReport,
} from '@src/store/actions/ruleEngine';
import {
  ruleEngineSelector,
  locationSelector,
  businessParcelTitleSelector,
} from '@src/store/selector';
import { addDryRunReportTab } from '@src/store/actions/ruleEngine';
import {
  DryRunReportFieldDetail,
  DryRunReportFieldDetailV1,
} from './DryRunReportFieldDetail';
import TagIssueState from './TagIssueState/index';
import {
  BP_PARAMS_KEY,
  EReactQueryKey,
  RULE_VERSION_PARAMS_KEY,
  THEME_LIGHT,
} from '@src/utils/constants';
import {
  useRequestDryRunList,
  requestDryRunReportV1,
  requestDryRunReportV2,
} from '@src/service/api';
import {
  IDryRunInfo,
  IDryRunReportItem,
  IRuleEngineReducer,
  IRuleInfo,
} from '@src/typing/ruleEngine';
import { Spin } from 'antd';
import { useQuery } from 'react-query';
import { useAppDispatch } from '@src/store/hooks';
import { IBusinessParcel } from '@src/typing/businessParcel';
import { isNumber, remove } from 'lodash';
import Table, { ColumnsType } from 'antd/lib/table';
import { set } from '@src/utils/url';

const selector = createSelector(
  ruleEngineSelector,
  locationSelector,
  businessParcelTitleSelector,
  (ruleEngine, location, businessParcelTitle) => {
    return {
      ruleEngine,
      location,
      businessParcelTitle,
    };
  },
);

interface Props {
  ruleEngine: IRuleEngineReducer;
  businessParcelTitle: string;
  location: any;
  match: any;
  addDryRunReportTab: any;
}

interface State {
  coverageThreshold: number;
  dryRunList: any;
  report: any;
  loading: boolean;
  dryRunId: string | null;
}

const DryRunReport: React.FC<{
  businessParcel: IBusinessParcel;
  ruleInfo: IRuleInfo;
  dryRunInfo: IDryRunInfo;
}> = (props) => {
  const { businessParcel, ruleInfo, dryRunInfo } = props;
  const [coverageThreshold, setCoverageThreshold] = useState(80);
  const { uuid: dryRunId } = dryRunInfo;
  const { versionNo } = ruleInfo;
  // return
  // const { dryRunId, versionNo } = this.props.match.params;
  const ruleVersion = useMemo(() => {
    return `${businessParcel.project.projectName} ${versionNo}.0`;
  }, [businessParcel, versionNo]);
  const { data: dataSource, status: status4RequestDryRunReport } = useQuery(
    [EReactQueryKey.DRY_RUN_REPORT.length, dryRunId],
    async () => {
      const { reVersion } = businessParcel;
      if (reVersion === 'V1') {
        return await requestDryRunReportV1({
          params: { dryRunId },
        });
      }
      return await requestDryRunReportV2({
        urlParams: {
          dryRunId,
        },
      });
    },
  );
  const columns: ColumnsType<IDryRunReportItem> = [
    {
      key: 'fieldName',
      title: 'Metadata',
      dataIndex: 'fieldName',
    },
    {
      key: 'traffic',
      title: 'All Traffic',
      dataIndex: 'coverage',
      render: (text: number, record, index) => {
        return (
          <TagIssueState
            type={text >= coverageThreshold ? 'Passed' : 'Not Passed'}
          />
        );
      },
    },
    {
      key: 'coverage',
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Valid Coverage</span>
          <Tooltip
            theme={THEME_LIGHT}
            placement="rightTop"
            title="The coverage rate of expected data (UNKNOWN, null, N/A, undefined etc. are not included)"
          >
            <Icon
              style={{ marginLeft: '10px' }}
              type="info-circle"
              theme="filled"
            />
          </Tooltip>
        </div>
      ),
      dataIndex: 'coverage',
      render: (text, record, index) => {
        return (
          <Popover
            trigger="click"
            placement="rightTop"
            destroyTooltipOnHide={true}
            style={{ maxHeight: '300px', overflow: 'scroll' }}
            content={
              Array.isArray(record.values) ? (
                <DryRunReportFieldDetail dataSource={record.values} />
              ) : (
                <DryRunReportFieldDetailV1
                  fieldId={record.fieldId}
                  dryRunId={dryRunId}
                />
              )
            }
          >
            <a href="!#">{text}%</a>
          </Popover>
        );
      },
    },
  ];
  return (
    <DryRunWrapper>
      {ruleVersion ? (
        <div style={{ fontSize: '26px', color: '#000', marginBottom: '10px' }}>
          {ruleVersion}
        </div>
      ) : (
        ''
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <InputNumber
          size="large"
          defaultValue={coverageThreshold}
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          onChange={(v) => {
            if (isNumber(v)) {
              setCoverageThreshold(v);
            }
          }}
        />
        <StatusTagBox>
          <TagIssueState type="Passed" />
          <div>
            <small>Passed</small>
          </div>
        </StatusTagBox>
        <StatusTagBox>
          <TagIssueState type="Not Passed" />
          <div>
            <small>Coverage not passed</small>
          </div>
        </StatusTagBox>
      </div>
      <StyledTableWrapper>
        <Table
          key={dryRunId}
          loading={status4RequestDryRunReport === 'loading'}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          rowKey={(record) => record.fieldName}
        />
      </StyledTableWrapper>
    </DryRunWrapper>
  );
};

const IndexPage: React.FC<{
  ruleEngine: IRuleEngineReducer;
  match: {
    params: { businessParcelId: string; ruleId: string; dryRunId: string };
  };
}> = (props) => {
  const { ruleEngine, match } = props;
  /** jump to default page on error case */
  let errorCase = false;
  // console.log('ruleEngine');
  // console.log(ruleEngine);
  // if (match) {
  //   message.error(`url is not correct`);
  // }
  const { businessParcelId, ruleId, dryRunId } = match.params;
  useEffect(() => {
    set({ [RULE_VERSION_PARAMS_KEY]: ruleId });
    set({ [BP_PARAMS_KEY]: businessParcelId });
  }, [businessParcelId, ruleId]);

  const {
    businessParcels,
    ruleVersions,
    dryRunReports: openedDryRunReports,
  } = ruleEngine;
  // const {} = useQuery([EReactQueryKey])
  const dispatch = useAppDispatch();
  let businessParcel: IBusinessParcel | undefined = undefined;
  let ruleInfo: IRuleInfo | undefined = undefined;
  if (Array.isArray(businessParcels) && businessParcels.length > 0) {
    businessParcel = businessParcels.find((it) => it.uuid === businessParcelId);
    if (!businessParcel) {
      errorCase = true;
    }
  }
  if (Array.isArray(ruleVersions) && ruleVersions.length > 0) {
    ruleInfo = ruleVersions.find((it) => it.uuid === ruleId);
    if (!ruleInfo) {
      errorCase = true;
    }
  }

  const { data: dryRunListInfo, status: status4RequestDryRunList } =
    useRequestDryRunList(businessParcel, ruleInfo);

  // return <div>ddd</div>
  const dryRun = useMemo(() => {
    if (!dryRunListInfo) {
      return null;
    }
    if (!Array.isArray(dryRunListInfo.entities)) {
      return null;
    }
    return dryRunListInfo.entities.find((it) => it.uuid === dryRunId);
  }, [dryRunListInfo, dryRunId]);
  useEffect(() => {
    if (!dryRun || !Array.isArray(openedDryRunReports) || !ruleInfo) {
      return;
    }
    const hasRecord = openedDryRunReports.some(
      (it) => it.dryRunId === dryRunId,
    );
    // console.log('hasRecord');
    // console.log(hasRecord);
    if (!hasRecord) {
      dispatch(addDryRunReportTab(businessParcelId, ruleId, dryRunId, dryRun));
    }
  }, [
    dryRun,
    openedDryRunReports,
    businessParcelId,
    ruleInfo,
    dryRunId,
    ruleId,
    dispatch,
  ]);
  const isReady = useMemo(() => {
    // console.log(businessParcel, ruleInfo, dryRun, status4RequestDryRunList);
    return (
      businessParcel &&
      ruleInfo &&
      status4RequestDryRunList === 'success' &&
      dryRun
    );
  }, [businessParcel, ruleInfo, status4RequestDryRunList, dryRun]);
  /** close the tab and navigate to default page on error case */
  if (errorCase) {
    const removeList = remove(openedDryRunReports, function (item) {
      return item.dryRunId === dryRunId;
    });
    if (removeList.length) {
      dispatch(receiveDryRunReport(openedDryRunReports));
    }
    dispatch(pushLocation('/rule-engine/dry-run'));
  }

  return isReady ? (
    <DryRunReport
      businessParcel={businessParcel as IBusinessParcel}
      ruleInfo={ruleInfo as IRuleInfo}
      dryRunInfo={dryRun as IDryRunInfo}
    />
  ) : (
    <Spin spinning={!isReady} tip="Loading">
      <div
        style={{
          minHeight: '300px',
        }}
      />
    </Spin>
  );
};
export default connect(selector, {})(IndexPage);
