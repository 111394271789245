import React, { useEffect, useState, useCallback } from 'react';
import { Affix } from 'antd';
import isEqual from 'lodash/isEqual';
import { useAppSelector, useAppDispatch } from '@src/store/hooks';
import RuleEditor from '@src/pages/RuleEngine/components/V2/RuleEditor';
import FieldList from '@src/pages/RuleEngine/components/V2/FieldList';
import {
  GROUP_CODE_AUTOMATED_METADATA_MAPPING,
  GROUP_CODE_CI_REQUIRED_FIELD,
  GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
  MAP_TYPE_EXTRACT,
} from '@src/store/types/types';
import { updateFieldRule } from '@src/store/actions/ruleEngine';
import { TopLabel } from '@src/pages/RuleEngine/modules/FiledMapRule/styled';
import { Button } from 'conviva-design/es/components/ButtonV2';
import {
  CREATE_CUSTOM_TAG_MODAL_KEY,
  openModal,
} from '@src/pages/RuleEngine/layouts/modals';
import { RULE_STATUS_INIT } from '@src/utils/constants';
import { IRuleFiledMapping } from '@src/typing/ruleEngine';

import styles from './index.less';

interface Props {
  type?:
    | 'ALL'
    | typeof GROUP_CODE_CI_REQUIRED_FIELD
    | typeof GROUP_CODE_CUSTOMER_CUSTOM_FIELDS
    | typeof GROUP_CODE_AUTOMATED_METADATA_MAPPING;
}

const FiledMapRuleV2: React.FC<Props> = (props) => {
  const [activeField, setActiveField] = useState<any>({});
  const [activeFieldInitValue, setActiveFieldInitValue] = useState<any>({});
  const [affixed, setAffixed] = useState<boolean | undefined>(false);
  const dispatch = useAppDispatch();
  const state = useAppSelector((rootState) => {
    return {
      draftRule: rootState.ruleEngine.draftRule,
      activeRule: rootState.ruleEngine.activeRule,
      userInfo: rootState.ruleEngine.userInfo,
      activeBu: rootState.ruleEngine.activeBusinessParcel,
    };
  });
  const { draftRule, activeRule } = state;
  const ruleFieldMappings = draftRule?.ruleFieldMappings || [];

  const handleFieldClick = useCallback(
    (field) => {
      setActiveField(field);
    },
    [activeRule],
  );

  const requiredFields = ruleFieldMappings.filter(
    (r) => r.groupCode === GROUP_CODE_CI_REQUIRED_FIELD,
  );
  const automatedFields = ruleFieldMappings.filter(
    (r) => r.groupCode === GROUP_CODE_AUTOMATED_METADATA_MAPPING,
  );
  const customFields = ruleFieldMappings.filter(
    (r) => r.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
  );

  const requiredFieldListDOM = (
    <FieldList
      onItemClick={handleFieldClick}
      key="requiredFieldListDOM"
      title="Required Fields"
      list={requiredFields}
      activeKey={activeField.fieldCode}
    />
  );
  const automatedFieldListDOM = (
    <FieldList
      onItemClick={handleFieldClick}
      key="automatedFieldListDOM"
      title="Automated Fields"
      list={automatedFields}
      activeKey={activeField.fieldCode}
    />
  );

  let fieldListDOM: any = [requiredFieldListDOM, automatedFieldListDOM];
  let fieldList = requiredFields;

  if (props.type === GROUP_CODE_CI_REQUIRED_FIELD) {
    fieldListDOM = requiredFieldListDOM;
    fieldList = requiredFields;
  } else if (props.type === GROUP_CODE_AUTOMATED_METADATA_MAPPING) {
    fieldListDOM = automatedFieldListDOM;
    fieldList = automatedFields;
  } else if (props.type === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS) {
    fieldList = customFields;
    const customTagsNumberLimit =
      state.userInfo?.customerConfig?.customTagsNumberLimit || 0;
    const pageTitle = (
      <div>
        <TopLabel>
          Define custom tags you want to show in Content Insights (
          {fieldList.length}/{customTagsNumberLimit})
        </TopLabel>
        <Button
          data-test-id="create-new-one-btn"
          theme="light"
          onClick={() => {
            openModal(CREATE_CUSTOM_TAG_MODAL_KEY);
          }}
          disabled={
            fieldList.length >= customTagsNumberLimit ||
            state.draftRule?.state !== RULE_STATUS_INIT
          }
        >
          Create New One
        </Button>
      </div>
    );
    fieldListDOM = (
      <FieldList
        onItemClick={handleFieldClick}
        key="customFieldListDOM"
        title={pageTitle}
        list={fieldList}
        activeKey={activeField.fieldCode}
      />
    );
  }
  useEffect(() => {
    setActiveField(fieldList[0] || {});
  }, [activeRule?.uuid]);

  useEffect(() => {
    const fieldList = activeRule?.ruleFieldMappings || [];
    const fieldInitValue =
      fieldList.find((i) => i.fieldCode === activeField.fieldCode) || {};
    setActiveFieldInitValue(fieldInitValue);
  }, [activeField]);

  const ruleEditorExtraProps = () => {
    if (activeField.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS) {
      return {
        onDelete: (field: IRuleFiledMapping) => {
          const ruleFieldMappings = state.draftRule?.ruleFieldMappings || [];
          const targetIndex = ruleFieldMappings.findIndex((i) => {
            return (
              i.fieldCode === field.fieldCode &&
              i.fieldName === field.fieldName &&
              i.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS
            );
          });
          if (targetIndex !== -1) {
            ruleFieldMappings.splice(targetIndex, 1);
            dispatch(updateFieldRule({ ruleFieldMappings }));
            setActiveField({});
          }
        },
      };
    }
    return {};
  };

  const handleRuleChange = (changes, values) => {
    const index = ruleFieldMappings.findIndex(
      (field) => field.fieldCode === activeField.fieldCode,
    );
    if (index !== -1) {
      ruleFieldMappings[index] = {
        ...ruleFieldMappings[index],
        ...values,
      };
      console.log('rule editor: ', changes, values);
      dispatch(updateFieldRule({ ruleFieldMappings }));
    }
    // Check if regex rule change.
    if (changes.ruleFieldMappedFroms !== undefined) {
      const regexFields: any[] = [];
      ruleFieldMappings.forEach((field) => {
        field.ruleFieldMappedFroms.forEach((i) => {
          if (i.mappingType === MAP_TYPE_EXTRACT) {
            const key = i.parentPath + i.mappedFrom;
            const alreadyExists = regexFields.find(
              (item) => item.parentPath + item.mappedFrom === key,
            );
            if (!alreadyExists) {
              regexFields.push(i);
            }
          }
        });
      });
      const ruleFieldExtractingConfigs: any[] = [];
      state.draftRule?.ruleFieldExtractingConfigs?.forEach((i) => {
        const key = i.parentPath + i.tagName;
        const alreadyHasRegexRule = regexFields.find(
          (item) => item.parentPath + item.mappedFrom === key,
        );
        if (alreadyHasRegexRule) {
          ruleFieldExtractingConfigs.push({ ...i });
        }
      });
      regexFields.forEach((i) => {
        const key = i.parentPath + i.mappedFrom;
        const alreadyHasRegexRule =
          state.draftRule?.ruleFieldExtractingConfigs?.find(
            (item) => item.parentPath + item.tagName === key,
          );
        if (!alreadyHasRegexRule) {
          ruleFieldExtractingConfigs.push({
            tagName: i.mappedFrom,
            parentPath: i.parentPath,
            regexExpressions: [],
          });
        }
      });
      if (
        !isEqual(
          ruleFieldExtractingConfigs,
          state.draftRule?.ruleFieldExtractingConfigs,
        )
      ) {
        console.log('regex rule changed: ', ruleFieldExtractingConfigs);
        dispatch(updateFieldRule({ ruleFieldExtractingConfigs }));
      }
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}>{fieldListDOM}</div>
      {activeField.fieldCode ? (
        <div>
          <div style={{ height: 55 }} />
          <Affix
            offsetTop={10}
            target={() => window.__QIANKUN_CONTAINER__}
            onChange={(affixed) => setAffixed(affixed)}
          >
            <div
              className={styles.ruleEditorWrapper}
              style={affixed ? {} : { maxHeight: 'calc(100vh - 355px)' }}
            >
              <RuleEditor
                rule={activeField}
                key={`ruleEditor-${state.activeRule?.uuid}`}
                isEditMode={state.draftRule?.state === RULE_STATUS_INIT}
                onChange={handleRuleChange}
                initValue={activeFieldInitValue}
                reVersion={state.activeBu.reVersion}
                {...ruleEditorExtraProps()}
              />
            </div>
          </Affix>
        </div>
      ) : null}
    </div>
  );
};

export default FiledMapRuleV2;
