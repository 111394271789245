import React, { useEffect, useState } from 'react';
import { ConfigProvider, Spin } from 'antd';
import moment from 'moment/moment';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import NoReport from '@src/pages/GapAnalysis/components/NoReport';
import CreateNewReports from '@src/pages/GapAnalysis/components/CreateNewReports';
import HistoricalReports from '@src/pages/GapAnalysis/components/HistoricalReports';
import GapAnalysisReport from '@src/pages/GapAnalysis/GapAnalysisReport';
import {
  postCreateJobs,
  useQueryGapAnalysisJob,
} from '@src/service/apis/gapAnalysis';
import {
  updateCurrentJob,
  updateHistoricalJob,
  updateHistoricalJobPagination,
  updateLatestJob,
} from '@src/store/actions/gapAnalysis';

const GapAnalysis: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => {
    return {
      customerId: state.ruleEngine?.userInfo?.customerBaseInfo?.customerId,
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const customerId = state.customerId;
  const { currentJob, historicalJobPagination, activeBusinessParcel } =
    gapAnalysis;
  const {
    dataSource,
    dataSource: { dataSourceCode, dataSourceName },
  } = activeBusinessParcel;

  const jobRequest = {
    customerId: customerId,
    dataSourceCode: dataSourceCode,
    pageNum: `${
      historicalJobPagination.pageNum ? historicalJobPagination.pageNum : 1
    }`,
    pageSize: `${
      historicalJobPagination.total ? historicalJobPagination.total : 1
    }`,
  };

  const {
    data: jobData,
    isLoading: jobDataLoading,
    refetch: jobRefetch,
  } = useQueryGapAnalysisJob(jobRequest);

  const [openCreateNewReport, setOpenCreateNewReport] = useState(false);
  const [openHistoricalReport, setOpenHistoricalReport] = useState(false);

  useEffect(() => {
    // request all job data
    if (!jobDataLoading && jobData.pageSize < jobData.total) {
      jobRefetch();
    }
  }, [jobData, jobDataLoading, jobRefetch]);

  useEffect(() => {
    if (!jobDataLoading) {
      const historicalJob = buildJobData(jobData);
      const extendHistoricalJob = historicalJob.map((item) => {
        item.dataSourceName = dataSourceName;
        return item;
      });
      const latestJob = extendHistoricalJob.find(
        (item) => item.jobStatus === 'SUCCESS',
      );
      dispatch(updateHistoricalJob(extendHistoricalJob));
      dispatch(
        updateHistoricalJobPagination({
          pageNum: jobData.pageNum,
          pageSize: jobData.pageSize,
          pages: jobData.pages,
          total: jobData.total,
        }),
      );
      if (latestJob) {
        dispatch(updateLatestJob(latestJob));
      }
      if (!currentJob || JSON.stringify(currentJob) === '{}') {
        dispatch(updateCurrentJob(latestJob));
      }
      if (
        !latestJob &&
        extendHistoricalJob.length > 0 &&
        jobData.pageSize === jobData.total
      ) {
        onOpenHistoricalReport();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceName, dispatch, jobData, jobDataLoading]);

  const buildJobData = (response) => {
    const entities = response?.entities ? response?.entities : [];
    const extendJobData = entities?.map((item) => {
      item.key = item.uuid;
      if (!item.startTime && !item.endTime) {
        item.timePeriod = null;
      } else {
        item.timePeriod = `${moment(item.startTime).format(
          'YYYY/MM/DD',
        )} - ${moment(item.endTime).format('YYYY/MM/DD')}`;
      }
      return item;
    });
    return extendJobData?.sort((a, b) => {
      let time1 = Date.parse(a.jobCreateTime);
      let time2 = Date.parse(b.jobCreateTime);
      return time2 - time1;
    });
  };

  const onOpenCreateNewReport = () => {
    setOpenCreateNewReport(true);
  };

  const onCancelCreateNewReport = () => {
    setOpenCreateNewReport(false);
  };

  const onCreateNewReport = async (values: any) => {
    setOpenCreateNewReport(false);
    await postCreateJobs({
      customerId: customerId,
      dataSource: dataSource,
      ...values,
    }).then((value) => {
      if (value.code === 'SUCCESS') {
        jobRefetch();
      }
    });
  };

  const onOpenHistoricalReport = () => {
    setOpenHistoricalReport(true);
  };

  const onCancelHistoricalReport = () => {
    setOpenHistoricalReport(false);
  };

  return (
    <ConfigProvider prefixCls="mds-ant">
      {/* gap analysis report Page */}
      <Spin spinning={jobDataLoading}>
        {currentJob && JSON.stringify(currentJob) !== '{}' ? (
          <GapAnalysisReport
            openCreateNewReport={openCreateNewReport}
            onOpenCreateNewReport={onOpenCreateNewReport}
            onCancelCreateNewReport={onCancelCreateNewReport}
            onCreateNewReport={onCreateNewReport}
            onOpenHistoricalReport={onOpenHistoricalReport}
          />
        ) : (
          <NoReport onOpen={onOpenCreateNewReport} />
        )}
      </Spin>
      {/* create new report Modal */}
      <CreateNewReports
        open={openCreateNewReport}
        onCancel={() => {
          setOpenCreateNewReport(false);
        }}
        onCreate={onCreateNewReport}
      />
      {/* historical report Modal */}
      <HistoricalReports
        open={openHistoricalReport}
        onCancel={onCancelHistoricalReport}
        refetch={jobRefetch}
        isLoading={jobDataLoading}
      />
    </ConfigProvider>
  );
};

export default GapAnalysis;
