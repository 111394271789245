import { useQuery } from 'react-query';
import { ruleEngineHttpClient } from '@src/service/http';
import { IRuleFiledMapping } from '@src/typing/ruleEngine';
import { getActiveProjectCode } from '@src/utils/ruleEngine';

const baseUrl = '/metadata-rules/dsl';
export const requestTranslateDsl = (
  field: IRuleFiledMapping,
  projectCode?: string,
) => {
  if (!projectCode) {
    projectCode = getActiveProjectCode();
  }
  const url = `${baseUrl}/translate/${projectCode}`;
  return ruleEngineHttpClient.post(url, {
    ...field,
  });
};
