import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antdV3';

// TODO: support other issue state in the future
interface Props {
  type: string;
}

class TagIssueState extends React.PureComponent<Props> {
  static propTypes = {
    type: PropTypes.oneOf(['NA', 'Passed', 'Not Passed', 'Not Received'])
      .isRequired,
  };

  static defaultProps = {
    type: 'NA',
  };

  getLabel() {
    switch (this.props.type) {
      case 'Not Received':
        return '1';
      default:
        return '';
    }
  }

  getColor() {
    switch (this.props.type) {
      case 'NA':
        return 'grey';
      case 'Passed':
        return 'green';
      default:
        return 'red';
    }
  }

  render() {
    return (
      <Avatar size="small" style={{ backgroundColor: this.getColor() }}>
        {this.getLabel()}
      </Avatar>
    );
  }
}

export default TagIssueState;
