import React, { useEffect } from 'react';
import { useUserInfo } from '@src/service/apis/system';
import {
  getUserInfo,
  requestBusinessParcels,
} from '@src/store/actions/ruleEngine';
import { useAppDispatch } from '@src/store/hooks';
import NavigationBar from '@src/layouts/NavigationBar';

const BaseLayout: React.FC = (props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useUserInfo();

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(requestBusinessParcels());
  }, [dispatch]);

  return (
    <>
      <NavigationBar />
      {isLoading ? null : props.children}
    </>
  );
};

export default BaseLayout;
