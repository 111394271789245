import { useQuery } from 'react-query';
import { ruleEngineHttpClient } from '@src/service/http';
import {
  requestTagKeyList,
  requestTagSampleData,
} from '@src/service/apis/ruleEngine/ruleEngine';
import store from '@src/store';
import { PARENT_PATH_CONNECT_KEY, VERSION_1 } from '@src/utils/constants';
import { getActiveProjectCode } from '@src/utils/ruleEngine';

export const SAMPLE_DATA_JOBS_QUERY_KEY = 'SAMPLE_DATA_JOBS';
const TAG_KEY_QUERY_KEY = 'TAG_KEY';

// Apis
const baseUrl = '/metadata-samples';

export const submitSampleDataJobV2 = (data: {
  endDateTime?: string;
  startDateTime?: string;
  projectCode: string;
}) => {
  let url = `${baseUrl}/jobs`;
  return ruleEngineHttpClient.post(url, data, {
    successMessage: 'It will take mins to hours depending on traffic volume...',
  });
};

export const getSampleDataJobsV2 = (projectCode: string) => {
  let url = `${baseUrl}/jobs`;
  if (projectCode) url += `?projectCode=${projectCode}`;
  return ruleEngineHttpClient.get(url);
};

export const requestTagKeyListV2 = (projectCode: string) => {
  const url = `${baseUrl}/tag-keys?projectCode=${projectCode}`;
  return ruleEngineHttpClient.get(url);
};

export const requestTagSampleDataV2 = async (
  parentPath: string,
  tagKey: string,
  pagination: any,
  projectCode?: string,
) => {
  const activeProjectCode = getActiveProjectCode();
  projectCode = projectCode ? projectCode : activeProjectCode;

  let url = `${baseUrl}/tags`;
  const pageNum = pagination?.current || 1;
  const pageSize = pagination?.pageSize || 10;
  url = `${url}?projectCode=${projectCode}&parentPath=${parentPath}&tagKey=${tagKey}&pageNum=${pageNum}&pageSize=${pageSize}&limit=${pageNum}&offset=${pageSize}`;
  return await ruleEngineHttpClient.get(url);
};

export const requestRunTestAllV2 = (
  parentPath: string,
  tagKey: string,
  regexes: string[],
  projectCode?: string,
  startPage?: number | undefined,
  endPage?: number | undefined,
) => {
  const activeProjectCode = getActiveProjectCode();
  projectCode = projectCode ? projectCode : activeProjectCode;

  const url = `${baseUrl}/preview-sample-data-with-rules`;
  const data = {
    tagKey,
    parentPath,
    projectCode,
    regexExpressions: regexes,
  };
  if (endPage) {
    data['page'] = {
      pageSize: 10,
      startPage,
      endPage,
    };
  }

  return ruleEngineHttpClient.post(url, { ...data });
};

// Queries
const useSampleDataJobs = (projectCode: string) => {
  return useQuery([SAMPLE_DATA_JOBS_QUERY_KEY, projectCode], async () => {
    const data = await getSampleDataJobsV2(projectCode);
    return data;
  });
};

export const useTagSampleData = (
  parentPath: string,
  tagName: string,
  pagination: any,
) => {
  const state = store.getState();
  const activeBu = state.ruleEngine.activeBusinessParcel;
  const { reVersion } = activeBu;
  return useQuery(
    [SAMPLE_DATA_JOBS_QUERY_KEY, tagName, parentPath, pagination, activeBu],
    async () => {
      let res;
      if (reVersion === VERSION_1) {
        const { data } = await requestTagSampleData(tagName, pagination);
        res = data;
      } else {
        const { data } = await requestTagSampleDataV2(
          parentPath,
          tagName,
          pagination,
        );
        res = data;
      }
      let tagSampleData;
      let newPagination = {};
      if (reVersion === VERSION_1) {
        tagSampleData = [...res.records];
        newPagination['pageSize'] = res.size;
        newPagination['pageNum'] = res.current;
      } else {
        tagSampleData = [...res.entities];
      }
      return {
        ...res,
        tagSampleData,
        ...newPagination,
      };
    },
  );
};

export const useTagKeyList = (projectCode?: string) => {
  const state = store.getState();
  const activeBu = state.ruleEngine.activeBusinessParcel;
  const activeProjectCode = activeBu.project.projectCode || '';
  projectCode = projectCode ? projectCode : activeProjectCode;
  return useQuery(
    [SAMPLE_DATA_JOBS_QUERY_KEY, TAG_KEY_QUERY_KEY, projectCode],
    async () => {
      let res;
      if (activeBu.reVersion === VERSION_1) {
        const { data } = await requestTagKeyList();
        res = data;
      } else {
        const { data } = await requestTagKeyListV2(projectCode as string);
        res = [];
        data?.forEach((i) => {
          const treeNode = {};
          if (i.parentPath) {
            treeNode['title'] = i.parentPath;
            treeNode['value'] = i.parentPath;
            treeNode['selectable'] = false;
            treeNode['children'] = i.tags.map((tag) => {
              return {
                title: tag.label,
                value: `${i.parentPath}${PARENT_PATH_CONNECT_KEY}${tag.tagKey}`,
                dslCategory: tag.dslCategory,
                valueType: tag.valueType,
                parentType: i.parentType,
              };
            });
            res.push(treeNode);
          } else {
            i.tags.forEach((tag) => {
              res.push({
                title: tag.label,
                value: `${PARENT_PATH_CONNECT_KEY}${tag.tagKey}`,
                selectable: true,
                children: [],
                dslCategory: tag.dslCategory,
                valueType: tag.valueType,
                parentType: i.parentType,
              });
            });
          }
        });
      }
      return res;
    },
  );
};
