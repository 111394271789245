import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Sentry Integrations
// Because sandbox of Qiankun overrides the original fetch
// If we do not create private fetch, application will not report to sentry from Pulse
// So create a private fetch and override its toString function

const initialFetch = window.fetch;
const privateFetch: typeof fetch = function (...p) {
  // @ts-ignore
  return initialFetch(...p);
};
privateFetch.toString = function () {
  return 'function fetch() { [native code] }';
};

export function startSentryMonitor() {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'DEV1'
  ) {
    return false;
  }
  window.fetch = privateFetch;
  Sentry.init({
    dsn: 'https://77f193cb6ca3486dbf3eb5b2338710d3@o123592.ingest.sentry.io/6276381',
    environment: process.env.REACT_APP_ENV,
    release: 'mds-portal-ui',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

export function stopSentryMonitor() {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'DEV1'
  ) {
    return false;
  }
  window.fetch = initialFetch;
  Sentry.init({});
}
