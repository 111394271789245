import React from 'react';
import { Tag } from 'antd';
import { MAP_TYPE_CONSTANT } from '@src/store/types/types';
import { getTagColor } from '@src/utils/ruleEngine';

type ResultType = {
  fieldCompare1?: any;
  fieldCompare2?: any;
};

const FieldsRight = (props: any) => {
  const { field1, field2 } = props;

  const compareFieldKeys = [
    'outputFieldTypeCode',
    'ruleFieldMappedFroms',
    'invalidValues',
    'ruleFieldTransforms',
    'regexTransforms',
    'trimExtraSpacesFlag',
    'ruleFieldLetterCasings',
    'useDsl',
  ];

  const compareField = (field1, field2) => {
    if (field1 && field2) {
      let res: ResultType = {
        fieldCompare1: {},
        fieldCompare2: {},
      };
      compareFieldKeys.forEach((key) => {
        if (JSON.stringify(field1?.[key]) !== JSON.stringify(field2?.[key])) {
          res.fieldCompare1[key] = field1?.[key];
          res.fieldCompare2[key] = field2?.[key];
        }
      });
      return res;
    }
    return {
      fieldCompare1: field1,
      fieldCompare2: field2,
    };
  };

  const res = compareField(field1, field2);

  const renderCompareResult = (result) => {
    if (result) {
      return (
        <ul>
          {result.outputFieldTypeCode ? (
            <li>
              <b>Data Type:</b> <span>{result.outputFieldTypeCode}</span>
            </li>
          ) : null}
          {result.ruleFieldMappedFroms ? (
            <li>
              <b>Mapped from:</b>
              {result.ruleFieldMappedFroms.map((from, ind) => {
                return (
                  <span style={{ margin: '0 10px' }} key={ind}>
                    {ind < result.ruleFieldMappedFroms.length - 1 ? (
                      <>
                        <Tag
                          key={ind}
                          className="tag"
                          color={getTagColor(from.mappingType)}
                        >
                          {from.mappedFrom}
                        </Tag>
                        <span style={{ margin: '0 10px' }}>and </span>{' '}
                      </>
                    ) : (
                      <>
                        {' '}
                        <Tag
                          key={ind}
                          className="tag"
                          color={getTagColor(from.mappingType)}
                        >
                          {from.mappedFrom}
                        </Tag>{' '}
                      </>
                    )}
                  </span>
                );
              })}
            </li>
          ) : null}
          {result.invalidValues ? (
            <li>
              <b>Invalid values:</b>
              {result.invalidValues.map((value: any, i: number) => {
                return (
                  <Tag
                    key={i}
                    className="tag"
                    color={getTagColor(MAP_TYPE_CONSTANT)}
                  >
                    {value === '' ? (
                      <> Empty String ""</>
                    ) : value === ' ' ? (
                      <span style={{ padding: '3px 5px' }}></span>
                    ) : (
                      value
                    )}
                  </Tag>
                );
              })}
            </li>
          ) : null}
          {result.ruleFieldTransforms ? (
            <li>
              <b>Transform by direct mapping:</b>
              {result.ruleFieldTransforms.map((v: any, i: number) => {
                return (
                  <p style={{ margin: 0, padding: 0 }} key={i}>
                    set{' '}
                    <Tag className="tag" color={getTagColor(MAP_TYPE_CONSTANT)}>
                      {v.mapFrom}
                    </Tag>
                    to new value{' '}
                    <Tag className="tag" color={getTagColor(MAP_TYPE_CONSTANT)}>
                      {v.mapTo}
                    </Tag>
                  </p>
                );
              })}
            </li>
          ) : null}
          {result.regexTransforms ? (
            <li>
              <b>Transform by regex rule :</b>
              {result.regexTransforms.map((v, i) => {
                return (
                  <p style={{ margin: 0, padding: 0 }} key={i}>
                    set{' '}
                    <Tag className="tag" color={getTagColor(MAP_TYPE_CONSTANT)}>
                      {v.mapFrom}
                    </Tag>
                    to new value
                    <Tag className="tag" color={getTagColor(MAP_TYPE_CONSTANT)}>
                      {v.mapTo}
                    </Tag>
                  </p>
                );
              })}
            </li>
          ) : null}
          {result.trimExtraSpacesFlag ? (
            <li>
              <b>Trim extra space between words : </b>
              {result.trimExtraSpacesFlag === true ? 'YES' : 'NO'}
            </li>
          ) : null}
          {result.ruleFieldLetterCasings ? (
            <li>
              <b>Letter casing : </b>
              {result.ruleFieldLetterCasings}
            </li>
          ) : null}
          {result.useDsl === true ? (
            <>
              <li>
                <b>useDsl : </b>
                <span>{result?.useDsl}</span>
              </li>
              <li>
                <b>dsl:</b> <span>{result?.dsl?.dsl}</span>
              </li>
              <li>
                <b>fallbackValue:</b> <span>{result?.dsl?.fallbackValue}</span>
              </li>
            </>
          ) : null}
        </ul>
      );
    }
    return noDataDom;
  };
  const noDataDom = (
    <ul
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      No Data in version
    </ul>
  );

  return (
    <div className="right">
      <div>
        {renderCompareResult(res.fieldCompare1)}
        {renderCompareResult(res.fieldCompare2)}
      </div>
    </div>
  );
};

export default FieldsRight;
