import * as types from '@src/store/types/dryRunCompare';

export function initializeState(payload) {
  return {
    type: types.INITIALIZE_STATE,
    payload: payload,
  };
}

export function updateStateDryRunOptions1(payload) {
  return {
    type: types.UPDATE_STATE_DRY_RUN_OPTIONS1,
    payload: payload,
  };
}

export function updateStateDryRunOptions2(payload) {
  return {
    type: types.UPDATE_STATE_DRY_RUN_OPTIONS2,
    payload: payload,
  };
}

export function updateStateDryRunContent(payload) {
  return {
    type: types.UPDATE_STATE_DRY_RUN_CONTENT,
    payload: payload,
  };
}
