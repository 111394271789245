import React, { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import {
  Table,
  Button,
  Divider,
  Badge,
  Tabs,
  Spin,
  Typography,
  InputNumber,
  Modal,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ProForm, {
  ProFormText,
  ProFormDependency,
  ProFormInstance,
} from '@ant-design/pro-form';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Code } from '@src/pages/RuleEngine/code';
import {
  requestVerifySingleRule,
  requestRunTestAll,
} from '@src/service/apis/ruleEngine/ruleEngine';
import styles from './index.less';
import {
  ResultItem,
  SectionLabel,
} from '@src/pages/RuleEngine/components/RuleEditor/styled';
import SortableTable from '@src/pages/RuleEngine/components/V2/RuleEditor/SortableTable';
import { IRegexRuleItem } from '@src/typing/ruleEngine';
import { getFieldFullPath } from '@src/utils/ruleEngine';
import {
  requestRunTestAllV2,
  useTagSampleData,
} from '@src/service/apis/ruleEngine/sampleData';
import { IReVersion } from '@src/typing/businessParcel';
import { VERSION_1 } from '@src/utils/constants';

type RuleEditorProps = {
  rule: IRegexRuleItem;
  isEditMode: boolean;
  onChange: any;
  reVersion: IReVersion;
};

interface DataType {
  key: string;
  tagValue: string;
  tagName: string;
}

const defaultPagination = {
  current: 1,
  total: 1,
  pageSize: 10,
};

const { TabPane } = Tabs;

const TEST_ALL_MAX_DATA_COUNT = 10 * 15000;

const RegexRuleEditor: React.FC<RuleEditorProps> = (props) => {
  const [pagination, setPagination] =
    useState<TablePaginationConfig>(defaultPagination);
  const [dataSource, setDataSource] = useState([]);
  const [singleTestResult, setSingleTestResult] = useState('');
  const [allRuleTestResult, setAllRuleTestResult] = useState<any>({});
  const [allRuleTestResultPagination, setAllRuleTestResultPagination] =
    useState<any>({ current: 1 });
  const [dragDataSource, setDragDataSource] = useState<any[]>(
    props.rule.regexExpressions.map((i, index) => {
      return {
        id: Date.now() + index,
        text: i,
      };
    }),
  );
  const [loading, setLoading] = useState(false);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [pageSelectModalVisible, setPageSelectModalVisible] = useState(false);

  useEffect(() => {
    setAllRuleTestResultPagination({ current: 1 });
  }, [allRuleTestResult]);

  const formRef = useRef<ProFormInstance>();

  const rule = cloneDeep(props.rule);

  const fieldFullPath = getFieldFullPath(rule.parentPath, rule.tagName);

  const columns: ColumnsType<DataType> = [
    {
      key: 'index',
      title: '#',
      width: 10,
      render: (text, record, index) => index + 1,
    },
    {
      key: 'tagValue',
      title: fieldFullPath,
      dataIndex: 'tagValue',
    },
  ];

  const dragColumns: ColumnsType<DataType> = [
    {
      key: 'text',
      title: 'Regex rule',
      dataIndex: 'text',
    },
    {
      key: 'action',
      title: 'action',
      dataIndex: 'action',
      width: 30,
      render: (value, record, index) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dragDataSource.splice(index, 1);
              const newDragDataSource = [...dragDataSource];
              setDragDataSource(newDragDataSource);
            }}
          >
            <CloseOutlined />
          </div>
        );
      },
    },
  ];

  const handleRunTest = (rule: string, text: string) => {
    setLoading(true);
    requestVerifySingleRule(rule, text)
      .then((res) => {
        if (res.data) {
          setSingleTestResult(JSON.stringify(res.data));
        } else {
          setSingleTestResult('');
        }
      })
      .catch((err) => {
        console.error(err);
        setSingleTestResult('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTestAll = () => {
    const regexes = dragDataSource.map((i) => i.text);
    setLoading(true);
    let request;
    if (props.reVersion === VERSION_1) {
      request = requestRunTestAll(rule.tagName, regexes, startPage, endPage);
    } else {
      request = requestRunTestAllV2(
        rule.parentPath,
        rule.tagName,
        regexes,
        '',
        startPage,
        endPage,
      );
    }
    request
      .then((res) => {
        setAllRuleTestResult(res.data);
      })
      .finally(() => {
        setLoading(false);
        setStartPage(0);
        setEndPage(0);
      });
  };

  const handleAddRule = (regex: string) => {
    const newDragDataSource = [
      ...dragDataSource,
      { id: Date.now(), text: regex },
    ];
    setDragDataSource(newDragDataSource);
    formRef.current?.resetFields();
  };

  const handleChange = () => {
    const regexExpressions = dragDataSource.map((i) => i.text);
    props.onChange({
      ...rule,
      regexExpressions,
    });
  };
  const { data: tagSampleData, isLoading: fetchSampleDataLoading } =
    useTagSampleData(rule.parentPath, rule.tagName, pagination);

  useEffect(() => {
    if (tagSampleData) {
      const pagination = {
        pageSize: tagSampleData.pageSize,
        current: tagSampleData.pageNum,
        total: tagSampleData.total,
      };
      setPagination(pagination);
      setDataSource(tagSampleData.tagSampleData);
    }
  }, [tagSampleData]);

  useEffect(() => {
    formRef.current?.resetFields();
    setSingleTestResult('');
    const dragDataSource = rule.regexExpressions.map((i, index) => {
      return {
        id: Date.now() + index,
        text: i,
      };
    });
    setDragDataSource(dragDataSource);
  }, [props.rule]);

  useEffect(() => {
    setAllRuleTestResult({});
    handleChange();
  }, [dragDataSource]);

  const sampleDataPages = Math.ceil((pagination.total || 10) / 10);
  const maxPages = Math.ceil(TEST_ALL_MAX_DATA_COUNT / 10);

  return (
    <div>
      <div style={{ position: 'relative' }} className={styles.editorWrapper}>
        {!props.isEditMode ? (
          <div
            style={{
              position: 'absolute',
              background: 'rgba(0,0,0,0.25)',
              width: '100%',
              height: '100%',
              zIndex: 2,
            }}
          />
        ) : null}
        <div className={styles.editorHeader}>
          <div className={styles.editorTitle}>Edit RegEx rules</div>
        </div>
        <div className={styles.fieldInfo}>
          <span>
            <span className={styles.metaTag}>RegEx rules name: </span>
            <span className={styles.fieldName}>{fieldFullPath}</span>
          </span>
        </div>
        <div className={styles.editorSection}>
          <Table
            loading={fetchSampleDataLoading || loading}
            rowKey={(record) => record.tagValue}
            dataSource={dataSource}
            columns={columns}
            pagination={{
              ...pagination,
              showQuickJumper: true,
              showSizeChanger: false,
            }}
            onChange={(pagination) => {
              setPagination({
                pageSize: pagination.pageSize,
                current: pagination.current,
                total: pagination.total,
              });
            }}
          />
          <ProForm
            formRef={formRef}
            formKey="regex-rule-editor"
            autoFocusFirstInput
            submitter={{ render: () => [] }}
            onValuesChange={() => {}}
            className={styles.proFormItemWrapper}
          >
            <ProForm.Item
              label="Input RegEx Rules:"
              name="rule"
              rules={[{ required: true, message: 'Required!' }]}
              required
            >
              <ProFormText />
            </ProForm.Item>
            <ProForm.Item label="Input Text:" name="text">
              <ProFormText />
            </ProForm.Item>
            <ProFormDependency name={['rule', 'text']}>
              {({ rule, text }) => {
                return (
                  <>
                    <Button
                      type="primary"
                      ghost
                      style={{ marginRight: '20px' }}
                      onClick={() => {
                        formRef.current?.validateFields().then(() => {
                          handleRunTest(rule, text);
                        });
                      }}
                      disabled={!rule || !text}
                    >
                      Run test
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleAddRule(rule);
                      }}
                      ghost
                      disabled={!rule}
                    >
                      Add Rule
                    </Button>
                  </>
                );
              }}
            </ProFormDependency>
          </ProForm>
          <Spin spinning={loading}>
            <Code showCode code={singleTestResult} />
          </Spin>
        </div>
        <Divider style={{ margin: 0 }} />
        <div className={styles.editorSection}>
          <SectionLabel>
            RegEx Rules:{' '}
            <span
              style={{
                color: '#4A4A4A',
                fontSize: '12px',
                margin: '10px 0',
              }}
            >
              {' '}
              (Drag to sort)
            </span>
          </SectionLabel>
          <SortableTable
            dataSource={dragDataSource}
            columns={dragColumns}
            rowKey="id"
            onSortEnd={(res) => {
              setDragDataSource(res);
            }}
            showHeader={false}
          />
          <Button
            type="primary"
            style={{ marginTop: 10 }}
            onClick={() => {
              const total = pagination.total || 0;
              if (total > TEST_ALL_MAX_DATA_COUNT) {
                setPageSelectModalVisible(true);
              } else {
                handleTestAll();
              }
            }}
            ghost
            disabled={dragDataSource.length === 0}
            loading={loading}
          >
            Test All Rules
          </Button>
          <Spin spinning={loading}>
            <Tabs
              defaultActiveKey="1"
              style={{ marginTop: '15px' }}
              type="card"
            >
              <TabPane
                tab={
                  <div>
                    Matched{' '}
                    <Badge
                      style={{ backgroundColor: '#52c41a', color: '#fff' }}
                      count={allRuleTestResult.matchedCount}
                      showZero
                      overflowCount={9999}
                    />
                  </div>
                }
                key="1"
              >
                <Table
                  dataSource={
                    allRuleTestResult?.previewResults?.filter(
                      (i) => i.matched,
                    ) || []
                  }
                  rowKey="input"
                  pagination={{
                    ...allRuleTestResultPagination,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    onChange: (page) => {
                      setAllRuleTestResultPagination({ current: page });
                    },
                  }}
                >
                  <Table.Column title="Input" dataIndex="input" key="input" />
                  <Table.Column
                    title="Result"
                    dataIndex="parsedResults"
                    key="parsedResults"
                    render={(data) => {
                      const keys = Object.keys(data);
                      return keys.map((key) => (
                        <ResultItem key={data[key]}>
                          <span style={{ color: '#1890FF' }}>{key}</span>：
                          {data[key]}
                        </ResultItem>
                      ));
                    }}
                  />
                </Table>
              </TabPane>
              <TabPane
                tab={
                  <div>
                    Unmatched{' '}
                    <Badge
                      showZero
                      count={allRuleTestResult.unmatchedCount}
                      overflowCount={9999}
                    />
                  </div>
                }
                key="2"
              >
                <Table
                  dataSource={
                    allRuleTestResult?.previewResults?.filter(
                      (i) => !i.matched,
                    ) || []
                  }
                  rowKey="input"
                  pagination={{
                    ...allRuleTestResultPagination,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    onChange: (page) => {
                      setAllRuleTestResultPagination({ current: page });
                    },
                  }}
                >
                  <Table.Column title="Input" dataIndex="input" key="input" />
                </Table>
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      </div>
      <Modal
        title="Test RegEx Rules"
        destroyOnClose
        maskClosable={false}
        visible={pageSelectModalVisible}
        onOk={() => {
          setPageSelectModalVisible(false);
          handleTestAll();
        }}
        onCancel={() => {
          setPageSelectModalVisible(false);
        }}
      >
        <p>
          <Typography.Text strong>
            You have {sampleDataPages} pages of {rule.tagName} data. Please
            enter the number of pages you want to test with regex rules:
          </Typography.Text>
        </p>
        <InputNumber
          onChange={(value) => {
            setStartPage(value);
          }}
          style={{ width: 100 }}
          placeholder="Start Page"
          min={1}
          max={endPage === 0 ? sampleDataPages : endPage}
        />
        {` to `}
        <InputNumber
          onChange={(value) => {
            setEndPage(value);
          }}
          style={{ width: 100 }}
          placeholder="End Page"
          min={startPage}
          max={
            sampleDataPages - startPage > maxPages
              ? startPage + maxPages
              : sampleDataPages
          }
        />
        <p style={{ marginTop: 15 }}>
          <Typography.Text type="secondary">
            *Up to {maxPages} pages with {TEST_ALL_MAX_DATA_COUNT / 1000}K{' '}
            {rule.tagName} data.
          </Typography.Text>
        </p>
      </Modal>
    </div>
  );
};

export default RegexRuleEditor;
