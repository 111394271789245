import styled from 'styled-components';

type Custom = {
  active: boolean;
};

export const ListItem = styled.div<Custom>`
  width: 100%;
  min-height: 50px;
  padding: 17px 31px;
  box-sizing: border-box;
  background: ${(props) => (props.active ? '#E9F3FD' : 'white')};
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  color: ${(props) => (props.active ? '#002BF7' : '#000000')};
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  cursor: pointer;
  line-height: 30px;
  .mds-ant-tag,
  .mds-ant-icon-close {
    color: #000;
  }
`;

export const FieldListHeader = styled.div`
  background: #fff;
  width: 100%;
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
  padding: 15px 0 15px 21px;
  border-bottom: 1px solid #4a4a4a;
`;

export const FieldListWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const AndSymbol = styled.span`
  color: #000;
  margin-right: 10px;
`;

export const UnsavedIcon = styled.span`
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #ff9300;
`;
