import React from 'react';
import * as styles from './index.less';
import { useAppSelector } from '@src/store/hooks';

interface INoReport {
  onOpen: () => void;
}

const Index: React.FC<INoReport> = ({ onOpen }) => {
  const state = useAppSelector((state) => {
    return {
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const {
    activeBusinessParcel: {
      dataSource: { dataSourceName },
    },
  } = gapAnalysis;
  return (
    <div className={styles.noReportWrapper}>
      <div className={styles.promptText}>
        <span className={styles.msg}>
          No Gap Analysis report for Data Source:{' '}
        </span>
        <span>{dataSourceName}</span>
      </div>
      <div>
        <span>
          Create One{' '}
          <span className={styles.createText} onClick={onOpen}>
            here
          </span>
        </span>
      </div>
    </div>
  );
};

export default Index;
