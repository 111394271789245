export const PUSH_LOCATION = `PUSH_LOCATION`;

export const UPDATE_URL_QUERY_PARAMS = `UPDATE_URL_QUERY_PARAMS`;

export const REQUEST_INPUT_METADATA_QUALITY = `REQUEST_INPUT_METADATA_QUALITY`;

export const RECEIVE_INPUT_METADATA_QUALITY = `RECEIVE_INPUT_METADATA_QUALITY`;

export const REQUEST_PLAYER_LIST = `REQUEST_PLAYER_LIST`;

export const RECEIVE_PLAYER_LIST = `RECEIVE_PLAYER_LIST`;

export const REQUEST_TAG_DETAILS = `REQUEST_TAG_DETAILS`;

export const RECEIVE_TAG_DETAILS = `RECEIVE_TAG_DETAILS`;

export const REQUEST_CUSTOMER_METADATA_STRATEGY = `REQUEST_CUSTOMER_METADATA_STRATEGY`;

export const RECEIVE_CUSTOMER_METADATA_STRATEGY = `RECEIVE_CUSTOMER_METADATA_STRATEGY`;

export const RECEIVE_RULE_ENGINE_REQUIRED_FIELDS = `RECEIVE_RULE_ENGINE_REQUIRED_FIELDS`;

export const REQUEST_RULE_ENGINE_REQUIRED_FIELDS = `REQUEST_RULE_ENGINE_REQUIRED_FIELDS`;

export const SELECT_FIELD_TO_ACTIVE = `SELECT_FIELD_TO_ACTIVE`;

export const SELECT_REGEX_TO_ACTIVE = `SELECT_REGEX_TO_ACTIVE`;

export const EDIT_RULES_FOR_FIELD = `EDIT_RULES_FOR_FIELD`;

export const END_LOADING = `END_LOADING`;

export const START_LOADING = `START_LOADING`;

export const REQUEST_RULE_ENGINE_SUPPORT_LETTER = `REQUEST_RULE_ENGINE_SUPPORT_LETTER`;

export const RECEIVE_RULE_ENGINE_SUPPORT_LETTER = `RECEIVE_RULE_ENGINE_SUPPORT_LETTER`;

export const REQUEST_TAG_KEY_LIST = `REQUEST_TAG_KEY_LIST`;

export const RECEIVE_TAG_KEY_LIST = `RECEIVE_TAG_KEY_LIST`;

export const REQUEST_TAG_SAMPLE_DATA = `REQUEST_TAG_SAMPLE_DATA`;

export const RECEIVE_TAG_SAMPLE_DATA = `RECEIVE_TAG_SAMPLE_DATA`;

export const RUN_REGEX_TEST = `RUN_REGEX_TEST`;

export const RECEIVE_REGEX_TEST_RESULT = `RECEIVE_REGEX_TEST_RESULT`;

export const REQUEST_RULE_ENGINE_OPTIONS = `REQUEST_RULE_ENGINE_OPTIONS`;

export const RECEIVE_RULE_ENGINE_OPTIONS = `RECEIVE_RULE_ENGINE_OPTIONS`;

export const GROUP_CODE_LETTER_CASING = `LETTER_CASING`;

export const GROUP_CODE_RULE_FIELD_MAPPING_TYPE = `RULE_FIELD_MAPPING_TYPE`;

export const GROUP_CODE_AUTOMATED_METADATA_MAPPING = `AUTOMATED_METADATA_MAPPING`;

export const GROUP_CODE_CI_REQUIRED_FIELD = `CI_REQUIRED_FIELD`;

export const GROUP_CODE_CUSTOMER_CUSTOM_FIELDS = `CUSTOMER_CUSTOM_FIELDS`;

export const FIELD_TYPE_CODE_CUSTOM = `CUSTOM`;

export const CLEAR_FIELD_RULES = `CLEAR_RULES`;

export const APPLY_RULES_TO_FIELD = `APPLY_RULES_TO_FIELD`;

export const APPLY_RULES_TO_REGEX = `APPLY_RULES_TO_REGEX`;

export const EDIT_RULES_FOR_REGEX = `EDIT_RULES_FOR_REGEX`;

export const EDIT_RULES_FOR_CUSTOM_TAG = `EDIT_RULES_FOR_CUSTOM_TAG`;

export const UPDATE_SELECTED_CUSTOM_TAGS = `UPDATE_SELECTED_CUSTOM_TAGS`;

export const CLEAR_CUSTOM_TAG_RULES = `CLEAR_CUSTOM_TAG_RULES`;

export const APPLY_RULES_TO_CUSTOM_TAG = `APPLY_RULES_TO_CUSTOM_TAG`;

export const DELETE_CUSTOM_TAG_RULES = `DELETE_CUSTOM_TAG_RULES`;

export const SELECT_CUSTOM_TAG_TO_ACTIVE = `SELECT_CUSTOM_TAG_TO_ACTIVE`;

export const RECEIVE_RULE_VERSIONS = `RECEIVE_RULE_VERSIONS`;

export const REQUEST_RULE_VERSIONS = `REQUEST_RULE_VERSIONS`;

export const SET_ACTIVE_VERSION = `SET_ACTIVE_VERSION`;

export const REQUEST_RULE_CONFIG = `REQUEST_RULE_CONFIG`;

export const REQUEST_INITIAL_RULE = `REQUEST_INITIAL_RULE`;

export const RECEIVE_RULES = `RECEIVE_RULES`;

export const MAP_TYPE_DIRECT = `DIRECT`;

export const MAP_TYPE_CONSTANT = `CONSTANT`;

export const MAP_TYPE_EXTRACT = `EXTRACT`;

export const POST_SAVE_RULE = `POST_SAVE_RULE`;

export const PUT_UPDATE_RULE = `PUT_UPDATE_RULE`;

export const CLEAR_REGEX_RULES = `CLEAR_REGEX_RULES`;

export const REQUEST_DRY_RUN_LIST = `REQUEST_DRY_RUN_LIST`;

export const RECEIVE_DRY_RUN_LIST = `RECEIVE_DRY_RUN_LIST`;

export const DRY_RUN_REPORT_NAME_PREFIX = `dry-run report`;

export const CREATE_DRY_RUN_REPORT = `CREATE_DRY_RUN_REPORT`;

export const DUPLICATE_VERSION = `DUPLICATE_VERSION`;

export const DELETE_VERSION = `DELETE_VERSION`;

export const RELEASE_VERSION = `RELEASE_VERSION`;

export const CHANGE_WORKFLOW_STATE = `CHANGE_WORKFLOW_STATE`;

export const GET_USER_INFO = `GET_USER_INFO`;

export const RECEIVE_USER_INFO = `RECEIVE_USER_INFO`;

export const GET_DRY_RUN_REPORT = `GET_DRY_RUN_REPORT`;

export const GET_DRY_RUN_REPORT_FIELD_DETAIL = `GET_DRY_RUN_REPORT_FIELD_DETAIL`;

export const ADD_DRY_RUN_REPORT_TAB = `ADD_DRY_RUN_REPORT_TAB`;

export const RECEIVE_DRY_RUN_REPORT = `RECEIVE_DRY_RUN_REPORT`;

export const RECEIVE_RULE_COMPARE_REPORT = `RECEIVE_RULE_COMPARE_REPORT`;

export const CLEAR_DRY_RUN_REPORT = `CLEAR_DRY_RUN_REPORT`;

export const UPDATE_DRY_RUN_COMPARE_LISTS = `UPDATE_DRY_RUN_COMPARE_LISTS`;

// V2

export const FETCH_SAMPLE_DATA = `FETCH_SAMPLE_DATA`;

export const GET_SAMPLE_DATA_JOBS = `GET_SAMPLE_DATA_JOBS`;

export const RECEIVE_SAMPLE_DATA_JOBS = `RECEIVE_SAMPLE_DATA_JOBS`;

export const RECEIVE_RULE_INFO = `RECEIVE_RULE_INFO`;

export const RECEIVE_BUSINESS_UNITS = `RECEIVE_BUSINESS_UNITS`;

export const SET_ACTIVE_BUSINESS_UNIT = `SET_ACTIVE_BUSINESS_UNIT`;

export const FETCH_BUSINESS_UNITS = `FETCH_BUSINESS_UNITS`;

export const UPDATE_RULE = `UPDATE_RULE`;
