import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { IRuleFiledMapping } from '@src/typing/ruleEngine';
import { getTagColor, getTagShowTitle, safeParse } from '@src/utils/ruleEngine';
import { MAP_TYPE_CONSTANT, MAP_TYPE_EXTRACT } from '@src/store/types/types';
import { Tag } from 'antd';
import {
  AndSymbol,
  ListItem,
  UnsavedIcon,
} from '@src/pages/RuleEngine/components/V2/FieldList/styled';

interface Props {
  hasUnsave: boolean;
  active: boolean;
  field: IRuleFiledMapping;
}

const FieldItem: React.FC<Props> = (props) => {
  const { field, hasUnsave, active } = props;
  const genTags = (field: IRuleFiledMapping) => {
    const tags: any[] = [];
    let mapFrom;

    if (field.useDsl) {
      mapFrom = [{ label: 'Advanced Transformer' }];
      if (field.dsl.fallbackValue) {
        let fallbackValue = field.dsl.fallbackValue;
        if (field.outputFieldTypeCode === 'STRING') {
          fallbackValue = safeParse(field.dsl.fallbackValue);
        }
        mapFrom.push({
          label: fallbackValue,
          mappedFrom: fallbackValue,
          mappingType: MAP_TYPE_CONSTANT,
        });
      }
    } else {
      mapFrom = field.ruleFieldMappedFroms || [];
    }
    if (!mapFrom.length) {
      return [];
    }

    tags.push(
      <span key="is-mapped-from" style={{ marginRight: '20px', color: '#000' }}>
        is mapped from
      </span>,
    );

    mapFrom.forEach((tag, index) => {
      const color = getTagColor(tag.mappingType);
      const showTitle = getTagShowTitle(tag);

      tags.push(
        <Tag key={`${Math.random()}${showTitle}`} color={color}>
          {showTitle}
        </Tag>,
      );
      if (index !== mapFrom.length - 1) {
        tags.push(
          <AndSymbol key={`${Math.random()}${showTitle}and`}>and</AndSymbol>,
        );
      }
    });

    return tags;
  };

  const title = hasUnsave ? (
    <>
      <UnsavedIcon /> {field.fieldName}
    </>
  ) : (
    field.fieldName
  );
  const tags = genTags(field);

  return (
    <ListItem key={field.fieldCode} active={active}>
      <span
        style={{
          minWidth: '110px',
          display: 'inline-block',
          paddingRight: '10px',
        }}
      >
        {title}
      </span>
      {tags}
    </ListItem>
  );
};

export default memo(FieldItem, (prev, next) => {
  return (
    isEqual(prev.field.ruleFieldMappedFroms, next.field.ruleFieldMappedFroms) &&
    prev.field.useDsl === next.field.useDsl &&
    prev.field.dsl === next.field.dsl &&
    prev.hasUnsave === next.hasUnsave &&
    prev.active === next.active
  );
});
