import * as types from '@src/store/types/gapAnalysis';

export const resetGapAnalysisState = () => ({
  type: types.RESET_GAP_ANALYSIS_STATE,
});

export const updateGapAnalysisReports = (data: any) => ({
  type: types.UPDATE_GAP_ANALYSIS_REPORTS,
  data,
});

export const updateHistoricalJob = (data: any) => ({
  type: types.UPDATE_HISTORICAL_JOB,
  data,
});

export const updateHistoricalJobPagination = (data: any) => ({
  type: types.UPDATE_HISTORICAL_JOB_PAGINATION,
  data,
});

export const updateLatestJob = (data: any) => ({
  type: types.UPDATE_LATEST_JOB,
  data,
});

export const updateCurrentJob = (data: any) => ({
  type: types.UPDATE_CURRENT_JOB,
  data,
});

export const updateConditionFilterList = (data: any) => ({
  type: types.UPDATE_CONDITION_FILTER_LIST,
  data,
});

export const updateConditionResultList = (data: any) => ({
  type: types.UPDATE_CONDITION_RESULT_LIST,
  data,
});

export const updateCoverageResultList = (data: any) => ({
  type: types.UPDATE_COVERAGE_RESULT_LIST,
  data,
});

export const updateCurrentResultList = (data: any) => ({
  type: types.UPDATE_CURRENT_RESULT_LIST,
  data,
});

export const updateSelectedFilterFields = (data: any) => ({
  type: types.UPDATE_SELECTED_FILTER_FIELDS,
  data,
});
export const updateCoverageFilterFields = (data: any) => ({
  type: types.UPDATE_COVERAGE_FILTER_FIELDS,
  data,
});

export const updateCurrentFilterFields = (data: any) => ({
  type: types.UPDATE_CURRENT_FILTER_FIELDS,
  data,
});

export const modifyUpdateCoverageMark = (data: boolean) => ({
  type: types.UPDATE_COVERAGE_MARK,
  data,
});

export const modifyUpdateFilterMark = (data: boolean) => ({
  type: types.UPDATE_FILTER_MARK,
  data,
});

export const resetConditionAndCoverage = () => ({
  type: types.RESET_CONDITION_AND_COVERAGE,
});
