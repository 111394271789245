import styled from 'styled-components';

export const Layout = styled.div`
  margin-top: 20px;
  background-color: #fff;
  box-sizing: border-box;
  font-family: 'Open Sans' !important;
`;

export const LayoutWrap = styled('div')`
  display: flex;
  overflow-y: scroll;
  background-color: #fff;
`;

export const Content = styled('div')`
  flex-grow: 1;
`;

export const PageTitle = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  padding: 20px 0 0 21px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
`;
//Conviva Tooltip

export const UnsavedField = styled.div`
  color: #f31407;
  font-size: 16px;
`;

export const UnsavedTips = styled.div`
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: 0;
`;
