import React, { useState } from 'react';
import {
  ModalWrapper,
  SampleValueItem,
  TabPaneHeader,
  TagDetailsItem,
  TagDetailsItemBox,
} from '@src/pages/PreMdsCoverage/styled';
import { Spin, Tabs } from 'antdV3';
import { Modal } from 'conviva-design';
import { ThemeContext } from '@src/contexts';
import { useShowSample } from '@src/service/apis/gapAnalysis';
import { useAppSelector } from '@src/store/hooks';
import { IColumn } from '@src/typing/gapAnalysis';

const TAG_VALUE_NULL = 'Null';

interface ISampleValueModal {
  visible: boolean;
  onCancel: () => void;
  tag?: {
    tagKey: string;
    parentPath: string;
  };
  currentColumn: IColumn | {};
}

const SampleValueModal: React.FC<ISampleValueModal> = ({
  visible = false,
  onCancel,
  tag,
  currentColumn,
}) => {
  const { theme } = React.useContext(ThemeContext);
  const state = useAppSelector((state) => {
    return {
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const {
    currentJob,
    coverageFilterFields,
    activeBusinessParcel: {
      dataSource: { dataSourceCode },
    },
  } = gapAnalysis;
  const jobId = currentJob?.uuid;
  const sampleRequest = {
    jobId: jobId,
    tag: tag,
    dataSourceCode: dataSourceCode,
    filterFields: coverageFilterFields,
    breakDownFields: currentColumn,
  };
  const { data: sampleData, isLoading: sampleLoading } =
    useShowSample(sampleRequest);

  const [sampleValueActiveTab, setSampleValueActiveTab] = useState('1');

  const onChangeTab = (key) => {
    setSampleValueActiveTab(key);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      theme={theme}
      width="600px"
      title="Metadata information"
      footer={[]}
      className="sample-modal"
    >
      <ModalWrapper>
        <Spin spinning={sampleLoading}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={sampleValueActiveTab}
            onChange={onChangeTab}
          >
            <Tabs.TabPane tab="Value distribution" key={'1'}>
              <TabPaneHeader>
                <div
                  style={{ width: '69%' }}
                  className="text-cut"
                  title={tag?.tagKey}
                >
                  {tag?.tagKey}
                </div>
                <div
                  style={{ width: '30%', textAlign: 'right' }}
                  className="text-cut"
                >
                  Traffic Percentage
                </div>
              </TabPaneHeader>
              <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                {sampleData?.sampleValues?.map((item) => (
                  <SampleValueItem key={item.value + item.coverage}>
                    {item.value === TAG_VALUE_NULL ? (
                      <div
                        style={{
                          width: '69%',
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                        className="text-cut"
                        title={item.value}
                      >
                        {item.value}
                      </div>
                    ) : (
                      <div
                        style={{ width: '69%' }}
                        className="text-cut"
                        title={item.value}
                      >
                        {item.value}
                      </div>
                    )}
                    <div
                      style={{ width: '30%', textAlign: 'right' }}
                      className="text-cut"
                    >
                      {item.coverage < 0.01 ? '< 0.01' : item.coverage}%
                    </div>
                  </SampleValueItem>
                ))}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tag details" key={'2'}>
              {/*{diffAppVersionCoverage.map((item) => (*/}
              <TagDetailsItemBox>
                <TagDetailsItem>
                  <div className="tag-details-item-label">Tag Name</div>
                  <div className="tag-details-item-value">{tag?.tagKey}</div>
                </TagDetailsItem>
                <TagDetailsItem>
                  <div className="tag-details-item-label">Coverage Passed</div>
                  <div className="tag-details-item-value">
                    {sampleData?.detail?.coverage}%
                  </div>
                </TagDetailsItem>
                <TagDetailsItem>
                  <div className="tag-details-item-label">App name</div>
                  <div className="tag-details-item-value">
                    {sampleData?.detail?.label}
                  </div>
                </TagDetailsItem>
              </TagDetailsItemBox>
              {/*))}*/}
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </ModalWrapper>
    </Modal>
  );
};

export default SampleValueModal;
