import store from '../store';
import {
  batchJobHttpClient,
  mdsHttpInstance,
  ruleEngineHttpClient,
  systemConfigurationHttpClient,
} from './http';
import * as types from '../store/types/types';
import {
  IBusinessParcel,
  TRuleEngineVersion,
} from '@src/typing/businessParcel';
import {
  IDryRunInfo,
  IDryRunReportFieldDetail,
  IDryRunReportItem,
  IRuleInfo,
  TDryRunStatus,
} from '@src/typing/ruleEngine';
import { useQuery } from 'react-query';
import { EReactQueryKey } from '@src/utils/constants';
import { toUrl } from '@src/utils/url';

export const getInputMetadataQuality = async (params: any) => {
  try {
    let url = '/metadata-quality?';
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    return mdsHttpInstance.get(url.substr(0, url.length - 1)); // remove ending & or ending ?
  } catch (e) {
    throw e;
  }
};

export const getPlayerList = async (params: any) => {
  try {
    let url = '/player-name?';
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    return await mdsHttpInstance.get(url.substr(0, url.length - 1));
  } catch (e) {
    throw e;
  }
};

export const getTagDetails = async (params: any) => {
  try {
    let url = '/tag-detail?';
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    return await mdsHttpInstance.get(url.substr(0, url.length - 1));
  } catch (e) {
    throw e;
  }
};

export const getCustomerMetadataStrategy = async (params: any) => {
  try {
    let url = '/metadata-strategy?';
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    return await mdsHttpInstance.get(url.substr(0, url.length - 1));
  } catch (e) {
    throw e;
  }
};

/*
 *
 *  Rule engine APIs
 *
 * */

export const getFetchDataStatus = async () => {
  try {
    const url = '/batch-jobs';
    return await batchJobHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const getRuleEngineFields = async (params: any) => {
  try {
    let url =
      '/sys-dict-groups?groupCodes=CI_REQUIRED_FIELD,AUTOMATED_METADATA_MAPPING&itemEnabled=true&';
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    return await systemConfigurationHttpClient.get(
      url.substr(0, url.length - 1),
    );
  } catch (e) {
    throw e;
  }
};

export const getRuleEngineSupportLetter = async () => {
  try {
    let url =
      '/sys-dict-groups?groupCodes=LETTER_CASING,CI_REQUIRED_FIELD,AUTOMATED_METADATA_MAPPING&itemEnabled=true';
    return await systemConfigurationHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const getRuleEngineOptions = async () => {
  try {
    const { GROUP_CODE_LETTER_CASING, GROUP_CODE_RULE_FIELD_MAPPING_TYPE } =
      types;
    let groupCodes = [
      GROUP_CODE_LETTER_CASING,
      GROUP_CODE_RULE_FIELD_MAPPING_TYPE,
    ].join(',');
    let url = `/sys-dict-groups?groupCodes=${groupCodes}&itemEnabled=true`;
    return await systemConfigurationHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const getTagKeyList = async () => {
  try {
    let url = '/metadata-tag-label-values';
    return await batchJobHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const getTagSampleData = async (tagKey: string, pageNum: number) => {
  try {
    let url =
      '/sample-data-tags?tagKey=' +
      tagKey +
      '&pageNum=' +
      pageNum +
      '&pageSize=10';
    return await batchJobHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const getCustomerTagLimit = async (projectCode?: string) => {
  try {
    let url = '/metadata-rules/customer-config';
    if (projectCode) {
      url = `/metadata-rules/customer-config?projectCode=${projectCode}`;
    }
    return await ruleEngineHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const runRegexTest = async (params: any) => {
  try {
    let url =
      '/metadata-rules/verify-single-rule?regexExpression=' +
      encodeURIComponent(params.inputRegex) +
      '&inputText=' +
      encodeURIComponent(params.inputText);
    const response = await ruleEngineHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const runRegexTestAll = async (params: any) => {
  try {
    let url = '/metadata-rules/preview-sample-data-with-rules?';
    const { rules, tagKey, startPage, endPage } = params;
    let rules_url = '';
    rules.forEach((rule) => {
      rules_url += `regexExpressions=${encodeURIComponent(rule.value)}&`;
    });
    url = `${url}${rules_url}tagKey=${encodeURIComponent(tagKey)}`;
    if (endPage) {
      url += `&page.startPage=${startPage}&page.endPage=${endPage}&page.pageSize=10`;
    }
    const response = await ruleEngineHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const requestRuleVersions = async (params: any = {}) => {
  const { ruleEngine } = store.getState();
  const { projectCode } = ruleEngine.activeBusinessParcel?.project;
  try {
    let url = `/metadata-rules?projectCode=${projectCode}&pageSize=999&`;
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    const response = await ruleEngineHttpClient.get(
      url.substr(0, url.length - 1),
    );
    return response;
  } catch (e) {
    throw e;
  }
};

/**
 * get ruleInfo by uuid
 * @param uuid
 * @returns
 */
export const requestRuleConfig = async (uuid: any) => {
  try {
    let url = '/metadata-rules/' + uuid;
    const response = await ruleEngineHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const putUpdateRule = async (uuid: string, data: any) => {
  try {
    let url = '/metadata-rules/' + uuid;
    const response = await ruleEngineHttpClient.put(url, data);
    return response;
  } catch (e) {
    throw e;
  }
};

export const requestInitialRule = async (params: any) => {
  const { ruleEngine } = store.getState();
  const { projectCode } = ruleEngine.activeBusinessParcel?.project;
  try {
    let url = `/metadata-rule-templates/init-rule?projectCode=${projectCode}`;
    const response = await ruleEngineHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const getRule = async (uuid) => {
  try {
    let url = `/metadata-rules/${uuid}`;
    const response = await ruleEngineHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const postSaveRule = async (params: any) => {
  try {
    let url = '/metadata-rules';
    const response = await ruleEngineHttpClient.post(url, params);
    return response;
  } catch (e) {
    throw e;
  }
};

/**
 * TODO: The function is replaced by requestDryRunList. For logic stability, it will be removed after all its use case is removed.
 * @param params
 * @returns
 */
export const requestDryRunList2 = async (params: any) => {
  try {
    let url = '/dry-run-jobs?';
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    const response = await batchJobHttpClient.get(
      url.substr(0, url.length - 1),
    );
    return response;
  } catch (e) {
    throw e;
  }
};

interface IDryRunList {
  V1: {
    request: {
      params: {
        ruleId?: string;
      };
    };
    responseData: any;
  };
  V2: {
    request: {
      params: {
        projectCode?: string;
        ruleId?: string;
      };
    };
    responseData: any;
  };
}
export const requestDryRunList = async <V extends TRuleEngineVersion>(
  v: V,
  config: IDryRunList[V]['request'],
): Promise<{
  entities: IDryRunInfo[];
  pageNum: number;
  pageSize: number;
  total: number;
}> => {
  if (v === 'V1') {
    const responseData = (await batchJobHttpClient({
      ...{
        url: '/dry-run-jobs',
        method: 'get',
      },
      ...config,
    })) as {
      data: {
        createDate: string;
        customerId: string;
        effective: boolean;
        endDate: string;
        jobStartTime: string;
        jobEndTime: string;
        jobStatus: 'success' | 'FAILED';
        ruleHash: string;
        ruleId: string;
        sparkJobId: string;
        startDate: string;
        updateDate: string;
        username: string;
        uuid: string;
      }[];
      pageNum: number;
      pageSize: number;
      total: number;
    };
    const { data, pageNum, pageSize, total } = responseData;
    return {
      entities: data.map((it) => {
        const { username, jobStatus = '' } = it;
        return {
          ...it,
          version: 'V1',
          name: '',
          description: '',
          userName: username,
          jobStatus: jobStatus.toUpperCase() as TDryRunStatus,
        };
      }),
      pageSize,
      pageNum,
      total,
    };
  }
  const { entities, pageNum, pageSize, total } = (
    await ruleEngineHttpClient({
      ...{
        url: '/metadata-dry-run/jobs',
        method: 'get',
      },
      ...config,
    })
  ).data as {
    entities: {
      name: string;
      description: string;
      customerId: string;
      effective: boolean;
      endDate: string;
      jobStartTime: string;
      jobEndTime: string;
      jobMessage: string;
      jobStatus: 'SUCCESS' | 'FAILED';
      ruleHash: string;
      ruleId: string;
      startDate: string;
      userName: string;
      uuid: string;
    }[];
    pageNum: number;
    pageSize: number;
    total: number;
  };
  return {
    entities: entities.map((it) => {
      const { jobStatus } = it;
      return {
        ...it,
        version: 'V2',
        jobStatus: jobStatus.toUpperCase() as TDryRunStatus,
      };
    }),
    pageSize,
    pageNum,
    total,
  };
};
export function useRequestDryRunList(
  businessParcel?: Pick<IBusinessParcel, 'uuid' | 'reVersion'>,
  ruleInfo?: Pick<IRuleInfo, 'uuid' | 'projectCode'> | null,
) {
  const { uuid: businessParcelId, reVersion } = (
    businessParcel ? businessParcel : {}
  ) as Pick<IBusinessParcel, 'uuid' | 'reVersion'>;
  const { uuid: ruleId, projectCode } = (ruleInfo ? ruleInfo : {}) as Pick<
    IRuleInfo,
    'uuid' | 'projectCode'
  >;
  return useQuery(
    [EReactQueryKey.DRY_RUN_LIST, businessParcelId, ruleId],
    async () => {
      if (!businessParcelId || !ruleId) {
        return {
          pageSize: 0,
          pageNum: 0,
          total: 0,
          entities: [],
        };
      }
      if (reVersion === 'V1') {
        return await requestDryRunList('V1', {
          params: {
            ruleId,
          },
        });
      }
      return await requestDryRunList('V2', {
        params: {
          projectCode,
          ruleId,
        },
      });
    },
  );
}
export interface ICreateDryRun {
  V1: {
    request: {
      data: {
        ruleId: string;
        startDate: string;
        endDate: string;
        customerId?: string;
      };
    };
    responseData: any;
  };
  V2: {
    request: {
      data: {
        ruleId: string;
        // projectCode: string;
        name: string;
        description: string;
        /** No need duration when partition is NONE */
        startDateTime?: string;
        endDateTime?: string;
      };
    };
    responseData: any;
  };
}
export const requestCreateDryRunReport = async <V extends TRuleEngineVersion>(
  v: V,
  config: ICreateDryRun[V]['request'],
): Promise<ICreateDryRun[V]['responseData']> => {
  try {
    if (v === 'V1') {
      return await batchJobHttpClient({
        ...{
          url: '/dry-run-jobs',
          method: 'post',
        },
        ...config,
      });
    }
    return await ruleEngineHttpClient({
      ...{
        url: '/metadata-dry-run/jobs',
        method: 'post',
      },
      ...config,
    });
  } catch (e) {
    throw e;
  }
};

export const duplicateVersion = async (uuid: string) => {
  try {
    let url = `/metadata-rules/${uuid}/duplicate`;
    const response = await ruleEngineHttpClient.post(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteVersion = async (uuid: string) => {
  try {
    let url = `/metadata-rules/${uuid}`;
    const response = await ruleEngineHttpClient.delete(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const changeWorkflowState = async (params: any) => {
  try {
    let url = `/metadata-rules/${params.uuid}/state`;
    const response = await ruleEngineHttpClient.patch(url, params);
    return response;
  } catch (e) {
    throw e;
  }
};

export const getUserInfo = async () => {
  try {
    let url = `/sys-users/login-user-info`;
    const response = await systemConfigurationHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

/**
 * TODO: The function is replaced by requestDryRunReportV1. It will be removed after all dependencies are removed.
 * @param uuid
 * @returns
 */
export const getDryRunReport = async (
  uuid: string,
): Promise<{ data: IDryRunReportItemtV1 }> => {
  try {
    let url = `/dry-run-reports?dryRunId=${uuid}`;
    // let url = `/dry-run-reports?dryRunId=29b307490c8010a156a00a59a2b71fac`;
    const response = await batchJobHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

interface IDryRunReportItemtV1 {
  uuid: string;
  dryRunId: string;
  /** used as unique id */
  metadataField: string;
  /** used for show */
  showFieldName: string;
  coverage: number;
  createDate: string;
  updateDate: string;
}
export const requestDryRunReportV1 = async (config: {
  params: {
    dryRunId: string;
  };
}): Promise<IDryRunReportItem[]> => {
  const res = (await batchJobHttpClient({
    ...{
      url: '/dry-run-reports',
      method: 'get',
    },
    ...config,
  })) as {
    data: IDryRunReportItemtV1[];
  };
  return res.data.map((it) => {
    const { coverage } = it;
    return {
      fieldId: it.metadataField,
      fieldName: it.showFieldName,
      coverage,
    };
  });
};

export interface IDryRunReportItemtV2 {
  fieldName: string;
  fieldCode: string;
  coverage: number;
  values:
    | {
        value: string;
        count: number;
      }[]
    | null;
}
export const requestDryRunReportV2 = async (config: {
  urlParams: {
    dryRunId: string;
  };
}): Promise<IDryRunReportItem[]> => {
  const res = (await ruleEngineHttpClient({
    ...{
      url: toUrl({
        path: '/metadata-dry-run/jobs/{dryRunId}/report',
        params: config.urlParams,
      }),
      method: 'get',
    },
    ...config,
  })) as {
    data: {
      fields: IDryRunReportItemtV2[];
    };
  };
  return res.data.fields.map((it) => {
    const { fieldName, coverage, values } = it;
    return {
      fieldName,
      fieldId: fieldName,
      coverage,
      values: (Array.isArray(values) ? values : []).map((it) => {
        const { value, count } = it;
        return {
          fieldValue: value,
          count,
        };
      }),
    };
  });
};

/**
 * TODO: The function is replaced by requestDryRunReportFieldDetailV1. It will be removed after all dependencies are removed.
 */
export const getDryRunReportFieldDetail = async (params: any) => {
  try {
    let url = `/dry-run-field-value-reports?`;
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    const response = await batchJobHttpClient.get(
      url.substr(0, url.length - 1),
    );
    return response;
  } catch (e) {
    throw e;
  }
};

interface IDryRunReportFieldDetailV1 {
  metadataValue: string;
  coverage: number;
  metadataField: string;
  dryRunId: string;
  uuid: string;
  createDate: string;
  updateDate: string | null;
}
export const requestDryRunReportFieldDetailV1 = async (config: {
  params: {
    dryRunId: string;
    fieldName: string;
  };
}): Promise<IDryRunReportFieldDetail[]> => {
  const res = (await batchJobHttpClient({
    ...{
      url: '/dry-run-field-value-reports',
      method: 'get',
    },
    ...config,
  })) as {
    data: IDryRunReportFieldDetailV1[];
  };
  return res.data.map((it) => {
    const { metadataValue, coverage } = it;
    return {
      fieldValue: metadataValue,
      count: coverage,
    };
  });
};

export const submitSampleDataJob = async (data: any) => {
  try {
    let url = `/batch-jobs`;
    return await batchJobHttpClient.post(url, data, {
      successMessage:
        'It will take mins to hours depending on traffic volume...',
    });
  } catch (e) {
    throw e;
  }
};

export const getSampleDataJobs = async () => {
  try {
    let url = `/batch-jobs`;
    const response = await batchJobHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchPreMdsData = async (params: any) => {
  try {
    let url = '/pre-mds';
    const response = await batchJobHttpClient.post(url, params);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchPreMdsTagClassify = async () => {
  try {
    let url = '/pre-mds/tag-classify';
    const response = await batchJobHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchProductClassify = async () => {
  try {
    let url = '/pre-mds/product-classify';
    const response = await batchJobHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchPreMdsAppsData = async (params: any) => {
  try {
    const url = '/pre-mds/coverage-by-app';
    const response = await batchJobHttpClient.post(url, params);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchSampleValue = async (params: any) => {
  try {
    const url = '/pre-mds-sample-data';
    const response = await batchJobHttpClient.post(url, params);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchDiffAppVersionCoverage = async (params: any) => {
  try {
    const url = '/pre-mds/diff-app-version-coverage-by-tag';
    const response = await batchJobHttpClient.post(url, params);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchPreMdsDisabledTimeRange = async () => {
  try {
    const url = '/pre-mds-job-record/time-range';
    const response = await batchJobHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchBusinessParcels = async (): Promise<{
  data: IBusinessParcel;
}> => {
  try {
    const url = '/metadata-business-parcels/list-by-customer';
    const response = await ruleEngineHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};

export const getBusiness = async (uuid: any) => {
  try {
    const url = `/metadata-business-units/${uuid}`;
    const response = await ruleEngineHttpClient.get(url);
    return response;
  } catch (e) {
    throw e;
  }
};
