const colorMap = {
  pageBackgroundColor: {
    light: '#e9edf0',
    dark: '#000',
  },
  backgroundColor: {
    light: '#ffffff',
    dark: '#17253a',
  },
  color: {
    light: '#17253a',
    dark: '#CACACA',
  },
  activeBackgroundColor: {
    light: '#e9f3fd',
    dark: '#214262',
  },
  borderColor: {
    light: '#ddd',
    dark: '#555'
  },
  activeColor: {
    light: '#0046FF',
    dark: '#1890ff'
  },
  disabledColor: {
    light: 'rgba(104,104,104,0.4)',
    dark: 'rgba(202,202,202,0.4)',
  },
  disabledBackground: {
    light: '#F2F2F2',
    dark: '#293649',
  },
  selectedBackground: {
    light: '#f6f6f6',
    dark: '#203854',
  },
  activeBorderColor: {
    light: '#215bff',
    dark: '#1890ff',
  },
  borderOne: {
    light: '#9B9B9B',
    dark: '#9B9B9B',
  },
};

export const toolTipBackgroundColor = '#F2F2F2';
export const invalidInputBorderColor = '#D0021B';
export default colorMap;
