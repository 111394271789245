import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';

declare module 'axios' {
  export interface AxiosRequestConfig {
    successMessage?: ((params: any) => string) | string;
    errorMessage?: ((params: any) => string) | string;
    showErrorMessage?: boolean;
  }
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

const domain = process.env.REACT_APP_RULE_ENGINE_API_HOST + '/api';
const baseUrl = {
  systemConfiguration: domain + '/system-configuration/v1.0',
  ruleEngine: domain + '/metadata-management-rule-engine/v1.0',
  batchJob: domain + '/metadata-management-batch-job/v1.0',
};

const clientCreator = (url: string = '', options: AxiosRequestConfig = {}) => {
  const config = {
    baseURL: url,
    timeout: 60 * 1000 * 2,
    withCredentials: true,
    showErrorMessage: true,
    ...options,
  };

  const client = axios.create(config);
  // Add a response interceptor
  client.interceptors.response.use(responseHandler, errorHandler);
  return client;
};

const errorMessageHandler = (response: AxiosResponse) => {
  const {
    config = {},
    data = { message: 'Network Error' },
    status = '-1',
  } = response;
  if (config.showErrorMessage) {
    if (config.errorMessage) {
      let errorMessage = config.errorMessage;
      if (typeof config.errorMessage === 'function') {
        errorMessage = config.errorMessage(data);
      }
      message.error(errorMessage);
    } else {
      let errorMessage = data.message || data.error;
      if (status > 200 && status < 300) {
        message.error(errorMessage);
      } else {
        message.error(`${status}: ${errorMessage}`);
      }
    }
  }
};

const responseHandler = (response: AxiosResponse) => {
  const { config, data, status } = response;
  if (status === 200 && data.code === 'SUCCESS') {
    if (config.successMessage) {
      let successMessage = config.successMessage;
      if (typeof config.successMessage === 'function') {
        successMessage = config.successMessage(data);
      }
      message.success(successMessage);
    }
  } else {
    errorMessageHandler(response);
    return Promise.reject(data);
  }
  return response.data;
};

const errorHandler = (error) => {
  errorMessageHandler(error.response);
  return Promise.reject(error);
};

export const mdsHttpInstance = clientCreator(
  process.env.REACT_APP_MDS_API_HOST,
);

export const systemConfigurationHttpClient = clientCreator(
  baseUrl.systemConfiguration,
);

export const ruleEngineHttpClient = clientCreator(baseUrl.ruleEngine);

export const batchJobHttpClient = clientCreator(baseUrl.batchJob);
