import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { Moment } from 'moment';
import { Form, Input, Modal, Tooltip, DatePicker, message } from 'antd';
import type { RangeValue } from 'rc-picker/lib/interface';
import { Icon } from 'antdV3';
import moment from 'moment';
import { IBusinessParcel } from '@src/typing/businessParcel';
import { IRuleInfo } from '@src/typing/ruleEngine';
import { ICreateDryRun, requestCreateDryRunReport } from '@src/service/api';
import { validateFormWrapper } from '@src/utils/common';
import TextArea from 'antd/lib/input/TextArea';

type TAction = 'cancel' | 'success';
interface IFormSetting {
  duration: RangeValue<Moment>;
  name: string;
  description: string;
}
const dateFormat = 'YYYY/MM/DD';

const ModalCreateDryRun: React.FC<{
  businessParcel: IBusinessParcel;
  ruleInfo: IRuleInfo;
  visible?: boolean;
  onCreateSuccess?: () => void;
  onClose?: () => void;
}> = (props) => {
  const { businessParcel, ruleInfo, visible, onClose, onCreateSuccess } = props;
  const [isRequesting, setIsRequesting] = useState(false);
  /** reference dates for calculating whether date is disabled or not on RangePicker */
  const [dates, setDates] = useState<RangeValue<Moment>>();
  /** used as temporary value for RangePicker when RangePicker panel is open, so the true value can restore on panel close */
  const [hackValue, setHackValue] = useState<RangeValue<Moment>>(null);
  const [form] = Form.useForm<IFormSetting>();
  /** the true value of RangePicker */
  const { duration } = form.getFieldsValue();
  const { reVersion, dataSource } = businessParcel;
  const requestCreateDryRun = useCallback(async () => {
    if (!businessParcel) {
      throw new Error(`businessParcel is not found`);
    }
    if (!ruleInfo) {
      throw new Error(`ruleInfo is not found`);
    }
    await validateFormWrapper(form);
    const { duration, name, description } = form.getFieldsValue();
    const [start, end] = duration ? duration : [];
    if (reVersion === 'V1') {
      if (!start || !end) {
        throw new Error(`Start time or end time is not set`);
      }
      await requestCreateDryRunReport('V1', {
        data: {
          ruleId: ruleInfo.uuid,
          startDate: start.format('YYYY-MM-DD'),
          endDate: end.format('YYYY-MM-DD'),
        },
      });
    } else {
      const payload: ICreateDryRun['V2']['request']['data'] = {
        name,
        description,
        ruleId: ruleInfo.uuid,
      };
      if (start) {
        payload.startDateTime = start.utc().startOf('day').format();
      }
      if (end) {
        payload.endDateTime = end.utc().endOf('day').format();
      }
      await requestCreateDryRunReport('V2', {
        data: payload,
      });
    }
  }, [ruleInfo, businessParcel, form, reVersion]);
  useEffect(() => {
    const { dataSource } = businessParcel;
    const partition = dataSource ? dataSource.partition : '';
    let start = '';
    let end = '';
    if (Array.isArray(duration) && duration.length > 0) {
      const [startTime, endTime] = duration;
      if (startTime) {
        start = startTime.format(dateFormat);
      }
      if (endTime) {
        end = endTime.format(dateFormat);
      }
    }
    let reportName = '';
    if (partition === 'NONE') {
      reportName = `dry-run report ${moment().utc().format()}`;
    } else {
      reportName = [
        'dry-run report',
        start ? start : '',
        start && end ? `- ${end}` : '',
      ]
        .join(' ')
        .trim();
    }
    form.setFieldsValue({
      name: reportName,
    });
  }, [duration, businessParcel, form]);
  const iconLabel = (
    <span>
      <span style={{ marginRight: '5px' }}>Time Period</span>
      <Tooltip
        placement="right"
        title={'Please choose time period less than 7 days.'}
      >
        <Icon type="info-circle" theme="filled" style={{ color: '#9B9B9B' }} />
      </Tooltip>
    </span>
  );
  const shouldSetDuration = useMemo(
    () =>
      reVersion === 'V1' ||
      (reVersion === 'V2' && dataSource.partition !== 'NONE'),
    [reVersion, dataSource.partition],
  );
  return (
    <Modal
      title="Create Dry Run Report"
      visible={visible}
      confirmLoading={isRequesting}
      cancelButtonProps={{
        disabled: isRequesting,
      }}
      onOk={async (evt) => {
        evt.stopPropagation();
        try {
          setIsRequesting(true);
          await requestCreateDryRun();
          // resolveStatus('success');
          const { name } = form.getFieldsValue();
          message.success(`Create Dry Run ${name} success`);
          onCreateSuccess && onCreateSuccess();
        } catch (err) {
          console.log(err);
        } finally {
          setIsRequesting(false);
        }
      }}
      onCancel={(evt) => {
        evt.stopPropagation();
        onClose && onClose();
        // resolveStatus('cancel');
      }}
    >
      <Form form={form} layout="vertical">
        {shouldSetDuration && (
          <Form.Item
            key="duration"
            name="duration"
            data-test-id="dry-run-dialog-range-picker"
            label={iconLabel}
            rules={[
              {
                required: true,
                validator(_rule, value) {
                  return new Promise<void>((res, rej) => {
                    if (!Array.isArray(value)) {
                      return rej('Time Peroid should be set');
                    }
                    const [start, end] = value;
                    if (!start) {
                      rej(`Start time should be set`);
                    }
                    if (!end) {
                      rej(`End time should be set`);
                    }
                    res();
                  });
                },
              },
            ]}
            getValueProps={(duration: IFormSetting['duration']) => {
              return {
                value: hackValue ? hackValue : duration,
              };
            }}
          >
            <DatePicker.RangePicker
              format={dateFormat}
              onCalendarChange={(v) => {
                // console.log('onCalendarChange');
                // console.log(v);
                setDates(v);
              }}
              onOpenChange={(open) => {
                if (open) {
                  setHackValue([null, null]);
                  setDates([null, null]);
                } else {
                  setHackValue(null);
                }
              }}
              disabledDate={(current) => {
                // The date greater than or equal to the current date should not be selected, Only can select 7 days.
                const afterToday = current > moment().endOf('day');
                if (!dates) {
                  return afterToday;
                }
                const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
                const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;
                return tooLate || tooEarly || afterToday;
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          data-test-id="dry-run-dialog-report-name"
          label="Report Name"
          name="name"
          key="name"
          rules={[
            {
              required: true,
              message: 'Report name should be set',
            },
          ]}
        >
          <Input disabled={reVersion === 'V1'} />
        </Form.Item>
        {reVersion === 'V2' && (
          <Form.Item
            data-test-id="dry-run-dialog-report-description"
            label="Description"
            name="description"
            key="description"
          >
            <TextArea rows={3} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ModalCreateDryRun;
