import React from 'react';
import store from '@src/store';
import { openModalAction, closeModalAction } from '@src/store/actions/modal';
import FetchSampleDataModal, {
  MODAL_KEY as FETCH_DATA_MODAL_KEY,
} from '@src/pages/RuleEngine/layouts/modals/FetchSampleDataModal';
import AdminPanelModal, {
  MODAL_KEY as ADMIN_PANEL_MODAL_KE,
} from '@src/pages/RuleEngine/layouts/modals/AdminPanelModal';
import CreateCustomTagModal, {
  MODAL_KEY as CREATE_CUSTOM_TAG_MODAL_KEY,
} from '@src/pages/RuleEngine/layouts/modals/CreateCustomTagModal';
import IDMergeConfigurationModal, {
  MODAL_KEY as ID_MERGE_CONFIGURATION_MODAL_KEY,
} from '@src/pages/RuleEngine/layouts/modals/IDMergeConfigurationModal';

const modalKeys = {
  FETCH_DATA_MODAL_KEY,
  ADMIN_PANEL_MODAL_KE,
  CREATE_CUSTOM_TAG_MODAL_KEY,
  ID_MERGE_CONFIGURATION_MODAL_KEY,
} as const;

export type TModalKey = typeof modalKeys[keyof typeof modalKeys];

export const openModal = (modalKey: TModalKey) => {
  store.dispatch(openModalAction(modalKey));
};

export const closeModal = (modalKey?: TModalKey) => {
  store.dispatch(closeModalAction(modalKey));
};

export {
  FETCH_DATA_MODAL_KEY,
  ADMIN_PANEL_MODAL_KE,
  CREATE_CUSTOM_TAG_MODAL_KEY,
  ID_MERGE_CONFIGURATION_MODAL_KEY,
};

const Modals: React.FC = () => {
  return (
    <>
      <FetchSampleDataModal />
      <AdminPanelModal />
      <CreateCustomTagModal />
      <IDMergeConfigurationModal />
    </>
  );
};

export default Modals;
