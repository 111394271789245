import styled from 'styled-components';

export const TopLabel = styled.span`
  margin-right: 16px;
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
`;
