import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  memo,
  ReactNode,
} from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import CodeEditor from '@uiw/react-textarea-code-editor';
import {
  Spin,
  Button,
  Tag,
  Divider,
  Badge,
  Collapse,
  Popconfirm,
  Radio,
  message,
  TreeSelect,
  Tooltip,
  RadioChangeEvent,
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import type { ProFormInstance, ProFormProps } from '@ant-design/pro-form';
import ProForm, {
  ProFormList,
  ProFormText,
  ProFormCheckbox,
  ProFormSelect,
  ProFormDependency,
} from '@ant-design/pro-form';
import { DraggableArea } from 'react-draggable-tags';
import { useSupportedLetterType } from '@src/service/apis/system';
import {
  IDslObj,
  IMappedFromItem,
  IRuleFiledMapping,
} from '@src/typing/ruleEngine';
import {
  MAP_TYPE_CONSTANT,
  MAP_TYPE_DIRECT,
  MAP_TYPE_EXTRACT,
} from '@src/store/types/types';
import {
  getFieldFullPath,
  getTagColor,
  getTagShowTitle,
  safeParse,
} from '@src/utils/ruleEngine';
import { useTagKeyList } from '@src/service/apis/ruleEngine/sampleData';
import {
  PARENT_PATH_CONNECT_KEY,
  VERSION_2,
  BASIC,
} from '@src/utils/constants';
import { TRuleEngineVersion } from '@src/typing/businessParcel';
import styles from './index.less';
import { requestTranslateDsl } from '@src/service/apis/ruleEngine/dsl';
import { fetchSampleData } from '@src/pages/RuleEngine/hooks';
import { useAppSelector } from '@src/store/hooks';

const { Panel } = Collapse;

type RuleEditorProps = {
  readonly rule: IRuleFiledMapping;
  isEditMode: boolean;
  onChange: ProFormProps['onValuesChange'];
  // initValue is to facilitate the reset of data in the rule editor.
  initValue: IRuleFiledMapping;
  onDelete?: (field: IRuleFiledMapping) => void;
  reVersion: TRuleEngineVersion;
};

type TOptionChildrenType = {
  title: ReactNode;
  value: string;
  disabled: boolean;
  dslCategory: string;
  parentType: string;
  valueType: string;
  children: TOptionChildrenType[];
};

const DEFAULT_LETTER_CASINGS = [null];

interface FieldMapFromTag extends IMappedFromItem {
  id?: number;
}

const mappedFromToolTip =
  'You can add multiple rules by clicking “Add” and adjust orders by dragging the labels. When the value is not passed, it will fall back to next priority.';
const invalidValuesToolTip =
  "Invalid values are not case-sensitive. The matched value will be converted to 'Fallback Value'.";
const formItemStyles = { padding: '20px', marginBottom: '0px' };
const dividerStyles = { margin: '0px' };
const mappingTypeOptions = [
  {
    label: 'Direct Mapping',
    value: MAP_TYPE_DIRECT,
  },
  {
    label: 'Regex Rule',
    value: MAP_TYPE_EXTRACT,
  },
  {
    label: 'Fallback Value',
    value: MAP_TYPE_CONSTANT,
  },
];

const RuleEditor: React.FC<RuleEditorProps> = (props) => {
  const [useDsl, setUseDsl] = useState<boolean>(props.rule.useDsl);
  const [memoryDsl, setMemoryDsl] = useState<{
    dsl: string | null;
    fallbackValue: string | null;
  }>({ dsl: '', fallbackValue: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<any[]>([]);
  const formRef = useRef<ProFormInstance>();

  const state = useAppSelector((rootState) => ({
    sampleDataJobs: rootState.ruleEngine.sampleDataJobs,
  }));

  const needParse = props.rule.outputFieldTypeCode === 'STRING';

  const parseDsl = useCallback(
    (dsl: IDslObj) => {
      let fallbackValue = dsl.fallbackValue;
      if (needParse && fallbackValue) {
        fallbackValue = safeParse(dsl.fallbackValue, dsl.fallbackValue, () => {
          message.warning('Fallback value is not serializable, please check');
        });
      }
      return {
        ...dsl,
        fallbackValue,
      };
    },
    [needParse],
  );

  useEffect(() => {
    const rule = cloneDeep(props.rule);
    const parsedDsl = parseDsl(rule.dsl);
    setMemoryDsl(parsedDsl);
    setUseDsl(rule.useDsl);
    if (!rule.invalidValues) {
      rule.invalidValues = [];
    }
    const ruleFieldMappedFroms = [...(rule.ruleFieldMappedFroms || [])];
    ruleFieldMappedFroms.forEach((item: any, index: number) => {
      item['id'] = Date.now() + index;
    });
    // When active filed changed. Reset some default value.
    formRef.current?.setFieldsValue({
      ...rule,
      ruleFieldMappedFroms,
      ruleFieldLetterCasings: rule.ruleFieldLetterCasings?.length
        ? rule.ruleFieldLetterCasings
        : DEFAULT_LETTER_CASINGS,
      mappingType: MAP_TYPE_DIRECT,
      mappedFrom: null,
      regexGroupName: null,
      dsl: { ...parsedDsl },
    });
  }, [parseDsl, props.rule]);

  const { isLoading: isLetterLoading, data: letterOptions } =
    useSupportedLetterType();
  const { isLoading: isTagKeyListLoading, data: tagKeyListOptions } =
    useTagKeyList();

  useEffect(() => {
    setTreeData(tagKeyListOptions);
  }, [tagKeyListOptions]);

  const handleRuleChange = (changedValues, values?: IRuleFiledMapping) => {
    if (!values) {
      const formValues = formRef.current?.getFieldsFormatValue?.(
        true,
      ) as IRuleFiledMapping;
      values = {
        ...formValues,
        useDsl,
      };
    }

    if (changedValues['mappingType']) {
      formRef.current?.setFieldsValue({
        mappedFrom: null,
        regexGroupName: null,
      });
    }
    const blackList = [
      'mappingType',
      'mappedFrom',
      'regexGroupName',
      'dslSelect',
    ];
    if (blackList.some((i) => Object.keys(changedValues).includes(i))) {
      return false;
    }
    blackList.forEach((i) => {
      delete values?.[i];
    });

    /*
     * There have some issue with values, if ruleFieldTransforms list is empty
     * values.ruleFieldTransforms will be [{}] need to deep check.
     *
     * */
    const ruleFieldTransforms = formRef.current?.getFieldValue(
      'ruleFieldTransforms',
    );
    const regexTransforms = formRef.current?.getFieldValue('regexTransforms');

    if (values.dsl['fallbackValue'] === undefined) {
      values.dsl['fallbackValue'] = null;
    }
    const ruleFieldMappedFroms = values.ruleFieldMappedFroms.map((i) => {
      const obj = { ...i };
      if (i['regexGroupName'] === undefined) {
        obj['regexGroupName'] = null;
      }
      if (i['label'] === undefined) {
        obj['label'] = obj['mappedFrom'];
      }
      if (i['parentPath'] === undefined) {
        obj['parentPath'] = '';
      }
      delete obj['id'];
      return obj;
    });
    // Remove the default letter casing [no change](null)
    const letterCasings = values.ruleFieldLetterCasings.filter((i) => i);
    props.onChange?.(changedValues, {
      ...values,
      ruleFieldLetterCasings: [...letterCasings],
      ruleFieldMappedFroms,
      ruleFieldTransforms,
      regexTransforms,
    });
  };

  const handleUseDslChange = (useDsl: boolean) => {
    // can't set to the form. form useDsl use to record the original useDsl value.
    // use in switch basic/advanced.
    // formRef.current?.setFieldsValue({ useDsl })
    const formValues = formRef.current?.getFieldsFormatValue?.(true);
    handleRuleChange(
      { useDsl },
      {
        ...formValues,
        useDsl,
      },
    );
    setUseDsl(useDsl);
  };

  const handleRemoveTagMap = (tag: any) => {
    const values = formRef.current?.getFieldValue('ruleFieldMappedFroms');
    const ruleFieldMappedFroms = [...values];
    const position = ruleFieldMappedFroms.findIndex(
      (r: any) => r.id === tag.id,
    );
    if (position > -1) {
      ruleFieldMappedFroms.splice(position, 1);
      formRef.current?.setFieldsValue({
        ruleFieldMappedFroms: ruleFieldMappedFroms,
      });
    }
    handleRuleChange({ ruleFieldMappedFroms });
  };

  const handleUseDslSwitchChange = ({
    target: { value },
  }: RadioChangeEvent) => {
    if (value) {
      setLoading(true);
      const formValues = formRef.current?.getFieldsFormatValue?.(true);
      // useDsl always false?
      // there use useDsl in state not from form. need to check this logic later. why need the state.
      requestTranslateDsl({ ...props.rule, ...formValues, useDsl: false })
        .then((res) => {
          //@ts-ignore
          if (res.success) {
            const dsl = res.data;
            const parsedDsl = parseDsl(dsl);
            formRef.current?.setFieldsValue({ dsl: { ...parsedDsl } });
            handleUseDslChange(value);
            setMemoryDsl(parsedDsl);
          }
        })
        .finally(() => setLoading(false));
    } else {
      const { dsl } = formRef.current?.getFieldsValue(['dsl']);
      if (!isEqual(dsl, memoryDsl)) {
        message.warning(
          'To switch back to the basic transformer, you have to reset all your configurations in the advanced transformer first.',
        );
        console.log('dsl changed!', dsl, memoryDsl);
      } else {
        handleUseDslChange(value);
      }
    }
  };

  const wrapperOptionByType = (
    option: TOptionChildrenType,
    mappingType: string,
  ) => {
    const newOption = { ...option };
    if (mappingType === MAP_TYPE_EXTRACT) {
      newOption.disabled = option.valueType !== 'string';
    } else {
      newOption.disabled = useDsl ? false : option.dslCategory !== BASIC;
    }
    newOption.title = (
      <Tooltip title={option.title + '   [   ' + option.valueType + '  ]   '}>
        {option.title}
      </Tooltip>
    );
    return newOption;
  };

  const handleTreeSelectDropdownVisibleChange = (
    visible: boolean,
    mappingType: string = '',
  ) => {
    const data: any[] = [];
    const optionSource: TOptionChildrenType[] = Array.isArray(tagKeyListOptions)
      ? tagKeyListOptions
      : [];
    if (!visible) {
      optionSource.forEach((i) => {
        const obj: {
          title: ReactNode;
          value: string;
          children: TOptionChildrenType[];
        } = {
          title: i.title,
          value: i.value,
          children: [],
        };
        if (i.children?.length) {
          i.children.forEach((child) => {
            const childObj = {
              ...child,
              title: getFieldFullPath(i.title, child.title),
            };
            obj.children.push(childObj);
          });
        }
        data.push(obj);
      });
    } else {
      //Expand the drop-down list
      optionSource?.forEach((item) => {
        if (item.children.length) {
          const obj: {
            title: ReactNode;
            value: string;
            children: TOptionChildrenType[];
          } = {
            title: item.title,
            value: item.value,
            children: [],
          };
          item?.children.forEach((i) => {
            const newObj = wrapperOptionByType(i, mappingType);
            obj.children.push(newObj);
          });
          data.push(obj);
        } else {
          // No children
          const newObj = wrapperOptionByType(item, mappingType);
          data.push(newObj);
        }
      });
    }
    setTreeData(data);
  };

  const genColorTag = ({ tag }: { tag: FieldMapFromTag }) => {
    const color = getTagColor(tag.mappingType);
    const showTitle = getTagShowTitle(tag);
    return (
      <Tag
        data-test-id="field-map-items"
        key={tag.id}
        color={color}
        closable
        onClose={(e) => {
          e.preventDefault();
          handleRemoveTagMap(tag);
        }}
        className={styles.tag}
      >
        {showTitle}
      </Tag>
    );
  };

  const dropdownRender = (originNode) => {
    const list = tagKeyListOptions || [];
    return (
      <>
        {originNode}
        {list.length ? null : (
          <>
            <Divider style={{ margin: '8px 0' }} />
            <div
              style={{
                padding: 5,
                width: '100%',
              }}
            >
              <Button
                block
                onClick={(e) => {
                  e.preventDefault();
                  fetchSampleData();
                }}
                disabled={state.sampleDataJobs.isFetching}
              >
                Fetch Data
              </Button>
            </div>
          </>
        )}
      </>
    );
  };

  const sortRuleFieldMappedFroms = (
    ruleFieldMappedFroms: FieldMapFromTag[] = [],
  ) => {
    // Fallback value can only be in the last position.
    const fallbackMappings = ruleFieldMappedFroms.filter(
      (i) => i.mappingType === MAP_TYPE_CONSTANT,
    );
    const otherMappings = ruleFieldMappedFroms.filter(
      (i) => i.mappingType !== MAP_TYPE_CONSTANT,
    );
    return [...otherMappings, ...fallbackMappings];
  };

  const getTagType = (parentPath: string, mappedFrom: string) => {
    let tagOption;
    if (parentPath !== '') {
      const tagParent = tagKeyListOptions?.find(
        (item) => item.title === parentPath,
      );
      tagOption = tagParent?.children.find((item) => item.title === mappedFrom);
    } else {
      tagOption = tagKeyListOptions?.find((item) => item.title === mappedFrom);
    }
    if (tagOption) {
      const { parentType, valueType, dslCategory, title: label } = tagOption;
      return { parentType, valueType, dslCategory, label };
    } else {
      return { parentType: '', valueType: '', dslCategory: '', label: '' };
    }
  };

  return (
    <Spin spinning={loading}>
      <ProForm
        formRef={formRef}
        formKey="rule-editor"
        autoFocusFirstInput
        submitter={{ render: () => [] }}
        onValuesChange={handleRuleChange}
      >
        <div style={{ position: 'relative' }} className={styles.editorWrapper}>
          {!props.isEditMode ? (
            <div
              style={{
                position: 'absolute',
                background: 'rgba(0,0,0,0.25)',
                width: '100%',
                height: '100%',
                zIndex: 2,
              }}
            />
          ) : null}
          <div className={styles.editorHeader}>
            {/* Form */}
            <div className={styles.editorTitle}>Edit Meta Tag</div>
            <ProFormDependency name={['ruleFieldMappedFroms']}>
              {({ ruleFieldMappedFroms }) => {
                ruleFieldMappedFroms = ruleFieldMappedFroms || [];
                const hasRegex = ruleFieldMappedFroms.find(
                  (i) => i.mappingType === MAP_TYPE_EXTRACT,
                );
                return hasRegex ? (
                  <Link to="/rule-engine/regex-match-rules">
                    <span className={styles.regexLink}>Edit RegEx rules</span>
                  </Link>
                ) : null;
              }}
            </ProFormDependency>
          </div>
          <div className={styles.fieldInfo}>
            <span>
              <span className={styles.metaTag}>Meta Tag Name: </span>
              <span className={styles.fieldName}>{props.rule.fieldName} </span>
              <span className={styles.fieldType}>
                [{props.rule.outputFieldTypeCode}]
              </span>
            </span>
            {props.reVersion === VERSION_2 ? (
              <Radio.Group
                onChange={handleUseDslSwitchChange}
                optionType="button"
                value={useDsl}
                options={[
                  {
                    label: 'Basic',
                    value: false,
                  },
                  {
                    label: 'Advanced',
                    value: true,
                  },
                ]}
              />
            ) : null}
          </div>
          {/* Advanced  */}
          <div
            style={!useDsl ? { height: 0, overflow: 'hidden' } : formItemStyles}
          >
            <div style={{ display: 'flex' }}>
              <ProForm.Item
                name="dslSelect"
                style={{ width: '328px' }}
                rules={[{ required: true, message: 'Required!' }]}
              >
                <TreeSelect
                  showSearch
                  style={{
                    width: '100%',
                  }}
                  disabled={isTagKeyListLoading}
                  placeholder="Please select"
                  treeDefaultExpandAll
                  treeData={treeData}
                  onDropdownVisibleChange={
                    handleTreeSelectDropdownVisibleChange
                  }
                  dropdownRender={dropdownRender}
                />
              </ProForm.Item>
              <ProFormDependency name={['dslSelect', 'dsl']}>
                {({ dslSelect, dsl }) => {
                  return (
                    <Button
                      disabled={!dslSelect}
                      style={{ margin: '0px 10px' }}
                      onClick={() => {
                        const parentPath = dslSelect.split(
                          PARENT_PATH_CONNECT_KEY,
                        )[0];
                        const fieldKey = dslSelect.split(
                          PARENT_PATH_CONNECT_KEY,
                        )[1];
                        const fullPath = getFieldFullPath(parentPath, fieldKey);
                        const newDsl = { dsl: dsl.dsl + fullPath };
                        formRef.current?.setFieldsValue({
                          dsl: { ...newDsl },
                          dslSelect: '',
                        });
                        handleRuleChange({ dsl: { ...newDsl } });
                      }}
                    >
                      Add
                    </Button>
                  );
                }}
              </ProFormDependency>

              <Button
                onClick={() => {
                  formRef.current?.setFieldsValue({
                    dsl: { ...memoryDsl },
                  });
                  handleRuleChange({ dsl: { ...memoryDsl } });
                }}
              >
                Reset
              </Button>
            </div>
            <ProForm.Item name={['dsl', 'dsl']}>
              <CodeEditor
                language="js"
                padding={15}
                placeholder="Please enter"
                style={{
                  width: '100%',
                  fontSize: 14,

                  backgroundColor: '#f5f5f5',
                  fontFamily:
                    'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
            </ProForm.Item>
            <div className={styles.dslFallbackValue}>
              <span style={{ marginRight: '10px' }}>Fallback Value:</span>
              <ProFormText
                name={['dsl', 'fallbackValue']}
                width="md"
                transform={(value) => {
                  let fallbackValue: any = null;
                  if (value) {
                    if (props.rule.outputFieldTypeCode === 'STRING') {
                      fallbackValue = JSON.stringify(value);
                    } else {
                      fallbackValue = value;
                    }
                  }
                  return {
                    dsl: {
                      fallbackValue,
                    },
                  };
                }}
              />
            </div>
          </div>
          {/* Basic */}
          <div style={useDsl ? { height: 0, overflow: 'hidden' } : {}}>
            <ProForm.Group
              title={
                <span>
                  Field is mapped from:{' '}
                  <Tooltip
                    title={mappedFromToolTip}
                    placement="rightTop"
                    color={'#9b9b9b'}
                  >
                    <InfoCircleFilled style={{ color: '#9b9b9b' }} />
                  </Tooltip>
                </span>
              }
              style={{ ...formItemStyles, paddingBottom: 0 }}
            >
              <ProFormSelect
                width="sm"
                name="mappingType"
                options={mappingTypeOptions}
                initialValue={MAP_TYPE_DIRECT}
                allowClear={false}
                rules={[{ required: true, message: 'Required!' }]}
              />
              <ProFormDependency name={['mappingType']}>
                {({ mappingType }) => {
                  let selectDom = (
                    <ProFormSelect
                      width="md"
                      name="mappedFrom"
                      options={tagKeyListOptions || []}
                      allowClear={false}
                      rules={[{ required: true, message: 'Required!' }]}
                      fieldProps={{
                        loading: isTagKeyListLoading,
                        showSearch: true,
                        dropdownRender: dropdownRender,
                      }}
                      placeholder="Please select"
                    />
                  );
                  if (props.reVersion === VERSION_2) {
                    selectDom = (
                      <ProForm.Item
                        name="mappedFrom"
                        style={{ width: '328px' }}
                        rules={[{ required: true, message: 'Required!' }]}
                      >
                        <TreeSelect
                          showSearch
                          style={{
                            width: '100%',
                          }}
                          disabled={isTagKeyListLoading}
                          placeholder="Please select"
                          treeDefaultExpandAll
                          treeData={treeData}
                          onDropdownVisibleChange={
                            //Expand the pulldowm list for callbacks
                            (visible: boolean) => {
                              handleTreeSelectDropdownVisibleChange(
                                visible,
                                mappingType,
                              );
                            }
                          }
                          //Customize the content of the drop-down list
                          dropdownRender={dropdownRender}
                        />
                      </ProForm.Item>
                      // <ProFormTreeSelect
                      //   width="md"
                      //   name="mappedFrom"
                      //   allowClear={false}
                      //   rules={[{ required: true, message: 'Required!' }]}
                      //   fieldProps={{
                      //     disabled: isTagKeyListLoading,
                      //     showSearch: true,
                      //     treeDefaultExpandAll: true,
                      //     treeData: tagKeyListOptions,
                      //     // @ts-ignore
                      //     // TODO commit PR for remove options.
                      //     options: tagKeyListOptions,
                      //   }}
                      //   placeholder="Please select"
                      // />
                    );
                  }
                  return mappingType === MAP_TYPE_CONSTANT ? (
                    <ProFormText
                      width="md"
                      name="mappedFrom"
                      placeholder="Please enter"
                      rules={[{ required: true, message: 'Required!' }]}
                    />
                  ) : (
                    selectDom
                  );
                }}
              </ProFormDependency>
              <ProFormDependency name={['mappingType']}>
                {({ mappingType }) => {
                  return mappingType === MAP_TYPE_EXTRACT ? (
                    <ProFormText
                      width={575}
                      name="regexGroupName"
                      placeholder="Please enter regex group name"
                    />
                  ) : null;
                }}
              </ProFormDependency>
              <ProFormDependency
                name={['mappedFrom', 'regexGroupName', 'mappingType']}
              >
                {({ mappedFrom, regexGroupName, mappingType }) => {
                  const disabled =
                    mappingType === MAP_TYPE_EXTRACT
                      ? !mappedFrom || !regexGroupName
                      : !mappedFrom;
                  return (
                    <Button
                      disabled={disabled}
                      onClick={() => {
                        const ruleFieldMappedFroms =
                          formRef.current?.getFieldValue(
                            'ruleFieldMappedFroms',
                          );
                        formRef.current
                          ?.validateFields([
                            'mappingType',
                            'mappedFrom',
                            'regexGroupName',
                          ])
                          .then((res) => {
                            let newMap;
                            if (
                              props.reVersion === VERSION_2 &&
                              res.mappingType !== MAP_TYPE_CONSTANT
                            ) {
                              const parentPath = res.mappedFrom.split(
                                PARENT_PATH_CONNECT_KEY,
                              )[0];
                              const mappedFrom = res.mappedFrom.split(
                                PARENT_PATH_CONNECT_KEY,
                              )[1];
                              const {
                                parentType,
                                valueType,
                                dslCategory,
                                label,
                              } = getTagType(parentPath, mappedFrom);
                              newMap = {
                                id: Date.now(),
                                ...res,
                                mappedFrom,
                                parentPath,
                                parentType,
                                valueType,
                                dslCategory,
                                label,
                              };
                            } else {
                              newMap = { ...res, id: Date.now() };
                            }
                            const newRuleFieldMappedFroms =
                              sortRuleFieldMappedFroms([
                                ...(ruleFieldMappedFroms || []),
                                newMap,
                              ]);
                            formRef.current?.setFieldsValue({
                              mappedFrom: null,
                              regexGroupName: null,
                              ruleFieldMappedFroms: newRuleFieldMappedFroms,
                            });
                            handleRuleChange({
                              ruleFieldMappedFroms: newRuleFieldMappedFroms,
                            });
                          });
                      }}
                    >
                      Add
                    </Button>
                  );
                }}
              </ProFormDependency>
            </ProForm.Group>
            <ProForm.Item
              name="ruleFieldMappedFroms"
              style={{ ...formItemStyles, paddingTop: 0 }}
            >
              <ProFormDependency name={['ruleFieldMappedFroms']}>
                {({ ruleFieldMappedFroms }) => {
                  ruleFieldMappedFroms = ruleFieldMappedFroms || [];
                  const colorMapping = (
                    <div className={styles.colorMappingSection}>
                      Color Mapping：
                      {mappingTypeOptions.map((item) => {
                        return (
                          <Tag
                            key={item.value}
                            className={styles.tag}
                            color={getTagColor(item.value)}
                          >
                            {item.label}
                          </Tag>
                        );
                      })}
                    </div>
                  );
                  return (
                    <>
                      {ruleFieldMappedFroms.length ? colorMapping : null}
                      <div
                        style={{
                          border: '1px solid rgb(217,217,217)',
                          padding: '10px',
                          borderRadius: '5px',
                          minHeight: '50px',
                        }}
                      >
                        <DraggableArea
                          tags={ruleFieldMappedFroms}
                          render={genColorTag}
                          onChange={(tags: FieldMapFromTag[]) => {
                            // Fallback value can only be in the last position.
                            const ruleFieldMappedFroms =
                              sortRuleFieldMappedFroms(tags);
                            formRef.current?.setFieldsValue({
                              ruleFieldMappedFroms,
                            });
                            handleRuleChange({ ruleFieldMappedFroms });
                          }}
                        />
                      </div>
                    </>
                  );
                }}
              </ProFormDependency>
            </ProForm.Item>
          </div>
          <Divider style={dividerStyles} />
          <ProForm.Item style={formItemStyles}>
            <div className="ant-pro-form-group-title">
              Invalid Values:{' '}
              <Tooltip
                title={invalidValuesToolTip}
                placement="rightTop"
                color={'#9b9b9b'}
              >
                <InfoCircleFilled style={{ color: '#9b9b9b' }} />
              </Tooltip>
            </div>
            <ProFormSelect
              name="invalidValues"
              mode="tags"
              style={{ width: '100%' }}
              options={[{ label: 'Empty String ""', value: '' }]}
            />
          </ProForm.Item>
          <ProForm.Item
            style={{
              ...formItemStyles,
              padding: 0,
            }}
          >
            <Collapse
              style={{ border: 0 }}
              defaultActiveKey={!props.isEditMode ? [1, 2] : 1}
            >
              <Panel
                header={
                  <span>
                    Transform these fields by direct mapping:
                    <Badge
                      count={
                        formRef.current?.getFieldValue('ruleFieldTransforms')
                          .length
                      }
                      style={{
                        marginLeft: '5px',
                        backgroundColor: 'rgb(236, 236, 236)',
                        color: '#000',
                      }}
                    />
                  </span>
                }
                key={1}
              >
                <ProFormList
                  name="ruleFieldTransforms"
                  copyIconProps={false}
                  deleteIconProps={{ tooltipText: 'Remove this row' }}
                  creatorRecord={{ mapFrom: '', mapTo: '' }}
                  creatorButtonProps={{
                    creatorButtonText: 'Add a transform',
                  }}
                >
                  <ProForm.Group key="ruleFieldTransforms" size={10}>
                    <ProFormText
                      name="mapFrom"
                      addonBefore="Set"
                      addonAfter="to new value"
                      width={300}
                      fieldProps={{ placeholder: 'Please enter' }}
                    />
                    <ProFormText
                      name="mapTo"
                      width={300}
                      fieldProps={{ placeholder: 'Please enter' }}
                    />
                  </ProForm.Group>
                </ProFormList>
              </Panel>
              <Panel
                header={
                  <span>
                    Transform these fields by regex rule:
                    <Badge
                      count={
                        formRef.current?.getFieldValue('regexTransforms').length
                      }
                      style={{
                        marginLeft: '5px',
                        backgroundColor: 'rgb(236, 236, 236)',
                        color: '#000',
                      }}
                    />
                  </span>
                }
                key={2}
              >
                <ProFormList
                  name="regexTransforms"
                  copyIconProps={false}
                  deleteIconProps={{ tooltipText: 'Remove this row' }}
                  creatorRecord={{ mapFrom: '', mapTo: '' }}
                  creatorButtonProps={{
                    creatorButtonText: 'Add a regex transform',
                  }}
                >
                  <ProForm.Group key="regexTransforms" size={10}>
                    <ProFormText
                      name="mapFrom"
                      addonBefore="Set"
                      addonAfter="to new value"
                      width={300}
                      fieldProps={{ placeholder: 'Please enter' }}
                    />
                    <ProFormText
                      name="mapTo"
                      width={300}
                      fieldProps={{ placeholder: 'Please enter' }}
                    />
                  </ProForm.Group>
                </ProFormList>
              </Panel>
            </Collapse>
          </ProForm.Item>
          <Divider style={dividerStyles} />
          <ProForm.Item style={formItemStyles}>
            <ProFormCheckbox name="trimExtraSpacesFlag">
              Trim extra space between words
            </ProFormCheckbox>
            <div className="ant-pro-form-group-title">Letter casing:</div>
            <ProFormSelect
              name={['ruleFieldLetterCasings', 0]}
              width="sm"
              fieldProps={{
                loading: isLetterLoading,
                placeholder: 'Please select',
              }}
              allowClear={false}
              options={letterOptions}
            />
          </ProForm.Item>
          {props.onDelete ? (
            <div style={{ ...formItemStyles, paddingTop: 0 }}>
              <Popconfirm
                placement="top"
                title={`Are you sure want to delete this custom tag? (${props.rule.fieldName})`}
                onConfirm={() => props.onDelete?.(props.rule)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger ghost>
                  Delete
                </Button>
              </Popconfirm>
            </div>
          ) : null}
        </div>
      </ProForm>
    </Spin>
  );
};

export default memo(RuleEditor, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.rule, nextProps.rule) &&
    prevProps.isEditMode === nextProps.isEditMode &&
    prevProps.reVersion === nextProps.reVersion
  );
});
