import React from 'react';
import { ThemeContext } from '@src/contexts';
import { RealtimeTile } from 'conviva-design';
import {
  BlueCellWrapper,
  RealtimeTileWrapper,
} from '@src/pages/PreMdsCoverage/styled';

const ALL_APPS_DATA_KEY = 'ALL';

interface ICell {
  cellData: string;
  unit: string;
  column: { [key: string]: any };
  rowData: { [key: string]: any };
  category: any;
  disabled: boolean;
  onClick: (any) => void;
  pressed: boolean;
}

const Cell: React.FC<ICell> = ({
  cellData,
  unit,
  column,
  rowData,
  category,
  disabled,
  onClick,
  pressed,
}) => {
  const { theme } = React.useContext(ThemeContext);
  // keep 2 decimal places
  const value = (data) => {
    if (data === 'N/A' || data === '0.0') return data;
    return parseFloat(data).toFixed(2).toString();
  };

  // drillDown action
  const handleClick = () => {
    const { aliasAppName } = column;
    const currentColumn = rowData[aliasAppName];
    onClick({ rowData, currentColumn });
  };

  const cell = (
    <RealtimeTile
      id="cell"
      theme={theme}
      category={category}
      value={value(cellData)}
      unit={unit}
      onClick={handleClick}
      pressed={pressed}
    />
  );
  if (
    column.dataKey === `${ALL_APPS_DATA_KEY}.coverage` ||
    rowData.tagKeyName === ALL_APPS_DATA_KEY
  ) {
    return <BlueCellWrapper>{cell}</BlueCellWrapper>;
  } else {
    return (
      <RealtimeTileWrapper style={{ opacity: disabled ? 0.2 : 1 }}>
        {cell}
      </RealtimeTileWrapper>
    );
  }
};

export default Cell;
