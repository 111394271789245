import React, { useEffect, useState } from 'react';
import { Modal, Radio, Spin, Button, message } from 'antd';
import { useAppSelector } from '@src/store/hooks';
import MergeConfig from '@src/pages/RuleEngine/layouts/modals/IDMergeConfigurationModal/MergeConfig';
import { closeModal } from '@src/pages/RuleEngine/layouts/modals';
import {
  postMetadataConfiguration,
  requestMetadataConfiguration,
} from '@src/service/apis/ruleEngine/metadataConfiguration';
import { ITemplate } from '@src/typing/businessParcel';
import { IConfigTemplate } from '@src/typing/IDMergeConfiguration';

export const MODAL_KEY = 'ID_MERGE_CONFIGURATION_MODAL_KEY';

const IDMergeConfigurationModal: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [configTemplate, setConfigTemplate] = useState<IConfigTemplate[]>([]);
  const [activeTemplate, setActiveTemplate] = useState<undefined | ITemplate>(
    undefined,
  );
  const state = useAppSelector((state) => {
    return {
      visibleKey: state.modal.visibleKey,
      loading: state.ruleEngine.loading['global'],
      activeBusinessParcel: state.ruleEngine.activeBusinessParcel,
    };
  });

  const visible = state.visibleKey === MODAL_KEY;

  const tabOnChange = (event) => {
    const uuid = event.target.value;
    const template = state.activeBusinessParcel.relatedTemplates?.find(
      (i) => i.uuid === uuid,
    );
    setActiveTemplate(template);
  };

  const handleSave = () => {
    setLoading(true);
    postMetadataConfiguration(configTemplate)
      .then(() => {
        message.success('ID configuration saved successfully');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (activeTemplate === undefined || !visible) return;
    setLoading(true);
    requestMetadataConfiguration(activeTemplate.uuid)
      .then((res) => {
        setConfigTemplate(res.data);
      })
      .finally(() => setLoading(false));
  }, [activeTemplate, visible]);

  useEffect(() => {
    setActiveTemplate(state.activeBusinessParcel.relatedTemplates?.[0]);
  }, [
    state.activeBusinessParcel.relatedTemplates,
    state.activeBusinessParcel.uuid,
  ]);

  return (
    <Modal
      title="ID Merge Configuration"
      onCancel={() => closeModal(MODAL_KEY)}
      width={800}
      visible={visible}
      footer={[]}
      destroyOnClose
    >
      <Spin spinning={state.loading || isLoading}>
        <Radio.Group
          defaultValue={0}
          onChange={tabOnChange}
          style={{ marginBottom: 16 }}
          value={activeTemplate?.uuid}
        >
          {state.activeBusinessParcel.relatedTemplates?.map((tp) => {
            return (
              <Radio.Button key={tp.uuid} value={tp.uuid}>
                {tp.dataModel.name}
              </Radio.Button>
            );
          })}
        </Radio.Group>
        <div>
          {activeTemplate ? (
            <MergeConfig
              onChange={(value) => {
                console.log(value);
                setConfigTemplate(value);
              }}
              config={configTemplate}
              template={activeTemplate}
            />
          ) : null}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{ marginRight: 15 }}
            onClick={() => closeModal(MODAL_KEY)}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default IDMergeConfigurationModal;
