import { useQuery } from 'react-query';
import get from 'lodash/get';
import { batchJobHttpClient, ruleEngineHttpClient } from '@src/service/http';

const TAG_KEY_LIST_QUERY_KEY = 'TAG_KEY_LIST';

export const requestDryRunList = async (params: any) => {
  try {
    let url = '/dry-run-jobs?';
    for (let key in params) {
      url = url + key + '=' + params[key] + '&';
    }
    const response = await batchJobHttpClient.get(
      url.substr(0, url.length - 1),
    );
    return get(response, 'data', []);
  } catch (e) {
    throw e;
  }
};

export const requestTagKeyList = async () => {
  try {
    let url = '/metadata-tag-label-values';
    return await batchJobHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const requestTagSampleData = async (tagKey: string, pagination: any) => {
  const pageNum = pagination?.current || 1;
  const pageSize = pagination?.pageSize || 10;
  const url = `/sample-data-tags?tagKey=${tagKey}&pageNum=${pageNum}&pageSize=${pageSize}`;
  return await batchJobHttpClient.get(url);
};

export const requestVerifySingleRule = async (regex: string, text: string) => {
  regex = encodeURIComponent(regex);
  text = encodeURIComponent(text);
  const url = `/metadata-rules/verify-single-rule?regexExpression=${regex}&inputText=${text}`;
  return await ruleEngineHttpClient.get(url);
};

export const requestRunTestAll = async (
  tagKey: string,
  regexes: string[],
  startPage?: number | undefined,
  endPage?: number | undefined,
) => {
  const url = '/metadata-rules/preview-sample-data-with-rules';
  const data = {
    tagKey,
    regexExpressions: regexes,
  };
  if (endPage) {
    data['page'] = {
      pageSize: 10,
      startPage,
      endPage,
    };
  }

  return await ruleEngineHttpClient.post(url, { ...data });
};

export const requestCleanIdConfigs = async () => {
  try {
    let url = '/metadata-rule-clean-id-configs?idType=ASSET';
    return await ruleEngineHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const requestRawIdConfigs = async () => {
  try {
    let url = '/metadata-rule-raw-id-configs?idType=ASSET';
    return await ruleEngineHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

// Queries

export const useTagKeyList = (projectCode?: string) => {
  return useQuery([TAG_KEY_LIST_QUERY_KEY, projectCode], async () => {
    const { data } = await requestTagKeyList();
    return data;
  });
};

export const useClenIdConfigs = () => {
  return useQuery(['clenId'], async () => {
    const { data } = await requestCleanIdConfigs();
    return data;
  });
};

export const useRawIdConfigs = () => {
  return useQuery(['rawId'], async () => {
    const { data } = await requestRawIdConfigs();
    return data;
  });
};
