// @ts-nocheck
import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  StyledAntDropdown,
  StyledMenu,
  StyledMenuItem,
  StyledSpanForKebab,
  ButtonSpan,
  MenuWrapper,
  InputWrapper,
} from './styled';
import {
  KebabLightIcon,
  KebabDarkIcon,
} from 'conviva-design/es/components/Icons';
import { Icon as AntIcon } from 'antdV3';
import { Button } from 'conviva-design/es/components/ButtonV2';
import { Header } from './Header';
import { SingleSelectDropdownProps } from './types';
import { Category } from './constants';
import { Input } from 'conviva-design/es/components/Input';
import debounce from 'lodash/debounce';

/**
 * The Singleselect Dropdown is used to select single item.
 *
 * @see Found on [All Pages](https://pulse.conviva.com/)
 * @see See [Mockup](https://conviva.invisionapp.com/d/main#/console/18500627/384603769/preview)
 *
 */

export function SingleSelectDropdownV2(props: SingleSelectDropdownProps) {
  const {
    theme = 'light',
    disabled,
    items,
    onApply,
    placement,
    type,
    text,
    dynamicText,
    mode,
    prefixText,
    children,
    persistSelection = true,
    searchable,
    itemRenderer,
    onSearch,
    autoCloseDropdown = true,
    dropDownRender = (menu: React.ReactNode) => menu,
    searchValue = '',
  } = props;
  const [visible, setVisible] = useState(false);
  const [selectedText, setselectedText] = useState(text);
  const [menuItems, setMenuItems] = useState(items);
  const [spanWidth, setspanWidth] = useState(80);
  const [search, setSearch] = useState(searchValue);
  const spanEl = useRef(null);
  const outerEl = useRef(null);

  const handleVisibleChange = useCallback((flag) => {
    setVisible(flag);
  }, []);

  const updateQuery = () => {
    if (search === '') {
      setMenuItems(
        items.map((item) => {
          return { ...item };
        }),
      );
    } else if (!onSearch) {
      setMenuItems(
        items.filter((item) => {
          if (item.displayValue.toLowerCase().includes(search.toLowerCase()))
            return { ...item };
        }),
      );
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 400), [search, items]);

  const onChange = (e) => {
    if (onSearch) {
      onSearch(e.target.value);
    }
    setSearch(e.target.value);
  };

  const onPressEnter = () => {
    setVisible(false);
  };

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [search, delayedQuery, items]);

  useEffect(() => {
    setselectedText(text);
  }, [text]);

  useEffect(() => {
    setMenuItems(
      items.map((item) => {
        return { ...item };
      }),
    );
  }, [items]);

  useEffect(() => {
    setTimeout(() => {
      if (outerEl.current) {
        setspanWidth(0);
        if (
          outerEl.current.parentNode.scrollWidth >
          outerEl.current.parentNode.parentNode.clientWidth
        ) {
          let width = outerEl.current.parentNode.parentNode.offsetWidth;
          setspanWidth(width - 43);
        } else {
          let width = spanEl.current.scrollWidth;
          setspanWidth(width + 1);
        }
      }
    }, 10);
  }, [text, selectedText, prefixText]);

  const memoizedCallback = useCallback(
    (selectedKey) => {
      if (!onApply) {
        return;
      }
      onApply(selectedKey);
      setSearch('');
    },
    [onApply, searchable],
  );
  const applyHandler = useCallback(
    (e, menuItems) => {
      const selectedDisplay = menuItems.filter((item) => {
        return item.key === e.key;
      });
      if (type !== Category.Kebab && dynamicText && persistSelection) {
        setselectedText(
          itemRenderer
            ? itemRenderer(selectedDisplay[0])
            : selectedDisplay[0].displayValue,
        );
      }
      if (!persistSelection) {
        memoizedCallback && memoizedCallback(e.key);
        setVisible(false);
      } else if (!selectedDisplay[0].selected) {
        const newMenuItems = menuItems.map((item) => {
          return item.key === e.key
            ? { ...item, selected: true }
            : { ...item, selected: false };
        });
        setMenuItems(newMenuItems);
        memoizedCallback && memoizedCallback(e.key);
      }
      if (autoCloseDropdown) {
        setVisible(false);
      }
    },
    [items, text, dynamicText, type, persistSelection],
  );

  const getChildren = (theme: string, disabled: boolean) => {
    switch (type) {
      case Category.Header:
        return (
          <Header pressed={visible} theme={theme} mode={mode}>
            {prefixText}
            {selectedText}
            <AntIcon theme="outlined" type="down" />
          </Header>
        );
      case Category.Kebab:
        return (
          <StyledSpanForKebab>
            {theme === 'light' ? <KebabLightIcon /> : <KebabDarkIcon />}
          </StyledSpanForKebab>
        );
      case Category.Custom:
        // support custom children.
        return children || null;
      case Category.Button:
      default:
        return (
          <Button category="secondary" theme={theme}>
            <span
              ref={outerEl}
              style={{
                width: '100%',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <ButtonSpan ref={spanEl} width={spanWidth}>
                {prefixText}
                {selectedText}
              </ButtonSpan>
              <AntIcon
                style={{ width: '10px', height: '10px', fontSize: '12px' }}
                theme="outlined"
                type="down"
              />
            </span>
          </Button>
        );
    }
  };

  const menu = (
    <>
      {searchable ? (
        <MenuWrapper theme={theme}>
          <InputWrapper>
            <Input
              onPressEnter={onPressEnter}
              value={search}
              onChange={onChange}
              theme={theme}
            />
          </InputWrapper>
        </MenuWrapper>
      ) : null}
      <StyledMenu theme={theme} onClick={(e) => applyHandler(e, menuItems)}>
        {menuItems.map((item) => {
          return (
            <StyledMenuItem
              key={item.key}
              disabled={item.disabled}
              theme={theme}
              selected={item.selected}
            >
              {itemRenderer ? itemRenderer(item) : item.displayValue}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </>
  );

  return (
    <StyledAntDropdown
      visible={visible}
      onVisibleChange={handleVisibleChange}
      disabled={disabled}
      placement={placement}
      overlay={dropDownRender(menu)}
      trigger={['click']}
    >
      {getChildren(theme, disabled)}
    </StyledAntDropdown>
  );
}
