import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '@src/store/reducers';
import sagas from './saga';
import { history } from '../router';

function* rootSaga() {
  yield all(sagas.map((saga) => fork(saga)));
}

const reducer = createRootReducer(history);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, routerMiddleware(history)),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
