interface IModalReducer {
  visibleKey: string | null;
}

const initState = {
  visibleKey: null,
};

const modalReducer = (state: IModalReducer = initState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return { ...state, visibleKey: action.modalKey };
    }
    case 'CLOSE_MODAL': {
      return { ...state, visibleKey: null };
    }
    default:
      return state;
  }
};

export default modalReducer;
