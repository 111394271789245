import styled from 'styled-components';
import { Dropdown as AntDropdown, Menu } from 'antdV3';
import { colorMap, acumenStyles } from '../styled/UXSettings';
import {
  ExtendedAntDropdownProps,
  ExtendedMenuItemProp,
  ButtonSpanProp,
} from './types';
const clsPrefix = 'ant';

export const InputWrapper = styled.div`
  background-color: ${(p) => colorMap.backgroundColor[p.theme]};
  margin: 10px;
`;
export const MenuWrapper = styled.div`
  background-color: ${(p) => colorMap.backgroundColor[p.theme]};
  border: 1px solid ${(p) => colorMap.borderOne[p.theme]};
`;
export const ButtonSpan = styled.span<ButtonSpanProp>`
  display: inline-flex;
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${(p) => (p.width ? p.width + 'px' : '100%')};
  white-space: nowrap;
  margin-right: 8px;
`;
export const StyledMenu = styled(Menu)`
  overflow: scroll;
  max-height: 350px;
  &&& {
    &.${clsPrefix}-menu {
      background-color: ${(p) => colorMap.backgroundColor[p.theme]};
      border: 1px solid ${(p) => colorMap.borderOne[p.theme]};
    }
  }
`;
export const StyledSpanForKebab = styled.span`
  height: 34px;
  line-height: 34px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 20px;
  justify-content: center;
`;
export const StyledMenuItem = styled(Menu.Item)<ExtendedMenuItemProp>`
  &&&& {
    &.${clsPrefix}-menu-item {
      /* padding: 5px 0px 5px 0px; */
      padding-top: 0px;
      padding-bottom: 0px;
      height: 27px;
      line-height: 27px;
      margin-top: 4px;
      margin-bottom: 4px;
      background-color: ${(p) => colorMap.backgroundColor[p.theme]};
      ${(p) =>
        p.disabled &&
        `
        color: ${colorMap.secondaryOneDisabled[p.theme]};
        font-style: italic;
        font-weight: ${acumenStyles.fontRegular};
      `}
      ${(p) =>
        p.selected &&
        `
        background-color: ${colorMap.highlight[p.theme]};
      `}
      :hover {
        ${(p) =>
          !p.disabled
            ? `
          background-color: ${colorMap.highlight[p.theme]};
        `
            : `
          height: 27px;
          line-height: 27px;
          color: ${colorMap.secondaryOneDisabled[p.theme]};
          background-color: ${colorMap.highlight[p.theme]};
        `}
      }
    }
  }
`;

export const StyledAntDropdown = styled(AntDropdown)<ExtendedAntDropdownProps>`
  max-width: 420px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.ant-dropdown-trigger > span {
    align-items: space-between;
  }
  /* width: 100%; */
  :hover {
    ${(p) =>
      p.disabled &&
      `
      cursor: not-allowed;
    `}
  }
`;
