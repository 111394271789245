export const INITIALIZE_RULE_STATE = 'INITIALIZE_RULE_STATE';
export const UPDATE_STATE_RULE_SELECT_VERSION1 =
  'UPDATE_STATE_RULE_SELECT_VERSION1';
export const UPDATE_STATE_RULE_SELECT_VERSION2 =
  'UPDATE_STATE_RULE_SELECT_VERSION2';
export const UPDATE_STATE_RULE_FIELDS_VERSION1 =
  'UPDATE_STATE_RULE_FIELDS_VERSION1';
export const UPDATE_STATE_RULE_FIELDS_VERSION2 =
  'UPDATE_STATE_RULE_FIELDS_VERSION2';
export const UPDATE_STATE_RULE_REGEX_VERSION1 =
  'UPDATE_STATE_RULE_REGEX_VERSION1';
export const UPDATE_STATE_RULE_REGEX_VERSION2 =
  'UPDATE_STATE_RULE_REGEX_VERSION2';
