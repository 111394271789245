import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Radio, Select, Space, TreeSelect, Button } from 'antd';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '@src/store/hooks';
import {
  EConfigTemplateCode,
  EConfigTemplateType,
  IConfigTemplate,
} from '@src/typing/IDMergeConfiguration';
import { ITemplate } from '@src/typing/businessParcel';
import { useTagKeyList } from '@src/service/apis/ruleEngine/sampleData';
import { PARENT_PATH_CONNECT_KEY } from '@src/utils/constants';
import { getFieldFullPath, safeParse } from '@src/utils/ruleEngine';

interface IProps {
  disabled?: boolean;
  config: IConfigTemplate[];
  template: ITemplate;
  onChange: (value: IConfigTemplate[]) => void;
}

// const splitStrToParentPathAndKey = (str = '') => {
//   const keySymbol = '.`';
//   if (str.includes(keySymbol)) {
//     const parentPath = str.split(keySymbol)[0];
//     const tagKey = str.split('`')[1];
//     return [parentPath, tagKey];
//   }
//   return ['', str];
// };

const MergeConfig: React.FC<IProps> = (props) => {
  const { disabled = false, onChange } = props;
  const [config, setConfig] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const treeSelectRef = useRef('');

  const { data: tagKeyListOptions } = useTagKeyList();

  const state = useAppSelector((state) => {
    return {
      activeRule: state.ruleEngine.activeRule,
    };
  });

  const cleanIdOptions = useMemo(() => {
    if (!state.activeRule) return;
    const fields = state.activeRule.ruleFieldMappings.filter(
      (i) => i.templateId === props.template.uuid,
    );
    return fields.map((i) => ({
      value: i.fieldCode,
      label: i.fieldName,
    }));
  }, [state.activeRule, props.template]);

  const rawIdConfig = useMemo(() => {
    return config.find(
      (it) => it.pipelineConfigCode === EConfigTemplateCode.rawId,
    );
  }, [config]);

  const cleanIdConfig = useMemo(() => {
    return config.find(
      (it) => it.pipelineConfigCode === EConfigTemplateCode.cleanId,
    );
  }, [config]);

  const handleChange = useCallback(() => {
    const res: IConfigTemplate[] = [];
    if (rawIdConfig) {
      const copyConfig = cloneDeep(rawIdConfig);
      res.push(copyConfig);
    }
    if (cleanIdConfig) {
      const copyConfig = cloneDeep(cleanIdConfig);
      res.push(copyConfig);
    }

    // Format config
    res.forEach((i) => {
      i.content = i.content.length ? JSON.stringify(i.content) : '[]';
      i.defaultContent = Array.isArray(i.defaultContent)
        ? JSON.stringify(i.defaultContent)
        : '[]';
    });
    onChange(res);

    // Trigger UI update
    setConfig([...config]);
  }, [rawIdConfig, cleanIdConfig, onChange, config]);

  const handleTreeSelectDropdownVisibleChange = useCallback(
    (visible: boolean) => {
      if (!visible) {
        const data: any[] = [];
        tagKeyListOptions.forEach((i) => {
          const obj: {
            title: string;
            value: string;
            children: any[];
          } = {
            title: i.title,
            value: i.value,
            children: [],
          };
          if (i.children?.length) {
            i.children.forEach((child) => {
              const childObj = {
                ...child,
                title: getFieldFullPath(i.title, child.title),
              };
              obj.children.push(childObj);
            });
          }
          data.push(obj);
        });
        setTreeData(data);
      } else {
        setTreeData(tagKeyListOptions);
      }
    },
    [tagKeyListOptions],
  );

  useEffect(() => {
    handleTreeSelectDropdownVisibleChange(false);
  }, [handleTreeSelectDropdownVisibleChange]);

  useEffect(() => {
    const config = cloneDeep(props.config);
    config.forEach((i) => {
      i.content = safeParse(i.content, []);
      // CleanId
      if (i.pipelineConfigCode === EConfigTemplateCode.cleanId) {
        i.defaultContent = safeParse(i.defaultContent, []);
        // @ts-ignore
        i.defaultContent = i.defaultContent.map((contentItem) => {
          const option = cleanIdOptions?.find((o) => o.value === contentItem);
          if (option) {
            return option.label;
          }
          return contentItem;
        });
      }
    });

    setConfig(config);
  }, [props.config, cleanIdOptions]);

  return (
    <div>
      {rawIdConfig ? (
        <div
          style={{
            marginBottom: '30px',
          }}
        >
          <h4>Raw ID configuration:</h4>
          <div
            style={{
              marginTop: '10px',
              paddingLeft: '24px',
            }}
          >
            <Radio.Group
              disabled={disabled}
              onChange={(evt) => {
                evt.stopPropagation();
                rawIdConfig.type = evt.target.value as EConfigTemplateType;
                handleChange();
              }}
              value={rawIdConfig.type}
            >
              <Space direction="vertical">
                <Radio value={EConfigTemplateType.auto}>
                  All fields you selected
                </Radio>
                <Radio value={EConfigTemplateType.custom}>
                  Customize Raw ID
                </Radio>
              </Space>
            </Radio.Group>
            {rawIdConfig.type === EConfigTemplateType.custom ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '12px',
                  }}
                >
                  <TreeSelect
                    value={treeSelectRef.current}
                    showSearch
                    style={{
                      width: '400px',
                      marginLeft: '22px',
                    }}
                    placeholder="Please select"
                    treeDefaultExpandAll
                    treeData={treeData}
                    onDropdownVisibleChange={
                      handleTreeSelectDropdownVisibleChange
                    }
                    onChange={(value) => {
                      treeSelectRef.current = value;
                    }}
                  />
                  <Button
                    style={{ margin: '0px 10px' }}
                    onClick={() => {
                      if (!treeSelectRef.current) {
                        return;
                      }
                      const [parentPath, tagKey] = treeSelectRef.current.split(
                        PARENT_PATH_CONNECT_KEY,
                      );
                      const value = getFieldFullPath(parentPath, tagKey);
                      rawIdConfig.content.push(value);
                      treeSelectRef.current = '';
                      handleChange();
                    }}
                  >
                    Add
                  </Button>
                </div>
                <Select
                  style={{
                    width: '600px',
                    marginLeft: '22px',
                    marginTop: '12px',
                  }}
                  value={rawIdConfig.content}
                  mode="multiple"
                  allowClear
                  placeholder=""
                  onChange={(values) => {
                    rawIdConfig.content = values;
                    handleChange();
                  }}
                >
                  {rawIdConfig.content.map((i) => {
                    return <Select.Option key={i}>{i}</Select.Option>;
                  })}
                </Select>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {cleanIdConfig ? (
        <div>
          <h4>Clean ID configuration:</h4>
          <div
            style={{
              marginTop: '10px',
              paddingLeft: '24px',
            }}
          >
            <Radio.Group
              onChange={(evt) => {
                cleanIdConfig.type = evt.target.value as EConfigTemplateType;
                handleChange();
              }}
              disabled={disabled}
              value={cleanIdConfig.type}
            >
              <Space direction="vertical">
                <Radio value={EConfigTemplateType.custom}>
                  Customize Clean ID
                </Radio>
                {cleanIdConfig.type === EConfigTemplateType.custom ? (
                  <Select
                    disabled={disabled}
                    mode="multiple"
                    value={cleanIdConfig.content}
                    style={{ width: '600px', marginLeft: '22px' }}
                    onChange={(value) => {
                      cleanIdConfig.content = value;
                      handleChange();
                    }}
                    options={cleanIdOptions}
                    tokenSeparators={[',']}
                  />
                ) : null}
                <Radio value={EConfigTemplateType.auto}>
                  Default ID merge configuration
                </Radio>
              </Space>
            </Radio.Group>
            <p style={{ marginLeft: '24px' }}>
              {cleanIdConfig.defaultContent.join(', ')}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default MergeConfig;
