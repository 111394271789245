import styled from "styled-components";
import { Checkbox } from 'conviva-design/es/components/Checkbox';

export const RuleEditorWrapper = styled.div`
  padding: 0 13px;
  margin-top: 55px;
`;

export const RuleEditorBorder = styled.div`
  width: 640px;
  min-height: 620px;
  border: 1px solid #002BF7;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;

export const RuleEditorMask = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: 0.3;
    z-index: 999;
`

export const ResultItem = styled.div`
    border-bottom: 1px solid #e3e3e3;
    &:last-child {
        border: none
    }
`

export const EditorHeader = styled.div`
    width: 100%;
    height: 49px;
    display: flex;
`;

export const EditorTitle = styled.div`
    width: 156px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #002BF7;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    border-right: 1px solid #979797;
`;

export const EditorRegexTitle = styled.div`
    width: 156px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    cursor: pointer;
`;

export const EditorSection = styled.div`
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    border-top: 0.5px solid #979797;
    
    .gray-btn {
        color: #686868;
        font-family: "Open Sans";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        border-color: #686868;
    }
    
    form .ant-select, form .ant-cascader-picker {
       width: 243px;
       height: 30px
    }
    
    .ant-tag, .anticon-close {
        color: #000
    }
    
`;

export const MetaTagLabel = styled.div`
    color: rgba(0,0,0,0.45);
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    width: 155px;
    flex-shrink: 0;
    line-height: 24px;
`;

export const MetaTagName = styled.div`
    color: #002BF7;
    font-family: "Open Sans";
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
`;

export const MetaTagBox = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const SectionLabel = styled.div`
    height: 18px;
    color: #000000;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 5px;
`;

export const TagGroups = styled.div`
    min-height: 46px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background-color: #FFFFFF;
    padding: 9px;
    margin-top: 5px;
`;

export const CloseIcon = styled.div`
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const EditorButtonGroup = styled.div`
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const UnSavedChanges = styled.div`
    width: 100%;
    margin-bottom: 50px;
    margin-top: 3px;
    text-align: right;
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    padding-right: 10px;
    box-sizing: border-box;
`;

export const RegexRuleItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    color: black;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    padding: 10px 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #fff;
`

export const CheckboxWrapper = styled("div")<any>`
    ${props => {
    if (props.checked) {
        return `span {
            color: black !important
        }`
    }
}};
`;


