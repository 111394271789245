import React from 'react';

interface ThemeContextType {
  theme: 'light' | 'dark';
  setTheme?: (value: string) => void;
}

const ThemeContext = React.createContext<ThemeContextType>({ theme: 'light' });

export default ThemeContext;
