const initState = {
  dryRunContentData: [],
  dryRunOption1: [],
  dryRunOption2: [],
};

function dryRunCompareReducer(state = initState, actions: any) {
  const { type, payload } = actions;
  switch (type) {
    case 'INITIALIZE_STATE':
      return { ...state, ...payload };
    case 'UPDATE_STATE_DRY_RUN_OPTIONS1':
      return { ...state, ...payload };
    case 'UPDATE_STATE_DRY_RUN_OPTIONS2':
      return { ...state, ...payload };
    case 'UPDATE_STATE_DRY_RUN_CONTENT':
      return { ...state, ...payload };
    default:
      return state;
  }
}

export default dryRunCompareReducer;
