import styled from 'styled-components';

export const SiderWrap = styled('div')`
  width: 230px;
  border-right: 2px solid #cdcdcd;
`;

export const StyledMenuWrap = styled.div`
  .mds-ant-menu-submenu-title,
  .mds-ant-menu-item {
    height: 60px !important;
    border-bottom: 1px solid #9b9b9b;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
`;
