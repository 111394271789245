import React, { useState, useEffect, useMemo } from 'react';
import { Affix } from 'antd';
import { isEqual } from 'lodash';
import RegexEditor from '@src/pages/RuleEngine/components/V2/RuleEditor/RegexEditor';
import { useAppSelector, useAppDispatch } from '@src/store/hooks';
import styles from '@src/pages/RuleEngine/modules/FiledMapRule/index.less';
import FieldItem from '@src/pages/RuleEngine/components/V2/FieldList/FieldItem';
import {
  FieldListHeader,
  FieldListWrapper,
} from '@src/pages/RuleEngine/components/V2/FieldList/styled';
import { IRegexRuleItem } from '@src/typing/ruleEngine';
import { RULE_STATUS_INIT } from '@src/utils/constants';
import { genNewField, getFieldFullPath } from '@src/utils/ruleEngine';
import { updateFieldRule } from '@src/store/actions/ruleEngine';

import { RegexMatchRuleWrapper } from './styled';

const RegexMatchRule: React.FC = (props) => {
  const [activeField, setActiveField] = useState<IRegexRuleItem>();
  const [activeFieldFullPath, setActiveFieldFullPath] = useState('');
  const [affixed, setAffixed] = useState<boolean | undefined>(false);
  const state = useAppSelector((rootState) => ({
    draftRule: rootState.ruleEngine.draftRule,
    activeRule: rootState.ruleEngine.activeRule,
    activeBu: rootState.ruleEngine.activeBusinessParcel,
  }));

  const ruleFieldExtractingConfigs: IRegexRuleItem[] =
    state.draftRule?.ruleFieldExtractingConfigs || [];

  const dispatch = useAppDispatch();

  const handleItemClick = (field: IRegexRuleItem) => {
    setActiveField(field);
  };

  useEffect(() => {
    const fullPath = getFieldFullPath(
      activeField?.parentPath,
      activeField?.tagName,
    );
    setActiveFieldFullPath(fullPath);
  }, [activeField]);

  useEffect(() => {
    setActiveField(ruleFieldExtractingConfigs[0] || null);
  }, [state.activeRule]);

  const genFieldList = useMemo(() => {
    const fieldList = ruleFieldExtractingConfigs.map((i) => {
      const fieldFullPath = getFieldFullPath(i.parentPath, i.tagName);
      const compare = state.activeRule?.ruleFieldExtractingConfigs?.find(
        (field) =>
          getFieldFullPath(field.parentPath, field.tagName) === fieldFullPath,
      );
      const active = fieldFullPath === activeFieldFullPath;
      const hasUnsave = !isEqual(i, compare);
      const field = genNewField({
        fieldCode: fieldFullPath,
        fieldName: fieldFullPath,
      });
      return (
        <div key={fieldFullPath} onClick={() => handleItemClick(i)}>
          <FieldItem active={active} hasUnsave={hasUnsave} field={field} />
        </div>
      );
    });
    return fieldList;
  }, [state.activeRule, state.draftRule]);

  return (
    <RegexMatchRuleWrapper>
      <div style={{ width: '100%' }}>
        <FieldListWrapper>
          <FieldListHeader>RegEx Match Rules</FieldListHeader>
          {genFieldList}
        </FieldListWrapper>
      </div>
      {activeField ? (
        <div>
          <div style={{ height: 55 }} />
          <Affix
            offsetTop={10}
            target={() => window.__QIANKUN_CONTAINER__}
            onChange={(affixed) => setAffixed(affixed)}
          >
            <div
              className={styles.ruleEditorWrapper}
              style={affixed ? {} : { maxHeight: 'calc(100vh - 355px)' }}
            >
              <RegexEditor
                rule={activeField}
                reVersion={state.activeBu.reVersion}
                key={`regexRuleEditor-${state.activeRule?.uuid}`}
                onChange={(values) => {
                  const index = ruleFieldExtractingConfigs.findIndex(
                    (field) => {
                      const fieldFullPath = getFieldFullPath(
                        field.parentPath,
                        field.tagName,
                      );
                      return fieldFullPath === activeFieldFullPath;
                    },
                  );
                  if (index !== -1) {
                    ruleFieldExtractingConfigs[index] = {
                      ...ruleFieldExtractingConfigs[index],
                      ...values,
                    };
                    dispatch(updateFieldRule({ ruleFieldExtractingConfigs }));
                  }
                }}
                isEditMode={state.draftRule?.state === RULE_STATUS_INIT}
              />
            </div>
          </Affix>
        </div>
      ) : null}
    </RegexMatchRuleWrapper>
  );
};

export default RegexMatchRule;
