import * as types from '@src/store/types/types';

const initState = {
  urlQueryParams: '',
};

function systemReducer(state = initState, action: any) {
  switch (action.type) {
    case types.UPDATE_URL_QUERY_PARAMS: {
      return { ...state, urlQueryParams: action.params };
    }
    default:
      return state;
  }
}

export default systemReducer;
