import React, { useState } from 'react';
import { Form, Input, Modal, Button } from 'antd';
import { closeModal } from '@src/pages/RuleEngine/layouts/modals/index';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import { convertTagNameToTagKey, genNewField } from '@src/utils/ruleEngine';
import { updateFieldRule } from '@src/store/actions/ruleEngine';
import { GROUP_CODE_CUSTOMER_CUSTOM_FIELDS } from '@src/store/types/types';

export const MODAL_KEY = 'ADD_CUSTOM_TAG_MODAL_KEY';

const CreateCustomTagModal: React.FC = () => {
  const [inputDisable, setDisable] = useState(true);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => {
    return {
      visibleKey: state.modal.visibleKey,
      draftRule: state.ruleEngine.draftRule,
      userInfo: state.ruleEngine.userInfo,
    };
  });

  const handleTagNameChange = (e) => {
    e.persist();
    const fieldName = e.target.value;
    const fieldCode = convertTagNameToTagKey(fieldName);
    form.setFieldsValue({ fieldCode: fieldCode });
    form.validateFields().then();
  };

  const addCustomTag = () => {
    form.validateFields().then((res) => {
      const oldRuleFieldMappings = state.draftRule?.ruleFieldMappings || [];
      const customTag = genNewField({
        ...res,
        invalidValues:
          state.userInfo?.customerConfig?.defaultInvalidValues || [],
        ruleFieldMappedFroms: [],
        fieldTypeCode: 'CUSTOM',
        outputFieldTypeCode: 'STRING',
        groupCode: GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
      });
      const ruleFieldMappings = [...oldRuleFieldMappings, customTag];
      dispatch(updateFieldRule({ ruleFieldMappings }));
      closeModal(MODAL_KEY);
    });
  };

  const checkDuplicate = (tagKey: string) => {
    const value = tagKey;
    const fieldList = state.draftRule?.ruleFieldMappings || [];
    const customTagList = fieldList.filter(
      (i) => i.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
    );
    const otherFields = fieldList.filter(
      (i) => i.groupCode !== GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
    );
    const duplicateWithFiled = otherFields.find((i) => i.fieldCode === value);
    const duplicateTag = customTagList.find((i) => i.fieldCode === value);
    const hasDuplicate = duplicateTag || duplicateWithFiled;
    return {
      duplicateTag,
      duplicateWithFiled,
      hasDuplicate,
    };
  };

  return (
    <Modal
      title="Create Custom Tag"
      visible={state.visibleKey === MODAL_KEY}
      onCancel={() => {
        setDisable(true);
        closeModal();
      }}
      onOk={addCustomTag}
      destroyOnClose
    >
      <Form form={form} layout="vertical" name="create_custom_tag">
        <div style={{ marginBottom: '10px' }}>Tag Display Name</div>
        <Form.Item
          name="fieldName"
          style={{ marginBottom: '15px' }}
          required
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input onChange={handleTagNameChange} />
        </Form.Item>
        <div style={{ marginBottom: '10px' }}>Tag Key</div>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, nextValues, info) =>
            prevValues.fieldName !== nextValues.fieldName
          }
        >
          {({ getFieldValue }) => {
            const value = getFieldValue('fieldCode');
            const { hasDuplicate } = checkDuplicate(value);
            return (
              <>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="fieldCode"
                  rules={[
                    { required: true, message: 'Required' },
                    { max: 64 },
                    {
                      pattern: /^[a-zA-Z_][a-zA-Z_0-9]*$/,
                      message:
                        'Use only alphanumeric characters or underscores. Cannot start with a number.',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const { duplicateTag, duplicateWithFiled } =
                          checkDuplicate(value);
                        if (duplicateTag) {
                          return Promise.reject(
                            new Error(
                              `There is already a tag with the same key (tag name:"${duplicateTag.fieldName}").`,
                            ),
                          );
                        }
                        if (duplicateWithFiled) {
                          return Promise.reject(
                            new Error(
                              `Tag key duplicate with field ${duplicateWithFiled.fieldName}`,
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input disabled={inputDisable} />
                </Form.Item>
                {hasDuplicate && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button type="link" onClick={() => setDisable(false)}>
                      Edit Tag Key
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCustomTagModal;
