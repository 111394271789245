import styled, { keyframes } from 'styled-components';
import colorMap from '@src/styles/styled/colorMap';

const localColorMap = {
  timeBackground: {
    light: '#f2f2f2',
    dark: '#253852',
  },
  timeColor: {
    light: '#9b9b9b',
    dark: '#CACACA',
  },
};

const topPanelMarginOnMode = () => {
  return '5px';
};

const summaryMarginOnMode = () => {
  return '20px';
};

export const SwitchWrapper = styled.div`
  width: 100%;
  padding: 16px 13px;
  .react-switch-bg {
    border: 1px solid #9b9b9b !important;
  }
  .react-switch-handle {
    border: 1px solid #9b9b9b !important;
  }
`;

export const SwitchItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SwitchItemLabel = styled.span`
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => colorMap.backgroundColor[theme]};
  height: calc(100vh - 85px);
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-alert-warning {
    background-color: #fff9f2;
    border: 1px solid #ff9300;
    color: #4a4a4a;
  }
  .conviva-base-table__header-row {
    border-bottom: 1px solid #dadada;
  }
  .conviva-base-table__row-cell .ant-dropdown-trigger {
    width: 91%;
    height: 30px;
    padding: 0px 12px;
    & > span {
      justify-content: space-between;
    }
  }

  .conviva-base-table__table-frozen-left
    .conviva-base-table__row--frozen
    .conviva-base-table__row-cell {
    border-bottom: 1px solid #ddd !important;
  }

  .conviva-base-table__table-frozen-left {
    .conviva-base-table__row {
      .conviva-base-table__row-cell:nth-child(2) {
        border-bottom: 1px solid #ddd !important;
      }
    }
  }
  .conviva-base-table__header
    .conviva-base-table__row--frozen:nth-child(3)
    .conviva-base-table__row-cell {
    border-bottom: 0px !important;
  }
`;

export const TopBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  box-sizing: border-box;
  .ant-calendar-picker-input.ant-input {
    border: 1px solid #686868;
    color: #686868;
    &:hover {
      border: 1px solid #686868;
    }
  }
`;

export const DragWrapper = styled.div`
  padding: 5px 0 5px 5px;
  border: 1px solid #cacaca;
  border-radius: 6px;
  background-color: #ffffff;
`;

export const TabsWrapper = styled.div`
  .saved-filter-panel {
    border: 1px solid #e5e5e5;
    height: 400px;
    overflow-y: scroll;
  }
  .ant-tabs .ant-tabs-left-content {
    padding-left: 0px;
  }
`;

export const TagSelectorWrapper = styled.div`
  width: 100%;
  .ant-select-selection.ant-select-selection--single {
    height: 44px;
  }
  .ant-select-selection__rendered {
    height: 44px;
    line-height: 44px;
  }
`;

export const StatusSelectorWrapper = styled.div`
  margin-right: 10px;
  .ant-dropdown-trigger {
    height: 38px;
    & > span {
      font-family: 'Open Sans';
      font-size: 16px;
      letter-spacing: 0;
      text-align: left;
      justify-content: space-between;
      line-height: 20px;
      color: #686868;
      font-weight: 600;
    }
  }
`;

export const RealtimeTileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    width: 102px;
    height: 38px;
  }
  & > span > span {
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    height: 38px;
  }
  & > span span:nth-child(2) {
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
  }
`;

export const BlueCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 38px;
  & > span {
    width: 102px;
    height: 38px;
  }
  & > span > span {
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    height: 38px;
  }
  & > span span:nth-child(2) {
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
  }
  & > span {
    background-color: #eaf3ff;
    border: 2px solid #eaf3ff;
    color: #686868;
  }
  & > span:hover {
    background-color: #eaf3ff;
    border: 2px solid #eaf3ff;
    color: #686868;
  }
`;

export const ScopeWrapper = styled.div`
  float: left;
  margin: 45px 10px 20px 20px;
`;

export const TopPanelWrapper = styled.div`
  margin-bottom: ${() => topPanelMarginOnMode()};
  background-color: ${({ theme }) => colorMap.backgroundColor[theme]};
  position: relative;
  width: 100%;
`;

export const TabWrapper = styled.div`
  position: absolute;
`;

export const MarginLayout = styled.div`
  margin-right: 12.5px !important;
  margin-left: 7.5px !important;
`;

export const WrapHeaderTitle = styled.span`
  h2 {
    font-size: 14px;
    white-space: normal;
    font-weight: 500;
    margin: 0;
    line-height: 0.9;
    position: relative;
    display: inline-block;
    color: ${({ theme }) => colorMap.color[theme]};

    .noc-title-holder {
      cursor: pointer;
      margin: 0.1rem 0 0.2rem;
      display: block;
      position: relative;
      white-space: normal;

      .noc-title {
        display: block;
        margin: 0 auto;
        max-height: 32px;
        line-height: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 5px;
        padding-bottom: 0.5rem;
        background-color: ${({ theme }) => localColorMap.timeBackground[theme]};
        padding: 0 0.5rem 0.2rem 10px;
        max-width: 600px;
        overflow-x: hidden;
        min-height: 32px;
        white-space: normal;
        line-height: 16px;

        &:hover {
          outline: 1px solid #ccc;
        }
      }
      &:focus {
        outline: none;
      }
      .vertical-bar {
        position: absolute;
        width: 0.3rem;
        top: 0;
        left: 0;
        bottom: 0;
        background: #bd10e0;
        z-index: 1;
      }
    }
  }
`;

export const Delimiter = styled.span`
  color: #215bff;
  padding: 0 0.25rem;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  margin-bottom: ${() => summaryMarginOnMode()};
  & > * {
    text-align: center;
    margin: 5px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
  & button {
    margin-left: 15px;
  }
  & .filter-label {
    width: 100px;
    color: #000000;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
`;

export const TabPaneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #979797;
`;

export const SampleValueItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #4a4a4a;
  font-size: 18px;
`;

export const TagDetailsItemBox = styled.div`
  border-bottom: 1px solid #979797;
  padding: 10px;
  & .tag-details-item-value {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    margin-left: 10px;
  }

  & .tag-details-item-label {
    font-size: 20px;
  }
`;

export const TagDetailsItem = styled.div`
  display: flex;
  padding: 10px;
  color: #4a4a4a;
  font-size: 18px;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  // @media (min-width: 900px) {
  //   max-width: 800px;
  // }
  // border: 1px solid ${({ theme }) => colorMap.color[theme]};
  // width: 800px;
  // background-color: ${({ theme }) => colorMap.backgroundColor[theme]};
  // padding: 1rem;
  // box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.3);
  // border-radius: 3px;
  .tableCell {
    text-indent: 30px;
  }
  .text-cut {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  td {
    border-bottom: 0px !important;
    padding: 7px 16px !important;
  }
  .row-border-top td {
    border-top: 1px solid #e4e4e4;
    padding-top: 20px !important;
  }
  .row-last-one td {
    padding-bottom: 20px !important;
  }
  & .ant-tabs {
    color: ${({ theme }) => colorMap.color[theme]}!important;
  }
  & .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    color: #595959;
    text-align: left !important;
  }
  & .ant-dropdown-menu-item-disabled .ant-dropdown-menu-submenu-title-disabled {
    background: none !important;
  }
  & .ant-tabs-tab {
    margin: 0px 5px 5px 0px !important;
    background-color: ${({ theme }) =>
      colorMap.backgroundColor[theme]}!important;
  }
  & .ant-tabs-tab-active {
    color: ${({ theme }) => colorMap.activeColor[theme]}!important;
    background-color: ${({ theme }) =>
      colorMap.activeBackgroundColor[theme]}!important;
  }

  & .ant-collapse-content-box > div:hover {
    background-color: ${({ theme }) =>
      colorMap.activeBackgroundColor[theme]}!important;
  }

  & .ant-select-selection {
    height: 100%;
    background-color: ${({ theme }) =>
      colorMap.backgroundColor[theme]}!important;
    .ant-select-selection__choice {
      background-color: ${({ theme }) =>
        colorMap.selectedBackground[theme]}!important;
    }
  }

  & .ant-checkbox-wrapper:hover {
    background-color: ${({ theme }) =>
      colorMap.activeBackgroundColor[theme]}!important;
  }

  & div[class^='filter-pill'] .ant-checkbox-wrapper-checked {
    background-color: ${({ theme }) =>
      colorMap.activeBackgroundColor[theme]}!important;
    border-color: ${({ theme }) => colorMap.activeBorderColor[theme]}!important;
  }

  & .ant-checkbox-inner {
    background: none !important;
  }

  & .ant-checkbox-inner::after {
    border-color: #002bf7 !important;
  }

  & .ant-tabs-extra-content a {
    color: #1890ff !important;
  }

  & .ant-select-dropdown {
    background-color: ${({ theme }) =>
      colorMap.backgroundColor[theme]}!important;
    border: 1px solid ${({ theme }) => colorMap.borderColor[theme]}!important;
    i svg path {
      color: ${({ theme }) => colorMap.backgroundColor[theme]}!important;
    }
    & .ant-select-dropdown-menu-item-selected,
    & .ant-select-dropdown-menu-item:hover {
      background-color: ${({ theme }) =>
        colorMap.activeBackgroundColor[theme]}!important;
      i svg path {
        color: ${({ theme }) => colorMap.color[theme]}!important;
      }
    }
  }
`;

export const ModalMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  opacity: var(--modal-opacity, 0.7);
  z-index: 70;
  background-color: rgb(0, 0, 0);
  background-color: var(--model-background, rgb(0, 0, 0));
  & + div {
    z-index: 71;
  }
`;

export const ThresholdHeader = styled.div`
  font-size: 20px;
  margin: 37px 20px 20px 20px;
  float: left;
  ${({ theme }) =>
    theme === 'dark' &&
    `
    color: #cacaca;
  `}
`;

const blink = keyframes`
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
`;

export const TimeTablet = styled.div`
  padding: 6px 10px;
  min-width: 200px;
  text-align: left;
  background-color: ${({ theme }) => localColorMap.timeBackground[theme]};
  color: ${({ theme }) => localColorMap.timeColor[theme]};
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;

  & .loading-time span {
    animation-name: ${blink};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
`;

export const ProductSelectWrapper = styled.div`
  .ant-dropdown-trigger {
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ant-dropdown-trigger > span {
    color: #000000;
    font-family: 'Open Sans';
    letter-spacing: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 42px;
  }
  .ant-dropdown-trigger > .anticon.anticon-down {
    font-size: 20px;
  }
`;

export const WarningText = styled.p`
  font-size: 24px;
  width: 100%;
  margin-top: 75px;
  text-align: center;
  font-family: Open Sans, sans-serif;
  opacity: 0.9;
`;
