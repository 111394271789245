import * as types from '@src/store/types/modal';

export function openModalAction(modalKey: string) {
  return {
    type: types.OPEN_MODAL,
    modalKey,
  };
}

export function closeModalAction(modalKey?: string) {
  return {
    type: types.CLOSE_MODAL,
    modalKey: modalKey,
  };
}
