import * as types from '@src/store/types/types';
import { cloneDeep } from 'lodash';
import { IRuleEngineReducer } from '@src/typing/ruleEngine';

const defaultRules = {
  mapFrom: [],
  transform: [],
  letterCase: '',
  trimExtraSpacesFlag: false,
  invalidValues: [],
  regexTransforms: [],
};

const initState: IRuleEngineReducer = {
  businessParcels: [],
  sampleDataJobs: {
    customerId: '',
    endDate: '',
    jobMessage: '',
    jobStatus: null,
    startDate: '',
    isFetching: false,
  },
  activeBusinessParcel: {
    dataSource: {
      dataSourceCode: '',
      dataSourceName: '',
      dataSourceUuid: '',
      partition: '',
    },
    createTime: '',
    createUser: '',
    description: '',
    name: '',
    project: {
      projectCode: '',
      projectName: '',
      uuid: '',
    },
    publisher: null,
    relatedTemplates: null,
    reVersion: 'V1',
    ruleInfo: null,
    status: 'ENABLED',
    updateTime: '',
    updateUser: '',
    uuid: '',
  },
  ruleVersions: [],
  activeRule: null,
  draftRule: null,
  userInfo: {
    permissions: [],
  },
  activeVersion: {
    versionNo: null,
  },
  activeVersionUI: {},
  fields: [],
  letter: [],
  ruleFieldMappingType: [],
  requiredFields: [],
  automatedFields: [],
  activeField: '',
  activeRegex: '',
  activeCustomTag: '',
  customTags: [],
  regexTestResult: '',
  loading: {},
  tagSampleData: [],
  rules: {
    // seriesName: {
    //     mapFrom: [{
    //         type:"regex",
    //         label:"advertisingId",
    //         value:"1111",
    //         id:1597024346614,
    //     }]
    // }
  },
  draftRules: {},
  selectedCustomTags: [],
  customTagRules: {},
  draftCustomTagRules: {},
  regexRules: {
    // object_id: [],
  },
  draftRegexRules: {},
  dryRunList: [],
  dryRunReports: [],
  dryRunCompareLists: [],
};

function ruleEngineReducer(state = initState, action: any): IRuleEngineReducer {
  switch (action.type) {
    case types.RECEIVE_RULE_ENGINE_REQUIRED_FIELDS: {
      return { ...state, fields: action.data };
    }
    case types.RECEIVE_RULE_ENGINE_SUPPORT_LETTER: {
      return { ...state, letter: action.data };
    }
    case types.RECEIVE_RULE_ENGINE_OPTIONS: {
      return { ...state, ...action.data };
    }
    case types.SELECT_FIELD_TO_ACTIVE: {
      return { ...state, activeField: action.data };
    }
    case types.SELECT_REGEX_TO_ACTIVE: {
      return { ...state, activeRegex: action.data };
    }
    case types.SELECT_CUSTOM_TAG_TO_ACTIVE: {
      return { ...state, activeCustomTag: action.data };
    }
    case types.START_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data]: true,
          global: true,
        },
      };
    }
    case types.END_LOADING: {
      const allLoadingKeys = Object.keys(state.loading).filter(
        (item) => item !== action.data && item !== 'global',
      );
      let global = false;
      allLoadingKeys.forEach((item) => {
        if (state.loading[item]) {
          global = true;
        }
      });
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data]: false,
          global,
        },
      };
    }
    case types.EDIT_RULES_FOR_FIELD: {
      return {
        ...state,
        draftRules: {
          ...state.draftRules,
          [action.data.target]: { ...action.data.rules },
        },
        rules: {
          ...state.rules,
          [action.data.target]: { ...action.data.rules },
        },
      };
    }
    case types.CLEAR_FIELD_RULES: {
      return {
        ...state,
        draftRules: {
          ...state.draftRules,
          [action.data]: { ...defaultRules },
        },
        rules: {
          ...state.rules,
          [action.data]: { ...defaultRules },
        },
      };
    }
    case types.APPLY_RULES_TO_FIELD: {
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.data]: { ...state.draftRules[action.data] },
        },
      };
    }
    case types.EDIT_RULES_FOR_REGEX: {
      return {
        ...state,
        draftRegexRules: {
          ...state.draftRegexRules,
          [action.data.target]: [...action.data.rules],
        },
        regexRules: {
          ...state.regexRules,
          [action.data.target]: [...action.data.rules],
        },
      };
    }
    case types.APPLY_RULES_TO_REGEX: {
      return {
        ...state,
        regexRules: {
          ...state.regexRules,
          [action.data]: [...state.draftRegexRules[action.data]],
        },
      };
    }
    case types.UPDATE_SELECTED_CUSTOM_TAGS: {
      return { ...state, selectedCustomTags: [...action.data] };
    }
    case types.EDIT_RULES_FOR_CUSTOM_TAG: {
      return {
        ...state,
        draftCustomTagRules: {
          ...state.draftCustomTagRules,
          [action.data.target]: { ...action.data.rules },
        },
        customTagRules: {
          ...state.customTagRules,
          [action.data.target]: { ...action.data.rules },
        },
      };
    }
    case types.APPLY_RULES_TO_CUSTOM_TAG: {
      return {
        ...state,
        customTagRules: {
          ...state.customTagRules,
          [action.data]: { ...state.draftCustomTagRules[action.data] },
        },
      };
    }
    case types.CLEAR_CUSTOM_TAG_RULES: {
      delete state.draftCustomTagRules[action.data];
      delete state.customTagRules[action.data];
      return {
        ...state,
        draftCustomTagRules: {
          ...state.draftCustomTagRules,
        },
        customTagRules: {
          ...state.customTagRules,
        },
      };
    }
    case types.RECEIVE_TAG_KEY_LIST: {
      return { ...state, customTags: action.data };
    }
    case types.RECEIVE_TAG_SAMPLE_DATA: {
      return { ...state, tagSampleData: action.data };
    }
    case types.RECEIVE_REGEX_TEST_RESULT: {
      return { ...state, regexTestResult: action.data };
    }
    case types.RECEIVE_RULE_VERSIONS: {
      return { ...state, ruleVersions: [...action.data] };
    }
    case types.SET_ACTIVE_VERSION: {
      return { ...state, activeVersion: { ...action.data } };
    }
    case types.RECEIVE_RULES: {
      return { ...state, ...action.data };
    }
    case types.RECEIVE_DRY_RUN_LIST: {
      return { ...state, dryRunList: [...action.data] };
    }
    case types.RECEIVE_USER_INFO: {
      return { ...state, userInfo: { ...action.data } };
    }
    case types.ADD_DRY_RUN_REPORT_TAB: {
      return {
        ...state,
        dryRunReports: [...state.dryRunReports, { ...action.data }],
      };
    }
    case types.RECEIVE_DRY_RUN_REPORT: {
      return { ...state, dryRunReports: [...action.data] };
    }
    case types.RECEIVE_BUSINESS_UNITS: {
      return {
        ...state,
        businessParcels: action.data,
      };
    }
    case types.SET_ACTIVE_BUSINESS_UNIT: {
      return {
        ...state,
        activeBusinessParcel: action.data,
        activeField: '',
        activeRegex: '',
        activeCustomTag: '',
        dryRunReports: [],
      };
    }
    case types.RECEIVE_SAMPLE_DATA_JOBS: {
      const jobStatus = action.data.jobStatus;
      return {
        ...state,
        sampleDataJobs: {
          ...state.sampleDataJobs,
          ...action.data,
          isFetching:
            jobStatus && jobStatus !== 'success' && jobStatus !== 'failed',
        },
      };
    }
    case types.RECEIVE_RULE_INFO: {
      return {
        ...state,
        activeRule: {
          ...cloneDeep(action.data),
        },
        draftRule: {
          ...action.data,
        },
      };
    }
    case types.UPDATE_RULE: {
      return {
        ...state,
        draftRule: {
          ...state.draftRule,
          ...action.data,
        },
      };
    }
    case types.UPDATE_DRY_RUN_COMPARE_LISTS: {
      return {
        ...state,
        dryRunCompareLists: [...action.data],
      };
    }
    default:
      return state;
  }
}

export default ruleEngineReducer;
