import { VERSION_2 } from '@src/utils/constants';
import { closeModalAction, openModalAction } from '@src/store/actions/modal';
import { FETCH_DATA_MODAL_KEY } from '@src/pages/RuleEngine/layouts/modals';
import { submitSampleDataJobV2 } from '@src/service/apis/ruleEngine/sampleData';
import { getSampleDataJobs } from '@src/store/actions/ruleEngine';
import { MODAL_KEY } from '@src/pages/RuleEngine/layouts/modals/FetchSampleDataModal';
import store from '@src/store';

// Since some V1 code not function component, so this not a real hooks.
export const fetchSampleData = () => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const activeBusinessParcel = state.ruleEngine.activeBusinessParcel;
  if (
    activeBusinessParcel.reVersion === VERSION_2 &&
    activeBusinessParcel.dataSource.partition === 'NONE'
  ) {
    submitSampleDataJobV2({
      projectCode: activeBusinessParcel.project.projectCode,
    })
      .then((res) => {
        dispatch(closeModalAction(MODAL_KEY));
        dispatch(getSampleDataJobs());
      })
      .finally(() => {});
  } else {
    dispatch(openModalAction(FETCH_DATA_MODAL_KEY));
  }
};
