import styled from 'styled-components';
// import { LoadingLayer } from '@components/common';
import colorMap from '../../styles/styled/colorMap';

export const TableWrapper = styled.div`
  width: auto;
  min-height: auto;
  flex: 1;
  margin:10px 0px;
  .conviva-base-table__sort-indicator {
    display: flex;
    align-items: center;
  }
  .conviva-base-table__table-frozen-left {
    box-shadow: none;
    .conviva-base-table__row {
      border-bottom: 0px;
      .conviva-base-table__row-cell{
        border-bottom : 0px!important;
      }
    }
  }

  .conviva-base-table__header-row {
    border-bottom: 0px;
  }

  .conviva-base-table__row{
    border-bottom: 0px;
  }
  
  .conviva-base-table__row-cell {
    padding: 0px;
  }

  .conviva-base-table__table-main .conviva-base-table__row{
    .conviva-base-table__row-cell{
      padding: 0px 32px 0px 0px;
      border-bottom: 1px solid ${({ theme }) => colorMap.borderColor[theme]}!important;
    }
  }
  .conviva-base-table__table-frozen-left .conviva-base-table__row-cell:first-child {
    padding-right: 30px;
  }

  .conviva-base-table__row:hover,
  .conviva-base-table__row--hovered {
    background-color: inherit;
  }

  .conviva-base-table__table-main .conviva-base-table__header-row {
    .conviva-base-table__header-cell{
      padding: 0px 32px 0px 0px;
      justify-content: space-between;
      &:hover{
        background: none!important;
      }
    }
    

    .conviva-base-table__sort-indicator{
      padding: 4px;
      font-size: 16px;
    }
  }

`;

export const RealtimeTileWrapper = styled.div`
  padding : 20px 0px;
  height: 100%;
`;

// export const LoadingWrapper = styled(LoadingLayer)`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
// `;

export const ScopeWrapper = styled.span`
  width: 100%;
  padding-right: 7.5px;
  & span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => colorMap.backgroundColor[theme]};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HoverDiv = styled.div`
  width: 100%;
  .handle {
    visibility: hidden;
    text-align: center;
    font-size: 20px;
    height: 15px;
    line-height: 15px; 
    course: move;
  }
  &:hover .handle{
    visibility: visible;
  } 
`;
