import React, { useMemo, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { useAppSelector } from '@src/store/hooks';
import { IRuleFiledMapping } from '@src/typing/ruleEngine';
import FieldItem from './FieldItem';
import { FieldListWrapper, FieldListHeader } from './styled';

interface Props {
  title?: any;
  onItemClick?: (activeItem: any, activeItemIndex: number) => void;
  list: IRuleFiledMapping[];
  activeKey: React.Key;
}

const FieldList: React.FC<Props> = (props) => {
  const state = useAppSelector((state) => {
    return {
      activeRule: state.ruleEngine.activeRule,
    };
  });

  const ruleFieldMappings = state.activeRule?.ruleFieldMappings || [];

  const genFieldList = () => {
    if (!props.list.length) {
      return [];
    }
    return props.list.map((field, index: number) => {
      const active = field.fieldCode === props.activeKey;
      const rule = ruleFieldMappings.find(
        (i) => i.fieldCode === field.fieldCode,
      );

      const hasUnsave = !isEqual(rule, field);

      return (
        <div
          key={field.fieldCode}
          onClick={() => props.onItemClick?.(field, index)}
        >
          <FieldItem hasUnsave={hasUnsave} active={active} field={field} />
        </div>
      );
    });
  };

  const fieldList = genFieldList();

  return (
    <FieldListWrapper>
      <FieldListHeader>{props.title}</FieldListHeader>
      {fieldList}
    </FieldListWrapper>
  );
};

export default memo(FieldList, (prev, next) => {
  return isEqual(prev, next);
});
