import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  systemSelector,
  userInfoSelector,
  ruleEngineSelector,
} from '@src/store/selector';
// Utils
import moment from 'moment';
import _sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import Switch from 'react-switch';
import queryString from 'query-string';
import Highlighter from 'react-highlight-words';
import { Parser } from 'json2csv';
// Style component
import {
  Spin,
  DatePicker,
  Tabs,
  Table,
  InputNumber,
  Icon,
  Input as AntInput,
} from 'antdV3';
import { message as AntdMessage } from 'antd';
import { ColumnProps } from 'antdV3/es/table';
import { THEME_LIGHT, VERSION_2 } from '@src/utils/constants';
import MetricTable from '@src/components/MetricTable';
import { downloadCSV } from '@src/utils/preMdsCoverage';
import { ThemeContext } from '@src/contexts';
import { Header, Scope } from '@src/components/MetricTable/common';
import { ScopeWrapper } from '@src/components/MetricTable/styled';
import { Button } from 'conviva-design/es/components/ButtonV2';
import { SingleSelectDropdownV2 } from '@src/components/SingleSelectDropdown';
import {
  Modal,
  RowDivider,
  Checkbox,
  Select,
  RealtimeTile,
  Tooltip,
} from 'conviva-design/es/components/index';
import {
  ContentWrapper,
  ProductSelectWrapper,
  BlueCellWrapper,
  RealtimeTileWrapper,
  StatusSelectorWrapper,
  TopBarWrapper,
  TabsWrapper,
  ModalWrapper,
  FilterWrapper,
  TabPaneHeader,
  SampleValueItem,
  TagDetailsItem,
  TagDetailsItemBox,
  SwitchWrapper,
  SwitchItemWrapper,
  SwitchItemLabel,
  WarningText,
} from './styled';
// Action && API
import { updateUrlQueryParams } from '@src/store/actions/ruleEngine';
import {
  fetchPreMdsData,
  fetchPreMdsTagClassify,
  fetchProductClassify,
  fetchPreMdsAppsData,
  fetchSampleValue,
  fetchDiffAppVersionCoverage,
  fetchPreMdsDisabledTimeRange,
} from '@src/service/api';
import { Redirect } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Option } = Select;
// Constants
const dateFormat = 'YYYY-MM-DD';
const UI_METRIC_TYPE_SELECTOR_PLACEHOLDER = 'UI-MetricType';
const ALL_APPS_DATA_KEY = 'allApps';
const METRIC_TYPE_PLAYS = 'plays';
const METRIC_TYPE_MINUTES = 'minutes';
const METRIC_TYPE_MAP = {
  [METRIC_TYPE_PLAYS]: 'Percentage by Plays',
  [METRIC_TYPE_MINUTES]: 'Percentage by Time Watched',
};
const ORDER_DESC = 'desc';
const ORDER_ASC = 'asc';
const STATUS_ALL = 'all';
const STATUS_PASSED = 'passed';
const STATUS_NOT_PASSED = 'notPassed';
const STATUS_NOT_RECEIVED = 'notReceived';
const STATUS_NOT_REQUIRED = 'N/A';
const TAG_DISPLAY_NAME = 'tagDisplayName';
const TAG_KEY_NAME = 'tagKeyName';
const APP_TYPE_SDK = 'SDK';
const APP_TYPE_LIBRARY = 'Library';
const TAG_VALUE_NULL = 'Null';
const IS_AD_T = 'T';
const IS_AD_F = 'F';
const IS_AD_ALL = null;
const CONTENT_TYPE_VOD = 'F';
const CONTENT_TYPE_LIVE = 'T';
const CONTENT_TYPE_ALL = null;
const APPS_LIMIT = 30;
const DEFAULT_SELECTED_APPS_NUM = 10;
const LIMIT_MESSAGE = 'Only 30 apps will be displayed due to column limit.';
const DEFAULT_SORT_BY = { key: TAG_DISPLAY_NAME, order: ORDER_ASC };
const COLUMN_WIDTH = 102 + 33;

enum Category {
  Kebab = 'kebab',
  Button = 'button',
  Header = 'header',
  SubHeader = 'subHeader',
  Custom = 'Custom',
}

const Cell = ({
  cellData,
  unit,
  column,
  rowData,
  category,
  disabled,
  onClick,
  pressed,
}) => {
  const { theme } = React.useContext(ThemeContext);

  // drillDown action
  function handleClick() {
    onClick({ column, rowData });
  }

  const cell = (
    <RealtimeTile
      id="cell"
      theme={theme}
      category={category}
      value={cellData}
      unit={unit}
      onClick={handleClick}
      pressed={pressed}
    />
  );
  if (
    column.dataKey === ALL_APPS_DATA_KEY ||
    rowData.tagKeyName === UI_METRIC_TYPE_SELECTOR_PLACEHOLDER
  ) {
    return <BlueCellWrapper>{cell}</BlueCellWrapper>;
  } else {
    return (
      <RealtimeTileWrapper style={{ opacity: disabled ? 0.2 : 1 }}>
        {cell}
      </RealtimeTileWrapper>
    );
  }
};

class Coverage extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      preMdsEnabled: true,
      tableSearchText: '',
      theme: THEME_LIGHT,
      modalVisible: false,
      appsLoading: false,
      sampleValueLoading: false,
      sampleValueTagDisplayName: '',
      sampleValueActiveTab: '1',
      appsActiveTab: '1',
      diffAppVersionCoverageLoading: false,
      thresholdVisible: false,
      sampleValueVisible: false,
      tagSearchStr: '',
      tagSampleValue: [],
      diffAppVersionCoverage: [],
      data: [],
      frozenData: [],
      apps: [],
      appsInEdit: [],
      statusFilter: STATUS_ALL,
      cols: [],
      loading: false,
      threshold: 80,
      thresholdInput: 80,
      response: {
        appsCoverage: [
          {
            appName: null,
            appNameAlias: null,
            appVersion: null,
            appVersionAlias: null,
            value: '',
          },
        ],
        tagsCoverage: [
          {
            apps: [],
            tagDisplayName: '',
            tagKeyName: '',
            tagType: 0,
          },
        ],
      },
      tagsClassifyOptions: [{ key: 99, value: '' }],
      productClassifyOptions: [{ key: 99, value: 'All Products' }],
      productClassify: '99',
      tagClassify: '99',
      metricType: 'plays',
      sortBy: { ...DEFAULT_SORT_BY },
      startDate: '',
      endDate: '',
      contentType: CONTENT_TYPE_ALL,
      modalContentType: CONTENT_TYPE_ALL,
      isAd: IS_AD_ALL,
      modalIsAd: IS_AD_ALL,
      rangePickerStartDate: '',
      rangePickerEndDate: '',
      noDataDates: [],
      viewTagKey: false,
    };
  }

  componentDidMount() {
    this.init();
    this.getTagsClassify();
    this.getProductClassify();
  }

  putStateToUrl = (newParams: any) => {
    let parsed = queryString.parse(window.location.search);
    parsed = { ...parsed, ...newParams };
    const url = queryString.stringify(parsed);
    window.history.replaceState(
      { url: url },
      'url',
      window.location.pathname + '?' + url,
    );
    this.props.updateUrlQueryParams(url);
  };

  getStateFromUrl = () => {
    const parsed = queryString.parse(window.location.search);
    // const { customerName } = this.props.userInfo.customerBaseInfo;
    // if (parsed.customerName !== customerName) return {};
    return parsed || {};
  };

  encodeAppList = (apps: any) => {
    const appsArr = apps.map((app) => {
      const { appVersion, appType, appName } = app;
      return `${appName}@${appVersion}@${appType}`;
    });
    return JSON.stringify(appsArr);
  };

  decodeAppList = (appsStrList: any) => {
    return appsStrList.map((item) => {
      const appArr = item.split('@');
      return {
        appName: appArr[0],
        appVersion: appArr[1],
        appType: appArr[2] === 'null' ? null : appArr[2],
      };
    });
  };

  emptyPromise = (message) =>
    new Promise((resolve, reject) => {
      reject(message);
      this.setState({ loading: false, appsLoading: false });
    });

  init() {
    const parsed = this.getStateFromUrl();
    let {
      startDate,
      endDate,
      metricType,
      statusFilter,
      threshold,
      contentType,
      tagClassify,
      productClassify,
      sortBy,
      isAd,
      tagSearchStr,
      viewTagKey,
      modalIsAd,
      modalContentType,
    } = parsed;

    this.getTimeRange()
      .then(() => {
        if (startDate && endDate) {
          this.setState({ startDate, endDate });
        }
        if (contentType) {
          this.setState(() => ({ contentType }));
        }
        if (isAd) {
          this.setState(() => ({ isAd }));
        }
        if (tagClassify) {
          this.setState(() => ({ tagClassify }));
        }
        if (productClassify) {
          this.setState(() => ({ productClassify }));
        }
        if (threshold) {
          this.setState(() => ({ threshold }));
        }
        if (metricType) {
          this.setState(() => ({ metricType }));
        }
        if (statusFilter) {
          this.setState(() => ({ statusFilter }));
        }
        if (tagSearchStr) {
          this.setState(() => ({ tagSearchStr }));
        }
        if (modalIsAd) {
          this.setState(() => ({ modalIsAd }));
        }
        if (modalContentType) {
          this.setState(() => ({ modalContentType }));
        }
        try {
          if (sortBy) {
            sortBy = JSON.parse(sortBy);
            this.setState(() => ({ sortBy }));
          }
        } catch (error) {
          console.error('parse sortBy error:', error);
        }
        try {
          if (viewTagKey) {
            viewTagKey = JSON.parse(viewTagKey);
            this.setState(() => ({ viewTagKey }));
          }
        } catch (error) {
          console.error('parse viewTagKey error:', error);
        }
        return this.getAppsData();
      })
      .then(() => {
        let { selectedApps } = parsed;
        const { apps } = this.state;
        try {
          selectedApps = JSON.parse(selectedApps);
          selectedApps = this.decodeAppList(selectedApps);
          selectedApps.forEach((app) => {
            const appKey = this.getAppKey(app);
            apps.forEach((item) => {
              const key = this.getAppKey(item);
              if (appKey === key) item.checked = true;
            });
          });
        } catch (error) {
          // Default select the top 10 Apps
          apps
            .slice(0, DEFAULT_SELECTED_APPS_NUM)
            .forEach((app) => (app.checked = true));
        }
        const appsInEdit = cloneDeep(apps);
        this.setState({ apps, appsInEdit }, this.getData);
      })
      .catch((error) => {
        console.log('get time range error:', error);
        this.renderTable();
        this.setState({ loading: false });
      });
  }

  getTimeRange() {
    this.setState({ loading: true });
    return fetchPreMdsDisabledTimeRange().then((res) => {
      const data = res.data;
      this.setState({ preMdsEnabled: data.preMdsEnabled });
      if (!data.endDate || !data.startDate) {
        return this.emptyPromise(res);
      }
      this.setState({
        noDataDates: data.noDataDates,
        rangePickerStartDate: data.startDate,
        rangePickerEndDate: data.endDate,
        startDate: data.endDate,
        endDate: data.endDate,
      });
    });
  }

  getData() {
    this.setState({ loading: true });
    const {
      startDate,
      endDate,
      metricType,
      productClassify,
      isAd,
      contentType,
      apps: appsData,
    } = this.state;
    const apps = this.getCheckedApps(appsData);
    const params = {
      startDate,
      endDate,
      metricType,
      isAd,
      contentType,
      apps,
    };
    if (!startDate || !endDate) return this.emptyPromise('getData');
    if (productClassify !== '99') {
      params['productClassifyOptions'] = parseInt(productClassify);
    }
    fetchPreMdsData(params)
      .then((res) => {
        const response = res.data;
        this.setState({ loading: false, response }, this.renderTable);
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  getProductClassify() {
    fetchProductClassify().then((res) => {
      const productClassifyOptions = res.data;
      productClassifyOptions.unshift({ key: 99, value: 'All Products' });
      this.setState({ productClassifyOptions });
    });
  }

  getTagsClassify() {
    fetchPreMdsTagClassify().then((res) => {
      const tagsClassifyOptions = res.data;
      tagsClassifyOptions.unshift({ key: 99, value: 'All Tags' });
      this.setState({ tagsClassifyOptions });
    });
  }

  getAppsData(contentType = this.state.contentType, isAd = this.state.isAd) {
    this.setState({ appsLoading: true });
    const { startDate, endDate } = this.state;
    if (!startDate || !endDate) return this.emptyPromise('getAppsData');
    const params = {
      startDate: startDate,
      endDate: endDate,
      contentType,
      isAd,
    };
    return fetchPreMdsAppsData(params).then((res) => {
      const apps = res.data;
      apps.forEach((app) => {
        // When change the "Traffic Type" or "Session Type" filter in apps dialog, Need to keep check status.
        const appsInEditCheckedAppsKeys = this.getCheckedAppsKeys(
          this.state.appsInEdit,
        );
        const appKey = this.getAppKey(app);
        app.checked = appsInEditCheckedAppsKeys.includes(appKey);
      });
      const appsInEdit = [...apps];
      this.setState({ appsInEdit, apps, appsLoading: false });
    });
  }

  buildTableData = (response) => {
    const { tagsCoverage, appsCoverage } = response;
    const allTagsCoverageObj = {
      tagDisplayName: UI_METRIC_TYPE_SELECTOR_PLACEHOLDER,
      tagKeyName: UI_METRIC_TYPE_SELECTOR_PLACEHOLDER,
      apps: appsCoverage,
    };
    const data = [allTagsCoverageObj, ...tagsCoverage].map((tag, index) => {
      const obj: any = { ...tag, key: tag.tagKeyName, id: tag.tagKeyName };
      tag.apps.forEach((app) => {
        const dataKey =
          !app.appNameAlias && !app.appVersionAlias
            ? ALL_APPS_DATA_KEY
            : `${app.appNameAlias}${app.appVersionAlias}${app.appType}`;
        obj[dataKey] = app.value;
      });
      return obj;
    });
    return data;
  };

  buildTableCols = (response) => {
    const { appsCoverage } = response;
    const { tagsClassifyOptions, theme, viewTagKey } = this.state;
    // Main columns means the first column and "All Apps" column.
    const mainCol = [
      {
        title: 'All Tags',
        dataKey: viewTagKey ? TAG_KEY_NAME : TAG_DISPLAY_NAME,
        key: 'All Tags',
        width: 290,
        frozen: true,
        align: 'left',
        resizable: true,
        cellRenderer: this.buildMainColCell,
        headerRenderer: () => {
          const activeTagClassify = tagsClassifyOptions.filter(
            (item) => `${item.key}` === this.state.tagClassify,
          )[0].value;
          const sortKey = this.state.sortBy.key;
          const sortChecked =
            sortKey === TAG_DISPLAY_NAME || sortKey === TAG_KEY_NAME;
          return (
            <ProductSelectWrapper data-test-id="tags-classify-selector">
              <SingleSelectDropdownV2
                key="tagsClassifySelect"
                searchable
                searchValue={this.state.tagSearchStr}
                onSearch={debounce(this.onTagSearch, 300)}
                items={tagsClassifyOptions.map((item) => ({
                  key: `${item.key}`,
                  displayValue: item.value,
                }))}
                type={Category.Header}
                theme={theme}
                text={activeTagClassify}
                onApply={this.onTagsClassifyChange}
                autoCloseDropdown={false}
                dropDownRender={(menu) => (
                  <>
                    <div data-test-id="tags-classify-list">{menu}</div>
                    <div
                      style={{
                        background: '#fff',
                        border: '1px solid #9B9B9B',
                      }}
                    >
                      <SwitchWrapper data-test-id="tag-key-switch">
                        <SwitchItemWrapper>
                          <Switch
                            onChange={this.onViewTagKeyChange}
                            checked={viewTagKey}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#93C90E"
                            offColor="#EFEFEF"
                            height={20}
                            width={41}
                            handleDiameter={20}
                          />
                          <SwitchItemLabel>View tag key</SwitchItemLabel>
                        </SwitchItemWrapper>
                        <SwitchItemWrapper
                          style={{ marginTop: '10px' }}
                          data-test-id="alphabetical-order-switch"
                        >
                          <Switch
                            onChange={this.onTagKeyOrderChange}
                            checked={sortChecked}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#93C90E"
                            offColor="#EFEFEF"
                            height={20}
                            width={41}
                            handleDiameter={20}
                          />
                          <SwitchItemLabel>
                            View by alphabetical order
                          </SwitchItemLabel>
                        </SwitchItemWrapper>
                      </SwitchWrapper>
                    </div>
                  </>
                )}
              />
            </ProductSelectWrapper>
          );
        },
      },
      {
        title: 'All Apps',
        key: ALL_APPS_DATA_KEY,
        dataKey: ALL_APPS_DATA_KEY,
        width: COLUMN_WIDTH,
        frozen: true,
        resizable: false,
        headerRenderer: () => <Header app={{ appName: 'All Apps' }} />,
        cellRenderer: this.buildMetricColCell,
        align: 'left',
      },
    ];

    const appsCols = appsCoverage.map((app) => {
      const { appName, appVersion, appType } = app;
      const dataKey = this.getAppAliasKey(app);
      return {
        appName,
        appVersion,
        appType,
        key: dataKey,
        dataKey: dataKey,
        align: 'left',
        sortable: true,
        width: COLUMN_WIDTH,
        headerRenderer: () => <Header app={app} />,
        cellRenderer: this.buildMetricColCell,
      };
    });
    // shift "All Apps" column.
    appsCols.shift();
    return [...mainCol, ...appsCols];
  };

  buildMainColCell = ({ cellData, rowData }) => {
    const {
      tagsClassifyOptions,
      theme,
      tagClassify,
      metricType,
      viewTagKey,
      sortBy,
    } = this.state;
    const metricTypeList = [
      {
        key: METRIC_TYPE_PLAYS,
        displayValue: METRIC_TYPE_MAP[METRIC_TYPE_PLAYS],
      },
      {
        key: METRIC_TYPE_MINUTES,
        displayValue: METRIC_TYPE_MAP[METRIC_TYPE_MINUTES],
      },
    ];
    if (cellData === UI_METRIC_TYPE_SELECTOR_PLACEHOLDER) {
      return (
        <div data-test-id="metric-type-selector" style={{ width: '100%' }}>
          <SingleSelectDropdownV2
            items={metricTypeList}
            type={Category.Button}
            theme={theme}
            text={
              metricTypeList.filter((item) => `${item.key}` === metricType)[0]
                .displayValue
            }
            onApply={this.onMetricTypeChange}
          />
        </div>
      );
    } else {
      return (
        <ScopeWrapper>
          <Scope text={cellData} rowData={rowData} />
        </ScopeWrapper>
      );
    }
  };

  buildMetricColCell = (options) => {
    const { cellData, column, rowData } = options;
    const { statusFilter, threshold } = this.state;
    if (!cellData) return <div></div>;
    let category = 'Good';
    const value = parseInt(cellData);
    if (value === 0) {
      category = 'Critical';
    }
    if (value > 0 && value < threshold) {
      category = 'Warning';
    }
    let disabled = false;
    if (statusFilter === STATUS_PASSED && value < threshold) {
      disabled = true;
    }
    if (
      statusFilter === STATUS_NOT_PASSED &&
      (value >= threshold || value === 0)
    ) {
      disabled = true;
    }
    if (statusFilter === STATUS_NOT_RECEIVED && value !== 0) {
      disabled = true;
    }
    if (
      statusFilter === STATUS_NOT_REQUIRED &&
      cellData !== STATUS_NOT_REQUIRED
    ) {
      disabled = true;
    }

    if (cellData === STATUS_NOT_REQUIRED) {
      disabled = !(
        statusFilter === STATUS_NOT_REQUIRED || statusFilter === STATUS_ALL
      );
      return (
        <Cell
          onClick={() => {}}
          pressed={true}
          disabled={disabled}
          cellData={cellData}
          column={column}
          rowData={rowData}
          unit=""
          category={category}
        />
      );
    }

    return (
      <Cell
        onClick={this.onClickCell}
        pressed={false}
        disabled={disabled}
        cellData={cellData}
        column={column}
        rowData={rowData}
        unit="%"
        category={category}
      />
    );
  };

  onViewTagKeyChange = () => {
    this.setState((prevState) => {
      const sortBy = prevState.sortBy;
      if (sortBy.key === TAG_DISPLAY_NAME) {
        sortBy.key = TAG_KEY_NAME;
      } else if (sortBy.key === TAG_KEY_NAME) {
        sortBy.key = TAG_DISPLAY_NAME;
      }
      return { viewTagKey: !prevState.viewTagKey, sortBy };
    }, this.renderTable);
  };

  onTagKeyOrderChange = () => {
    const { sortBy } = this.state;
    const defaultSortBy = this.getDefaultSortBy();
    if (sortBy.key !== defaultSortBy.key) {
      this.setState({ sortBy: defaultSortBy }, this.renderTable);
    }
  };

  getDefaultSortBy = () => {
    const { viewTagKey } = this.state;
    const sortBy = { ...DEFAULT_SORT_BY };
    sortBy.key = viewTagKey ? TAG_KEY_NAME : TAG_DISPLAY_NAME;
    return sortBy;
  };

  onClickCell = ({ rowData, column }) => {
    console.log('cell click', rowData, column);
    const { appName, appVersion, dataKey } = column;
    const { tagKeyName, key, tagDisplayName } = rowData;
    const { endDate, startDate, isAd, contentType } = this.state;
    if (key === UI_METRIC_TYPE_SELECTOR_PLACEHOLDER) return;
    const params = {
      appName,
      appVersion,
      tagKeyName,
      contentType,
      endDate,
      startDate,
      isAd,
    };
    this.setState({
      sampleValueLoading: true,
      diffAppVersionCoverageLoading: true,
      sampleValueVisible: true,
      sampleValueTagDisplayName: tagDisplayName,
      sampleValueActiveTab: '1',
      tagSampleValue: [],
      diffAppVersionCoverage: [],
    });
    fetchSampleValue(params)
      .then((res) => {
        this.setState({ tagSampleValue: res.data, sampleValueLoading: false });
      })
      .catch((error) => {
        this.setState({ tagSampleValue: [], sampleValueLoading: false });
        console.error(error);
      });
    if (dataKey !== ALL_APPS_DATA_KEY) {
      fetchDiffAppVersionCoverage(params)
        .then((res) => {
          this.setState({
            diffAppVersionCoverage: res.data,
            diffAppVersionCoverageLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            diffAppVersionCoverage: [],
            diffAppVersionCoverageLoading: false,
          });
          console.error(error);
        });
    } else {
      this.setState({
        diffAppVersionCoverage: [],
        diffAppVersionCoverageLoading: false,
      });
    }
  };

  onTagSearch = (tagSearchStr) => {
    this.setState({ tagSearchStr }, this.renderTable);
  };

  onMetricTypeChange = (metricType) => {
    console.log('metric type changed', metricType);
    this.setState({ metricType }, this.getData);
  };

  onProductChange = (productClassify) => {
    console.log('product changed', productClassify);
    this.setState({ productClassify }, this.getData);
  };

  onTagsClassifyChange = (classifyKey) => {
    this.setState(
      { tagClassify: classifyKey, tagSearchStr: '' },
      this.renderTable,
    );
  };

  onColumnSort = (sort) => {
    const sortKey = sort.key;
    const order = sort.order;
    this.setState({ sortBy: { key: sortKey, order } }, this.renderTable);
  };

  onStatusFilterChange = (statusFilter) => {
    this.setState({ statusFilter }, this.renderTable);
  };

  buildStatusItem = (item) => {
    const statusDefault = {
      boxSizing: 'border-box',
      height: '15.5px',
      width: '9.5px',
      borderRadius: '2px',
      marginRight: '5px',
    };
    const styles = {
      [STATUS_PASSED]: {
        backgroundColor: '#D8D8D8',
      },
      [STATUS_NOT_PASSED]: {
        border: '1.5px solid #F5A623',
      },
      [STATUS_NOT_RECEIVED]: {
        border: '1.5px solid #D0021B',
        backgroundColor: '#F46F6F',
      },
      [STATUS_NOT_REQUIRED]: {
        border: '1.5px solid #DADADA',
        backgroundColor: '#F6F6F6',
      },
    };
    const itemStyle = styles[item.key] || {};
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={item.key}>
        <div style={{ ...statusDefault, ...itemStyle }}></div>
        {item.displayValue}
      </div>
    );
  };

  onDatePickerChange = (dates, dateStrings) => {
    this.setState(
      {
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      },
      () => {
        this.getData();
        this.getAppsData();
      },
    );
  };

  renderTable = () => {
    const {
      response,
      tagClassify,
      startDate,
      endDate,
      metricType,
      isAd,
      contentType,
      productClassify,
      threshold,
      statusFilter,
      tagSearchStr,
      sortBy,
      viewTagKey,
      modalContentType,
      modalIsAd,
      apps,
    } = this.state;
    this.putStateToUrl({
      startDate,
      endDate,
      metricType,
      isAd,
      contentType,
      tagClassify,
      productClassify,
      threshold,
      statusFilter,
      tagSearchStr,
      sortBy: JSON.stringify(sortBy),
      viewTagKey,
      modalContentType,
      modalIsAd,
      selectedApps: this.encodeAppList(this.getCheckedApps(apps)),
    });
    const tableDataSource = cloneDeep(response);
    const { appsCoverage, tagsCoverage } = tableDataSource;

    // Filter data by status.
    let filteredTagsCoverage = tagsCoverage;
    if (statusFilter === STATUS_NOT_RECEIVED) {
      filteredTagsCoverage = tagsCoverage.filter((tag) => {
        return tag.apps.some(
          (item) =>
            item.appNameAlias && item.value && parseFloat(item.value) === 0,
        );
      });
    }

    if (statusFilter === STATUS_NOT_REQUIRED) {
      filteredTagsCoverage = tagsCoverage.filter((tag) => {
        return tag.apps.some(
          (item) =>
            item.appNameAlias &&
            item.value &&
            item.value === STATUS_NOT_REQUIRED,
        );
      });
    }

    if (statusFilter === STATUS_NOT_PASSED) {
      filteredTagsCoverage = tagsCoverage.filter((tag) => {
        return tag.apps.some(
          (item) =>
            item.appNameAlias &&
            item.value &&
            parseFloat(item.value) < threshold &&
            parseFloat(item.value) !== 0,
        );
      });
    }

    if (statusFilter === STATUS_PASSED) {
      filteredTagsCoverage = tagsCoverage.filter((tag) => {
        return tag.apps.some(
          (item) =>
            item.appNameAlias &&
            item.value &&
            parseFloat(item.value) > threshold,
        );
      });
    }

    // Sort data
    // Alphabetical order first use appNameAlias + appNameVersion when the value is same.
    // Sort by value
    const [firstColumnCoverage, ...needSortApps] = appsCoverage;
    let sortedAppsCoverage: any = [];
    sortedAppsCoverage = _sortBy(needSortApps, (o) => this.getAppKey(o));
    sortedAppsCoverage = _sortBy(sortedAppsCoverage, (o) =>
      parseFloat(o.value),
    ).reverse();

    tableDataSource.appsCoverage = [firstColumnCoverage, ...sortedAppsCoverage];
    tableDataSource.tagsCoverage = [...filteredTagsCoverage];
    const data = this.buildTableData(tableDataSource);
    const cols = this.buildTableCols(tableDataSource);

    const frozenData = data.slice(0, 1);
    let tableData = data.slice(1);
    if (this.state.tagClassify !== '99' && !this.state.tagSearchStr) {
      const intSelectedTagClassify = parseInt(this.state.tagClassify);
      tableData = tableData.filter(
        (item) => item.tagType === intSelectedTagClassify,
      );
    }
    if (this.state.tagSearchStr) {
      tableData = tableData.filter((item) => {
        const text = this.state.tagSearchStr.toLowerCase();
        return (
          item.key.toLowerCase().indexOf(text) > -1 ||
          item.tagDisplayName.toLowerCase().indexOf(text) > -1
        );
      });
    }

    const sortKey = sortBy.key;
    let tableDataSorted: any[] = [];
    if (sortKey !== TAG_KEY_NAME && sortBy.key !== TAG_DISPLAY_NAME) {
      tableDataSorted = _sortBy(tableData, (o) => parseInt(o[sortKey]));
      if (sortBy.order === ORDER_DESC) {
        tableDataSorted = tableDataSorted.reverse();
      }
    } else {
      tableDataSorted = _sortBy(tableData, (o) => o[sortKey].toLowerCase());
    }

    this.setState({ data: tableDataSorted, cols, frozenData });
    console.log('render table', { tableDataSorted, cols, frozenData });
  };

  handleRemoveAppItem = (itemKey) => {
    const { appsInEdit } = this.state;
    const result: any[] = appsInEdit.map((app) => {
      const appKey = this.getAppKey(app);
      if (appKey === itemKey) app.checked = false;
      return app;
    });
    this.setState({ appsInEdit: result });
  };

  handleSelectAppInTop = (list) => {
    if (list.length > APPS_LIMIT) return this.overAppsLimit();
    const { appsInEdit } = this.state;
    const newState = appsInEdit.map((app) => {
      const key = this.getAppKey(app);
      app.checked = list.includes(key);
      return app;
    });
    this.setState({ appsInEdit: newState });
  };

  handleSelectAppItem = (record, event) => {
    const { appsInEdit } = this.state;
    const checked = event.target.checked;
    if (checked) {
      if (this.getCheckedApps(appsInEdit).length >= APPS_LIMIT) {
        return this.overAppsLimit();
      }
    }
    const newState = [...appsInEdit];
    const index = appsInEdit.indexOf(record);
    newState[index].checked = checked;
    this.setState({ appsInEdit: newState });
  };

  handleSelectAllApps = (event, allAppsList) => {
    const { appsInEdit } = this.state;
    const checked = event.target.checked;
    if (checked && this.getCheckedApps(appsInEdit).length >= APPS_LIMIT) {
      return this.overAppsLimit();
    }
    const newState = appsInEdit.map((app) => {
      if (allAppsList.includes(app)) {
        app.checked = checked;
      }
      return app;
    });
    this.setState({ appsInEdit: newState });
  };

  clearWaring = () => {
    AntdMessage.destroy();
  };
  overAppsLimit = () => {
    console.log('over limit');
    AntdMessage.warning({
      content: (
        <span>
          {LIMIT_MESSAGE}
          <Icon
            style={{ color: '#4a4a4a', cursor: 'pointer', marginLeft: '10px' }}
            onClick={this.clearWaring}
            type="close"
          />
        </span>
      ),
      duration: 0,
      className: 'pre-mds-warning',
      key: 'pre-mds-warning-message',
    });
  };

  getAppKey = (app) => {
    const { appName, appVersion, appType } = app;
    return `${appName}${appVersion}${appType}`;
  };

  getCheckedApps = (apps: any = []) => {
    return apps.filter((app) => app.checked);
  };

  getCheckedAppsKeys = (apps: any = []) => {
    const arry = this.getCheckedApps(apps);
    return arry.map((app) => this.getAppKey(app));
  };

  onContentTypeChange = (contentType) => {
    this.setState({ modalContentType: contentType }, () => {
      this.getAppsData(contentType, this.state.modalIsAd);
    });
  };

  onIsAdChange = (isAd) => {
    this.setState({ modalIsAd: isAd }, () => {
      this.getAppsData(this.state.modalContentType, isAd);
    });
  };

  onActionsChange = (action) => {
    if (action === 'threshold') {
      this.setState({
        thresholdVisible: true,
        thresholdInput: this.state.threshold,
      });
    } else if (action === 'export') {
      this.handleExportData();
    }
  };

  getAppAliasKey = (app) => {
    const { appNameAlias, appVersionAlias, appType } = app;
    return `${appNameAlias}${appVersionAlias}${appType}`;
  };

  handleExportData = () => {
    const _that = this;
    const { startDate, endDate, metricType, tagsClassifyOptions } = this.state;
    const { customerName } = this.props.userInfo.customerBaseInfo;
    // const currentTime = moment().format("dddd, MMMM Do YYYY, h:mm:ss a");
    const currentTime = moment().format();
    const dateRangeFormat = `${moment(startDate).format(
      'MMM DD YYYY',
    )} - ${moment(endDate).format('MMM DD YYYY')}`;
    const fileNameSuffix = `${moment(startDate).format('YYYYMMDD')}-${moment(
      endDate,
    ).format('YYYYMMDD')}`;
    const csvFileName = `metadata-quality-report-${fileNameSuffix}`;
    const csvDefaultRows = [
      { c1: 'Report generated by Conviva Pulse.' },
      { c1: "This report must be used in conformance with customer's." },
      { c1: 'Subscription Agreement & Data Policy with Conviva.' },
      { c1: `Report generated on ${currentTime}` },
      { c1: 'Copyright 2021 Conviva Inc.' },
      { c1: 'Conviva Confidential' },
      { c1: `c3 Account: ${customerName}` },
      { c1: `Date Range: ${dateRangeFormat}` },
      { c1: `Metrics: ${METRIC_TYPE_MAP[metricType]}` },
      { c1: '' },
    ];
    const baseParams = {
      startDate,
      endDate,
      contentType: CONTENT_TYPE_ALL,
      isAd: IS_AD_ALL,
    };

    const addSymbolToValue = (value) => {
      const sym = '%';
      if (value === STATUS_NOT_REQUIRED) return value;
      if (value) return `${value}${sym}`;
      if (!value) return value;
    };

    this.setState(() => ({ loading: true }));
    fetchPreMdsAppsData(baseParams).then((data) => {
      const allApps = data.data;
      fetchPreMdsData({ ...baseParams, metricType, apps: allApps }).then(
        (res) => {
          const { appsCoverage, tagsCoverage } = res.data;
          let sortedAppsCoverage = _sortBy(appsCoverage, function (o) {
            return o.appNameAlias + o.appVersionAlias;
          });
          sortedAppsCoverage = _sortBy(sortedAppsCoverage, function (o) {
            return parseFloat(o.value);
          }).reverse();
          // Generate All Apps row
          const allAppsNameRow = {
            c1: 'All Apps',
            c2: '',
            c3: '',
            c4: 'All Apps',
          };
          const allAppsDataRow = {
            c1: '',
            c2: '',
            c3: `${METRIC_TYPE_MAP[metricType]}`,
          };
          const tagsNameRow = {
            c1: 'Tag Display Name',
            c2: 'Tag Key',
            c3: 'Tag Type',
            c4: 'All Apps',
          };
          sortedAppsCoverage.forEach((app) => {
            const columnKey = _that.getAppAliasKey(app);
            const columnValue = _that.getAppKey(app);
            const key = columnKey === 'nullnullnull' ? 'c4' : columnKey;
            if (columnKey !== 'nullnullnull') {
              allAppsNameRow[columnKey] = columnValue;
              tagsNameRow[key] = columnValue;
            }
            allAppsDataRow[key] = addSymbolToValue(app.value);
          });
          // Generate metadata tag rows
          const tagsClassifyMap = {};
          tagsClassifyOptions.forEach(
            (item) => (tagsClassifyMap[item.key] = item.value),
          );
          const tagRows = tagsCoverage.map((tag) => {
            // Tag info column
            const rowData = {
              c1: tag.tagDisplayName,
              c2: tag.tagKeyName,
              c3: tagsClassifyMap[tag.tagType] || '',
            };
            // Apps column
            tag.apps.forEach((app) => {
              let key = _that.getAppAliasKey(app);
              key = key === 'nullnullnull' ? 'c4' : key;
              rowData[key] = addSymbolToValue(app.value);
            });
            return rowData;
          });
          // Merge rows
          const rows = [
            ...csvDefaultRows,
            allAppsNameRow,
            allAppsDataRow,
            { c1: 'Metadata Tag' },
            tagsNameRow,
            ...tagRows,
          ];
          const json2csvParser = new Parser({ header: false });
          const csv = json2csvParser.parse(rows);
          downloadCSV(csv, csvFileName);
          this.setState(() => ({ loading: false }));
        },
      );
    });
  };

  onThresholdCancel = () => {
    this.setState({
      thresholdVisible: false,
      thresholdInput: this.state.threshold,
    });
  };

  onThresholdApply = () => {
    this.setState(
      { thresholdVisible: false, threshold: this.state.thresholdInput },
      this.renderTable,
    );
  };

  onThresholdChange = (value) => {
    this.setState({ thresholdInput: value });
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
      appsActiveTab: '1',
      appsInEdit: cloneDeep(this.state.apps),
    });
  };

  onCancel = () => {
    const { contentType, isAd } = this.state;
    this.clearWaring();
    this.setState({
      modalVisible: false,
      modalContentType: contentType,
      modalIsAd: isAd,
    });
  };

  onApply = () => {
    const { appsInEdit, modalContentType, modalIsAd } = this.state;
    const checkedApps = this.getCheckedApps(appsInEdit);
    if (checkedApps.length > APPS_LIMIT) {
      return this.overAppsLimit();
    }
    this.clearWaring();
    this.setState(
      {
        modalVisible: false,
        apps: cloneDeep(appsInEdit),
        contentType: modalContentType,
        isAd: modalIsAd,
      },
      this.getData,
    );
  };

  getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <AntInput
            ref={(node) => {
              // @ts-ignore
              this.searchInput = node;
            }}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8, fontSize: 14 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90, fontSize: 14 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              type="search"
              style={{
                fontSize: '20px',
                color: this.state.tableSearchText ? '#1890ff' : '#4A4A4A',
              }}
            />
          </div>
        );
      },
      onFilter: (value, record) => {
        const text = record.appName + ' ' + record.appVersion;
        return text.toString().toLowerCase().includes(value.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // @ts-ignore
          setTimeout(() => this.searchInput.select());
        }
      },
      render: (record) => {
        const text = `${record.appName}${' '}${record.appVersion}`;
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.tableSearchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        );
      },
    };
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      tableSearchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ tableSearchText: '' });
  };

  render() {
    // if (this.props.ruleEngine.activeBusinessParcel.reVersion === VERSION_2) {
    //   return <Redirect to="/rule-engine/mapping-rules" />;
    // }
    const {
      data,
      preMdsEnabled,
      frozenData,
      cols,
      sortBy,
      loading,
      theme,
      modalVisible,
      appsLoading,
      appsInEdit,
      thresholdVisible,
      threshold,
      sampleValueVisible,
      sampleValueLoading,
      tagSampleValue,
      startDate,
      endDate,
      sampleValueTagDisplayName,
      sampleValueActiveTab,
      appsActiveTab,
      rangePickerStartDate,
      rangePickerEndDate,
      noDataDates,
      modalContentType,
      modalIsAd,
      diffAppVersionCoverageLoading,
      diffAppVersionCoverage,
      statusFilter,
    } = this.state;
    // apps table data
    const appsTableList = [...appsInEdit];

    let appsTableListAfterFilter = appsTableList.filter((app) => {
      const key = this.getAppKey(app);
      return key
        .toString()
        .toLowerCase()
        .includes(this.state.tableSearchText.toLowerCase());
    });
    if (appsActiveTab === '2') {
      appsTableListAfterFilter = appsInEdit.filter(
        (app) => app.appType === APP_TYPE_LIBRARY,
      );
    } else if (appsActiveTab === '3') {
      appsTableListAfterFilter = appsInEdit.filter(
        (app) => app.appType === APP_TYPE_SDK,
      );
    }

    // apps selector in the top
    const appsSelectorOptions = appsTableList.map((app) => {
      const key = this.getAppKey(app);
      const { appName, appVersion } = app;
      return (
        <Option key={key}>
          {appName} {appVersion}
        </Option>
      );
    });
    const appsSelectorValues = this.getCheckedAppsKeys(appsTableList);

    const isAllChecked =
      appsTableListAfterFilter.length > 0
        ? appsTableListAfterFilter.every((item) => item.checked)
        : appsTableList.every((item) => item.checked) &&
          appsTableList.length > 0;
    const indeterminate =
      appsTableList.some((item) => item.checked) && !isAllChecked;

    const columns: ColumnProps<any>[] = [
      {
        key: 'checkBox',
        title: (
          <Checkbox
            checked={isAllChecked}
            onChange={(e) => {
              this.handleSelectAllApps(e, appsTableListAfterFilter);
            }}
            indeterminate={indeterminate}
            theme={theme}
          ></Checkbox>
        ),
        render: (record) => {
          const checked = record.checked;
          return (
            <Checkbox
              checked={checked}
              onChange={(e) => {
                this.handleSelectAppItem(record, e);
              }}
              indeterminate={record.indeterminate}
              theme={theme}
            ></Checkbox>
          );
        },
        width: 35,
      },
      {
        key: 'appName',
        title: 'Apps',
        ...this.getColumnSearchProps('appName'),
        width: 230,
      },
      {
        key: 'appType',
        title: 'Sensor Type',
        dataIndex: 'appType',
        width: 120,
        filtered: true,
        align: 'center',
      },
      {
        key: 'playsPercent',
        title: 'Number of Plays',
        dataIndex: 'playsPercent',
        width: 150,
        sorter: (a, b) => a.playsPercent - b.playsPercent,
        render: (plays) => (
          <div className="tableCell">
            {plays}
            {plays || plays === 0 ? '%' : ''}
          </div>
        ),
      },
      {
        key: 'minutesPercent',
        title: 'Number of Minutes',
        dataIndex: 'minutesPercent',
        width: 150,
        sorter: (a, b) => a.minutesPercent - b.minutesPercent,
        render: (mins) => (
          <div className="tableCell">
            {mins}
            {mins || mins === 0 ? '%' : ''}
          </div>
        ),
      },
    ];
    const appsTable = (
      <Table
        scroll={{ y: 320 }}
        columns={columns}
        rowKey={(record) => this.getAppKey(record)}
        dataSource={appsTableListAfterFilter}
        pagination={false}
      />
    );

    const footerButtons = appsLoading
      ? []
      : [
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              key="cancel"
              category="apps-dialog-cancel"
              theme={theme}
              onClick={this.onCancel}
            >
              Cancel
            </Button>
            <Button
              key="apply"
              data-test-id="apps-dialog-apply"
              theme={theme}
              onClick={this.onApply}
            >
              Apply
            </Button>
          </div>,
        ];

    const thresholdButtons = [
      <Button
        key="cancel"
        category="secondary"
        theme={theme}
        onClick={this.onThresholdCancel}
      >
        Cancel
      </Button>,
      <Button key="apply" theme={theme} onClick={this.onThresholdApply}>
        Apply
      </Button>,
    ];
    const coverageStatusFilterItems = [
      { key: STATUS_ALL, displayValue: 'All Metadata Coverage' },
      { key: STATUS_PASSED, displayValue: 'Passed' },
      { key: STATUS_NOT_PASSED, displayValue: 'Coverage not passed' },
      { key: STATUS_NOT_RECEIVED, displayValue: 'Value not received' },
      { key: STATUS_NOT_REQUIRED, displayValue: 'Not applicable' },
    ];
    const showTable =
      startDate && (data.length || frozenData[0]?.apps?.[0]?.value !== '');
    return (
      <Spin spinning={loading}>
        <ContentWrapper theme={theme}>
          {showTable ? (
            <TopBarWrapper>
              <div style={{ marginRight: '10px' }} data-test-id="add-apps-btn">
                <Button
                  category="secondary"
                  theme={theme}
                  onClick={this.showModal}
                >
                  Add Apps
                </Button>
              </div>
              <StatusSelectorWrapper data-test-id="coverage-status-selector">
                <SingleSelectDropdownV2
                  items={coverageStatusFilterItems}
                  type={Category.Button}
                  theme={theme}
                  itemRenderer={this.buildStatusItem}
                  text={this.buildStatusItem(
                    coverageStatusFilterItems.filter(
                      (item) => `${item.key}` === statusFilter,
                    )[0],
                  )}
                  onApply={this.onStatusFilterChange}
                />
              </StatusSelectorWrapper>
              <RangePicker
                value={
                  startDate && endDate
                    ? [moment(startDate), moment(endDate)]
                    : [null, null]
                }
                format={dateFormat}
                onChange={this.onDatePickerChange}
                renderExtraFooter={() =>
                  preMdsEnabled ? null : (
                    <div style={{ lineHeight: '22px' }}>
                      Please enable the Meta Data Quality Report in Pulse Portal
                      to fetch the most up-to-date data.
                    </div>
                  )
                }
                disabledDate={(current: any) => {
                  if (!startDate || !endDate) {
                    return true;
                  }
                  return (
                    current &&
                    (current < moment(rangePickerStartDate) ||
                      current > moment(rangePickerEndDate) ||
                      noDataDates.includes(moment(current).format(dateFormat)))
                  );
                }}
              />
              <div data-test-id="action-btn" style={{ marginLeft: '15px' }}>
                <SingleSelectDropdownV2
                  theme={theme}
                  items={[
                    { key: 'threshold', displayValue: 'Threshold' },
                    { key: 'export', displayValue: 'Export all data as .CSV' },
                  ]}
                  type={Category.Kebab}
                  onApply={this.onActionsChange}
                />
              </div>
            </TopBarWrapper>
          ) : null}
          {showTable ? (
            <MetricTable
              frozenData={frozenData}
              data={data}
              columns={cols}
              sortBy={sortBy}
              onColumnSort={this.onColumnSort}
            />
          ) : loading ? null : (
            <WarningText>
              Coverage reports are not available, please make sure you enable
              the Meta Data Quality Report in Pulse Portal and wait until data
              is ready (usually needs a few hours).
            </WarningText>
          )}

          {/* Apps Modal */}
          <Modal
            closable={false}
            visible={modalVisible}
            onCancel={this.onCancel}
            theme={theme}
            footer={footerButtons}
            width="1000px"
            bodyStyle={{ paddingBottom: '20px' }}
            destroyOnClose
          >
            <ModalWrapper theme={theme}>
              <Spin spinning={appsLoading}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={LIMIT_MESSAGE}
                  onChange={this.handleSelectAppInTop}
                  onDeselect={this.handleRemoveAppItem}
                  value={appsSelectorValues}
                >
                  {appsSelectorOptions}
                </Select>
                <FilterWrapper>
                  <div className="filter-label">Traffic Type</div>
                  <Button
                    data-test-id="traffic-type-all-traffic"
                    onClick={() => {
                      this.onContentTypeChange(CONTENT_TYPE_ALL);
                    }}
                    theme={theme}
                    category={
                      modalContentType === CONTENT_TYPE_ALL ? '' : 'secondary'
                    }
                  >
                    All Traffic
                  </Button>
                  <Button
                    data-test-id="traffic-type-live"
                    onClick={() => {
                      this.onContentTypeChange(CONTENT_TYPE_LIVE);
                    }}
                    theme={theme}
                    category={
                      modalContentType === CONTENT_TYPE_LIVE ? '' : 'secondary'
                    }
                  >
                    LIVE
                  </Button>
                  <Button
                    data-test-id="traffic-type-vod"
                    onClick={() => {
                      this.onContentTypeChange(CONTENT_TYPE_VOD);
                    }}
                    theme={theme}
                    category={
                      modalContentType === CONTENT_TYPE_VOD ? '' : 'secondary'
                    }
                  >
                    VOD
                  </Button>
                </FilterWrapper>
                <RowDivider theme={theme} />
                <FilterWrapper>
                  <div className="filter-label">Session Type</div>
                  <Button
                    data-test-id="session-type-all-sessions"
                    onClick={() => {
                      this.onIsAdChange(IS_AD_ALL);
                    }}
                    theme={theme}
                    category={modalIsAd === IS_AD_ALL ? '' : 'secondary'}
                  >
                    All Sessions
                  </Button>
                  <Tooltip theme={theme} title="isAd NOT EQUALS T">
                    <Button
                      data-test-id="session-type-content"
                      onClick={() => {
                        this.onIsAdChange(IS_AD_F);
                      }}
                      theme={theme}
                      category={modalIsAd === IS_AD_F ? '' : 'secondary'}
                    >
                      Content
                    </Button>
                  </Tooltip>
                  <Tooltip theme={theme} title="isAd EQUALS T">
                    <Button
                      data-test-id="session-type-ad"
                      onClick={() => {
                        this.onIsAdChange(IS_AD_T);
                      }}
                      theme={theme}
                      category={modalIsAd === IS_AD_T ? '' : 'secondary'}
                    >
                      Ad
                    </Button>
                  </Tooltip>
                </FilterWrapper>
                <TabsWrapper>
                  <Tabs
                    tabPosition="left"
                    className={'saved-filter-panel'}
                    onChange={(key) => {
                      this.setState({ appsActiveTab: key });
                    }}
                    activeKey={appsActiveTab}
                  >
                    <Tabs.TabPane tab="All Apps" key={'1'}>
                      {appsTable}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={APP_TYPE_LIBRARY} key={'2'}>
                      {appsTable}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={APP_TYPE_SDK} key={'3'}>
                      {appsTable}
                    </Tabs.TabPane>
                  </Tabs>
                </TabsWrapper>
              </Spin>
            </ModalWrapper>
          </Modal>
        </ContentWrapper>
        {/*Threshold Modal*/}
        <Modal
          visible={thresholdVisible}
          onCancel={this.onThresholdCancel}
          theme={theme}
          width="400px"
          title="Threshold"
          footer={thresholdButtons}
        >
          <div style={{ display: 'flex' }}>
            Threshold Settings for Apps
            <InputNumber
              defaultValue={threshold}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              style={{ width: '70px', marginLeft: '10px' }}
              onChange={this.onThresholdChange}
              value={this.state.thresholdInput}
            />
          </div>
        </Modal>
        {/*sample value modal*/}
        <Modal
          visible={sampleValueVisible}
          onCancel={() => {
            this.setState({ sampleValueVisible: false });
          }}
          theme={theme}
          width="600px"
          title="Metadata information"
          footer={[]}
        >
          <ModalWrapper>
            <Spin
              spinning={sampleValueLoading || diffAppVersionCoverageLoading}
            >
              <Tabs
                type="card"
                defaultActiveKey="1"
                activeKey={sampleValueActiveTab}
                onChange={(key) => {
                  this.setState({ sampleValueActiveTab: key });
                }}
              >
                <Tabs.TabPane tab="Value distribution" key={'1'}>
                  <TabPaneHeader>
                    <div
                      style={{ width: '69%' }}
                      className="text-cut"
                      title={sampleValueTagDisplayName}
                    >
                      {sampleValueTagDisplayName}
                    </div>
                    <div
                      style={{ width: '30%', textAlign: 'right' }}
                      className="text-cut"
                    >
                      Traffic Percentage
                    </div>
                  </TabPaneHeader>
                  <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                    {tagSampleValue.map((item) => (
                      <SampleValueItem key={item.tagValue + item.valueCoverage}>
                        {item.tagValue === TAG_VALUE_NULL ? (
                          <div
                            style={{
                              width: '69%',
                              fontStyle: 'italic',
                              fontWeight: 'bold',
                            }}
                            className="text-cut"
                            title={item.tagValue}
                          >
                            {item.tagValue}
                          </div>
                        ) : (
                          <div
                            style={{ width: '69%' }}
                            className="text-cut"
                            title={item.tagValue}
                          >
                            {item.tagValue}
                          </div>
                        )}
                        <div
                          style={{ width: '30%', textAlign: 'right' }}
                          className="text-cut"
                        >
                          {item.valueCoverage < 0.01
                            ? '< 0.01'
                            : item.valueCoverage}
                          %
                        </div>
                      </SampleValueItem>
                    ))}
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tag details" key={'2'}>
                  {diffAppVersionCoverage.map((item) => (
                    <TagDetailsItemBox>
                      <TagDetailsItem>
                        <div className="tag-details-item-label">Tag Name</div>
                        <div className="tag-details-item-value">
                          {item.tagDisplayName || item.tagKeyName}
                        </div>
                      </TagDetailsItem>
                      <TagDetailsItem>
                        <div className="tag-details-item-label">
                          Coverage Passed
                        </div>
                        <div className="tag-details-item-value">
                          {item.value}%
                        </div>
                      </TagDetailsItem>
                      <TagDetailsItem>
                        <div className="tag-details-item-label">App name</div>
                        <div className="tag-details-item-value">
                          {item.appName + ' ' + item.appVersion}
                        </div>
                      </TagDetailsItem>
                      <TagDetailsItem>
                        <div className="tag-details-item-label">
                          Collected by
                        </div>
                        <div className="tag-details-item-value">
                          {item.appType}
                        </div>
                      </TagDetailsItem>
                    </TagDetailsItemBox>
                  ))}
                </Tabs.TabPane>
              </Tabs>
            </Spin>
          </ModalWrapper>
        </Modal>
      </Spin>
    );
  }
}

const selector = createSelector(
  systemSelector,
  userInfoSelector,
  ruleEngineSelector,
  (mds, userInfo, ruleEngine) => {
    return {
      mds,
      userInfo,
      ruleEngine,
    };
  },
);

export default connect(selector, { updateUrlQueryParams })(Coverage);
