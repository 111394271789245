import React from 'react';
import { Button, Divider, Modal, Spin } from 'antd';
import { closeModal } from '@src/pages/RuleEngine/layouts/modals/index';
import { useAppSelector } from '@src/store/hooks';
import {
  PERMISSION_ACCESS_DATA_MODEL,
  PERMISSION_APPROVE_RULE,
  VERSION_1,
} from '@src/utils/constants';
import { useUserInfo } from '@src/service/apis/system';
import {
  useClenIdConfigs,
  useRawIdConfigs,
} from '@src/service/apis/ruleEngine/ruleEngine';

export const MODAL_KEY = 'ADMIN_PANEL_MODAL_KEY';

const AdminPanelModal: React.FC = (props) => {
  const { data: userInfo, isLoading } = useUserInfo();
  const { data: clenIds, isLoading: clenIdLoading } = useClenIdConfigs();
  const { data: rawIds, isLoading: rawIdLoading } = useRawIdConfigs();
  const state = useAppSelector((state) => {
    return {
      visibleKey: state.modal.visibleKey,
      activeBusinessParcel: state.ruleEngine.activeBusinessParcel,
    };
  });

  const { visibleKey, activeBusinessParcel } = state;

  return (
    <Modal
      visible={visibleKey === MODAL_KEY}
      onCancel={() => closeModal(MODAL_KEY)}
      footer={[]}
      width={800}
      title="Admin Panel"
    >
      <Spin spinning={isLoading || clenIdLoading || rawIdLoading}>
        {userInfo.permissions.includes(PERMISSION_APPROVE_RULE) &&
        activeBusinessParcel?.reVersion === VERSION_1 ? (
          <>
            <h4>CleanId Keys:</h4>
            <p>{clenIds?.idKeys?.join(', ')}</p>
            <h4>RawId Keys:</h4>
            <p>{rawIds?.idKeys?.join(', ')}</p>
            <Divider />
          </>
        ) : null}
        {userInfo.permissions.includes(PERMISSION_ACCESS_DATA_MODEL) ? (
          <Button type="primary">
            <a href="/app/mds-portal">Access Data Model Portal</a>
          </Button>
        ) : null}
      </Spin>
    </Modal>
  );
};

export default AdminPanelModal;
