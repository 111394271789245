export const RESET_GAP_ANALYSIS_STATE = 'RESET_GAP_ANALYSIS_STATE';
export const UPDATE_GAP_ANALYSIS_REPORTS = `UPDATE_GAP_ANALYSIS_REPORTS`;
export const UPDATE_HISTORICAL_JOB = 'UPDATE_HISTORICAL_JOB';
export const UPDATE_HISTORICAL_JOB_PAGINATION =
  'UPDATE_HISTORICAL_JOB_PAGINATION';
export const UPDATE_LATEST_JOB = 'UPDATE_LATEST_JOB';
export const UPDATE_CURRENT_JOB = 'UPDATE_CURRENT_JOB';
export const UPDATE_CONDITION_FILTER_LIST = 'UPDATE_CONDITION_FILTER_LIST';
export const UPDATE_CONDITION_RESULT_LIST = 'UPDATE_CONDITION_RESULT_LIST';
export const UPDATE_COVERAGE_RESULT_LIST = 'UPDATE_COVERAGE_RESULT_LIST';
export const UPDATE_CURRENT_RESULT_LIST = 'UPDATE_CURRENT_RESULT_LIST';
export const UPDATE_COVERAGE_FILTER_FIELDS = 'UPDATE_COVERAGE_FILTER_FIELDS';
export const UPDATE_SELECTED_FILTER_FIELDS = 'UPDATE_SELECTED_FILTER_FIELDS';
export const UPDATE_CURRENT_FILTER_FIELDS = 'UPDATE_CURRENT_FILTER_FIELDS';
export const UPDATE_COVERAGE_MARK = 'UPDATE_COVERAGE_MARK';
export const UPDATE_FILTER_MARK = 'UPDATE_FILTER_MARK';
export const RESET_CONDITION_AND_COVERAGE = 'RESET_CONDITION_AND_COVERAGE';
