import { FormInstance } from 'rc-field-form';
import { NamePath, ValidateErrorEntity } from 'rc-field-form/lib/interface';

export function isNumber(val: any) {
  return (
    typeof val === 'number' && !isNaN(parseFloat(val + '')) && isFinite(val)
  );
}

export function isInteger(n: any) {
  return Number.isInteger(n);
}

export function isString(s: any) {
  return typeof s === 'string' || s instanceof String;
}

export function isDate(val: any) {
  return toString.call(val) === '[object Date]';
}

export function isFile(val: any) {
  return toString.call(val) === '[object File]';
}

export function isBlob(val: any) {
  return toString.call(val) === '[object Blob]';
}

export function isBuffer(obj: any) {
  return (
    obj != null &&
    obj.constructor != null &&
    typeof obj.constructor.isBuffer === 'function' &&
    obj.constructor.isBuffer(obj)
  );
}

export function isObject(val: any) {
  return val !== null && typeof val === 'object';
}
export function isPlainObject(obj: any) {
  const hasOwn = Object.prototype.hasOwnProperty;
  if (!obj || toString.call(obj) !== '[object Object]') {
    return false;
  }

  const hasOwnConstructor = hasOwn.call(obj, 'constructor');
  const hasIsPrototypeOf =
    obj.constructor &&
    obj.constructor.prototype &&
    hasOwn.call(obj.constructor.prototype, 'isPrototypeOf');
  // Not own constructor property must be Object
  if (obj.constructor && !hasOwnConstructor && !hasIsPrototypeOf) {
    return false;
  }

  // Own properties are enumerated firstly, so to speed up,
  // if last one is own, then all properties are own.
  let key;
  for (key in obj) {
    /**/
  }

  return typeof key === 'undefined' || hasOwn.call(obj, key);
}

/**
 * wrap form validate, return first error tip
 * @param form
 * @param nameList
 */
export async function validateFormWrapper(
  form: FormInstance,
  nameList?: NamePath[],
) {
  try {
    await form.validateFields(nameList);
  } catch (err) {
    // console.log('err');
    // console.log(err);
    if ((err as ValidateErrorEntity).hasOwnProperty('errorFields')) {
      const {
        errorFields: [{ errors }],
      } = err as ValidateErrorEntity;
      throw new Error(errors[0]);
    }
  }
}
