export enum EConfigTemplateCode {
  rawId = 'RAW_ID',
  cleanId = 'CLEAN_ID',
}
export enum EConfigTemplateType {
  auto = 'AUTO',
  custom = 'CUSTOM',
}
export interface IConfigTemplate {
  uuid?: string;
  pipelineConfigCode: EConfigTemplateCode;
  type: EConfigTemplateType;
  /** seralized string list for server side compatible */
  content: string;
  defaultContent: string;
}
