import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  duotoneLight,
  duotoneSpace,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import styled from "styled-components";
const CodeWrap = styled("div")`
  width: 100%;
  code,
  pre {
    white-space: pre-wrap !important;
  }
`;

function fn(props) {
  const obj = {
    light: duotoneLight,
    dark: duotoneSpace
  };
  const { showCode } = props
  return (
    showCode ?
    <CodeWrap>
      <SyntaxHighlighter language="json" style={obj.light}>
        {props.code}
      </SyntaxHighlighter>
    </CodeWrap>
    : null
  );
};

export const Code = fn
