export const colorMap = {
  backgroundColor: {
    light: "#ffffff",
    dark: "#17253a"
  },
  primaryNormal: {
    light: "#0046FF",
    dark: "#4FABF8"
  },
  primaryHover: {
    light: "#002a97",
    dark: "#9ED3FF"
  },
  primaryDisabled: {
    light: "rgba(0,70,255,0.4)",
    dark: "rgba(79,171,248,0.4)"
  },
  secondaryOneNormal: {
    light: "#686868",
    dark: "#CACACA"
  },
  secondaryOneHover: {
    light: "#4A4A4A",
    dark: "#F2F2F2"
  },
  secondaryOneDisabled: {
    light: "rgba(104,104,104,0.4)",
    dark: "rgba(202,202,202,0.4)"
  },
  secondaryBackDisabled: {
    light: "#F1F1F1",
    dark: "#293649"
  },
  bgHighlight: {
    light: "#E9F3FD",
    dark: "#253852"
  },
  highlight: {
    light: "#e9f3fd",
    dark: "#0b325e"
  },
  primarySelected: {
    light: "#0035BF",
    dark: "#1AACFF"
  },
  lineSeparator: {
    light: "#dadada",
    dark: "#4A4A4A"
  },
  positiveChange: {
    light: "#00A150",
    dark: "#00A150"
  },
  negativeChange: {
    light: "#D0021B",
    dark: "#FD2D46"
  },
  tooltip: {
    light: "#F2F2F2",
    dark: "#415277"
  },
  boxShadow: {
    light: "rgba(0,0,0,0.2)",
    dark: "rgba(0,0,0,0.2)"
  },
  tabNormal: {
    light: "#F6F6F6",
    dark: "#253852"
  },
  tabSelected: {
    light: '#1556FF',
    dark: '#2D79FF'
  },
  data: {
    light: "#000000",
    dark: "#ffffff"
  },
  text: {
    light: "#000",
    dark: "#fff"
  },
  grayText: {
    light: "#4A4A4A",
    dark: "#CACACA"
  },
  keyword: {
    light: "#054AFF",
    dark: "#4FABF8"
  },
  borderOne: {
    light: "#9B9B9B",
    dark: "#9B9B9B"
  },
  scopeAlpha: {
    light: 0.1,
    dark: 0.4
  },
  tileGoodBorder: {
    light: "#DADADA",
    dark: "#3D649A"
  },
  tileGoodFill:{
    light: "#EAEAEA",
    dark: "#344F74"
  },
  tileWarningBorder:{
    light: "#F99838",
    dark: "#F99838"
  },
  tileWarningFill:{
    light: "rgba(255,147,0,0.4)",
    dark: "#FF9300"
  },
  tileCriticalBorder:{
    light: "#D0021B",
    dark: "#D0021B"
  },
  tileCriticalFillNormal:{
    light: "rgba(236,15,15,0.6)",
    dark: "rgb(198,67,67)"
  },
  tileCriticalFillHover:{
    light: "rgba(236, 15, 15, 0.8)",
    dark: "#E30000"
  },
  white:{
    light: "#FFFFFF",
    dark: "#FFFFFF"
  },
  util:{
    light: "#1F1F1F",
    dark: "#FFFFFF"
  },
  sorter: {
    light: '#4A4A4A',
    dark: '#FFFFFF',
  },
  warningDescription: {
    light: '#FFEAE4',
    dark: '#611C24',
  },
  dropdownBackground: {
    light: '#fff',
    dark: '#0C1E34',
  },
  hoverColor: {
    light: '#4A4A4A',
    dark: '#fff'
  },
  subTabBackground: {
    light: '#fff',
    dark: '#142134'
  }
};

export const themes = ["light", "dark"];

export const acumenStyles = {fontExtraLight: 200, fontLight: 300, fontRegular: 400, fontSemibold: 600};
