import { TRuleEngineVersion } from '@src/typing/businessParcel';

export const PERMISSION_RELEASE_RULE = 'release_rule';

export const PERMISSION_APPROVE_RULE = 'approve_rule';

export const PERMISSION_ACCESS_DATA_MODEL = 'rule_data_model_access';

export const DRYRUN_JOB_STATUS_SUCCESS = 'success';

export const RULE_EVENT_SKIP = 'SKIP';

export const RULE_EVENT_SUBMIT = 'SUBMIT';

export const RULE_EVENT_APPROVE = 'APPROVE';

export const RULE_EVENT_REVERT = 'REJECT';

export const RULE_EVENT_RELEASE = 'RELEASE';

export const RULE_STATUS_INIT = 'INIT';

export const RULE_STATUS_READY = 'READY';

export const RULE_STATUS_APPROVED = 'APPROVED';

export const RULE_STATUS_RELEASED = 'RELEASED';

export const RULE_STATUS_REPLACED = 'REPLACED';

export const RULE_STATUS_RELEASING = 'RELEASING';

export const THEME_LIGHT = 'light';

export const VERSION_1: TRuleEngineVersion = 'V1';

export const VERSION_2: TRuleEngineVersion = 'V2';

export const BP_PARAMS_KEY = 'bp';

export const RULE_VERSION_PARAMS_KEY = 'rv';

export const PARENT_PATH_CONNECT_KEY = '@|@';

export const BASIC = 'BASIC';

export enum EReactQueryKey {
  BUSINESS_PARCEL_LIST = 'businessParcelList',
  DRY_RUN_LIST = 'dryRunList',
  DRY_RUN_REPORT = 'dryRunReport',
  DRY_RUN_REPORT_FIELD_DETAIL = 'dryRunReportFieldDetail',
}
