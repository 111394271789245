import styled from 'styled-components';
import { Button } from 'antd'

export const StyledTableWrapper = styled('div')`
    width: 100%;
    thead tr th {
        background: #fff;
        border-bottom-color: black;
        padding-left: 3px;
    }
    tbody tr td {
        padding-left: 3px;
    }
`;

export const ActionButton = styled(Button)`
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px !important;
    border: none !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
`;
