import React, { useEffect, useState } from 'react';
import { Modal, Table, Dropdown, Menu, Tooltip } from 'antd';
import {
  SyncOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import './index.less';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import { IJobData } from '@src/typing/gapAnalysis';
import {
  modifyUpdateCoverageMark,
  modifyUpdateFilterMark,
  resetConditionAndCoverage,
  updateCurrentJob,
} from '@src/store/actions/gapAnalysis';
import { QueryObserverBaseResult } from 'react-query/types/core/types';

const WIDTH = 100;
const ACTION_WIDTH = 50;

type refetch = QueryObserverBaseResult['refetch'];

interface IHistoricalReports {
  open: boolean;
  onCancel: () => void;
  isLoading: boolean;
  refetch: refetch;
}

const Index: React.FC<IHistoricalReports> = ({
  open,
  onCancel,
  isLoading,
  refetch,
}) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => {
    return {
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const { historicalJob } = gapAnalysis;

  const [jobDataSource, setJobDataSource] = useState<IJobData[]>([]);

  useEffect(() => {
    setJobDataSource(historicalJob);
  }, [historicalJob]);

  const columns = [
    {
      key: 'reportName',
      title: 'Name',
      dataIndex: 'reportName',
      width: WIDTH,
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      width: WIDTH,
    },
    {
      key: 'timePeriod',
      title: 'Time Period',
      dataIndex: 'timePeriod',
      width: WIDTH + 80,
    },
    {
      key: 'jobStatus',
      dataIndex: 'jobStatus',
      title: (
        <div>
          Status
          <SyncOutlined
            style={{ marginLeft: '5px', cursor: 'pointer' }}
            onClick={(evt) => {
              evt.preventDefault();
              refetch();
            }}
          />
        </div>
      ),
      render: (text, record) => {
        const { jobMessage } = record;
        if (text === 'FAILED') {
          return (
            <div>
              <span style={{ marginRight: '5px' }}>{text}</span>
              <Tooltip title={jobMessage}>
                <ExclamationCircleOutlined />
              </Tooltip>
            </div>
          );
        }
        return <span>{text}</span>;
      },
      width: WIDTH - 20,
    },
    {
      key: 'dataSourceName',
      title: 'Data Source Name',
      dataIndex: 'dataSourceName',
      width: WIDTH + 50,
    },
    {
      key: 'createUser',
      title: 'Creator',
      dataIndex: 'createUser',
      width: WIDTH,
    },
    {
      key: 'jobCreateTime',
      title: 'Create Time',
      dataIndex: 'jobCreateTime',
      width: WIDTH + 70,
    },
    {
      key: 'jobUpdateTime',
      title: 'Finish Time',
      dataIndex: 'jobUpdateTime',
      width: WIDTH + 70,
    },
    {
      key: 'action',
      render: (text, record) => {
        const disabled = record.jobStatus !== 'SUCCESS';
        const items = [
          {
            key: 'view',
            label: 'view',
            disabled: disabled,
          },
        ];
        const onClickMenu = () => {
          dispatch(updateCurrentJob(record));
          dispatch(resetConditionAndCoverage());
          dispatch(modifyUpdateCoverageMark(true));
          dispatch(modifyUpdateFilterMark(true));
          onCancel();
        };
        const menu = <Menu items={items} onClick={onClickMenu} />;
        return (
          <Dropdown overlay={menu}>
            <MoreOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
      width: ACTION_WIDTH,
    },
  ];

  return (
    <Modal
      title={'Historical reports'}
      visible={open}
      footer={null}
      width={1200}
      onCancel={onCancel}
    >
      <Table
        className="historical-report-table"
        columns={columns}
        pagination={false}
        dataSource={jobDataSource}
        scroll={{ y: jobDataSource.length > 5 ? 400 : undefined }}
        loading={isLoading}
      />
    </Modal>
  );
};

export default Index;
