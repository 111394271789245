import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import BaseLayout from '@src/pages/RuleEngine/layouts/BaseLayout';

export function RuleEngine({ route, ...rest }) {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  if (pathname === '/rule-engine') {
    history.push('/rule-engine/mapping-rules');
  }
  // TODO after V2 complete delete the config provider
  return (
    <ConfigProvider locale={enUS} prefixCls="mds-ant">
      <BaseLayout>{renderRoutes(route.routes)}</BaseLayout>
    </ConfigProvider>
  );
}
