/*
*
* @Author : zyang@conviva.com
* @Date : 2021/3/4
* @Version : 1.0
*
*/

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const downloadCSV = (csv, filename) => {
  filename = filename + '.csv';
  if (window.navigator.msSaveBlob) {
    //Internet Explorer
    window.navigator.msSaveBlob(new Blob([csv]), filename);
  } else {
    const eleLink = document.createElement('a');
    eleLink.download = filename;
    eleLink.style.display = 'none';
    // CSV to blob URI
    const type = isSafari() ? 'application/csv' : 'text/csv';
    const blob = new Blob(['', csv], {type});
    const dataURI = `data:${type};charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;

    const href = (typeof URL.createObjectURL === 'undefined')
        ? dataURI
        : URL.createObjectURL(blob);

    eleLink.href = href;
    // Mock click
    document.body.appendChild(eleLink);
    eleLink.click();
    // Remove button
    document.body.removeChild(eleLink);
  }
};
