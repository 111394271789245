import { useQuery } from 'react-query';
import { ruleEngineHttpClient } from '@src/service/http';

const SHOW_COVERAGE = 'show_coverage';
const SHOW_SAMPLE = 'show_sample';
const QUERY_GAP_ANALYSIS_JOB = 'query_gap_analysis_job';
const SHOW_CONDITION = 'show_condition';

const options = {
  retry: 1,
  retryDelay: (attempt) => {
    return Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000);
  },
};

export const postCreateJobs = async (params) => {
  try {
    const url = '/gap-analysis/jobs';
    return await ruleEngineHttpClient.post(url, params);
  } catch (e) {
    throw e;
  }
};

export const postShowCoverage = async (params) => {
  try {
    const url = '/gap-analysis';
    return await ruleEngineHttpClient.post(url, params);
  } catch (e) {
    throw e;
  }
};

export const postShowSample = async (params) => {
  try {
    const url = '/gap-analysis/sample';
    return await ruleEngineHttpClient.post(url, params);
  } catch (e) {
    throw e;
  }
};

export const getQueryGapAnalysisJob = async (params) => {
  try {
    const { customerId, dataSourceCode, pageNum, pageSize } = params;
    let url = `/gap-analysis/jobs?customerId=${customerId}&dataSourceCode=${dataSourceCode}`;
    if (pageNum && pageSize) {
      url = `/gap-analysis/jobs?customerId=${customerId}&dataSourceCode=${dataSourceCode}&pageNum=${pageNum}&pageSize=${pageSize}`;
    }
    return await ruleEngineHttpClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const postShowCondition = async (params) => {
  try {
    let url = `/gap-analysis/condition`;
    return await ruleEngineHttpClient.post(url, params);
  } catch (e) {
    throw e;
  }
};

export const useShowCoverage = (params) => {
  const { jobId, filterFields, dataSourceCode, breakDownFields, enabled } =
    params;
  delete params.enabled;
  return useQuery(
    [SHOW_COVERAGE, jobId, filterFields, dataSourceCode, breakDownFields],
    async () => {
      const { data } = await postShowCoverage(params);
      return data;
    },
    { ...options, enabled: enabled },
  );
};

export const useShowSample = (params) => {
  const { tag, breakDownFields } = params;
  return useQuery(
    [SHOW_SAMPLE, tag, breakDownFields],
    async () => {
      const { data } = await postShowSample(params);
      return data;
    },
    { ...options, enabled: tag.tagKey !== '' },
  );
};

export const useQueryGapAnalysisJob = (params) => {
  const { dataSourceCode, pageNum, pageSize } = params;
  return useQuery(
    [QUERY_GAP_ANALYSIS_JOB, dataSourceCode, pageNum, pageSize],
    async () => {
      const { data } = await getQueryGapAnalysisJob(params);
      return data;
    },
    options,
  );
};

export const useShowCondition = (params) => {
  const { jobId, dataSourceCode, filterCondition } = params;
  return useQuery(
    [SHOW_CONDITION, jobId, dataSourceCode, filterCondition],
    async () => {
      const { data } = await postShowCondition(params);
      return data;
    },
    { ...options, enabled: typeof jobId !== 'undefined' && jobId !== '' },
  );
};
