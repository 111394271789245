import {
  MAP_TYPE_CONSTANT,
  MAP_TYPE_DIRECT,
  MAP_TYPE_EXTRACT,
  GROUP_CODE_CI_REQUIRED_FIELD,
  GROUP_CODE_AUTOMATED_METADATA_MAPPING,
  GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
  FIELD_TYPE_CODE_CUSTOM,
} from '@src/store/types/types';
import {
  IDryRunInfo,
  IDslObj,
  IMappedFromItem,
  IRuleFiledMapping,
} from '@src/typing/ruleEngine';
import store from '@src/store';
import moment from 'moment';

export const getActiveProjectCode = () => {
  const state = store.getState();
  const activeBu = state.ruleEngine.activeBusinessParcel;
  return activeBu.project.projectCode || '';
};

export const getTagShowTitle = (tag: IMappedFromItem) => {
  const mappedFrom = tag.label || tag.mappedFrom;
  const label = tag.parentPath ? `${tag.parentPath}.${mappedFrom}` : mappedFrom;
  return tag.mappingType === MAP_TYPE_EXTRACT
    ? `${label}<${tag.regexGroupName}>`
    : label;
};

export const safeParse = (
  target: any,
  defaultValue?: any,
  errorCallback?: (e: any) => void,
) => {
  let result;
  try {
    result = JSON.parse(target);
  } catch (e) {
    console.error(`${target} is not serializable, please check`);
    result = defaultValue || target;
    errorCallback?.(e);
  }
  return result;
};

export function getTagColor(tagType) {
  let color = '';
  switch (tagType) {
    case MAP_TYPE_DIRECT:
      color = '#FFF3CD';
      break;
    case MAP_TYPE_CONSTANT:
      color = '#ECECEC';
      break;
    case MAP_TYPE_EXTRACT:
      color = '#F5EAFF';
      break;
    default:
      color = '#FFF3CD';
  }
  return color;
}

export const convertTagNameToTagKey = (tagName: string) => {
  /*
   *
   * Tag key is the unique identifier of the tag, which is converted from the tag name field. tag key will be used as a column name in the data store. So it follows the following conversion rules.
   * 1. All lowercase.
   * 2. All numbers in the header will be converted to _
   * 3. All non-numeric non-alphabetic characters will be converted to _
   * 4. multiple consecutive _ will be converted to one _
   *
   * */
  let tagKey = tagName.toLowerCase();
  const validTagKeyPattern =
    /(^_([a-zA-Z0-9]_?)*$)|(^[a-zA-Z](_?[a-zA-Z0-9])*_?$)/;
  if (!validTagKeyPattern.test(tagKey)) {
    tagKey = tagKey.replace(/^\d+/, '_');
    tagKey = tagKey.replace(/[^a-zA-Z0-9_]/g, '_');
    tagKey = tagKey.replace(/_{2,}/g, '_');
  }
  return tagKey;
};

export const genNewField = (field: any): IRuleFiledMapping => {
  return {
    fieldTypeCode: '',
    groupCode: '',
    invalidValues: [],
    outputFieldTypeCode: '',
    regexTransforms: [],
    ruleFieldLetterCasings: [],
    ruleFieldMappedFroms: [],
    ruleFieldTransforms: [],
    templateId: null,
    templateName: null,
    trimExtraSpacesFlag: false,
    useDsl: false,
    dsl: {
      dsl: '',
      fallbackValue: '',
    },
    ...field,
  };
};

export const getFieldFullPath = (parentPath, fieldKey) => {
  if (parentPath) {
    return `${parentPath}.\`${fieldKey}\``;
  } else {
    return fieldKey;
  }
};

interface IReducerObj {
  rules: any;
  draftRules: any;
  customTagRules: any;
  draftCustomTagRules: any;
  regexRules: any;
  draftRegexRules: any;
  requiredFields: any[];
  automatedFields: any[];
  activeVersionUI: any;
  selectedCustomTags: any[];
  // activeField: string,
  // activeRegex: string,
  activeCustomTag: string;
}

export function convertRuleConfigFromBackendFormatToUIFormat(ruleConfig) {
  const obj: IReducerObj = {
    rules: {},
    draftRules: {},
    customTagRules: { outputFieldTypeCode: 'STRING' },
    draftCustomTagRules: {},
    regexRules: {
      // object_id: [],
    },
    draftRegexRules: {},
    requiredFields: [],
    automatedFields: [],
    activeVersionUI: {},
    selectedCustomTags: [],
    // activeField: '',
    // activeRegex: '',
    activeCustomTag: '',
  };
  const { ruleFieldExtractingConfigs, ruleFieldMappings } = ruleConfig;
  if (ruleFieldMappings) {
    const rules = {};
    const regexRules = {};
    const customTagRules = {};
    let target = rules;
    ruleFieldExtractingConfigs?.forEach((field) => {
      regexRules[field.tagName] = field.regexExpressions.map(
        (field, index) => ({
          id: `${index}${field}`,
          value: field,
        }),
      );
    });

    ruleFieldMappings.forEach((field) => {
      const fieldRuleFieldMappedFroms = field.ruleFieldMappedFroms || [];
      const fieldRuleFieldTransforms = field.ruleFieldTransforms || [];
      const fieldRegexTransforms = field.regexTransforms || [];

      // Categorize fields
      switch (field.groupCode) {
        case GROUP_CODE_CI_REQUIRED_FIELD:
          obj.requiredFields.push(field);
          break;
        case GROUP_CODE_AUTOMATED_METADATA_MAPPING:
          obj.automatedFields.push(field);
          break;
        case GROUP_CODE_CUSTOMER_CUSTOM_FIELDS:
          obj.selectedCustomTags.push(field);
          break;
      }

      if (
        field.groupCode === GROUP_CODE_CI_REQUIRED_FIELD ||
        field.groupCode === GROUP_CODE_AUTOMATED_METADATA_MAPPING
      ) {
        target = rules;
      } else if (field.groupCode === GROUP_CODE_CUSTOMER_CUSTOM_FIELDS) {
        target = customTagRules;
      }

      target[field.fieldName] = {};
      target[field.fieldName]['mapFrom'] = fieldRuleFieldMappedFroms.map(
        (field, index) => ({
          type: field.mappingType,
          label: field.label,
          value: field.mappedFrom || '',
          regexGroupName: field.regexGroupName || '',
          id: `${index}${field.mappingType}${field.mappedFrom}`,
        }),
      );

      target[field.fieldName]['transform'] = fieldRuleFieldTransforms.map(
        (field) => {
          return {
            from: field.mapFrom,
            to: field.mapTo,
          };
        },
      );
      target[field.fieldName]['regexTransforms'] = fieldRegexTransforms.map(
        (field) => {
          return {
            from: field.mapFrom,
            to: field.mapTo,
          };
        },
      );
      target[field.fieldName]['letterCasing'] =
        field.ruleFieldLetterCasings?.[0] || '';
      target[field.fieldName]['trimExtraSpacesFlag'] =
        field.trimExtraSpacesFlag || false;
      target[field.fieldName]['invalidValues'] = field.invalidValues || [];
      // rules[field.fieldName]['letterCasing'] = field.ruleFieldLetterCasings || []
    });

    obj.rules = { ...rules };
    obj.draftRules = { ...rules };
    obj.regexRules = { ...regexRules };
    obj.draftRegexRules = { ...regexRules };
    obj.customTagRules = { ...customTagRules };
    obj.draftCustomTagRules = { ...customTagRules };
    // obj.activeField = '';
    // obj.activeRegex = '';
    // obj.activeCustomTag = '';
    obj.activeVersionUI = {
      rules,
      draftRules: rules,
      regexRules,
      draftRegexRules: regexRules,
      customTagRules,
      draftCustomTagRules: customTagRules,
      selectedCustomTags: obj.selectedCustomTags,
    };
  }
  return obj;
}

function convertTransformsFromUIFormatToBackendFormat(transform: any[] = []) {
  return transform.map((transformItem: any) => ({
    mapFrom: transformItem.from,
    mapTo: transformItem.to,
  }));
}

function convertMapFromFromUIFormatToBackendFormat(mapFrom: any[] = []) {
  return mapFrom.map((mapFromItem: any) => ({
    mappedFrom: mapFromItem.value,
    mappingType: mapFromItem.type,
    label: mapFromItem.label,
    regexGroupName: mapFromItem.regexGroupName || null,
  }));
}

export function convertRuleConfigFromUIFormatToBackendFormat(reducer) {
  const obj = reducer.activeVersion;

  const regexRulesKeys = Object.keys(reducer.regexRules);
  const ruleFieldExtractingConfigs = regexRulesKeys.map((field) => {
    const regexs = reducer.regexRules[field] || [];
    const regexExpressions = regexs.map((regex) => regex.value);
    return {
      tagName: field,
      regexExpressions,
    };
  });
  obj.ruleFieldExtractingConfigs = ruleFieldExtractingConfigs;

  const customTagFieldMappings = reducer.selectedCustomTags.map((field) => {
    let tagObj: any = {
      fieldName: field.fieldName,
      fieldCode: field.fieldCode,
      fieldTypeCode: FIELD_TYPE_CODE_CUSTOM,
      groupCode: GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
      ruleFieldMappedFroms: [],
      ruleFieldLetterCasings: [],
      ruleFieldTransforms: [],
      regexTransforms: [],
      invalidValues: [],
    };
    if (reducer.customTagRules[field.fieldName]) {
      const ruleFieldMappedFroms = convertMapFromFromUIFormatToBackendFormat(
        reducer.customTagRules[field.fieldName].mapFrom,
      );
      const ruleFieldTransforms = convertTransformsFromUIFormatToBackendFormat(
        reducer.customTagRules[field.fieldName].transform,
      );
      const regexTransforms = convertTransformsFromUIFormatToBackendFormat(
        reducer.customTagRules[field.fieldName].regexTransforms,
      );
      tagObj.ruleFieldMappedFroms = [...ruleFieldMappedFroms];
      tagObj.ruleFieldTransforms = [...ruleFieldTransforms];
      tagObj.regexTransforms = [...regexTransforms];
      const letterCasing = reducer.customTagRules[field.fieldName].letterCasing;
      const trimExtraSpacesFlag =
        reducer.customTagRules[field.fieldName].trimExtraSpacesFlag;
      const invalidValues =
        reducer.customTagRules[field.fieldName].invalidValues;
      // field.ruleFieldLetterCasings = letterCasing ? [...letterCasing] : [];
      tagObj.ruleFieldLetterCasings = letterCasing ? [letterCasing] : [];
      tagObj.trimExtraSpacesFlag = trimExtraSpacesFlag || false;
      tagObj.invalidValues = invalidValues || [];
      tagObj.outputFieldTypeCode = 'STRING';
    }
    return tagObj;
  });

  let exceptCustomTagFieldMappings = [];
  if (obj.ruleFieldMappings) {
    exceptCustomTagFieldMappings = obj.ruleFieldMappings.filter(
      (item) => item.groupCode !== GROUP_CODE_CUSTOMER_CUSTOM_FIELDS,
    );
    exceptCustomTagFieldMappings.forEach((field: any) => {
      if (reducer.rules[field.fieldName]) {
        const ruleFieldMappedFroms = convertMapFromFromUIFormatToBackendFormat(
          reducer.rules[field.fieldName].mapFrom,
        );
        const ruleFieldTransforms =
          convertTransformsFromUIFormatToBackendFormat(
            reducer.rules[field.fieldName].transform,
          );
        const regexTransforms = convertTransformsFromUIFormatToBackendFormat(
          reducer.rules[field.fieldName].regexTransforms,
        );
        field.ruleFieldMappedFroms = [...ruleFieldMappedFroms];
        field.ruleFieldTransforms = [...ruleFieldTransforms];
        field.regexTransforms = [...regexTransforms];
        const letterCasing = reducer.rules[field.fieldName].letterCasing;
        const trimExtraSpacesFlag =
          reducer.rules[field.fieldName].trimExtraSpacesFlag;
        const invalidValues = reducer.rules[field.fieldName].invalidValues;
        // field.ruleFieldLetterCasings = letterCasing ? [...letterCasing] : [];
        field.ruleFieldLetterCasings = letterCasing ? [letterCasing] : [];
        field.trimExtraSpacesFlag = trimExtraSpacesFlag || false;
        field.invalidValues = invalidValues || [];
      }
    });
  }

  obj.ruleFieldMappings = [
    ...exceptCustomTagFieldMappings,
    ...customTagFieldMappings,
  ];
  return obj;
}
export function getNameByDryRunInfo(dryRun: IDryRunInfo) {
  const { version, name, startDate, endDate } = dryRun;
  if (version === 'V2' && name) {
    return name;
  } else if (startDate && endDate) {
    return `dry-run report ${moment
      .utc(startDate)
      .format('YYYY/MM/DD')} - ${moment.utc(endDate).format('YYYY/MM/DD')}`;
  } else {
    return `dry-run report`;
  }
}
