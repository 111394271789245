import * as types from '../types/types';
import * as constants from '../../utils/constants';

export function pushLocation(path) {
  return {
    type: types.PUSH_LOCATION,
    path: path,
  };
}

export function updateUrlQueryParams(params: any) {
  return {
    type: types.UPDATE_URL_QUERY_PARAMS,
    params,
  };
}

export const requestInputMetadataQuality = (params: any) => ({
  type: types.REQUEST_INPUT_METADATA_QUALITY,
  params,
});

export const receiveInputMetadataQuality = (data: any) => ({
  type: types.RECEIVE_INPUT_METADATA_QUALITY,
  data,
});

export const requestPlayerList = (params: any) => ({
  type: types.REQUEST_PLAYER_LIST,
  params,
});

export const receivePlayerList = (data: any) => ({
  type: types.RECEIVE_PLAYER_LIST,
  data,
});

export const requestTagDetails = (params: any) => ({
  type: types.REQUEST_TAG_DETAILS,
  params,
});

export const receiveTagDetails = (data: any) => ({
  type: types.RECEIVE_TAG_DETAILS,
  data,
});

export const requestCustomerMetadataStrategy = (params: any) => ({
  type: types.REQUEST_CUSTOMER_METADATA_STRATEGY,
  params,
});

export const receiveCustomerMetadataStrategy = (data: any) => ({
  type: types.RECEIVE_CUSTOMER_METADATA_STRATEGY,
  data,
});

export const requestRuleEngineRequiredFields = (params: any) => ({
  type: types.REQUEST_RULE_ENGINE_REQUIRED_FIELDS,
  params,
});

export const receiveRuleEngineRequiredFields = (data: any) => ({
  type: types.RECEIVE_RULE_ENGINE_REQUIRED_FIELDS,
  data,
});

export const requestRuleEngineSupportLetter = (params: any) => ({
  type: types.REQUEST_RULE_ENGINE_SUPPORT_LETTER,
  params,
});

export const receiveRuleEngineSupportLetter = (data: any) => ({
  type: types.RECEIVE_RULE_ENGINE_SUPPORT_LETTER,
  data,
});

export const requestRuleEngineOptions = (params: any) => ({
  type: types.REQUEST_RULE_ENGINE_OPTIONS,
  params,
});

export const receiveRuleEngineOptions = (data: any) => ({
  type: types.RECEIVE_RULE_ENGINE_OPTIONS,
  data,
});

export const selectFieldToActive = (data: any) => ({
  type: types.SELECT_FIELD_TO_ACTIVE,
  data,
});

export const selectRegexToActive = (data: any) => ({
  type: types.SELECT_REGEX_TO_ACTIVE,
  data,
});

export const editRulesForField = (data: any) => ({
  type: types.EDIT_RULES_FOR_FIELD,
  data,
});

export const clearFieldRules = (data: any) => ({
  type: types.CLEAR_FIELD_RULES,
  data,
});

export const applyRulesToField = (data: any) => ({
  type: types.APPLY_RULES_TO_FIELD,
  data,
});

export const updateSelectedCustomTags = (data: any) => ({
  type: types.UPDATE_SELECTED_CUSTOM_TAGS,
  data,
});

export const editRulesForCustomTag = (data: any) => ({
  type: types.EDIT_RULES_FOR_CUSTOM_TAG,
  data,
});

export const clearCustomTagRules = (data: any) => ({
  type: types.CLEAR_CUSTOM_TAG_RULES,
  data,
});

export const applyRulesToCustomTag = (data: any) => ({
  type: types.APPLY_RULES_TO_CUSTOM_TAG,
  data,
});

export const selectCustomTagToActive = (data: any) => ({
  type: types.SELECT_CUSTOM_TAG_TO_ACTIVE,
  data,
});

export const deleteCustomTagRules = (data: any) => ({
  type: types.DELETE_CUSTOM_TAG_RULES,
  data,
});

export const editRulesForRegex = (data: any) => ({
  type: types.EDIT_RULES_FOR_REGEX,
  data,
});

export const applyRulesToRegex = (data: any) => ({
  type: types.APPLY_RULES_TO_REGEX,
  data,
});

export const startLoading = (data: any) => ({
  type: types.START_LOADING,
  data,
});

export const endLoading = (data: any) => ({
  type: types.END_LOADING,
  data,
});

export const receiveTagKeyList = (data: any) => ({
  type: types.RECEIVE_TAG_KEY_LIST,
  data,
});

export const requestTagKeyList = (data: any) => ({
  type: types.REQUEST_TAG_KEY_LIST,
  data,
});

export const requestTagSampleData = (tagKey: any, pageNum: number = 1) => ({
  type: types.REQUEST_TAG_SAMPLE_DATA,
  tagKey,
  pageNum,
});

export const receiveTagSampleData = (data: any) => ({
  type: types.RECEIVE_TAG_SAMPLE_DATA,
  data,
});

export const runRegexTest = (data: any) => ({
  type: types.RUN_REGEX_TEST,
  data,
});

export const receiveRegexTestResult = (data: any) => ({
  type: types.RECEIVE_REGEX_TEST_RESULT,
  data,
});

export const receiveRuleVersions = (data: any) => ({
  type: types.RECEIVE_RULE_VERSIONS,
  data,
});

export const requestRuleVersions = (data: any) => ({
  type: types.REQUEST_RULE_VERSIONS,
  data,
});

export const setActiveVersion = (data: any) => ({
  type: types.SET_ACTIVE_VERSION,
  data,
});

export const requestRuleConfig = (data: any) => ({
  type: types.REQUEST_RULE_CONFIG,
  data,
});

export const requestInitialRule = (data: any) => ({
  type: types.REQUEST_INITIAL_RULE,
  data,
});

export const updateRule = (data: any) => ({
  type: types.PUT_UPDATE_RULE,
  data,
});

export const saveRule = (data: any) => ({
  type: types.POST_SAVE_RULE,
  data,
});

export const receiveRules = (data: any) => ({
  type: types.RECEIVE_RULES,
  data,
});

export const requestDryRunList = (data: any) => ({
  type: types.REQUEST_DRY_RUN_LIST,
  data,
});

export const receiveDryRunList = (data: any) => ({
  type: types.RECEIVE_DRY_RUN_LIST,
  data,
});

export const createDryRunReport = (data: any) => ({
  type: types.CREATE_DRY_RUN_REPORT,
  data,
});

export const duplicateVersion = (data: any) => ({
  type: types.DUPLICATE_VERSION,
  data,
});

export const deleteVersion = (data: any) => ({
  type: types.DELETE_VERSION,
  data,
});

export const releaseVersion = (uuid: any) => ({
  type: types.RELEASE_VERSION,
  data: {
    uuid,
    ruleEvent: constants.RULE_EVENT_SKIP,
  },
});

export const changeWorkflowState = (uuid: any, ruleEvent: string) => ({
  type: types.CHANGE_WORKFLOW_STATE,
  data: {
    uuid,
    ruleEvent,
  },
});

export const editFromVersion = (data: any) => ({
  type: types.DELETE_VERSION,
  data,
});

export const getUserInfo = () => ({
  type: types.GET_USER_INFO,
});

export const receiveUserInfo = (data: any) => ({
  type: types.RECEIVE_USER_INFO,
  data,
});

export const getDryRunReport = (data: any) => ({
  type: types.GET_DRY_RUN_REPORT,
  data,
});

export const getDryRunReportFieldDetail = (data: any) => ({
  type: types.GET_DRY_RUN_REPORT_FIELD_DETAIL,
  data,
});

export const receiveDryRunReport = (data: any) => ({
  type: types.RECEIVE_DRY_RUN_REPORT,
  data,
});

export const receiveRuleCompareReport = (data: any) => ({
  type: types.RECEIVE_RULE_COMPARE_REPORT,
  data,
});

export const addDryRunReportTab = (
  businessParcelId: string,
  ruleId: string,
  dryRunId: string,
  dryRunInfo: any,
) => ({
  type: types.ADD_DRY_RUN_REPORT_TAB,
  data: {
    businessParcelId,
    ruleId,
    dryRunId,
    dryRunInfo,
  },
});

export const setFiltersSorting = (sort) => ({
  type: '',
  sort,
});

export const fetchData = () => ({
  type: '',
});

export const setAutoSortEnabled = (isAutoSortEnabled) => ({
  type: '',
  isAutoSortEnabled,
});

// V2

export const getSampleDataJobs = () => ({
  type: types.GET_SAMPLE_DATA_JOBS,
});

export const receiveSampleDataJobs = (data: any) => ({
  type: types.RECEIVE_SAMPLE_DATA_JOBS,
  data,
});

export const requestBusinessParcels = () => ({
  type: types.FETCH_BUSINESS_UNITS,
});

export const setActiveBusinessParcel = (data: any) => ({
  type: types.SET_ACTIVE_BUSINESS_UNIT,
  data,
});

export const receiveBusinessParcels = (data: any) => ({
  type: types.RECEIVE_BUSINESS_UNITS,
  data,
});

export const updateFieldRule = (data: any) => ({
  type: types.UPDATE_RULE,
  data,
});

export const updateDryRunCompareLists = (data: any) => ({
  type: types.UPDATE_DRY_RUN_COMPARE_LISTS,
  data,
});
