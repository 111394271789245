import React, { useEffect, useState } from 'react';
import {
  FilterWrapper,
  ModalWrapper,
  TabsWrapper,
} from '@src/pages/PreMdsCoverage/styled';
import { Icon, Input as AntInput, Spin, Table, Tabs } from 'antdV3';
import { Tooltip } from 'antd';
import { Checkbox, Modal, RowDivider, Select } from 'conviva-design';
import { Button } from 'conviva-design/es/components/ButtonV2';
import { ThemeContext } from '@src/contexts';
import { ColumnProps } from 'antdV3/es/table';
import Highlighter from 'react-highlight-words';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import FilterButton from '@src/pages/GapAnalysis/components/FilterModal/FilterButton';
import {
  modifyUpdateCoverageMark,
  updateConditionResultList,
} from '@src/store/actions/gapAnalysis';
import '@src/pages/GapAnalysis/components/FilterModal/index.less';
import { IResultList } from '@src/typing/gapAnalysis';
import { isEqual } from 'lodash';

const { Option } = Select;

const LIMIT_MESSAGE = 'Only 30 apps will be displayed due to column limit.';

interface IFilterModal {
  visible: boolean;
  onCancel: () => void;
  onApply: () => void;
  loading: boolean;
}

const FilterModal: React.FC<IFilterModal> = ({
  visible,
  onCancel,
  onApply,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { theme } = React.useContext(ThemeContext);
  const state = useAppSelector((state) => {
    return {
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const { conditionFilterList, conditionResultList } = gapAnalysis;

  const [tableSearchText, setTableSearchText] = useState<string>('');
  const [newResultList, setNewResultList] =
    useState<IResultList[]>(conditionResultList);

  useEffect(() => {
    setNewResultList(conditionResultList);
  }, [conditionResultList]);

  const getAppKey = (data) => {
    return data.label;
  };

  const appsSelectorValues = newResultList
    .map((item) => {
      const { column } = item;
      if (!column.checked) return null;
      return column.label;
    })
    .filter(Boolean);

  let appsTableListAfterFilter = newResultList.filter((item) => {
    const { column } = item;
    const key = getAppKey(column);
    return key.toString().toLowerCase().includes(tableSearchText.toLowerCase());
  });

  const isAllChecked =
    appsTableListAfterFilter.length > 0
      ? appsTableListAfterFilter.every((item) => item.column.checked)
      : newResultList.every((item) => item.column.checked) &&
        newResultList.length > 0;

  const indeterminate =
    newResultList.some((item) => item.column.checked) && !isAllChecked;

  const filterButton = () => {
    return conditionFilterList.map((item, index) => {
      const label = item.key;
      return (
        <div key={label}>
          <FilterWrapper>
            <div className="filter-label">
              <Tooltip title={label}>{label}</Tooltip>
            </div>
            <FilterButton label={label} filterListValues={item.values} />
          </FilterWrapper>
          {index !== conditionFilterList.length - 1 && (
            <RowDivider theme={theme} />
          )}
        </div>
      );
    });
  };

  const footerButtons = loading
    ? []
    : [
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          key="footer-buttons"
        >
          <Button
            key="cancel"
            category="apps-dialog-cancel"
            theme={theme}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            key="apply"
            data-test-id="apps-dialog-apply"
            theme={theme}
            onClick={onApply}
          >
            Apply
          </Button>
        </div>,
      ];

  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <AntInput
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8, fontSize: 14 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90, fontSize: 14 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: () => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              type="search"
              style={{
                fontSize: '20px',
                color: tableSearchText ? '#1890ff' : '#4A4A4A',
              }}
            />
          </div>
        );
      },
      onFilter: (value, record) => {
        const text = record.column.label;
        return text.toString().toLowerCase().includes(value.toLowerCase());
      },
      onFilterDropdownVisibleChange: () => {},
      render: (record) => {
        const text = `${record.column.label}`;
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[tableSearchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        );
      },
    };
  };

  const appsSelectorOptions = newResultList
    .map((app) => {
      const { column } = app;
      const key = getAppKey(column);
      const { label } = column;
      if (label === '') return null;
      return <Option key={key}>{label}</Option>;
    })
    .filter(Boolean);

  const handleSelectAllApps = (event, allAppsList) => {
    const checked = event.target.checked;
    const newState = allAppsList.map((item) => {
      item.column.checked = checked;
      return item;
    });
    setNewResultList(newState);
    dispatch(updateConditionResultList(newState));
    dispatch(modifyUpdateCoverageMark(false));
  };

  const handleSelectAppItem = (record, event) => {
    const checked = event.target.checked;
    const newState = newResultList.map((item) => {
      if (isEqual(item, record)) {
        item.column.checked = checked;
      }
      return item;
    });
    setNewResultList(newState);
    dispatch(updateConditionResultList(newState));
    dispatch(modifyUpdateCoverageMark(false));
  };

  const columns: ColumnProps<any>[] = [
    {
      key: 'checkBox',
      title: (
        <Checkbox
          checked={isAllChecked}
          onChange={(e) => {
            handleSelectAllApps(e, appsTableListAfterFilter);
          }}
          indeterminate={indeterminate}
          theme={theme}
        ></Checkbox>
      ),
      render: (record) => {
        const checked = record.column.checked;
        return (
          <Checkbox
            checked={checked}
            onChange={(e) => {
              handleSelectAppItem(record, e);
            }}
            theme={theme}
          ></Checkbox>
        );
      },
      width: 35,
    },
    {
      key: 'label',
      title: 'Break Down Fields',
      ...getColumnSearchProps('label'),
      width: 200,
    },
    {
      key: 'coverage',
      title: 'Number of Records',
      dataIndex: 'column.coverage',
      width: 150,
      sorter: (a, b) => a.column.coverage - b.column.coverage,
      render: (plays) => {
        return (
          <div className="tableCell">
            {plays}
            {plays || plays === 0 ? '%' : ''}
          </div>
        );
      },
    },
  ];

  const appsTable = (
    <Table
      scroll={{ y: 320 }}
      columns={columns}
      rowKey={(record) => {
        const { column } = record;
        return getAppKey(column);
      }}
      dataSource={appsTableListAfterFilter}
      pagination={false}
    />
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setTableSearchText(selectedKeys[0]);
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setTableSearchText('');
  };

  const handleSelectAppInTop = (value) => {
    const newState = newResultList.map((item) => {
      const { column } = item;
      column.checked = value.includes(column.label);
      return item;
    });
    setNewResultList(newState);
  };

  const handleRemoveAppItem = (value) => {
    const newState = newResultList.map((item) => {
      const { column } = item;
      if (column.label === value) {
        column.checked = false;
      }
      return item;
    });
    setNewResultList(newState);
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      onCancel={onCancel}
      theme={theme}
      footer={footerButtons}
      width="1000px"
      bodyStyle={{ paddingBottom: '20px' }}
      destroyOnClose
    >
      <ModalWrapper theme={theme}>
        <Spin spinning={loading}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={LIMIT_MESSAGE}
            onChange={handleSelectAppInTop}
            onDeselect={handleRemoveAppItem}
            value={appsSelectorValues}
          >
            {appsSelectorOptions}
          </Select>
          {filterButton()}
          <TabsWrapper>
            <Tabs
              tabPosition="left"
              className={'saved-filter-panel'}
              activeKey={'1'}
            >
              <Tabs.TabPane tab="All" key={'1'}>
                {appsTable}
              </Tabs.TabPane>
            </Tabs>
          </TabsWrapper>
        </Spin>
      </ModalWrapper>
    </Modal>
  );
};

export default FilterModal;
