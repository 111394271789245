import React from 'react';
import { createSelector } from 'reselect';
import { ruleEngineSelector } from '@src/store/selector';
import { List, Spin } from 'antdV3';
import { useQuery } from 'react-query';
import { EReactQueryKey } from '@src/utils/constants';
import { requestDryRunReportFieldDetailV1 } from '@src/service/api';
import { IDryRunReportFieldDetail } from '@src/typing/ruleEngine';

const selector = createSelector(ruleEngineSelector, (ruleEngine) => {
  return {
    ruleEngine,
  };
});

export const DryRunReportFieldDetailV1: React.FC<{
  dryRunId?: string;
  fieldId: string;
}> = (props) => {
  const { dryRunId = '', fieldId } = props;
  const { data: dataSource, status } = useQuery(
    [EReactQueryKey.DRY_RUN_REPORT_FIELD_DETAIL, dryRunId, fieldId],
    async () => {
      return await requestDryRunReportFieldDetailV1({
        params: {
          dryRunId,
          fieldName: fieldId,
        },
      });
    },
  );
  return (
    <Spin tip={'Loading...'} spinning={status === 'loading'} size={'small'}>
      {Array.isArray(dataSource) ? (
        <DryRunReportFieldDetail dataSource={dataSource} />
      ) : null}
    </Spin>
  );
};

export const DryRunReportFieldDetail: React.FC<{
  dataSource: IDryRunReportFieldDetail[];
}> = (props) => {
  const { dataSource } = props;
  return (
    <List
      style={{ maxHeight: '300px', overflow: 'scroll' }}
      dataSource={dataSource}
      renderItem={(item) => (
        <List.Item key={item.fieldValue}>
          <div style={{ marginRight: '10px', whiteSpace: 'pre' }}>
            {item.fieldValue}
          </div>
          <div>{item.count}</div>
        </List.Item>
      )}
    />
  );
};
