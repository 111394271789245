import { createSelector } from 'reselect';
import { convertTagKeyToMetricsGatewayDimensionKey } from '../utils/metricsGateway';

export const systemSelector = (state: any) => state.system;

export const userInfoSelector = (state: any) => state.ruleEngine.userInfo;

export const locationSelector = (state) => state.router.location;

export const getInputMetadataQualitySelector = createSelector(
  systemSelector,
  (state) => state.inputMetadataQuality,
);

export const getPlayerListSelector = createSelector(
  systemSelector,
  (state) => state.playerList,
);

export const getTagDetailsSelector = createSelector(
  (state, props) =>
    state.mds.tagDetails[props.playerName] &&
    state.mds.tagDetails[props.playerName][
      convertTagKeyToMetricsGatewayDimensionKey(props.tagKey)
    ],
  (state) => state,
);

export const getCustomerMetadataStrategySelector = createSelector(
  systemSelector,
  (state) => state.customerMetadataStrategy,
);

export const ruleEngineSelector = (state: any) => state.ruleEngine;

export const businessParcelTitleSelector = (state: any) =>
  state.ruleEngine.activeBusinessParcel.project?.projectName;
