import styled from 'styled-components';

type Passed = {
  theme?: string;
};

export const VersionControlWrapper = styled.div`
  padding: 0px 18px;
  margin-bottom: 300px;
  margin-top: 20px;
  .row-active {
    background-color: rgb(235, 243, 252);
  }
`;

export const EmailFormWrapper = styled.div`
  .mds-ant-select-selection-overflow-item:first-child
    .mds-ant-select-selection-item-remove {
    display: none;
  }
`;

export const PageTitle = styled.div`
  height: 36px;
  color: #000000;
  font-family: 'Open Sans';
  font-size: 26px;
  letter-spacing: 0;
  line-height: 36px;
`;

export const WorkFlowWrapper = styled.div`
  width: 100%;
  min-width: 1300px;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 250px;
  border-top: 10px solid #e9edf0;
  border-right: 10px solid #e9edf0;
  border-left: 10px solid #e9edf0;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  overflow: scroll;
`;

export const WorkFlowInnerBox = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1300px;

  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
`;

export const WorkFlowTopTitle = styled.div`
  padding: 0px 15px 15px 15px;
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  border-bottom: 1px solid #4a4a4a;
  margin-bottom: 30px;
`;

export const WorkFlowStateBox = styled.div`
  display: flex;
`;

export const WorkFlowStateItem = styled.div`
  display: flex;
`;

export const WorkFlowVersionName = styled.div<Passed>`
  width: 290px;
  min-height: 60px;
  padding: 8px 35px;
  color: ${(props) => {
    if (props.theme === 'green') {
      return '#006900';
    } else if (props.theme === 'gray') {
      return '#fff';
    } else {
      return '#5B381A';
    }
  }};
  background-color: ${(props) => {
    if (props.theme === 'green') {
      return '#B6EEB6';
    } else if (props.theme === 'gray') {
      return '#B6B6B6';
    } else {
      return '#FFDFAA';
    }
  }};
  border-radius: 100px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 5px;
`;

export const WorkFlowActionBox = styled.div`
  width: 290px;
  border-radius: 100px;
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
`;
