import React, { useEffect, useState } from 'react';
import { DatePicker, Form, Modal, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { useAppSelector } from '@src/store/hooks';

const { RangePicker } = DatePicker;

interface ICreateNewReports {
  open: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
}

const CreateNewReports: React.FC<ICreateNewReports> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const state = useAppSelector((state) => {
    return {
      gapAnalysis: state.gapAnalysis,
    };
  });
  const gapAnalysis = state.gapAnalysis;
  const {
    activeBusinessParcel: {
      dataSource: { dataSourceName, partition },
    },
  } = gapAnalysis;

  const [haveData, setHaveDate] = useState(false);

  useEffect(() => {
    if (partition !== '' && partition.toUpperCase() !== 'NONE') {
      setHaveDate(true);
    } else {
      setHaveDate(false);
    }
  }, [partition]);

  return (
    <Modal
      visible={open}
      title="New Gap Analysis Report"
      okText="OK"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            if (Array.isArray(values?.timePeriod)) {
              const time = values?.timePeriod.map((item) => {
                return moment(item).format('YYYY-MM-DDTHH:mm:ss.SSS');
              });
              values.startTime = time[0];
              values.endTime = time[1];
              delete values.timePeriod;
            }
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <p>Data Source Name: {dataSourceName}</p>
      <Form form={form} preserve={false} labelAlign={'left'}>
        {haveData && (
          <Form.Item
            name={['timePeriod']}
            label={
              <label>
                Time Period{' '}
                <ExclamationCircleFilled style={{ color: '#909090' }} />
              </label>
            }
            rules={[
              {
                required: true,
                message: 'Please input the Time Period of collection!',
              },
            ]}
            style={{ flexDirection: 'column' }}
          >
            <RangePicker />
          </Form.Item>
        )}
        <Form.Item
          name={['reportName']}
          label={'Report Name'}
          style={{ flexDirection: 'column' }}
          rules={[
            {
              required: true,
              message: 'Please input the Report Name of collection!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['description']}
          label={'Description'}
          style={{ flexDirection: 'column' }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNewReports;
