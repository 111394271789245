import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createSelector } from 'reselect';
import { ruleEngineSelector } from '@src/store/selector';
import { connect } from 'react-redux';
import {
  Table,
  Icon,
  Dropdown,
  Menu,
  Modal as ModalV3,
  Button,
  Spin,
  message,
} from 'antdV3';
import { Form, FormInstance, Select, ConfigProvider, Modal } from 'antd';
import * as Api from '../../service/api';
import {
  pushLocation,
  requestRuleVersions,
  duplicateVersion,
  deleteVersion,
  requestRuleConfig,
  changeWorkflowState,
  setActiveVersion,
  receiveRuleVersions,
  requestInitialRule,
} from '@src/store/actions/ruleEngine';
import { ActionButton, StyledTableWrapper } from '@src/components/styled';
import {
  VersionControlWrapper,
  PageTitle,
  WorkFlowWrapper,
  WorkFlowTopTitle,
  WorkFlowVersionName,
  WorkFlowActionBox,
  WorkFlowStateBox,
  WorkFlowStateItem,
  EmailFormWrapper,
} from './styled';
import {
  PERMISSION_RELEASE_RULE,
  RULE_STATUS_RELEASED,
  RULE_STATUS_INIT,
  RULE_STATUS_REPLACED,
  RULE_EVENT_SUBMIT,
  RULE_STATUS_READY,
  RULE_EVENT_APPROVE,
  RULE_EVENT_REVERT,
  RULE_STATUS_APPROVED,
  PERMISSION_APPROVE_RULE,
  RULE_EVENT_RELEASE,
  RULE_STATUS_RELEASING,
  DRYRUN_JOB_STATUS_SUCCESS,
  THEME_LIGHT,
  VERSION_1,
  VERSION_2,
} from '@src/utils/constants';
import { IRuleEngineReducer } from '@src/typing/ruleEngine';
import { Button as ConvivaButton } from 'conviva-design/es/components/Button';

const { confirm } = ModalV3;

const selector = createSelector(ruleEngineSelector, (ruleEngine) => {
  return {
    ruleEngine,
  };
});

interface Props {
  pushLocation: any;
  ruleEngine: IRuleEngineReducer;
  requestRuleVersions: any;
  duplicateVersion: any;
  deleteVersion: any;
  requestRuleConfig: any;
  changeWorkflowState: any;
  setActiveVersion: any;
  receiveRuleVersions: any;
  requestInitialRule: any;
}

let versionStateChecker: any = null;

// TODO change to backend data struct

class VersionControl extends React.PureComponent<Props, any> {
  formRef = React.createRef<FormInstance>();

  constructor(props) {
    super(props);
    this.state = {
      activeRow: {},
      hasSuccessDryrun: false,
      versionList: [],
      loading: false,
      dryrunLoading: false,
      statusLoading: false,
      submitModalVisible: false,
    };
  }

  static propTypes = {
    pushLocation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pushLocation: () => {},
  };

  componentDidMount() {
    const checker = setInterval(() => {
      if (this.props.ruleEngine.activeBusinessParcel.uuid) {
        this.getVersionList();
        clearInterval(checker);
      }
    }, 300);
  }

  getVersionList = (backendFetch = false) => {
    if (!backendFetch) {
      this.setState({ loading: true });
    }
    Api.requestRuleVersions()
      .then((res) => {
        const versionList = res.data;
        this.props.receiveRuleVersions(res.data);
        // Fix released to top
        const releasedRuleIndex = versionList.findIndex(
          (i) => i.state === RULE_STATUS_RELEASED,
        );
        if (versionList.length && releasedRuleIndex !== -1) {
          versionList.unshift(versionList.splice(releasedRuleIndex, 1)[0]);
        }
        // Set default activeRow
        if (!this.state.activeRow.uuid && versionList.length) {
          const record = versionList[0];
          // Get default activeRow status
          this.setState({ activeRow: record });
          this.checkVersionDryrunStatus(record.uuid).then((res) => {
            this.setState({ hasSuccessDryrun: res });
          });
        }
        // Reset activeRow
        if (this.state.activeRow.uuid && versionList.length) {
          const newActiveRowStatus = versionList.find(
            (i) => i.uuid === this.state.activeRow.uuid,
          );
          if (newActiveRowStatus) {
            this.setState({ activeRow: newActiveRowStatus });
          }
        }
        this.setState({ versionList });
        const hasRuleReleasing = versionList.some(
          (i) => i.state === RULE_STATUS_RELEASING,
        );
        const _this = this;
        if (hasRuleReleasing && !versionStateChecker) {
          versionStateChecker = setTimeout(() => {
            _this.getVersionList(true);
            versionStateChecker = null;
          }, 3000);
        }
      })
      .finally(() => {
        if (!backendFetch) {
          this.setState({ loading: false });
        }
      });
  };

  checkVersionDryrunStatus = async (ruleId) => {
    this.setState({ dryrunLoading: true });
    const reVersion = this.props.ruleEngine.activeBusinessParcel.reVersion;
    const projectCode =
      this.props.ruleEngine.activeBusinessParcel.project.projectCode;
    let dryRunList;
    if (reVersion === VERSION_1) {
      const res = await Api.requestDryRunList(VERSION_1, {
        params: {
          ruleId,
        },
      });
      dryRunList = res.entities || [];
    } else {
      const res = await Api.requestDryRunList(VERSION_2, {
        params: {
          projectCode,
          ruleId,
        },
      });
      dryRunList = res.entities || [];
    }
    this.setState({ dryrunLoading: false });
    return dryRunList.some(
      (item) =>
        item.jobStatus.toLocaleLowerCase() === DRYRUN_JOB_STATUS_SUCCESS &&
        item.effective,
    );
  };

  changeVersionWorkflowState = (uuid, ruleEvent, ...rest) => {
    this.setState({ statusLoading: true });
    const params = { uuid, ruleEvent };
    if (ruleEvent === RULE_EVENT_SUBMIT) {
      params['submitters'] = rest[0];
    }

    Api.changeWorkflowState(params)
      .then((res) => {
        if (res && res.code === 'SUCCESS') {
          if (
            this.props.ruleEngine.activeBusinessParcel.reVersion ===
              VERSION_2 &&
            ruleEvent === RULE_EVENT_RELEASE
          ) {
            ruleEvent = 'RELEASING';
          }
          message.success(`Successfully ${ruleEvent}`);
          this.getVersionList();
        } else {
          message.error(
            'Fail to change rule state: ' + res.message + ', please try again.',
          );
        }
      })
      .finally(() => this.setState({ statusLoading: false }));
  };

  handleEdit = (record) => {
    this.props.setActiveVersion(record);
    this.props.pushLocation('/rule-engine');
  };

  transTime = (text) => {
    const dateFormat = 'MMM D, YYYY h:mm A';
    const timeZone = 'ZZ';
    return `${moment.utc(text).local().format(dateFormat)} GTM${moment(
      text,
    ).format(timeZone)}`;
  };

  handleDelete = (uuid) => {
    const _this = this;
    confirm({
      content: 'Are you sure you want to delete this version?',
      onOk() {
        _this.setState({ statusLoading: true });
        Api.deleteVersion(uuid)
          .then((res) => {
            if (res && res.code === 'SUCCESS') {
              message.success(`Successfully deleted`);
              if (_this.props.ruleEngine.activeVersion.uuid === uuid) {
                const newList = _this.state.versionList.filter(
                  (i) => i.uuid !== uuid,
                );
                if (newList.length) {
                  _this.props.setActiveVersion(
                    _this.state.versionList.filter((i) => i.uuid !== uuid)[0],
                  );
                } else {
                  _this.props.requestInitialRule();
                }
              }
              _this.getVersionList();
            } else {
              message.error(
                'Fail to delete version due to: ' +
                  res.message +
                  ', please try again.',
              );
            }
          })
          .finally(() => {
            _this.setState({ statusLoading: false });
          });
      },
      onCancel() {
        console.log('Cancel Delete');
      },
    });
  };

  handleSubmit = (uuid, submitters) => {
    this.changeVersionWorkflowState(uuid, RULE_EVENT_SUBMIT, submitters);
  };

  handleApprove = (uuid) => {
    this.changeVersionWorkflowState(uuid, RULE_EVENT_APPROVE);
  };

  handleRevert = (uuid) => {
    this.changeVersionWorkflowState(uuid, RULE_EVENT_REVERT);
  };

  handleWorkflowRelease = (uuid) => {
    this.changeVersionWorkflowState(uuid, RULE_EVENT_RELEASE);
  };

  handleDuplicateVersion = (uuid) => {
    this.setState({ statusLoading: true });
    Api.duplicateVersion(uuid)
      .then((res) => {
        if (res && res.code === 'SUCCESS') {
          message.success(`Successfully duplicated`);
          this.getVersionList();
        } else {
          message.error(
            'Fail to duplicate version due to: ' +
              res.message +
              ', please try again.',
          );
        }
      })
      .finally(() => this.setState({ statusLoading: false }));
  };

  handleCreateNewRule = () => {
    this.props.requestInitialRule('/rule-engine/mapping-rules');
  };

  getReleaseShowDOM = (activeRow, step) => {
    let showTitle = '';
    if (activeRow.state === RULE_STATUS_RELEASING) {
      showTitle = 'Releasing';
    } else if (
      activeRow.state === RULE_STATUS_APPROVED &&
      activeRow.releaseUser
    ) {
      showTitle = 'Release failed, please retry.';
    } else {
      showTitle = step > 3 ? 'Released' : 'To Be Released';
    }

    const canUserRelease =
      this.props.ruleEngine.userInfo.permissions.includes(
        PERMISSION_RELEASE_RULE,
      ) ||
      this.props.ruleEngine.userInfo.sysUser.username === activeRow.createUser;

    const hasRuleReleasing = this.state.versionList.some(
      (i) => i.state === RULE_STATUS_RELEASING && i.uuid !== activeRow.uuid,
    );

    let showState = '';
    if (activeRow.state === RULE_STATUS_APPROVED) {
      showState = hasRuleReleasing
        ? 'Some rule releasing...'
        : 'Ready for release.';
    } else if (activeRow.state === RULE_STATUS_RELEASING) {
      showState = 'Releasing...';
    }

    return (
      <div data-test-id="version-control-workflow-release">
        <WorkFlowVersionName theme={step > 3 ? 'green' : 'orange'}>
          {showTitle}
        </WorkFlowVersionName>
        <WorkFlowActionBox>
          {activeRow.state === RULE_STATUS_APPROVED ||
          activeRow.state === RULE_STATUS_RELEASING ? (
            <>
              <div
                style={{
                  marginBottom: '5px',
                  color: '#4a4a4a',
                  fontSize: '11px',
                }}
              >
                {showState}
              </div>
              {canUserRelease ? (
                <Button
                  data-test-id="release-btn"
                  style={{ width: '105px' }}
                  type="primary"
                  size="small"
                  ghost
                  disabled={hasRuleReleasing}
                  loading={activeRow.state === RULE_STATUS_RELEASING}
                  onClick={() => {
                    this.handleWorkflowRelease(activeRow.uuid);
                  }}
                >
                  Release
                </Button>
              ) : null}
            </>
          ) : step > 3 ? (
            <>
              <div>{this.transTime(activeRow.releaseTime)}</div>
              <div>released by {activeRow.releaseUser}</div>
            </>
          ) : null}
        </WorkFlowActionBox>
      </div>
    );
  };

  render() {
    const { loading, activeBusinessParcel } = this.props.ruleEngine;
    const { activeRow } = this.state;
    const versionTitle = activeBusinessParcel.project.projectName;
    const dataSource: any[] = this.state.versionList;
    const isLoading =
      this.state.loading ||
      this.state.dryrunLoading ||
      this.state.statusLoading ||
      loading['get-initial-rule'] ||
      loading['version-actions'] ||
      loading['get-versions'] ||
      loading['get-rule-config'] ||
      loading['get-dry-run-list'] ||
      false;
    const iconStyle = { fontSize: '22px', margin: '18px' };
    const hasSuccessDryrun = this.state.hasSuccessDryrun;
    let step = 0;

    switch (activeRow.state) {
      case RULE_STATUS_INIT:
        step = 0;
        break;
      case RULE_STATUS_READY:
        step = 1;
        break;
      case RULE_STATUS_APPROVED:
        step = 2;
        break;
      case RULE_STATUS_RELEASING:
        step = 3;
        break;
      case RULE_STATUS_RELEASED:
        step = 4;
        break;
      case RULE_STATUS_REPLACED:
        step = 5;
        break;

      default:
        step = 0;
    }
    const columns = [
      {
        key: 'versionName',
        title: 'Version Name',
        dataIndex: 'versionNo',
        render: (text) =>
          text ? `${versionTitle} ${text}.0` : `${versionTitle} New Version`,
      },
      {
        key: 'releaseDate',
        title: 'Release Date',
        dataIndex: 'releaseTime',
        render: (text) => (text && this.transTime(text)) || 'NA',
      },
      {
        key: 'status',
        title: (
          <div>
            Status{' '}
            <Icon
              data-test-id="refresh-version-list-status-icon"
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={(evt) => {
                evt.preventDefault();
                this.getVersionList();
              }}
              type="sync"
            />
          </div>
        ),
        dataIndex: 'state',
      },
      {
        key: 'lastModified',
        title: 'Last Modified',
        dataIndex: 'lastModifyTime',
        render: (text) => (text && this.transTime(text)) || 'NA',
      },
      {
        key: 'createBy',
        title: 'Create By',
        dataIndex: 'createUser',
        render: (text) => text || 'NA',
      },
      {
        key: 'approvedBy',
        title: 'Approved By',
        dataIndex: 'approveUser',
        render: (text) => text || 'NA',
      },
      {
        key: 'action',
        render: (text, record, index) => {
          const releaseProtect = record.state !== RULE_STATUS_RELEASED;
          const defaultProtect = record.state === RULE_STATUS_INIT;
          const canEdit = defaultProtect && releaseProtect;
          const canDelete = defaultProtect && releaseProtect;

          const menu = (
            <Menu data-test-id="version-control-actions">
              {canEdit ? (
                <Menu.Item
                  onClick={() => {
                    this.handleEdit(record);
                  }}
                >
                  Edit
                </Menu.Item>
              ) : (
                ''
              )}
              <Menu.Item
                onClick={() => {
                  this.handleDuplicateVersion(record.uuid);
                }}
              >
                Duplicate
              </Menu.Item>
              {canDelete ? (
                <Menu.Item
                  onClick={() => {
                    this.handleDelete(record.uuid);
                  }}
                >
                  Delete
                </Menu.Item>
              ) : (
                ''
              )}
            </Menu>
          );
          return (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Dropdown overlay={menu} placement="bottomLeft">
                <ActionButton>
                  <Icon type="more" style={{ fontSize: '22px' }} />
                </ActionButton>
              </Dropdown>
            </div>
          );
        },
      },
    ];

    return (
      <Spin spinning={isLoading}>
        <VersionControlWrapper>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <PageTitle>{versionTitle} Version Control</PageTitle>
            <div
              data-test-id="create-new-rule-btn"
              style={{ marginRight: '20px' }}
            >
              <ConvivaButton
                clickFn={this.handleCreateNewRule}
                theme={THEME_LIGHT}
              >
                Create New Rule
              </ConvivaButton>
            </div>
          </div>
          <StyledTableWrapper>
            <Table
              pagination={false}
              columns={columns}
              dataSource={dataSource}
              rowKey={(record) => record.uuid}
              onRow={(record) => {
                return {
                  onClick: async () => {
                    if (record.uuid !== activeRow.uuid) {
                      this.setState({ activeRow: record });
                      this.getVersionList();
                      const hasSuccessDryrun =
                        await this.checkVersionDryrunStatus(record.uuid);
                      this.setState({ hasSuccessDryrun });
                    } else {
                      this.setState({ activeRow: {} });
                    }
                  },
                };
              }}
              rowClassName={(record, index) => {
                if (record.uuid === activeRow.uuid) {
                  return 'row-active';
                }
                return '';
              }}
            />
          </StyledTableWrapper>
          {activeRow.uuid ? (
            <WorkFlowWrapper>
              <WorkFlowTopTitle>Current Version Status</WorkFlowTopTitle>
              <WorkFlowStateBox>
                <WorkFlowStateItem>
                  <div data-test-id="version-control-workflow-info">
                    <WorkFlowVersionName theme="green">{`${versionTitle} ${activeRow.versionNo}.0`}</WorkFlowVersionName>
                    <WorkFlowActionBox>
                      <div>{this.transTime(activeRow.createTime)}</div>
                      <div>created by {activeRow.createUser}</div>
                    </WorkFlowActionBox>
                  </div>
                  <Icon type="arrow-right" style={iconStyle} />
                </WorkFlowStateItem>
                {/*SUBMIT*/}
                <WorkFlowStateItem>
                  <div data-test-id="version-control-workflow-dryrun">
                    <WorkFlowVersionName
                      theme={hasSuccessDryrun || step > 0 ? 'green' : 'orange'}
                    >
                      {!hasSuccessDryrun && !activeRow.revertUser
                        ? 'Dry Run Report To Be Reviewed'
                        : 'Dry Run Report Reviewed'}
                    </WorkFlowVersionName>
                    <WorkFlowActionBox>
                      {activeRow.state === RULE_STATUS_INIT ? (
                        <Button
                          data-test-id="submit-to-review-btn"
                          disabled={!hasSuccessDryrun}
                          type="primary"
                          size="small"
                          ghost
                          onClick={() => {
                            this.setState({ submitModalVisible: true });
                          }}
                        >
                          Submit To Review
                        </Button>
                      ) : (
                        <>
                          <div>{this.transTime(activeRow.submitTime)}</div>
                          <div>submitted by {activeRow.submitUser}</div>
                        </>
                      )}
                    </WorkFlowActionBox>
                  </div>
                  <Icon type="arrow-right" style={iconStyle} />
                </WorkFlowStateItem>
                {/*END SUBMIT*/}
                {/*APPROVE*/}
                <WorkFlowStateItem>
                  <div data-test-id="version-control-workflow-review">
                    <WorkFlowVersionName theme={step > 1 ? 'green' : 'orange'}>
                      {step > 1
                        ? 'Review Approved'
                        : activeRow.revertUser
                        ? 'Reverted'
                        : 'Not Reviewed'}
                    </WorkFlowVersionName>
                    <WorkFlowActionBox>
                      {activeRow.state === RULE_STATUS_READY ? (
                        <>
                          <div
                            style={{
                              marginBottom: '5px',
                              color: '#4a4a4a',
                              fontSize: '11px',
                            }}
                          >
                            Dry run report is under review…
                          </div>
                          {this.props.ruleEngine.userInfo.permissions.includes(
                            PERMISSION_APPROVE_RULE,
                          ) ? (
                            <>
                              <Button
                                data-test-id="approve-btn"
                                style={{ width: '105px' }}
                                type="primary"
                                size="small"
                                ghost
                                onClick={() => {
                                  this.handleApprove(activeRow.uuid);
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                data-test-id="revert-btn"
                                style={{ width: '105px', marginLeft: '9px' }}
                                type="primary"
                                size="small"
                                ghost
                                onClick={() => {
                                  this.handleRevert(activeRow.uuid);
                                }}
                              >
                                Revert
                              </Button>
                            </>
                          ) : null}
                        </>
                      ) : step > 1 ? (
                        <>
                          <div>{this.transTime(activeRow.approveTime)}</div>
                          <div>approved by {activeRow.approveUser}</div>
                        </>
                      ) : activeRow.revertUser ? (
                        <>
                          <div>{this.transTime(activeRow.revertTime)}</div>
                          <div>reverted by {activeRow.revertUser}</div>
                        </>
                      ) : null}
                    </WorkFlowActionBox>
                  </div>
                  <Icon type="arrow-right" style={iconStyle} />
                </WorkFlowStateItem>
                {/*END APPROVE*/}
                {/*RELEASE*/}
                <WorkFlowStateItem>
                  {this.getReleaseShowDOM(activeRow, step)}
                  {activeRow.state === RULE_STATUS_REPLACED ? (
                    <Icon type="arrow-right" style={iconStyle} />
                  ) : null}
                </WorkFlowStateItem>
                {/*END RELEASE*/}
                {activeRow.state === RULE_STATUS_REPLACED ? (
                  <WorkFlowStateItem>
                    <div data-test-id="version-control-workflow-replaced">
                      <WorkFlowVersionName theme="gray">
                        Replaced
                      </WorkFlowVersionName>
                      <WorkFlowActionBox />
                    </div>
                  </WorkFlowStateItem>
                ) : null}
              </WorkFlowStateBox>
            </WorkFlowWrapper>
          ) : null}
        </VersionControlWrapper>
        <ConfigProvider prefixCls="mds-ant">
          <Modal
            destroyOnClose
            title="Submit to review"
            visible={this.state.submitModalVisible}
            onOk={() => {
              this.formRef.current?.validateFields().then((res) => {
                this.handleSubmit(this.state.activeRow.uuid, res.submitters);
                this.setState({ submitModalVisible: false });
              });
            }}
            onCancel={() => {
              this.setState({ submitModalVisible: false });
            }}
          >
            <div
              style={{
                textAlign: 'left',
                color: 'rgba(0, 0, 0, 0.65)',
                marginBottom: 5,
              }}
            >
              Please indicate the email recipients. After the rule has been
              reviewed by the “mds-rule-reviewers” team, you will receive the
              rule release notification email
            </div>
            <Form
              ref={this.formRef}
              layout="vertical"
              initialValues={{
                submitters: [this.props.ruleEngine.userInfo?.sysUser?.username],
              }}
              onValuesChange={(values) => {
                const userName =
                  this.props.ruleEngine.userInfo?.sysUser?.username;
                if (!values.submitters.includes(userName)) {
                  this.formRef.current?.setFieldsValue({
                    submitters: [userName, ...values.submitters],
                  });
                }
              }}
            >
              <EmailFormWrapper>
                <Form.Item
                  required={true}
                  label="Rule Submitter"
                  name="submitters"
                  rules={[
                    {
                      required: true,
                      message: 'rule submitter is required!',
                    },
                    {
                      validator: (_, value) => {
                        const re =
                          /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
                        if (value.some((i) => !re.test(i))) {
                          return Promise.reject(
                            new Error(
                              'The email address you have entered is not valid!',
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Select
                    placeholder="please input submitter email"
                    mode="tags"
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                    dropdownStyle={{ display: 'none' }}
                  />
                </Form.Item>
              </EmailFormWrapper>
            </Form>
          </Modal>
        </ConfigProvider>
      </Spin>
    );
  }
}

export default connect(selector, {
  pushLocation,
  requestRuleVersions,
  duplicateVersion,
  deleteVersion,
  requestRuleConfig,
  changeWorkflowState,
  setActiveVersion,
  receiveRuleVersions,
  requestInitialRule,
})(VersionControl);
