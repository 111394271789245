const initRuleState = {
  ruleSelectVersion1: '',
  ruleSelectVersion2: '',
  ruleFieldsVersion1: [],
  ruleFieldsVersion2: [],
  ruleRegexVersion1: [],
  ruleRegexVersion2: [],
};

function ruleCompareReducer(state = initRuleState, actions: any) {
  const { type, payload } = actions;
  switch (type) {
    case 'INITIALIZE_RULE_STATE':
      return { ...state, ...payload };
    case 'UPDATE_STATE_RULE_SELECT_VERSION1':
      return { ...state, ruleSelectVersion1: payload };
    case 'UPDATE_STATE_RULE_SELECT_VERSION2':
      return { ...state, ruleSelectVersion2: payload };
    case 'UPDATE_STATE_RULE_FIELDS_VERSION1':
      return { ...state, ruleFieldsVersion1: [...payload] };
    case 'UPDATE_STATE_RULE_FIELDS_VERSION2':
      return { ...state, ruleFieldsVersion2: [...payload] };
    case 'UPDATE_STATE_RULE_REGEX_VERSION1':
      return { ...state, ruleRegexVersion1: [...payload] };
    case 'UPDATE_STATE_RULE_REGEX_VERSION2':
      return { ...state, ruleRegexVersion2: [...payload] };
    default:
      return state;
  }
}

export default ruleCompareReducer;
