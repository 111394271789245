import React, { useEffect } from 'react';
import remove from 'lodash/remove';
import { useLocation } from 'react-router-dom';
import { Tabs } from 'conviva-design/es/components/Tabs';
import {
  pushLocation,
  receiveDryRunReport,
} from '@src/store/actions/ruleEngine';
import { THEME_LIGHT, VERSION_1 } from '@src/utils/constants';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import { toUrl } from '@src/utils/url';
import { getNameByDryRunInfo } from '@src/utils/ruleEngine';
import {
  resetGapAnalysisState,
  updateGapAnalysisReports,
} from '@src/store/actions/gapAnalysis';

const NavigationBar: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => {
    return {
      visibleKey: state.modal.visibleKey,
      system: state.system,
      ruleEngine: state.ruleEngine,
      activeBusinessParcel: state.ruleEngine.activeBusinessParcel,
      gapAnalysis: state.gapAnalysis,
    };
  });
  const { activeBusinessParcel } = state;

  const handleCloseDryRunTab = (dryRunId) => {
    const dryRunReports = [...state.ruleEngine.dryRunReports];
    const removeList = remove(dryRunReports, function (item) {
      return item.dryRunId === dryRunId;
    });
    if (removeList.length) {
      dispatch(receiveDryRunReport(dryRunReports));
      dispatch(pushLocation('/rule-engine/dry-run'));
    }
  };

  const navigationTo = (path) => {
    const search = state.system.urlQueryParams;
    dispatch(pushLocation(path + '?' + search));
  };

  const { pathname } = location;
  const dryRunReports = state.ruleEngine.dryRunReports;
  const dryRunTabs = dryRunReports.map((item) => {
    const { businessParcelId, ruleId, dryRunId, dryRunInfo } = item;
    const url = toUrl({
      path: '/dry-run/:businessParcelId/:ruleId/:dryRunId',
      params: {
        businessParcelId,
        ruleId,
        dryRunId,
      },
    });
    const title = getNameByDryRunInfo(dryRunInfo);
    return {
      id: item.dryRunId,
      label: title,
      url: url,
      selected: pathname.indexOf(url) > -1,
      onClick: () => {
        dispatch(pushLocation(url));
      },
      onClose: () => {
        handleCloseDryRunTab(item.dryRunId);
      },
    };
  });

  const ruleCompareTabs = state?.ruleEngine?.fields?.map((item) => {
    const { uuid } = item;
    const url = toUrl({
      path: `/rule-compare`,
      query: {
        uuid,
      },
    });
    const title = 'Rule Compare';
    return {
      id: item.uuid,
      label: title,
      url: url,
      selected: pathname.indexOf('/rule-compare') > -1,
      onClick: () => {
        dispatch(pushLocation(url));
      },
      onClose: () => {
        handleCloseRuleCompareTab(uuid);
      },
    };
  });

  const handleCloseRuleCompareTab = (uuid) => {
    const removeList = remove(state?.ruleEngine?.fields, function (item) {
      return item.uuid === uuid;
    });
    state.ruleEngine.fields = [];
    if (!state?.ruleEngine?.fields.length) {
      navigationTo('/rule-engine/mapping-rules');
    }
  };

  const dryRunCompareTabs = state?.ruleEngine?.dryRunCompareLists?.map(
    (item) => {
      const { businessParcelId, ruleId1, dryRunId1, ruleId2, dryRunId2 } = item;

      const url = toUrl({
        path: `/dry-run-compare`,
        query: {
          businessParcelId,
          ruleId1,
          dryRunId1,
          ruleId2,
          dryRunId2,
        },
      });
      const title = 'Dry Run Compare';
      return {
        id: item.dryRunId,
        label: title,
        url: url,
        selected: pathname.indexOf('/dry-run-compare') > -1,
        onClick: () => {
          dispatch(pushLocation(url));
        },
        onClose: () => {
          handleCloseDryRunCompareTab(dryRunId1);
        },
      };
    },
  );

  const handleCloseDryRunCompareTab = (dryRunId) => {
    const removeList = remove(
      state?.ruleEngine?.dryRunCompareLists,
      function (item) {
        return item.dryRunId === dryRunId;
      },
    );
    state.ruleEngine.dryRunCompareLists = [];
    if (!state?.ruleEngine?.dryRunCompareLists.length) {
      navigationTo('/rule-engine/mapping-rules');
    }
  };

  const gapAnalysisReportTab = state?.gapAnalysis?.gapAnalysisReports?.map(
    (item) => {
      const url = toUrl({
        path: `/gap-analysis-report`,
      });
      const label = 'Gap Analysis';
      return {
        id: label,
        url: url,
        label: label,
        selected: pathname.indexOf('/gap-analysis-report') > -1,
        onClick: () => {
          dispatch(pushLocation(url));
        },
        onClose: () => {
          handleCloseGapAnalysisReportTab(activeBusinessParcel.uuid);
        },
      };
    },
  );

  const handleCloseGapAnalysisReportTab = (GapAnalysisId) => {
    const dryRunReports = [...state.gapAnalysis.gapAnalysisReports];
    const removeList = remove(dryRunReports, function (item) {
      return item === GapAnalysisId;
    });
    if (removeList.length) {
      dispatch(updateGapAnalysisReports([]));
      dispatch(pushLocation('/rule-engine/dry-run'));
    }
    dispatch(resetGapAnalysisState());
  };

  const payloads = [
    {
      id: 'rule-engine',
      label: 'Rule Engine',
      url: '/rule-engine/mapping-rules',
      selected: pathname.indexOf('/rule-engine') > -1,
      onClick: () => {
        navigationTo('/rule-engine/mapping-rules');
      },
    },
    {
      id: 'version-control',
      label: 'Version Control',
      url: '/version-control',
      selected: pathname.indexOf('/version-control') > -1,
      onClick: () => {
        navigationTo('/version-control');
      },
    },
    ...gapAnalysisReportTab,
    ...ruleCompareTabs,
    ...dryRunCompareTabs,
    ...dryRunTabs,
  ];

  payloads.unshift({
    id: 'coverage-report',
    label: 'Coverage',
    url: '/coverage-report',
    selected: pathname.indexOf('/coverage-report') > -1,
    onClick: () => {
      navigationTo('/coverage-report');
    },
  });

  return <Tabs theme={THEME_LIGHT} payload={payloads} />;
};

export default NavigationBar;
