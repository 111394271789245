import './public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import NoAccess from './components/NoAccess';
import { startSentryMonitor, stopSentryMonitor } from '@src/sentry';

function render(props) {
  const { container } = props;
  window.__QIANKUN_CONTAINER__ = container ? container : window;
  let app = <App />;
  if (window.__POWERED_BY_QIANKUN__) {
    const hasMdsAccess = props.nav?.application.find(
      (item) => item.name === 'Metadata Service',
    );
    console.log('hasMdsAccess: ', hasMdsAccess);
    if (!hasMdsAccess) {
      app = <NoAccess />;
    }
  }
  ReactDOM.render(
    app,
    container
      ? container.querySelector('#root')
      : document.querySelector('#root'),
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  startSentryMonitor();
  render({});
}

export async function bootstrap() {
  console.log('MDS app bootstraped');
}

export async function mount(props) {
  startSentryMonitor();
  console.log('MDS app mounted, props from main framework', props);
  render(props);
}

export async function unmount(props) {
  stopSentryMonitor();
  const { container } = props;
  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector('#root')
      : document.querySelector('#root'),
  );
}
